import React from 'react'
import { Button, Heading } from 'react-bulma-components'
import GlobalContext from '../../../../contexts/GlobalContext'
import Hr from '../../../utils/Hr'

/**
 * Onglet "Langues" dans les paramètres utilisateurs.
 * Permet de changer la langue de l'application.
 */
export default class Languages extends React.Component {
	static contextType = GlobalContext

	constructor(props) {
		super(props)
		this.availableLanguages = [
			{
				value: 'fr',
				name: 'Français',
			},
			{
				value: 'en',
				name: 'English',
			},
		]
	}

	render() {
		const { lang, changeLang, translate } = this.context

		return (
			<>
				<Heading size={6}>
					{translate('modals.specific.user_preferences.current_lang')}: {this.availableLanguages.find(language => language.value === lang).name}
				</Heading>
				<Hr />
				<Heading subtitle size={6}>
					{this.availableLanguages.length} {translate('modals.specific.user_preferences.available_lang')}
				</Heading>
				<div className="buttons">
					{this.availableLanguages.map(({ name, value }, key) => (
						<Button disabled={lang === value} key={key} color={'info'} onClick={() => lang !== value && changeLang(value)}>
							{name}
						</Button>
					))}
				</div>
			</>
		)
	}
}
