import { Navbar } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import React from 'react'
import GlobalContext from '../../../contexts/GlobalContext'

/**
 * Dropdown de l'utilisateur dans la navbar, quand la souris survole le rond avec les initiales de l'utilsiateur.
 */
export default class UserDropdown extends React.Component {
	static contextType = GlobalContext

	render() {
		const { isLightTheme, logout, showModal, user, translate } = this.context

		return (
			<Navbar.Item renderAs="div" dropdown hoverable>
				<Navbar.Link arrowless className={'is-uppercase'}>
					<p className="is-navbar-name">{user.firstname.charAt(0) + user.lastname.charAt(0)}</p>
				</Navbar.Link>
				<Navbar.Dropdown right backgroundColor={isLightTheme ? 'white' : 'black'}>
					<Navbar.Item textColor={isLightTheme ? 'black' : 'light'} onClick={() => showModal('userPreferences', true)}>
						<Icon icon={'fal fa-user-cog'} />
						<span className="is-text-icon">{translate('navbar.preferences')}</span>
					</Navbar.Item>
					<Navbar.Divider backgroundColor={isLightTheme ? 'light' : 'black-ter'} />
					<Navbar.Item textColor={'danger'} onClick={logout}>
						<Icon icon={'fal fa-sign-out'} />
						<span className={'is-text-icon'}>{translate('navbar.logout')}</span>
					</Navbar.Item>
				</Navbar.Dropdown>
			</Navbar.Item>
		)
	}
}
