const AG_GRID_LOCALE_FR = {
    // Set Filter
    selectAll: '(Tout sélectionner)',
    selectAllSearchResults: '(Sélectionner tous les résultats)',
    searchOoo: 'Rechercher...',
    blanks: '(Vide)',
    noMatches: 'Aucun résultat',

    // Number Filter & Text Filter
    filterOoo: 'Filtrer...',
    equals: 'Egal à',
    notEqual: 'Différent de',
    empty: 'Choisir un',

    // Number Filter
    lessThan: 'Plus petit que',
    greaterThan: 'Plus grand que',
    lessThanOrEqual: 'Inférieur ou égal',
    greaterThanOrEqual: 'Supérieur ou égal',
    inRange: 'Dans',
    inRangeStart: 'jusqu\'à',
    inRangeEnd: 'de',

    // Text Filter
    contains: 'Contient',
    notContains: 'Ne contient pas',
    startsWith: 'Commence par',
    endsWith: 'Fini par',

    // Date Filter
    dateFormatOoo: 'aaaa-mm-jj',

    // Filter Conditions
    andCondition: 'ET',
    orCondition: 'OU',

    // Filter Buttons
    applyFilter: 'Valider',
    resetFilter: 'Réinitialiser',
    clearFilter: 'Nettoyer',
    cancelFilter: 'Annuler',

    // Filter Titles
    textFilter: 'Filtre de texte',
    numberFilter: 'Filtre de nombre',
    dateFilter: 'Filtre de date',
    setFilter: 'Définir un filtre',

    // Side Bar
    columns: 'Colonnes',
    filters: 'Filtres',

    // columns tool panel
    pivotMode: 'Mode pivot',
    groups: 'Groupes',
    rowGroupColumnsEmptyMessage: 'Glissez ici pour définir les groupes',
    values: 'Valeurs',
    valueColumnsEmptyMessage: 'Glisser ici pour aggréger',
    pivots: 'Nom des colonnes',
    pivotColumnsEmptyMessage: 'Glisser ici pour définir le nom de colonne',

    // Header of the Default Group Column
    group: 'Groupe',

    // Other
    loadingOoo: 'Chargement...',
    noRowsToShow: 'Aucun établissement sélectionné',
    enabled: 'Activé',
    rows: 'utilisateurs',

    // Menu
    pinColumn: 'Attacher la colonne',
    pinLeft: 'Attacher à gauche',
    pinRight: 'Attacher à droite',
    noPin: 'Détacher',
    valueAggregation: 'Value Aggregation',
    autosizeThiscolumn: 'Ajuster cette colonne',
    autosizeAllColumns: 'Ajuster toutes les colonnes',
    groupBy: 'Regrouper par',
    ungroupBy: 'Dégrouper par',
    resetColumns: 'Réinitialiser les colonnes',
    expandAll: 'Tout étendre',
    collapseAll: 'Tout fermer',
    copy: 'Copier',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Copier avec les entêtes',
    paste: 'Coller',
    ctrlV: 'Ctrl+V',
    export: 'Exporter',
    csvExport: 'Export CSV',
    excelExport: 'Export Excel (.xlsx)',
    excelXmlExport: 'Export Excel (.xml)',

    sum: 'Somme',
    min: 'Min',
    max: 'Max',
    none: 'Aucun',
    count: 'Compteur',
    avg: 'Moyenne',
    filteredRows: 'Filtré',
    selectedRows: 'Sélectionné',
    totalRows: 'Total utilisateurs',
    totalAndFilteredRows: 'Utilisateurs',
    more: 'Plus',
    to: 'Pour',
    of: 'sur',
    page: 'Page',
    nextPage: 'Page suivante',
    lastPage: 'Dernière page',
    firstPage: 'Première page',
    previousPage: 'Page précédente',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
    pivotChart: 'Pivot Chart',
    chartRange: 'Chart Range',

    columnChart: 'Colonne',
    groupedColumn: 'Groupé',
    stackedColumn: 'Stacked',
    normalizedColumn: '100% Stacked',

    barChart: 'Bar',
    groupedBar: 'Grouped',
    stackedBar: 'Stacked',
    normalizedBar: '100% Stacked',

    pieChart: 'Camembert',
    pie: 'Camembert',
    doughnut: 'Doghnut',

    line: 'Ligne',

    xyChart: 'X Y (Scatter)',
    scatter: 'Scatter',
    bubble: 'Bulle',

    areaChart: 'Area',
    area: 'Surface',
    stackedArea: 'Stacked',
    normalizedArea: '100% Stacked',

    histogramChart: 'Histogramme',

    // Charts
    pivotChartTitle: 'Pivot Chart',
    rangeChartTitle: 'Range Chart',
    settings: 'Paramètres',
    data: 'Data',
    format: 'Format',
    categories: 'Categories',
    defaultCategory: '(None)',
    series: 'Series',
    xyValues: 'X Y Values',
    paired: 'Paired Mode',
    axis: 'Axis',
    navigator: 'Navigator',
    color: 'Color',
    thickness: 'Thickness',
    xType: 'X Type',
    automatic: 'Automatic',
    category: 'Category',
    number: 'Number',
    time: 'Time',
    xRotation: 'X Rotation',
    yRotation: 'Y Rotation',
    ticks: 'Ticks',
    width: 'Width',
    height: 'Height',
    length: 'Length',
    padding: 'Padding',
    spacing: 'Spacing',
    chart: 'Chart',
    title: 'Title',
    titlePlaceholder: 'Chart title - double click to edit',
    background: 'Background',
    font: 'Font',
    top: 'Top',
    right: 'Right',
    bottom: 'Bottom',
    left: 'Left',
    labels: 'Labels',
    size: 'Size',
    minSize: 'Minimum Size',
    maxSize: 'Maximum Size',
    legend: 'Legend',
    position: 'Position',
    markerSize: 'Marker Size',
    markerStroke: 'Marker Stroke',
    markerPadding: 'Marker Padding',
    itemSpacing: 'Item Spacing',
    itemPaddingX: 'Item Padding X',
    itemPaddingY: 'Item Padding Y',
    layoutHorizontalSpacing: 'Horizontal Spacing',
    layoutVerticalSpacing: 'Vertical Spacing',
    strokeWidth: 'Stroke Width',
    offset: 'Offset',
    offsets: 'Offsets',
    tooltips: 'Tooltips',
    callout: 'Callout',
    markers: 'Markers',
    shadow: 'Shadow',
    blur: 'Blur',
    xOffset: 'X Offset',
    yOffset: 'Y Offset',
    lineWidth: 'Line Width',
    normal: 'Normal',
    bold: 'Bold',
    italic: 'Italic',
    boldItalic: 'Bold Italic',
    predefined: 'Predefined',
    fillOpacity: 'Fill Opacity',
    strokeOpacity: 'Line Opacity',
    histogramBinCount: 'Bin count',
    columnGroup: 'Column',
    barGroup: 'Bar',
    pieGroup: 'Pie',
    lineGroup: 'Line',
    scatterGroup: 'X Y (Scatter)',
    areaGroup: 'Area',
    histogramGroup: 'Histogram',
    groupedColumnTooltip: 'Grouped',
    stackedColumnTooltip: 'Stacked',
    normalizedColumnTooltip: '100% Stacked',
    groupedBarTooltip: 'Grouped',
    stackedBarTooltip: 'Stacked',
    normalizedBarTooltip: '100% Stacked',
    pieTooltip: 'Pie',
    doughnutTooltip: 'Doughnut',
    lineTooltip: 'Line',
    groupedAreaTooltip: 'Area',
    stackedAreaTooltip: 'Stacked',
    normalizedAreaTooltip: '100% Stacked',
    scatterTooltip: 'Scatter',
    bubbleTooltip: 'Bubble',
    histogramTooltip: 'Histogram',
    noDataToChart: 'No data available to be charted.',
    pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.'
}

export default AG_GRID_LOCALE_FR;