import React from 'react'
import { Modal, Heading, Columns, Menu, Button } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import GlobalContext from '../../../contexts/GlobalContext'
import Hr from '../../utils/Hr'
import Languages from './user_preferences/Languages'
import Appearance from './user_preferences/Appearance'
import GridSettings from './user_preferences/GridSettings'
import AccountSettings from './user_preferences/AccountSettings'

export default class ImportModal extends React.Component {
	static contextType = GlobalContext

	constructor(props, context) {
		super(props)
		this.name = 'userPreferences'

		const { translate } = context

		this.state = {
			view: 'appearance',
		}

		this.parameters = {
			[translate('modals.specific.user_preferences.general')]: [
				{
					key: 'account',
					icon: 'fal fa-user-cog',
					name: translate('modals.specific.user_preferences.my_account'),
				},
				{
					key: 'appearance',
					icon: 'fal fa-lightbulb-on',
					name: translate('modals.specific.user_preferences.appearance'),
				},
				{
					key: 'table',
					icon: 'fal fa-table',
					name: translate('modals.specific.user_preferences.grid'),
				},
				{
					key: 'languages',
					icon: 'fal fa-language',
					name: translate('modals.specific.user_preferences.language'),
				},
			],
			[translate('modals.specific.user_preferences.legal')]: [
				{
					key: 'cgu',
					icon: 'fal fa-balance-scale-left',
					name: translate('modals.specific.user_preferences.legal'),
				},
			],
		}
	}

	render() {
		const views = {
			account: <AccountSettings />,
			appearance: <Appearance />,
			table: <GridSettings />,
			languages: <Languages />,
			cgu: 'cgu',
		}

		const { translate } = this.context

		return (
			<Modal className={`is-large ${!this.context.isLightTheme && 'is-dark'}`} show={this.props.show} showClose onClose={() => this.props.onClose(this.name)} closeOnBlur>
				<Modal.Card className={'fade-in-bottom'}>
					<Modal.Card.Body>
						<header className="is-flex" style={{ justifyContent: 'space-between' }}>
							<Heading size={5}>{translate('modals.specific.user_preferences.title')}</Heading>
							<Button color={'light'} onClick={() => this.props.onClose(this.name)}>
								<Icon icon={'fal fa-times fa-2x'} />
							</Button>
						</header>
						<Hr />
						<Columns multiline>
							<Columns.Column size={'one-fifth'}>
								<Menu className={'params-menu'}>
									{Object.entries(this.parameters).map((category, key) => {
										return (
											<Menu.List title={category[0]} key={key}>
												{category[1].map((view, key) => (
													<Menu.List.Item key={key} active={this.state.view === view.key} onClick={() => this.setState({ view: view.key })}>
														<>
															<Icon icon={view.icon} />
															<span className={'is-text-icon'}>{view.name}</span>
														</>
													</Menu.List.Item>
												))}
											</Menu.List>
										)
									})}
								</Menu>
							</Columns.Column>
							<Columns.Column>{views[this.state.view]}</Columns.Column>
						</Columns>
						<div className="is-flex" style={{ justifyContent: 'right' }}>
							<Button color={'primary'} type={'submit'} onClick={() => this.props.onClose(this.name)}>
								{translate('global.close')}
							</Button>
						</div>
					</Modal.Card.Body>
				</Modal.Card>
			</Modal>
		)
	}
}
