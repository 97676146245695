import React from 'react'
import Icon from '../../utils/Icon'
import GlobalContext from '../../../contexts/GlobalContext'
import { Loader } from 'react-bulma-components'
import Queries from '../../../tools/Query'

export default class DropdownBulk extends React.Component {
	static contextType = GlobalContext

	bulkUpdate = (event, action) => {
		event.preventDefault()

		const { showModal } = this.context

		showModal('standardEdit', true, {
			bulkAction: action,
		})
	}

	parseErrorMsgPNE = msg => {
		return msg
			.map(err => {
				if (err.message) {
					return `<strong>${err.username}</strong>: ${err.message}<br/>`
				}
				if (err.keys) {
					return `<strong>${err.username}</strong>: ${err.keys
						.filter(key => key.error !== undefined)
						.map(key => `${key.key} : ${key.error}`)
						.join(', ')}<br/>`
				}

				return ''
			})
			.join(',')
	}

	activatePNE = async event => {
		event.preventDefault()

		const { selected } = this.props
		const { addToast, updateToast, showModal, translate } = this.context

		const isTeacherOrEducadhoc = () => {
			let teacherOrEducadhoc = true
			selected.map(user => {
				if (user.Profil !== 'Enseignant' || !user['Educadhoc-Code']) {
					teacherOrEducadhoc = false
				}
				return user
			})

			return teacherOrEducadhoc
		}

		if (!isTeacherOrEducadhoc()) {
			return addToast(translate('table.bulks.pne_key.not_teacher_educadhoc'), { appearance: 'warning' })
		}

		const users = await Queries.getAppsPassword(
			this.context,
			selected.map(user => user['ID utilisateur']),
			['KNE']
		)

		const activate = (toastId, usersLogin) => {
			const params = selected.map(user => {
				return {
					username: users[user['ID utilisateur']].KNE.login,
					password: users[user['ID utilisateur']].KNE.password,
					keys: user['Educadhoc-Code']
						.split(',')
						.map(code => (code.split(':').length === '2' ? code.split(':')[1].replace(/ /g, '') : code))
						.join(','),
				}
			})

			Queries.activatePNE(this.context, params).then(res => {
				switch (res.status) {
					case 200:
						return updateToast(toastId, {
							content: translate('table.bulks.pne_key.success_msg'),
							autoDismiss: true,
							appearance: 'success',
						})
					case 207:
						return updateToast(toastId, {
							content: (
								<>
									{translate('global.errors.rejected')} <br />
									<a
										href={'/'}
										onClick={e => {
											e.preventDefault()
											showModal('error', true, { text: this.parseErrorMsgPNE(res.data) })
										}}
									>
										{translate('global.errors.see_report')}
									</a>
								</>
							),
							appearance: 'error',
							autoDismiss: true,
							autoDismissTimeout: 10000,
						})
					default:
						return updateToast(toastId, {
							content: translate('global.errors.occurred'),
							autoDismiss: true,
							appearance: 'error',
						})
				}
			})
		}

		addToast(
			<>
				{translate('table.bulks.pne_key.activating')}
				<br />
				<Loader />
			</>,
			{ appearance: 'info', autoDismiss: false },
			toastId => activate(toastId)
		)
	}

	adminMode = event => {
		event.preventDefault()

		const { selected } = this.props
		const { addToast, refresh, translate } = this.context

		const ids = []
		let val = null
		let notSameAdminMode = false

		selected.forEach(user => {
			if (!val) {
				val = user['Mode admin']
			}
			if (user['Mode admin'] !== val) {
				notSameAdminMode = true
			}
			ids.push(user['ID utilisateur'])
		})

		val = val === 1 ? '0' : '1'

		if (notSameAdminMode) {
			return addToast(translate('table.bulks.admin.not_same_value'), { appearance: 'warning' })
		}

		Queries.updateBulk(this.context, ids, 'ModeAdmin', val, 'std')
			.then(res => {
				addToast(
					translate('table.bulks.admin.success', {
						users: ids.length,
						changed: val === '0' ? 'désafectés' : 'affectés',
					}),
					{ appearance: 'success' }
				)
				refresh()
			})
			.catch(err => addToast(translate('global.errors.occurred'), { appearance: 'error' }))
	}

	render() {
		const { shouldMount, translate } = this.context

		const onlyStudentsAndTeachers = () => {
			if (!this.props.selected) {
				return false
			}
			return this.props.selected.map(user => user.Profil).every(profil => ['Eleve', 'Enseignant'].includes(profil))
		}

		return (
			<div className="dropdown is-hoverable" style={{ marginRight: '0.5rem' }}>
				<div className="dropdown-trigger">
					<button className="button is-info" disabled={!this.props.enabled} aria-haspopup="true" aria-controls="dropdown-menu">
						<Icon icon={'fad fa-user-edit'} />
						<span className={'text-icon'}>{translate('global.update_alt')}</span>
						<Icon icon={'fal fa-angle-down'} />
					</button>
				</div>
				<div className="dropdown-menu" id="dropdown-menu" role="menu">
					<div className={`dropdown-content fade-in-fwd-faster ${!this.context.isLightTheme ? 'has-background-black' : ''}`} hidden={!this.props.enabled}>
						{shouldMount('buttonBulkEdit_bulkCommentaire', 'Lecture') && (
							<a href="/" className="dropdown-item" onClick={event => this.bulkUpdate(event, 'Commentaire')}>
								{translate('global.comment')}
							</a>
						)}

						{shouldMount('buttonBulkEdit_bulkMatiere', 'Lecture') && (
							<a href="/" className="dropdown-item" onClick={event => this.bulkUpdate(event, 'Matiere')}>
								Matière
							</a>
						)}

						{shouldMount('buttonBulkEdit_bulkClasse', 'Lecture') && (
							<a href="/" className="dropdown-item" onClick={event => this.bulkUpdate(event, 'Classe')}>
								{translate('global.class')}
							</a>
						)}

						{shouldMount('buttonBulkEdit_bulkNiveau', 'Lecture') && (
							<a href="/" className="dropdown-item" onClick={event => this.bulkUpdate(event, 'Niveau')}>
								{translate('global.level')}
							</a>
						)}

						{shouldMount('buttonBulkEdit_bulkProfil', 'Lecture') && (
							<a
								href="/"
								className={`dropdown-item ${!onlyStudentsAndTeachers() && 'is-disabled'}`}
								onClick={event => {
									event.preventDefault()
									if (onlyStudentsAndTeachers()) {
										this.bulkUpdate(event, 'Profil')
									}
								}}
								title={!onlyStudentsAndTeachers() && translate('table.bulks.only_students_and_teachers')}
							>
								{translate('global.profile')}
							</a>
						)}

						{shouldMount('buttonBulkEdit_bulkOption', 'Lecture') && (
							<a
								href="/"
								className="dropdown-item"
								onClick={event => {
									event.preventDefault()
									this.context.showModal('bulkOptions', true)
								}}
							>
								{translate('global.options')}
							</a>
						)}

						{shouldMount('buttonBulkEdit_bulkAppli', 'Lecture') && (
							<a
								href="/"
								className="dropdown-item"
								onClick={event => {
									event.preventDefault()
									this.context.showModal('bulkApps', true)
								}}
							>
								{translate('global.applications')}
							</a>
						)}

						{shouldMount('buttonBulkEdit_bulkFiles', 'Lecture') && (
							<a
								href="/"
								className="dropdown-item"
								onClick={event => {
									event.preventDefault()
									this.context.showModal('bulkDocs', true)
								}}
							>
								Documents
							</a>
						)}

						<hr className="dropdown-divider" />

						{shouldMount('buttonBulkEdit_bulkPNE', 'Lecture') && (
							<a href="/" className="dropdown-item" onClick={this.activatePNE}>
								{translate('table.bulks.pne')}
							</a>
						)}

						{shouldMount('buttonBulkEdit_bulkAdmin', 'Lecture') && (
							<a href="/" className="dropdown-item" onClick={this.adminMode}>
								{translate('table.bulks.adm')}
							</a>
						)}

						<hr className="dropdown-divider" />

						{shouldMount('buttonBulkEdit_bulkNotification', 'Lecture') && (
							<a
								href="/"
								className="dropdown-item"
								onClick={event => {
									event.preventDefault()
									this.context.showModal('sendNotification', true)
								}}
							>
								{translate('table.bulks.send_notification')}
							</a>
						)}

						{shouldMount('buttonBulkEdit_bulkNotification', 'Lecture') && (
							<a
								href="/"
								className="dropdown-item"
								onClick={event => {
									event.preventDefault()
									this.context.showModal('bulkMails', true)
								}}
							>
								Envoyer un Mail
							</a>
						)}
					</div>
				</div>
			</div>
		)
	}
}
