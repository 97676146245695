import React, { useContext, useState } from 'react'
import { Modal, Heading, Form, Button, Table } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import GlobalContext from '../../../contexts/GlobalContext'
import Hr from '../../utils/Hr'
import Queries from '../../../tools/Query'

export default function BulkMails({ show, onClose }) {
	const context = useContext(GlobalContext)
	const { isLightTheme, translate, addToast } = context
	const name = 'bulkMails'
	const students = getSelectedAfterFilter(context)
	const headers = ['Mail', 'Nom', 'Prénom', 'Login', 'Niveau', 'Classe']
	const selected = selectedAllUser()

	const [loading, setLoading] = useState()
	const [template, setTemplate] = useState(165)

	function getSelectedAfterFilter(context) {
		const users = []
		context.grid.forEachNodeAfterFilter(node => {
			if (node.isSelected()) {
				users.push(node.data)
			}
		})
		return users
	}

	function selectedAllUser() {
		return students.filter(user => user['Mail']).map(user => user['ID utilisateur'])
	}

	async function submit(formEvent) {
		formEvent.preventDefault()
		setLoading(true)

		const usersData = students.filter(student => selected.includes(student['ID utilisateur'])).map(user => ({ email: user['Mail'], idUser: user['ID utilisateur'] }))
		Queries.sendMails(context, usersData)
			.then(res => {
				setLoading(false)
				addToast(`Les accès à Tabuléo ont été envoyés à ${usersData.length} ${translate('global.users')}`, { appearance: 'success' })
			})
			.catch(err => {
				setLoading(false)
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
			})
		onClose(name)
	}

	return (
		<Modal className={`is-large ${!isLightTheme && 'is-dark'}`} show={show} showClose onClose={() => onClose(name)} closeOnBlur>
			<Modal.Card className={'fade-in-bottom'}>
				<Modal.Card.Body>
					<form onSubmit={submit}>
						<header className="is-flex" style={{ justifyContent: 'space-between' }}>
							<div>
								<Heading size={5}>Envoi de Mail</Heading>
							</div>
							<Hr />
							<Button color={'light'} type={'reset'} onClick={() => onClose(name)}>
								<Icon icon={'fal fa-times fa-2x'} />
							</Button>
						</header>

						<Form.Field>
							<Form.Control className={'is-expanded'}>
								<div className="select is-fullwidth">
									<select name="template" id="" defaultValue={template} onChange={e => setTemplate(e.value)}>
										<option value={165}>Envoyer Accès Tabuleo Connect</option>
									</select>
								</div>
							</Form.Control>
						</Form.Field>

						<Hr />
						<Heading subtitle marginless size={5} textColor={'primary'}>
							{selected.length} {translate('global.users')} avec un email
						</Heading>

						<div className="table-container">
							<Table striped className={`is-narrow ${!isLightTheme && 'is-dark'}`} marginless>
								<thead>
									<tr>
										{headers.map((head, key) => (
											<th key={key}>{head}</th>
										))}
									</tr>
								</thead>
								<tbody>
									{students.map(
										(student, key) =>
											selected.includes(student['ID utilisateur']) && (
												<tr key={key}>
													{headers.map((head, key) => (
														<td key={key}>{student[head]}</td>
													))}
												</tr>
											)
									)}
								</tbody>
							</Table>
						</div>
						<br />
						<div className="is-flex is-fixed" style={{ justifyContent: 'space-between' }}>
							<Button color={'danger'} type={'reset'} onClick={() => onClose(name)}>
								{translate('global.cancel')}
							</Button>
							<Button color={'primary'} type={'submit'} loading={loading} disabled={selected.length < 1}>
								{translate('global.confirm')}
							</Button>
						</div>
					</form>
				</Modal.Card.Body>
			</Modal.Card>
		</Modal>
	)
}
