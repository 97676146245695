import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Form, Heading, Section, Tag } from 'react-bulma-components'
import GlobalContext from '../contexts/GlobalContext'
import Hr from './utils/Hr'
import { AgGridReact } from 'ag-grid-react'
import AG_GRID_LOCALE_FR from '../config/ag-grid-locale-fr'
import AG_GRID_LOCALE_EN from '../config/ag-grid-locale-en'
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.min.css'
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.min.css'
import SchoolMonitoring, { formatSteps, resetSchoolWorkflow } from './SchoolMonitoring'
import _ from 'lodash'
import Icon from './utils/Icon'
import { io } from 'socket.io-client'

/**
 * Suivi global de chaque établissements, indiquant la situation pour chaque étape.
 * Il s'agit seulement de lecture, il n'y a pas de modification possible sur cette vue.
 * Cependant, un utilisateur peut cliquer sur la cellule avec le nom de l'établissement pour accéder à
 * la vue "Suivi" de cet établissement.
 * @return {JSX.Element}
 * @constructor
 */
function GlobalMonitoring() {
	/**
	 * Variable de contexte.
	 * @type {unknown}
	 */
	const context = useContext(GlobalContext)
	const {
		isLightTheme,
		translate,
		lang,
		addToast,
		removeGridFilter,
		workflowFilters,
		resetGridFilter,
		setGridFilter,
		showModal,
		schools,
		user
	} = context

	const GLOBAL_MONITORING_VALUES = {
		1: '🟢',
		0: '🟡',
		2: '',
		3: '🔘',
		5: '⚫'
	}

	const [socket, setSocket] = useState()

	const [selectedUAIs, setSelectedUAIs] = useState([])
	const [grid, setGrid] = useState(null)
	const [data, setData] = useState([])
	const [columns, setColumns] = useState([])
	const [displayedSchool, setDisplayedSchool] = useState(null)
	const [loadingMessage, setLoadingMessage] = useState('Récupération des devis Dolibarr...')

	useEffect(() => {
		const connectionSocket = io(process.env.REACT_APP_URLSERVER, { transports: ['websocket'] })
		const uais = schools.filter((s) => s.Effectif > 0).map((s) => s.UAI)
		const proposalsMap = {}
		let loadedEstabs = 0

		connectionSocket.on('connect', () => {
			setSocket(connectionSocket)

			connectionSocket.emit('workflow.prepare', { uais })
		})

		connectionSocket.on('workflow.ready', async () => {
			setLoadingMessage(`Chargement des établissements (0/${uais.length})...`)

			for (const uai of uais) {
				connectionSocket.emit('workflow.uai', { uai, userId: user.id })
			}
		})

		connectionSocket.on('workflow.uai.data', ({ data, uai, statusList }) => {
			const formattedSteps = formatSteps(data, statusList)
			const school = schools.find(({ UAI }) => UAI === uai)

			loadedEstabs++
			setLoadingMessage(`Chargement des établissements (${loadedEstabs}/${uais.length})...`)

			if (loadedEstabs === uais.length) {
				setLoadingMessage(null)
				updateDolibarrProposals(proposalsMap)
			}

			setData((data) => {
				const newData = [...data]
				const rowToAdd = transformStepsToGrid(`${school.UAI} - ${school.Nom} (${school.Extension})`, formattedSteps)

				const existingIndex = newData.findIndex((row) => row.estab === `${school.UAI} - ${school.Nom} (${school.Extension})`)

				if (existingIndex !== -1) {
					newData.splice(existingIndex, 1, rowToAdd)
				} else {
					newData.push(rowToAdd)
				}

				newData.sort((a, b) => a.estab.localeCompare(b.estab))

				return newData
			})

			connectionSocket.emit('workflow.uai.propal', { uai })
		})

		connectionSocket.on('workflow.uai.propal.status', ({ uai, status }) => {
			proposalsMap[uai] = status
		})

		return () => {
			connectionSocket.disconnect()
		}
	}, [schools, user.id])

	useEffect(() => {
		grid?.columnApi?.autoSizeAllColumns()
	}, [data, grid])

	const updateDolibarrProposals = useCallback((proposalsMap) => {
		setData((prevData) => {
			const newData = prevData.map((row) => {
				const uai = row.estab.split(' ')[0]
				return {
					...row,
					'Devis signé': GLOBAL_MONITORING_VALUES[proposalsMap[uai] ?? '2']
				}
			})
			return newData
		})
	}, [GLOBAL_MONITORING_VALUES])

	function transformStepsToGrid(school, steps) {
		const transformedSteps = Object.fromEntries(steps.map(({
																   name,
																   state
															   }) => [name, GLOBAL_MONITORING_VALUES[state]]))

		const latestUpdatedAt = steps.reduce((acc, { updated_at }) => {
			if (updated_at > acc) {
				return updated_at
			}

			return acc
		}).updated_at
		const latestUpdatedBy = steps.find(({ updated_at }) => updated_at === latestUpdatedAt).updated_by

		return {
			updated_at: latestUpdatedAt,
			by: latestUpdatedBy,
			estab: school,
			...transformedSteps,
			'Devis signé': GLOBAL_MONITORING_VALUES['3']
		}
	}

	useEffect(() => {
		function closeOnEscapeKey(event) {
			if (event.code === 'Escape') {
				setDisplayedSchool(null)
			}
		}

		window.addEventListener('keyup', closeOnEscapeKey)

		return () => {
			window.removeEventListener('keyup', closeOnEscapeKey)
		}
	}, [])

	useEffect(() => {
		if (!grid) {
			return
		}

		if (!_.isEqual(grid.api.getFilterModel(), workflowFilters)) {
			grid.api.setFilterModel(workflowFilters)
		}

		generateColumnsDefs()
	}, [grid, workflowFilters])

	function generateColumnsDefs() {
		const columnsDefs = [
			{
				field: 'updated_at',
				headerName: translate('global_monitoring.last_update'),
				filter: 'agDateColumnFilter',
				hide: true,
				comparator: (valueA, valueB) => valueA - valueB,
				valueFormatter: ({ value }) => (value ? value.toLocaleString() : 'Date invalide')
			},
			{
				field: 'by',
				headerName: translate('global.by'),
				filter: 'agSetColumnFilter',
				hide: true
			},
			{
				field: 'estab',
				headerName: translate('global.school'),
				filter: 'agTextColumnFilter',
				pinned: 'left',
				headerCheckboxSelectionFilteredOnly: true,
				headerCheckboxSelection: true,
				cellClass: 'ag-login',
				cellRenderer: 'agGroupCellRenderer',
				cellRendererParams: {
					checkbox: true
				}
			},
			{
				field: 'Liste des manuels',
				headerName: 'Liste des manuels',
				filter: 'agSetColumnFilter'
			},
			{
				field: 'Devis envoyé',
				headerName: 'Devis envoyé',
				filter: 'agSetColumnFilter'
			},
			{
				field: 'Devis signé',
				headerName: 'Devis signé',
				filter: 'agSetColumnFilter'
			}, {
				field: 'Commandes fournisseurs',
				headerName: 'Commandes fournisseurs',
				filter: 'agSetColumnFilter'
			}, {
				field: 'Liste élèves reçue',
				headerName: 'Liste élèves reçue',
				filter: 'agSetColumnFilter'
			}, {
				field: 'Liste profs reçue',
				headerName: 'Liste profs reçue',
				filter: 'agSetColumnFilter'
			}, {
				field: 'Déploiement',
				headerName: 'Déploiement',
				filter: 'agSetColumnFilter'
			}, {
				field: 'Back-office élèves',
				headerName: 'Back-office élèves',
				filter: 'agSetColumnFilter'
			}, {
				field: 'Back-office profs',
				headerName: 'Back-office profs',
				filter: 'agSetColumnFilter'
			}, {
				field: 'PDF envoyé',
				headerName: 'PDF envoyé',
				filter: 'agSetColumnFilter'
			}, {
				field: 'Sources',
				headerName: 'Sources',
				filter: 'agSetColumnFilter'
			}, {
				field: 'Formation/Copil',
				headerName: 'Formation/Copil',
				filter: 'agSetColumnFilter'
			}, {
				field: 'Facturation',
				headerName: 'Facturation',
				filter: 'agSetColumnFilter'
			}
		]

		setColumns(columnsDefs)
	}

	/**
	 * Définitions par défaut des colonnes.
	 * On fait en sorte qu'il est impossible de les modifier.
	 * On peut : les trier, modifier leur largeur et filtrer.
	 * On donne également une classe qui va changer la couleur d'arrière plan d'une étape selon son état.
	 * @type {{filter: string, floatingFilter: boolean, resizable: boolean, editable: boolean, cellClass: (function(*): (string)), sortable: boolean}}
	 */
	const defaultColumnsDefs = {
		editable: false,
		sortable: true,
		resizable: true,
		floatingFilter: true,
		filter: 'agSetColumnFilter',
		cellClass: params => {
			switch (params.value) {
				case GLOBAL_MONITORING_VALUES['1']:
					return 'bg-green-400/25 hover:bg-green-500/25 text-center'
				case GLOBAL_MONITORING_VALUES['0']:
					return 'bg-yellow-400/25  hover:bg-yellow-600/25 text-center'
				case GLOBAL_MONITORING_VALUES['5']:
					return 'bg-gray-400/25 hover:bg-gray-500/25 text-center'
				case GLOBAL_MONITORING_VALUES['3']:
					return 'animate-pulse cursor-loading text-center'
				default:
					return 'hover:bg-blue-200'
			}
		}
	}

	/**
	 * A l'initialisation de la grille, nous définissons nos variables globales.
	 * @param api
	 * @param columnApi
	 */
	function prepareGrid({ api, columnApi }) {
		api.closeToolPanel()

		columnApi.applyColumnState({
			state: [
				{
					colId: 'estab',
					sort: 'asc'
				}
			],
			defaultState: { sort: null }
		})

		setGrid({ columnApi, api })
	}

	/**
	 * Charge l'établissement sur lequel l'utilisateur a cliqué, et change la vue sur le suivi de cet établissement.
	 * @param data
	 * @param field
	 */
	function fetchSchoolWorkflowAndDisplayPreview({ data, colDef: { field } }) {
		if (field === 'estab') {
			setDisplayedSchool(null)
			const [schoolUai] = data.estab.split(' ')

			setDisplayedSchool({ uai: schoolUai, fullName: data.estab })
		}
	}

	function resetEstabs() {
		showModal('confirmAction', true, {
			content: (
				<>
					Êtes-vous sûr de vouloir remettre à zéro les établissements
					suivants: <strong>{selectedUAIs.join()}</strong>
				</>
			),
			action: async () => {
				for (const uai of selectedUAIs) {
					try {
						await resetSchoolWorkflow(context, uai)
						addToast(`${uai} remis à zéro.`, { appearance: 'success' })
						socket.emit('workflow.uai', { uai, userId: user.id })
					} catch (e) {
						addToast(`${uai}: ${e.toString()}`, { appearance: 'error' })
					}
				}
			}
		})
	}

	return (
		<Section style={{ height: 'calc(100vh - 3.5rem)', overflowY: 'auto' }}>
			<Heading textColor={!isLightTheme && 'light'} className={'fade-in-right'}>
				{translate('global_monitoring.title')}
			</Heading>
			<Hr />

			{displayedSchool?.uai && (
				<Section className={`tab-container fade-in-fwd`} backgroundColor={isLightTheme ? '' : 'black'}
						 style={{ borderColor: 'transparent' }}>
					<header className="flex justify-between">
						<Heading size={6}>{displayedSchool.fullName}</Heading>
						<Button color={isLightTheme ? 'light' : 'black'} type={'reset'}
								onClick={() => setDisplayedSchool(null)}>
							<Icon icon={'fa-2x fal fa-times-circle'} />
						</Button>
					</header>
					<SchoolMonitoring uai={displayedSchool.uai} />
				</Section>
			)}

			<section className={'buttons'} style={{ flexWrap: 'initial' }}>
				<Button color={'danger'} disabled={selectedUAIs.length === 0} onClick={resetEstabs}>
					<Icon icon={'fad fa-exclamation-triangle'} />
					<span className="text-icon">{translate('global_monitoring.reset')}</span>
				</Button>
			</section>

			<p className="text-sm text-gray-500">{loadingMessage}</p>

			{/* Filtres */}
			{workflowFilters && Object.keys(workflowFilters).length > 0 && (
				<div className="field is-grouped is-grouped-multiline">
					<Form.Control>
						<Tag.Group className={'fade-in-right'}>
							<Tag
								remove
								renderAs="a"
								color={'danger'}
								onClick={() => {
									resetGridFilter('workflow')
								}}
							/>
						</Tag.Group>
					</Form.Control>

					{Object.keys(workflowFilters).map((filter, k) => (
						<Form.Control key={k}>
							<Tag.Group className="has-addons fade-in-right" style={{ animationDelay: `${k * 100}ms` }}>
								<Tag color={'info'}>{filter}</Tag>
								<Tag color={'dark'} remove renderAs="a"
									 onClick={() => removeGridFilter('workflow', filter, null, 'GlobalMonitoring.removeTag')} />
							</Tag.Group>
						</Form.Control>
					))}
				</div>
			)}

			<div className={'agrid-container'}>
				<section className={`ag-theme-balham${isLightTheme ? '' : '-dark'} agrid`} style={{ height: '95%' }}>
					<AgGridReact
						localeText={lang === 'fr' ? AG_GRID_LOCALE_FR : AG_GRID_LOCALE_EN}
						statusBar={{
							statusPanels: [
								{
									statusPanel: 'agTotalAndFilteredRowCountComponent',
									align: 'left'
								},
								{
									statusPanel: 'agFilteredRowCountComponent',
									align: 'left'
								},
								{ statusPanel: 'agSelectedRowCountComponent', align: 'left' }
							]
						}}
						defaultColDef={defaultColumnsDefs}
						columnDefs={columns}
						rowData={data}
						rowSelection="multiple"
						groupSelectsChildren
						suppressFieldDotNotation
						suppressRowClickSelection
						suppressAggFuncInHeader
						popupParent={document.body}
						sideBar
						debounceVerticalScrollbar
						applyColumnDefOrder
						animateRows
						onGridReady={prepareGrid}
						onSelectionChanged={e => setSelectedUAIs(e.api.getSelectedRows().map(({ estab }) => estab.slice(0, 8)))}
						onCellClicked={fetchSchoolWorkflowAndDisplayPreview}
						onFilterChanged={() => {
							const gridFilter = grid.api.getFilterModel()

							setGridFilter('workflow', gridFilter)
						}}
					/>
				</section>
			</div>
		</Section>
	)
}

export default GlobalMonitoring
