import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as ReactGA from 'react-ga'
import './config/i18n'

if (process.env.REACT_APP_GA) {
	ReactGA.initialize(process.env.REACT_APP_GA)
	ReactGA.pageview(window.location.pathname + window.location.search)
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
)