import FileIcons from '../../config/file_icons.json'
import { saveAs } from 'file-saver'

/**
 * Hiérarchie des droits:
 * Un gestionnaire à moins de droit qu'un admin étab qu'un admin.
 * @type {string[]}
 */
const hierarchy = ['Gestionnaire', 'Admin_etablissement', 'Administrateur']

function getFileUploadedByUserHierarchy({ uploadedBy }) {
	const split = uploadedBy.split('(')
	const profil = split[split.length - 1]
	return profil.slice(0, profil.length - 1)
}

const FileUtils = {
	/**
	 * Défini si le fichier passé en paramètre est un dossier.
	 * @param {object} file
	 * @return {boolean}
	 */
	isDirectory: file => file.type === '' && file.size === 0,
	/**
	 * Retourne l'extension du fichier, en prenant la dernière partie du nom après le pointt
	 * @param {string} filename
	 * @return {string}
	 */
	getExtension: filename => filename.split('.').pop().toLowerCase(),
	/**
	 * Retourne une icone correspondant au fichier selon son extension.
	 * @param {string} name
	 * @param {string} type
	 * @param {number} size
	 * @return {string|*}
	 */
	getIcons: ({ name, type, size }) => (FileUtils.isDirectory({ type, size }) ? 'fas fa-folder' : FileIcons[FileUtils.getExtension(name)] || 'fa-file'),
	/**
	 * Converti un nombres de bytes en lecture simplifié.
	 * @param {number} bytes
	 * @return {string}
	 */
	humanFileSize: bytes => {
		const thresh = 1000
		const decimals = 2

		if (Math.abs(bytes) < thresh) {
			return bytes + ' octets'
		}

		const units = ['Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo']
		let u = -1
		const r = 10 ** decimals

		do {
			bytes /= thresh
			++u
		} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

		return bytes.toFixed(decimals) + ' ' + units[u]
	},
	/**
	 * Autorise la modification/suppression d'un fichier si et uniquement si le rôle de l'utilisateur connecté
	 * est supérieur ou égal à celui qui a uploadé le document.
	 * Un Administrateur a tout les droits.
	 * Un Administrateur établissement peut si c'est lui, ou un gestionnaire qui a upload.
	 * Un Gestionnaire peut si c'est lui même qui a upload.
	 * @param user Utilisateur authentifié
	 * @param file Fichier demandé
	 * @type {function(*, *=): boolean}
	 */
	canEditFile: (user, file) => (file ? hierarchy.indexOf(user.profile) >= hierarchy.indexOf(getFileUploadedByUserHierarchy(file)) : false),
	saveErrorReport: error => {
		let report = "Rapport d'erreur:\n"
		Object.values(error).map(reason => (report += `- ${reason}\n`))
		saveAs(new Blob([report], { type: 'text/plain;charset=utf-8' }), "Rapport d'erreur.txt")
	},
}

/**
 * Différentes actions possibles.
 * PREVIEW: montre le contenu du fichier
 * AFFECT: affecte des documents à un ou plusieurs établissements
 * UPLOAD: envoie d'un fichier
 * EDIT: renomme un fichier
 * DOWNLOAD: télécharge un fichier pour l'utilisateur
 * DELETE: supprime un fichier sur le serveur
 * DETAILS: affiche les informations du fichier
 * MAN: affiche l'aide
 * @type {{DELETE: string, AFFECT: string, CREATE_DIR: string, UPLOAD: string, DETAILS: string, DOWNLOAD: string, EDIT: string, PREVIEW: string}}
 */
const CONTEXT_ACTIONS = {
	PREVIEW: 'preview',
	AFFECT: 'affect',
	UPLOAD: 'upload',
	CREATE_DIR: 'mkdir',
	EDIT: 'mv',
	DOWNLOAD: 'dl',
	DELETE: 'rm',
	DETAILS: 'cat',
	HELP: 'man',
}

export { CONTEXT_ACTIONS }
export default FileUtils
