import React, { ChangeEvent, useContext, useEffect, useReducer, useState } from 'react'
// @ts-ignore
import { Modal, Heading, Form, Button } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import GlobalContext from '../../../contexts/GlobalContext'
import Hr from '../../utils/Hr'
import Queries from '../../../tools/Query'
import axios from 'axios'

/**
 * Fenêtre de création d'un établissement à partir de son UAI.
 */

type AddSchoolProps = {
	show: any
	onClose: any
}

const AddSchoolModal = ({ show, onClose }: AddSchoolProps) => {
	type ReducerType = {
		uai: string
		name: string
		typeOfEstablishment: number
		sector: string
		idCity: string
		nameOfCity: string
		idCounty: string
		idCountry: string
		idAcademy: string
		address: string
	}

	type SchoolData = {
		cities: Array<{ idCity: string; city: string }>
		countries: Array<{ idCountry: string; country: string }>
		typeEstab: Array<{ idTypeEstab: number; typeEstab: string; orderTypeEstab: number }>
	}

	type ReducerActionType = {
		key: keyof ReducerType
		value: string | number
	}

	type EstabGouvType = { name: string; nameOfCity: string; sector: string; zipCode: string }

	const initialState: ReducerType = {
		uai: '',
		name: '',
		typeOfEstablishment: 1,
		address: '',
		idCounty: '999',
		sector: 'Privé',
		idCity: '0',
		idCountry: '',
		nameOfCity: '',
		idAcademy: '99',
	}

	function _reducer(state: ReducerType, { key, value }: ReducerActionType): ReducerType {
		return { ...state, [key]: value }
	}

	const [state, dispatch] = useReducer(_reducer, initialState)

	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [estabFind, setEstabFind] = useState<EstabGouvType | null>(null)
	const [isEtabExist, setIsEtabExist] = useState<boolean>(true)
	const [error, setError] = useState<string | null>(null)
	const [valueCity, setValueCity] = useState<boolean>(false)

	const [dataSchools, setDataSchools] = useState<SchoolData>({
		cities: [],
		countries: [],
		typeEstab: [],
	})

	const context = useContext(GlobalContext)

	async function findEstabGouv(uai: string) {
		if (uai.length > 7) {
			const schoolData = (await axios.get(`https://data.education.gouv.fr/api/explore/v2.1/catalog/datasets/fr-en-annuaire-education/records?where=identifiant_de_l_etablissement%3D%22${uai}%22&limit=20`)).data
			const fields = schoolData.results[0]

			if (fields) setEstabFind({ name: fields.nom_etablissement, nameOfCity: fields.nom_commune, sector: fields.statut_public_prive, zipCode: fields.code_postal })
			else setEstabFind(null)
		} else setEstabFind(null)
	}

	/**
	 * Envoie l'UAI en BDD pour l'ajouter à la liste des établissements existants, puis
	 * va recharger l'application.
	 * @param formEvent
	 */
	function addSchool(e: any) {
		e.preventDefault()

		setIsLoading(true)

		const { addToast, translate } = context

		Queries.create(context, state.uai, isEtabExist, state)
			.then(res => {
				addToast(translate('modals.specific.school.created'), { appearance: 'success' })
				addToast(translate('modals.specific.school.add_reload'), { appearance: 'warning' })
				setTimeout(() => {
					window.location.reload()
				}, 5000)
				onClose(state.name)
			})
			.catch(err => {
				setIsLoading(false)
				setError('Aucun établissement ne correspond à cet UAI.')
			})
	}

	useEffect(() => {
		setIsLoading(false)
		if (!isEtabExist)
			Queries.getDataSchools(context)
				.then(res => {
					// @ts-ignore
					setDataSchools(res)
					setIsLoading(false)
				})
				.catch(err => {
					setIsLoading(false)
					console.error(err)
				})
	}, [isEtabExist])

	const { translate, isLightTheme } = context

	function createCity() {
		return <option value={state.idCity}>&lt;Nouvelle ville&gt;</option>
	}

	// @ts-ignore
	function changeOption({ target }) {
		const { value } = target
		if (value === 'newCity') {
			setValueCity(true)
			dispatch({
				key: 'idCity',
				value: '0',
			})
		} else {
			setValueCity(false)
			dispatch({
				key: 'idCity',
				value: value,
			})
		}
	}

	const validateUai = (value: string) => {
		const regex = /^[0-9]{7}[A-Z]$/i
		if (value.length !== 8 || !regex.test(value)) {
			setError(`L'UAI doit être composé de 7 chiffres et d'une lettre.`)
		} else {
			setError(null)
		}
	}

	return (
		<Modal className={!isLightTheme && 'is-dark'} show={show} showClose onClose={() => onClose(state.name)} closeOnBlur>
			<Modal.Card className={'fade-in-bottom'}>
				<Modal.Card.Body>
					<form onSubmit={addSchool}>
						<header className="is-flex" style={{ justifyContent: 'space-between' }}>
							<Heading size={5}>{translate('modals.specific.school.add_title')}</Heading>
							<Button color={'light'} type={'reset'} onClick={() => onClose(state.name)}>
								<Icon icon={'fal fa-times fa-2x'} />
							</Button>
						</header>
						<Hr />
						<div className="flex justify-content align-items space-x-4 pb-4">
							<label>Etablissement référencé</label>
							<input id="etabNotExist" type="checkbox" className="switch is-rounded is-success is-small" checked={isEtabExist} onChange={() => setIsEtabExist(!isEtabExist)} />
							<label htmlFor="etabNotExist" />
						</div>
						{isEtabExist ? (
							<>
								<Form.Field>
									<Form.Label>{translate('modals.specific.school.uai')}</Form.Label>
									<Form.Control>
										<Form.Input
											className={error && 'is-danger'}
											placeholder={'0000000X'}
											name={'uai'}
											maxLength={8}
											value={state.uai}
											onChange={(e: ChangeEvent<HTMLInputElement>) => {
												dispatch({
													key: 'uai',
													value: e.target.value,
												})
												findEstabGouv(e.target.value)
											}}
										/>
									</Form.Control>
									{error && <Form.Help color={'danger'}>{error}</Form.Help>}
								</Form.Field>
								{estabFind ? (
									<div className="border flex space-x-2 p-2 rounded fade-in-fwd">
										<Icon icon={'fad fa-university'} />
										<section>
											<p className="font-bold">
												{estabFind.name} <span className="font-thin text-gray-600 tracking-wide uppercase text-sm">({estabFind.sector})</span>
											</p>
											<p>
												{estabFind.zipCode} - {estabFind.nameOfCity}
											</p>
										</section>
									</div>
								) : (
									<div>Aucun établissement trouvé</div>
								)}
							</>
						) : (
							<>
								<Form.Field>
									<Form.Label>{translate('modals.specific.school.uai')}</Form.Label>
									<Form.Control>
										<Form.Input
											className={error && 'is-danger'}
											placeholder={'0000000X'}
											name={'uai'}
											maxLength={8}
											value={state.uai}
											onChange={(e: ChangeEvent<HTMLInputElement>) => {
												dispatch({
													key: 'uai',
													value: e.target.value,
												})
												validateUai(e.target.value)
											}}
										/>
									</Form.Control>
									{error && <Form.Help color={'danger'}>{error}</Form.Help>}
								</Form.Field>
								<Form.Field>
									<Form.Label>Nom</Form.Label>
									<Form.Control>
										<Form.Input
											name={'Nom'}
											value={state.name}
											onChange={(e: ChangeEvent<HTMLInputElement>) =>
												dispatch({
													key: 'name',
													value: e.target.value,
												})
											}
											required
										/>
									</Form.Control>
								</Form.Field>
								<div className="flex space-x-12 mb-4">
									<Form.Control className={'is-expanded '}>
										<Form.Label>Type</Form.Label>
										<div className={`select`}>
											<Form.Control className={'is-expanded'} size={'small'}>
												<select
													name={'type'}
													value={state.typeOfEstablishment}
													onChange={(e: ChangeEvent<HTMLSelectElement>) =>
														dispatch({
															key: 'typeOfEstablishment',
															value: parseInt(e.target.value),
														})
													}
												>
													{dataSchools.typeEstab
														.sort((a, b) => a.orderTypeEstab - b.orderTypeEstab)
														.map(type => (
															<option value={type.idTypeEstab} key={type.idTypeEstab}>
																{type.typeEstab}
															</option>
														))}
												</select>
											</Form.Control>
										</div>
									</Form.Control>
									<Form.Control className={'is-expanded'}>
										<Form.Label>Secteur</Form.Label>
										<div className={`select`}>
											<Form.Control className={'is-expanded'} size={'small'}>
												<select
													name={'secteur'}
													value={state.sector}
													onChange={(e: ChangeEvent<HTMLSelectElement>) =>
														dispatch({
															key: 'sector',
															value: e.target.value,
														})
													}
													required
												>
													<option value="Prive">Privé</option>
													<option value="Public">Public</option>
												</select>
											</Form.Control>
										</div>
									</Form.Control>
								</div>
								<Form.Control className={'is-expanded'}>
									<Form.Label>Ville</Form.Label>
									<div className={`select`}>
										<Form.Control className={'is-expanded'} size={'medium'}>
											<select
												name={'cities'}
												value={state.idCity}
												onChange={(e: ChangeEvent<HTMLSelectElement>) => {
													const selectedValue = e.target.value
													const selectedCity = dataSchools.cities.find(city => city.idCity === selectedValue)
													if (selectedValue === 'newCity') {
														changeOption(e)
													} else {
														setValueCity(false)
														const nameOfCountry = selectedCity?.city.substring(selectedCity?.city.indexOf('(') + 1, selectedCity?.city.indexOf(')'))
														const countryOfSelectedCity = dataSchools.countries.find(country => country.country === nameOfCountry)
														dispatch({
															key: 'idCity',
															value: selectedValue,
														})
														dispatch({
															key: 'nameOfCity',
															value: selectedCity?.city!,
														})
														dispatch({
															key: 'idCountry',
															value: countryOfSelectedCity?.idCountry!,
														})
													}
												}}
											>
												<option value="">&lt;choisir une ville&gt;</option>
												<option value="newCity">&lt;Nouvelle ville&gt;</option>
												{dataSchools.cities
													.sort((a, b) => a.city.localeCompare(b.city))
													.map(ville => (
														<option key={ville.idCity} value={ville.idCity}>
															{ville.city}
														</option>
													))}
											</select>
											{valueCity ? (
												<div className="mt-2">
													<Form.Field>
														<Form.Control>
															<Form.Input
																type={'text'}
																name={'newCityInput'}
																value={state.nameOfCity}
																onChange={(e: ChangeEvent<HTMLInputElement>) => {
																	dispatch({
																		key: 'idCity',
																		value: '0',
																	})
																	dispatch({
																		key: 'nameOfCity',
																		value: e.target.value,
																	})
																}}
																required
																autoFocus
															/>
														</Form.Control>
													</Form.Field>
												</div>
											) : null}
										</Form.Control>
									</div>
								</Form.Control>
								<div className={valueCity ? 'pt-16' : 'pt-4'}>
									{valueCity ? (
										<Form.Control className={'is-expanded'}>
											<Form.Label>Pays</Form.Label>
											<div className={`select is-fullwidth mb-4`}>
												<select
													name={'countries'}
													value={state.idCountry}
													onChange={(e: ChangeEvent<HTMLSelectElement>) =>
														dispatch({
															key: 'idCountry',
															value: e.target.value,
														})
													}
													required
												>
													{dataSchools.countries
														.sort((a, b) => a.country.localeCompare(b.country))
														.map(country => (
															<option value={country.idCountry} key={country.idCountry}>
																{country.country}
															</option>
														))}
												</select>
											</div>
										</Form.Control>
									) : null}
									<Form.Field>
										<Form.Label>Adresse</Form.Label>
										<Form.Control>
											<Form.Input
												name={'address'}
												value={state.address}
												onChange={(e: ChangeEvent<HTMLInputElement>) =>
													dispatch({
														key: 'address',
														value: e.target.value,
													})
												}
												required
											/>
										</Form.Control>
									</Form.Field>
								</div>
							</>
						)}
						<br />
						<div className="is-flex" style={{ justifyContent: 'space-between' }}>
							<Button color={'danger'} type={'reset'} onClick={() => onClose(state.name)}>
								{translate('global.cancel')}
							</Button>
							<Button color={'primary'} type={'submit'} disabled={isEtabExist && !estabFind} loading={isLoading}>
								{translate('global.create')}
							</Button>
						</div>
					</form>
				</Modal.Card.Body>
			</Modal.Card>
		</Modal>
	)
}

export default AddSchoolModal
