import { Navbar } from 'react-bulma-components'
import React from 'react'
import GlobalContext from '../../../contexts/GlobalContext'

/**
 * Heure affichée dans la Navbar pour ne pas passer trop de temps sur le manager !!!
 */
export default class Timer extends React.Component {
	static contextType = GlobalContext

	constructor(props) {
		super(props)

		this.timer = null
		this.state = {
			hour: new Date(),
		}
	}

	/**
	 * Si l'option est déjà activée, on set le timer.
	 */
	componentDidMount() {
		if (this.context.showHour) {
			this.timerId = setInterval(() => {
				this.setState({
					hour: new Date(),
				})
			}, 1000)
		}
	}

	/**
	 * Si on désactive l'option, on clear l'interval, sinon on active le timer.
	 * @param prevProps
	 * @param prevState
	 * @param snapshot
	 */
	componentDidUpdate(prevProps, prevState, snapshot) {
		const { showHour } = this.context

		if (!showHour && this.timer) {
			clearInterval(this.timer)
		} else if (showHour && !this.timer) {
			this.setState({ hour: new Date() })
			this.timer = setInterval(() => {
				this.setState({
					hour: new Date(),
				})
			}, 1000)
		}
	}

	/**
	 * On supprime l'interval pour éviter la surchage mémoire.
	 */
	componentWillUnmount() {
		if (this.timerId) {
			clearTimeout(this.timerId)
		}
	}

	render() {
		const { showHour } = this.context
		const { hour } = this.state

		return (
			showHour && (
				<Navbar.Item unselectable renderAs={'div'} className={'is-family-code fade-in-left'}>
					{hour.toLocaleTimeString()}
				</Navbar.Item>
			)
		)
	}
}
