import React, { useContext, useState } from 'react'
import { Heading, Menu, Columns, Box, Section } from 'react-bulma-components'
import Icon from './utils/Icon'
import GlobalContext from '../contexts/GlobalContext'
import Hr from './utils/Hr'
import GlobalApps from './settings/global/GlobalApps'
import GlobalRegex from './settings/global/GlobalRegex'
import GlobalTabuleo from './settings/global/GlobalTabuleo'
import { GlobalPlatforms } from './settings/global/GlobalPlatforms'
import GlobalTokens from './settings/global/GlobalTokens'

/**
 * Paramètres globaux accessibles uniquements aux super admin.
 * Par exemple: modifications des regex, configuration des applications.
 *
 * @author Hugo DERIGNY
 * @returns {JSX.Element}
 * @constructor
 */
function GlobalSettings() {
	const context = useContext(GlobalContext)
	const { translate, isLightTheme } = context

	/**
	 * Vue actuelle (regex / apps)
	 */
	const [view, setView] = useState('tabuleo')

	/**
	 * Différentes vue
	 * @type {({name, icon: string, value: string}|{name, icon: string, value: string})[]}
	 */
	const menu = [
		{
			name: 'Tabuléo',
			icon: 'fal fa-star',
			value: 'tabuleo',
		},
		{
			name: translate('settings.menu.regex'),
			icon: 'fal fa-kerning',
			value: 'regex',
		},
		{
			name: translate('settings.menu.platforms'),
			icon: 'fal fa-boxes',
			value: 'platforms',
		},
		{
			name: translate('settings.menu.applications'),
			icon: 'fal fa-tablet',
			value: 'apps',
		},
		{
			name: translate('settings.menu.tokens'),
			icon: 'fal fa-key',
			value: 'tokens',
		},
	]

	/**
	 * Lien entre la vue et le composant lié
	 * @type {{regex: JSX.Element, apps: JSX.Element}}
	 */
	const views = {
		tabuleo: <GlobalTabuleo />,
		regex: <GlobalRegex />,
		platforms: <GlobalPlatforms />,
		apps: <GlobalApps />,
		tokens: <GlobalTokens />,
	}

	return (
		<Section style={{ height: 'calc(100vh - 3.5rem)', overflowY: 'auto' }}>
			<Heading textColor={!isLightTheme ? 'light' : ''} className={'fade-in-right'}>
				{translate('settings.title')}
			</Heading>
			<Hr />
			<Columns>
				<Columns.Column className={'is-narrow is-one-quarter-widescreen'}>
					<Box backgroundColor={!isLightTheme ? 'black' : ''} className={'fade-in-bottom'} style={{ animationDelay: '50ms' }}>
						<Menu className={'params-menu'}>
							<Menu.List>
								{menu.map(({ value, name, icon }, idx) => (
									<Menu.List.Item key={idx} active={view === value} onClick={() => setView(value)}>
										<Icon icon={icon} />
										<span className="is-text-icon">{name}</span>
									</Menu.List.Item>
								))}
							</Menu.List>
						</Menu>
					</Box>
				</Columns.Column>
				<Columns.Column>
					<Box backgroundColor={!isLightTheme ? 'black' : ''} className={'fade-in-bottom'} style={{ animationDelay: '100ms' }}>
						{views[view]}
					</Box>
				</Columns.Column>
			</Columns>
		</Section>
	)
}

export default GlobalSettings
