import React from 'react'
import { Modal, Heading, Form, Button } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import GlobalContext from '../../../contexts/GlobalContext'
import Hr from '../../utils/Hr'

export default class UnitaryAdd extends React.Component {
	static contextType = GlobalContext

	constructor(props) {
		super(props)
		this.name = 'unitaryAdd'
		this.state = {
			input: '',
		}
	}

	addType = formEvent => {
		formEvent.preventDefault()

		this.props.changeType(this.state.input)
		this.props.onClose(this.name)
	}

	handleInput = event => this.setState({ input: event.target.value })

	render() {
		const { translate } = this.context

		return (
			<Modal className={!this.context.isLightTheme && 'is-dark'} show={this.props.show} showClose onClose={() => this.props.onClose(this.name)} closeOnBlur>
				<Modal.Card className={'fade-in-bottom'}>
					<Modal.Card.Body>
						<form onSubmit={this.addType}>
							<header className="is-flex" style={{ justifyContent: 'space-between' }}>
								<div>
									<Heading size={5}>{translate('global.add_alt')}</Heading>
									<Heading size={6} subtitle>
										{translate(`global.${this.props.type}`)}
									</Heading>
								</div>
								<Button color={'light'} type={'reset'} onClick={() => this.props.onClose(this.name)}>
									<Icon icon={'fal fa-times fa-2x'} />
								</Button>
							</header>
							<Hr />
							<Form.Field>
								<Form.Control>
									<Form.Input autoFocus placeholder={translate(`global.${this.props.type}`)} type={'text'} value={this.state.input} onChange={this.handleInput} />
								</Form.Control>
							</Form.Field>
							<br />
							<div className="is-flex" style={{ justifyContent: 'space-between' }}>
								<Button color={'danger'} type={'reset'} onClick={() => this.props.onClose(this.name)}>
									{translate('global.cancel')}
								</Button>
								<Button color={'primary'} type={'submit'}>
									{translate('global.add')}
								</Button>
							</div>
						</form>
					</Modal.Card.Body>
				</Modal.Card>
			</Modal>
		)
	}
}
