import React, { Component } from 'react'

export default class SelectedFilter extends Component {
	constructor(props) {
		super(props)

		this.state = {
			currentValue: null,
		}
		this.valueGetter = props.valueGetter
		this.name = props.colDef.field
	}

	init(params) {
		this.valueGetter = params.valueGetter
		this.params = params
	}

	isFilterActive() {
		return this.state.currentValue !== undefined
	}

	doesFilterPass({ node: { selected, data } }) {
		const { currentValue } = this.state

		if (currentValue === null) {
			return true
		}

		if (this.name !== 'Selection') {
			return Boolean(data[this.name]) === currentValue
		} else {
			return selected === currentValue
		}
	}

	getModel() {
		return this.state.currentValue
	}

	setModel(model) {
		this.setState(
			{
				currentValue: model,
			},
			() => {
				this.props.filterChangedCallback()
			}
		)
	}

	render() {
		return <div />
	}
}
