import React from 'react'
import { Form } from 'react-bulma-components'
import Icon from '../../../utils/Icon'
import GlobalContext from '../../../../contexts/GlobalContext'

/**
 * Onglet "Apparence" dans les paramètres utilisateurs
 * Permet de changer le thème de l'application.
 */
export default class Appearance extends React.Component {
	static contextType = GlobalContext

	render() {
		const { isLightTheme, switchTheme, showHour, changeHourVisibility, translate } = this.context

		return (
			<>
				<Form.Field>
					<Form.Label>{translate('modals.specific.user_preferences.dark_theme')}</Form.Label>
					<Form.Control>
						<input id="theme" type="checkbox" name="theme" className="switch is-rounded is-link is-small" checked={!isLightTheme} onChange={switchTheme} />
						<label htmlFor="theme">
							<Icon className={'fade-in-fwd'} key={isLightTheme} color={isLightTheme ? 'warning' : 'white'} icon={isLightTheme ? 'fad fa-sun' : 'fad fa-moon-stars'} />
						</label>
					</Form.Control>
				</Form.Field>
				<Form.Field>
					<Form.Label>{translate('modals.specific.user_preferences.show_hour')}</Form.Label>
					<Form.Control>
						<input id="hour" type="checkbox" name="hour" className="switch is-rounded is-success is-small" checked={showHour} onChange={changeHourVisibility} />
						<label htmlFor="hour" />
					</Form.Control>
				</Form.Field>
			</>
		)
	}
}
