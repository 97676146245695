import React from "react";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExcelExport extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            headers: Object.keys(this.props.data[0]),
            body: this.props.data,
        }
    }

    render() {
        return (
            <ExcelFile hideElement={true} filename={this.props.filename}>
                <ExcelSheet data={this.state.body} name="export">
                    {
                        this.state.headers.map(column =>
                            <ExcelColumn key={column} label={column} value={column}/>
                        )
                    }
                </ExcelSheet>
            </ExcelFile>
        );
    }
}

export default ExcelExport;