import React from 'react'
import { Modal, Heading, Form, Button, Table } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import GlobalContext from '../../../contexts/GlobalContext'
import Hr from '../../utils/Hr'
import { SegmentedControl, SegmentedOption } from '../../utils/SegmentedControl'
import Queries from '../../../tools/Query'

export default class BulkOptions extends React.Component {
	static contextType = GlobalContext

	constructor(props, context) {
		super(props)
		this.name = 'bulkOptions'
		this.students = this.getSelectedAfterFilter(context)
		this.headers = this.initHeaders(['Nom', 'Prénom', 'Login', 'Niveau', 'Classe'])
		this.state = {
			segmented: 'update',
			confirmModal: false,
			usersOptions: this.getAllUsersOptions(),
			delOption: '',
			oldOption: '',
			newOption: '',
			newOptionInput: '',
			newOptionCombo: '',
			addNewOpt: false,
			header: this.headers,
			selected: this.selectedAllUser(),
		}
	}

	getSelectedAfterFilter = context => {
		const users = []
		context.grid.forEachNodeAfterFilter(node => {
			if (node.isSelected()) {
				users.push(node.data)
			}
		})
		return users
	}

	selectedAllUser = () => {
		return this.students.map(user => user['ID utilisateur'])
	}

	initHeaders = header => {
		const headers = header
		for (let columnHead in this.students[0]) {
			if (new RegExp('Option').test(columnHead)) {
				headers.push(columnHead)
			}
		}
		return headers
	}

	segmentedChange = value => {
		this.setState({
			segmented: value,
			selected: this.selectedAllUser(),
			oldOption: '',
			newOption: '',
			newOptionInput: '',
			delOption: '',
			addNewOpt: false,
		})
	}

	buttonIsDisabled = () => {
		switch (this.state.segmented) {
			case 'update':
				if (this.state.addNewOpt && this.state.selected.length > 0) {
					return !(this.state.newOptionInput && this.state.oldOption)
				}
				return !(this.state.oldOption && this.state.newOption && this.state.selected.length > 0)
			case 'insert':
				if (this.state.addNewOpt && this.state.selected.length > 0) {
					return !this.state.newOptionInput
				}
				return !this.state.newOptionCombo
			case 'delete':
				return !this.state.delOption
			default:
				return true
		}
	}

	changeOption = ({ target }) => {
		const { name, value } = target
		if (value === 'newOpt') {
			this.setState({ addNewOpt: true /*, selected: [] */ })
		} else {
			this.checkedOptUser(name, value)
			this.setState(state => ({
				[name]: value,
				addNewOpt: (name === 'oldOption' && state.addNewOpt) || name === 'newOptionInput',
			}))
		}
	}

	checkedOptUser = (nameInput, value) => {
		const { oldOption, newOption, segmented } = this.state
		const selected = []

		if (value === '' && segmented !== 'update') {
			return this.setState({ selected: [] })
		}

		this.students.forEach(user => {
			const options = []
			for (let elmt in user) {
				if (new RegExp('Option').test(elmt)) {
					options.push(user[elmt])
				}
			}
			switch (nameInput) {
				case 'oldOption':
					if (options.includes(value) && !options.includes(newOption)) {
						selected.push(user['ID utilisateur'])
					}
					break
				case 'newOption':
					if (options.includes(oldOption) && !options.includes(value)) {
						selected.push(user['ID utilisateur'])
					}
					break
				case 'newOptionCombo':
				case 'newOptionInput':
					if (segmented === 'update') {
						if (!options.includes(value) && options.includes(oldOption)) {
							selected.push(user['ID utilisateur'])
						}
					} else if (!options.includes(value)) {
						selected.push(user['ID utilisateur'])
					}
					break
				case 'delOption':
					if (options.includes(value)) {
						selected.push(user['ID utilisateur'])
					}
					break
				default:
					break
			}
		})
		this.setState({ selected })
	}

	getAllUsersOptions = () => {
		const usersOptions = []
		this.students.forEach(user => {
			for (let column in user) {
				if (new RegExp('Option').test(column) && !usersOptions.includes(user[column]) && user[column] !== null && user[column] !== '') {
					usersOptions.push(user[column])
				}
			}
		})
		return usersOptions.sort()
	}

	createOptions = schoolOptions => {
		const data = schoolOptions ? this.state.usersOptions : Object.keys(this.searchThroughTable(new RegExp('Option'))).sort()

		return data.map((value, idx) => (
			<option name={value} key={idx} value={value}>
				{value}
			</option>
		))
	}

	searchThroughTable = value => {
		let res = {}

		this.context.grid.forEachNode(node => {
			for (const j in node.data) {
				if (value.test(j) && node.data[j] !== null) {
					res = { ...res, [node.data[j]]: true }
				}
			}
		})

		return res
	}

	submit = formEvent => {
		formEvent.preventDefault()
		this.setState({ loading: true })

		const { addToast, refresh, translate } = this.context
		const { oldOption, newOption, newOptionInput, delOption, newOptionCombo } = this.state

		const options = () => {
			switch (true) {
				case oldOption !== '' && newOptionInput !== '':
					return { optionCible: oldOption, optionSource: newOptionInput }
				case oldOption !== '' && newOption !== '':
					return { optionCible: oldOption, optionSource: newOption }
				case newOptionInput !== '':
					return { optionCible: newOptionInput }
				case delOption !== '':
					return { optionCible: delOption }
				case newOptionCombo !== '':
					return { optionCible: newOptionCombo }
				default:
					return []
			}
		}

		Queries.updateBulk(this.context, this.state.selected, this.state.segmented, options(), 'opt')
			.then(res => {
				addToast(translate('modals.specific.bulk_opt.success', { users: this.state.selected.length }), { appearance: 'success' })
				refresh()
				this.props.onClose(this.name)
			})
			.catch(err => {
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
				this.props.onClose(this.name)
			})
	}

	render() {
		const { translate } = this.context

		const view = () => {
			switch (this.state.segmented) {
				case 'update':
					return (
						<>
							<Form.Field>
								<Form.Control className={'is-expanded'}>
									<div className="select is-fullwidth">
										<select name="oldOption" id="" onChange={this.changeOption}>
											<option value="">&lt;{translate('modals.specific.bulk_opt.select_edit')}&gt;</option>
											{this.createOptions(true)}
										</select>
									</div>
								</Form.Control>
							</Form.Field>
							<Form.Field>
								<Form.Control className={'is-expanded'}>
									<div className="select is-fullwidth">
										<select name="newOption" id="" onChange={this.changeOption}>
											<option value="">&lt;{translate('modals.specific.bulk_opt.select_new')}&gt;</option>
											<option value="newOpt">&lt;{translate('global.create')}&gt;</option>
											{this.createOptions(false)}
										</select>
									</div>
								</Form.Control>
							</Form.Field>
							{this.state.addNewOpt && (
								<Form.Field>
									<Form.Control>
										<Form.Input type={'text'} name={'newOptionInput'} placeholder={'Option'} value={this.state.newOptionInput} onChange={this.changeOption} autoFocus />
									</Form.Control>
								</Form.Field>
							)}
						</>
					)
				case 'delete':
					return (
						<>
							<Form.Field>
								<Form.Control className={'is-expanded'}>
									<div className="select is-fullwidth">
										<select name="delOption" id="" onChange={this.changeOption}>
											<option value="">&lt;{translate('modals.specific.bulk_opt.select_remove')}&gt;</option>
											{this.createOptions(true)}
										</select>
									</div>
								</Form.Control>
							</Form.Field>
						</>
					)
				case 'insert':
					return (
						<>
							<Form.Field>
								<Form.Control className={'is-expanded'}>
									<div className="select is-fullwidth">
										<select name="newOptionCombo" id="" onChange={this.changeOption}>
											<option value="">&lt;{translate('modals.specific.bulk_opt.select_add')}&gt;</option>
											<option value="newOpt">&lt;{translate('global.create')}&gt;</option>
											{this.createOptions(false)}
										</select>
									</div>
								</Form.Control>
							</Form.Field>
							{this.state.addNewOpt && (
								<Form.Field>
									<Form.Control>
										<Form.Input type={'text'} name={'newOptionInput'} placeholder={'Option'} value={this.state.newOptionInput} onChange={this.changeOption} autoFocus />
									</Form.Control>
								</Form.Field>
							)}
						</>
					)
				default:
					return <p>{translate('global.errors.format')}</p>
			}
		}

		return (
			<Modal className={`is-large ${!this.context.isLightTheme && 'is-dark'}`} show={this.props.show} showClose onClose={() => this.props.onClose(this.name)} closeOnBlur>
				<Modal.Card className={'fade-in-bottom'}>
					<Modal.Card.Body>
						<form onSubmit={this.submit}>
							<header className="is-flex" style={{ justifyContent: 'space-between' }}>
								<div>
									<Heading size={5}>{translate('modals.generic.mass_update')}</Heading>
									<Heading size={6} subtitle>
										{translate('modals.specific.bulk_opt.options')}
									</Heading>
								</div>
								<Button color={'light'} type={'reset'} onClick={() => this.props.onClose(this.name)}>
									<Icon icon={'fal fa-times fa-2x'} />
								</Button>
							</header>
							<Hr />
							<div style={{ margin: '0 25%' }}>
								<SegmentedControl id="F" defaultValue={this.state.segmented} mini onChange={this.segmentedChange} style={{ marginBotton: '1rem' }}>
									<SegmentedOption value="update">{translate('global.replace_alt')}</SegmentedOption>
									<SegmentedOption value="insert">{translate('global.add_alt')}</SegmentedOption>
									<SegmentedOption value="delete">{translate('global.remove_alt')}</SegmentedOption>
								</SegmentedControl>
							</div>
							{view()}
							<Hr />
							<Heading subtitle marginless size={5} textColor={'primary'}>
								{this.state.selected.length} {translate('global.users')}
							</Heading>
							<div className="table-container">
								<Table striped className={`is-narrow ${!this.context.isLightTheme && 'is-dark'}`} marginless>
									<thead>
										<tr>
											{this.headers.map((head, key) => (
												<th key={key}>{head}</th>
											))}
										</tr>
									</thead>
									<tbody>
										{this.students.map(
											(student, key) =>
												this.state.selected.includes(student['ID utilisateur']) && (
													<tr key={key}>
														{this.headers.map((head, key) => (
															<td key={key}>{student[head]}</td>
														))}
													</tr>
												)
										)}
									</tbody>
								</Table>
							</div>
							<br />
							<div className="is-flex is-fixed" style={{ justifyContent: 'space-between' }}>
								<Button color={'danger'} type={'reset'} onClick={() => this.props.onClose(this.name)}>
									{translate('global.cancel')}
								</Button>
								<Button color={'primary'} type={'submit'} loading={this.state.loading} disabled={this.buttonIsDisabled()}>
									{translate('global.confirm')}
								</Button>
							</div>
						</form>
					</Modal.Card.Body>
				</Modal.Card>
			</Modal>
		)
	}
}
