import React from 'react'
import { Columns, Box, Heading, Section, Content, Button, Form, Loader, Tag } from 'react-bulma-components'
import Icon from './utils/Icon'
import GlobalContext from '../contexts/GlobalContext'
import Hr from './utils/Hr'
import Queries from '../tools/Query'
import { Doughnut, HorizontalBar } from 'react-chartjs-2'
import appsColor from '../config/apps_color.json'

export default class Overview extends React.Component {
	static contextType = GlobalContext

	constructor(props) {
		super(props)

		this.state = {
			syncLib: false,
			syncLls: false,
			uaiLib: 'all',
			uaiLls: 'all',
			loadingToken: true,
			tokenReport: [],
			loadingStats: true,
			windowWidth: window.innerWidth,
		}
	}

	componentDidMount() {
		const { translate, addToast } = this.context

		window.addEventListener('resize', this.updateWindowWidth)

		Queries.getTokenReport(this.context)
			.then(report => {
				this.setState({
					tokenReport: report,
					loadingToken: false,
				})
			})
			.catch(() => {
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
				this.setState({
					loadingToken: false,
				})
			})

		Queries.getGlobalStats(this.context)
			.then(stats => {
				this.setState({
					loadingStats: false,
					stats: {
						numberOfSchools: stats.nbEtab[0]['Nb établissements'],
						roles: this.getRoles(stats),
						admin: this.getAdminData(stats),
						connected: this.getConnected(stats),
						apps: this.getApps(stats),
					},
				})
			})
			.catch(err => {
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
				this.setState({
					loadingStats: false,
				})
			})
	}

	updateWindowWidth = () => {
		this.setState({
			windowWidth: window.innerWidth,
		})
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowWidth)
	}

	getApps = stats => {
		const apps = stats.usersApp.sort((a, b) => Object.values(b)[0] - Object.values(a)[0])

		const sortedColors = []

		for (const app of apps) {
			const [appName] = Object.keys(app)

			sortedColors.push(appsColor[appName])
		}

		return {
			labels: apps.map(app => {
				const [[appName, appCount]] = Object.entries(app)

				return `${appName} (${appCount.toLocaleString()})`
			}),
			datasets: [
				{
					data: apps.map(app => Object.values(app)[0]),
					backgroundColor: sortedColors,
					hoverBackgroundColor: sortedColors,
					borderColor: this.context.isLightTheme ? '#ffffff' : '#262626',
				},
			],
		}
	}

	getRoles = stats => {
		const roles = stats.repartitionRoles.sort((a, b) => Object.keys(a)[0].localeCompare(Object.keys(b)[0]))

		return {
			labels: roles.map(role => {
				const [[roleName, roleCount]] = Object.entries(role)

				return `${roleName} (${roleCount.toLocaleString()})`
			}),
			datasets: [
				{
					data: roles.map(role => Object.values(role)[0]),
					backgroundColor: ['#12E288', '#FFD700', '#0ead69', '#0C975B', '#0A7144', '#074B2D'],
					hoverBackgroundColor: ['#12E288', '#FFD700', '#0ead69', '#0C975B', '#0A7144', '#074B2D'],
					borderColor: this.context.isLightTheme ? '#ffffff' : '#262626',
				},
			],
		}
	}

	getAdminData = stats => {
		const admins = stats.modeAdmin
		const disabled = admins.find(admin => admin['Désactivé'])?.['Désactivé'] || 0
		const enabled = admins.find(admin => admin['Activé'])?.['Activé'] || 0

		return {
			labels: [`${this.context.translate('global.enabled')} (${enabled.toLocaleString()})`, `${this.context.translate('global.disabled')} (${disabled.toLocaleString()})`],
			datasets: [
				{
					data: [enabled, disabled],
					backgroundColor: ['#a53860', '#da627d'],
					hoverBackgroundColor: ['#a53860', '#da627d'],
					borderColor: this.context.isLightTheme ? '#ffffff' : '#262626',
				},
			],
		}
	}

	getConnected = stats => {
		const connectedOrNot = stats.usersConnectes
		const connected = connectedOrNot.find(user => user['Connectés'])?.['Connectés'] || 0
		const vanished = connectedOrNot.find(user => user['Jamais connectés'])?.['Jamais connectés'] || 0

		return {
			labels: [`${this.context.translate('global.connected')} (${connected})`, `${this.context.translate('global.never_connected')} (${vanished})`],
			datasets: [
				{
					data: [connected, vanished],
					backgroundColor: ['#fdb833', '#fff056'],
					hoverBackgroundColor: ['#fdb833', '#fff056'],
					borderColor: this.context.isLightTheme ? '#ffffff' : '#262626',
				},
			],
		}
	}

	synchronizeEdulib = _ => {
		const { addToast, translate } = this.context
		this.setState({ syncLib: true })

		Queries.synchronizeEdulibTokens(this.context, this.state.uaiLib)
			.then(res => {
				addToast(res.Commentaire, { appearance: 'info' })
				this.setState({ syncLib: false })
			})
			.catch(err => {
				addToast(err.Commentaire, { appearance: 'info' })
				this.setState({ syncLib: false })
			})
	}

	synchronizeLeLivreScolaire = _ => {
		const { addToast, translate } = this.context
		this.setState({ syncLls: true })

		Queries.synchronizeLeLivreScolaireTokens(this.context, this.state.uaiLls)
			.then(res => {
				const generateUrlFile = () => {
					const blob = new Blob([res.errors.join('\n')], { type: 'text/plain' })
					return URL.createObjectURL(blob)
				}
				const content = () => (
					<>
						{res.Commentaire}
						{res?.errors?.length > 0 && (
							<a href={generateUrlFile()} download={'lls_errors.txt'}>
								Voir rapport d'erreur
							</a>
						)}
					</>
				)
				addToast(content(), { appearance: 'info', autoDismiss: false })
				this.setState({ syncLls: false })
			})
			.catch(err => {
				console.error({ err })
				addToast(err.Commentaire, { appearance: 'error' })
				this.setState({ syncLls: false })
			})
	}

	render() {
		const { shouldMount, isLightTheme, user, schools, translate } = this.context
		const { loadingToken, loadingStats, stats } = this.state

		return (
			shouldMount('Table', 'Lecture') && (
				<Section style={{ maxHeight: 'calc(100vh - 3.5rem)', overflowY: 'auto', overflowX: 'hidden' }}>
					<Heading textAlignment={'centered'} textColor={!isLightTheme ? 'light' : ''} className={'fade-in-bottom'}>
						{translate('global.welcome')} {user.prenom}
					</Heading>
					<Hr />
					<Columns multiline>
						{/* SYNC */}
						<Columns.Column size={'half'}>
							<Box backgroundColor={!isLightTheme ? 'black' : ''} className={'fade-in-top'} style={{ animationDelay: '100ms' }}>
								<Heading subtitle size={5} textColor={!isLightTheme ? 'light' : ''} className={'is-flex'} style={{ alignItems: 'center' }}>
									{translate('overview.lib_sync')}
								</Heading>
								<Hr />
								<Form.Field className={'has-addons'}>
									<Form.Control>
										<Button color={'primary'} onClick={() => this.synchronizeEdulib()} disabled={this.state.syncLib}>
											{this.state.syncLib ? <Icon key="icon-syncing" icon={'fal fa-sync fa-spin'} /> : <Icon key="icon-not-syncing" icon={'fal fa-sync'} />}
											{this.state.syncLib ? (
												<span key="syncing" className="is-text-icon">
													{translate('overview.syncing')}
												</span>
											) : (
												<span key="not-syncing" className="is-text-icon">
													{translate('overview.sync')}
												</span>
											)}
										</Button>
									</Form.Control>
									<Form.Control className={'is-expanded'}>
										<div className={`select is-fullwidth ${!schools ? 'is-loading' : ''}`}>
											<select name="school" value={this.state.uaiLib} onChange={event => this.setState({ uaiLib: event.target.value })}>
												<option value="all" className="has-text-weight-bold">
													&lt; {translate('overview.sync_all')} &gt;
												</option>
												{schools ? (
													schools.map((school, idx) => (
														<option key={idx} value={school.UAI}>
															{school.UAI} - {school.Nom} - {school.Commune}
														</option>
													))
												) : (
													<option disabled>{translate('overview.fetching_schools')}</option>
												)}
											</select>
										</div>
									</Form.Control>
								</Form.Field>
							</Box>
						</Columns.Column>
						<Columns.Column size={'half'}>
							<Box backgroundColor={!isLightTheme ? 'black' : ''} className={'fade-in-top'} style={{ animationDelay: '100ms' }}>
								<Heading subtitle size={5} textColor={!isLightTheme ? 'light' : ''} className={'is-flex'} style={{ alignItems: 'center' }}>
									Synchronisation LeLivreScolaire.fr
								</Heading>
								<Hr />
								<Form.Field className={'has-addons'}>
									<Form.Control>
										<Button color={'primary'} onClick={() => this.synchronizeLeLivreScolaire()} disabled={this.state.syncLls}>
											{this.state.syncLls ? <Icon key="icon-syncing" icon={'fal fa-sync fa-spin'} /> : <Icon key="icon-not-syncing" icon={'fal fa-sync'} />}
											{this.state.syncLls ? (
												<span key="syncing" className="is-text-icon">
													{translate('overview.syncing')}
												</span>
											) : (
												<span key="not-syncing" className="is-text-icon">
													{translate('overview.sync')}
												</span>
											)}
										</Button>
									</Form.Control>
									<Form.Control className={'is-expanded'}>
										<div className={`select is-fullwidth ${!schools ? 'is-loading' : ''}`}>
											<select name="school" value={this.state.uaiLls} onChange={event => this.setState({ uaiLls: event.target.value })}>
												<option value="all" className="has-text-weight-bold">
													&lt; {translate('overview.sync_all')} &gt;
												</option>
												{schools ? (
													schools.map((school, idx) => (
														<option key={idx} value={school.UAI}>
															{school.UAI} - {school.Nom} - {school.Commune}
														</option>
													))
												) : (
													<option disabled>{translate('overview.fetching_schools')}</option>
												)}
											</select>
										</div>
									</Form.Control>
								</Form.Field>
							</Box>
						</Columns.Column>
					</Columns>
					<Box backgroundColor={!isLightTheme ? 'black' : ''} className={'fade-in-top'} style={{ animationDelay: '150ms' }}>
						<Heading subtitle size={5} textColor={!isLightTheme ? 'light' : ''}>
							{translate('overview.tokenReport')}
						</Heading>
						<Hr />
						{loadingToken ? (
							<Loader />
						) : (
							<>
								<p className="has-text-danger is-italic">{this.state.tokenReport.length} établissements avec un rapport d'erreur</p>
								<Content className="space-y-2">
									{this.state.tokenReport.map(report => {
										const [[uai, apps]] = Object.entries(report)
										return (
											<details key={uai} className="py-1 px-2 border rounded-md">
												<summary className="cursor-pointer hover:underline">
													<span className="is-family-monospace has-text-dark">{uai}</span> <span className="is-size-7 has-text-grey-dark">({Object.keys(apps).join(', ')})</span>
												</summary>
												{Object.entries(apps).map(([appId, users], idx) => (
													<details key={idx} className="px-4 py-2">
														<summary className="cursor-pointer hover:underline">
															{appId} <span className="is-size-7">({users.length})</span>
														</summary>
														<ul>
															{users.map(({ Nom, Prenom, Login }) => (
																<li key={Login}>
																	{Nom} {Prenom} - {Login}
																</li>
															))}
														</ul>
													</details>
												))}
											</details>
										)
									})}
								</Content>
							</>
						)}
					</Box>
					<Box backgroundColor={!isLightTheme ? 'black' : ''} className={'fade-in-top'} style={{ animationDelay: '200ms' }}>
						<Heading subtitle size={5} textColor={!isLightTheme ? 'light' : ''}>
							{translate('overview.stats')}
						</Heading>
						<Hr />
						{loadingStats ? (
							<Loader />
						) : (
							<>
								<Tag color="link" className="mb-8">
									{stats.numberOfSchools} {translate('global.schools')}
								</Tag>
								<Columns>
									<Columns.Column size={'half'}>
										<Heading subtitle size={6} textColor={!isLightTheme ? 'light' : ''}>
											<Icon icon={'fad fa-tablet'} style={{ color: '#68BDE0' }} />
											<span className="is-text-icon">{translate('dashboard.user_per_apps')}</span>
										</Heading>
										<HorizontalBar
											data={stats.apps}
											options={{
												legend: {
													display: false,
												},
											}}
										/>
									</Columns.Column>
									<Columns.Column size={'half'}>
										<Columns multiline>
											<Columns.Column size="half">
												<Heading subtitle size={6} textColor={!isLightTheme ? 'light' : ''}>
													<Icon icon={'fad fa-graduation-cap'} style={{ color: '#074B2D' }} />
													<span className="is-text-icon">{translate('dashboard.role_repartition')}</span>
												</Heading>
												<Doughnut
													data={stats.roles}
													options={{
														legend: {
															labels: {
																fontSize: 11,
																boxWidth: 11,
															},
														},
													}}
												/>
											</Columns.Column>
											<Columns.Column size="half">
												<Heading subtitle size={6} textColor={!isLightTheme ? 'light' : ''}>
													<Icon icon={'fad fa-qrcode'} style={{ color: '#fdb833' }} />
													<span className="is-text-icon">{translate('dashboard.users_connected')}</span>
												</Heading>
												<Doughnut
													data={stats.connected}
													options={{
														legend: {
															labels: {
																fontSize: 11,
																boxWidth: 11,
															},
														},
													}}
												/>
											</Columns.Column>
											<Columns.Column size="half">
												<Heading subtitle size={6} textColor={!isLightTheme ? 'light' : ''}>
													<Icon icon={'fad fa-star-exclamation'} style={{ color: '#da627d' }} />
													<span className="is-text-icon">{translate('dashboard.admin_mode')}</span>
												</Heading>
												<Doughnut
													data={stats.admin}
													options={{
														legend: {
															labels: {
																fontSize: 11,
																boxWidth: 11,
															},
														},
													}}
												/>
											</Columns.Column>
										</Columns>
									</Columns.Column>
								</Columns>
							</>
						)}
					</Box>
				</Section>
			)
		)
	}
}
