import React, { useContext, useEffect, useState } from 'react'
import GlobalContext from '../../../contexts/GlobalContext'
import Required from '../../utils/Required'
import { Button, Form as BulmaForm, Heading, Modal, Table, Loader } from 'react-bulma-components'
import { Formik, Form, FastField } from 'formik'
import Icon from '../../utils/Icon'
import Hr from '../../utils/Hr'
import { SegmentedControl, SegmentedOption } from '../../utils/SegmentedControl'
import Queries from '../../../tools/Query'
import * as yup from 'yup'

const BULKACTIONTYPE = {
	INSERT: 'insert',
	DELETE: 'delete',
	UPDATE: 'update',
}

function BulkApps(props) {
	const context = useContext(GlobalContext)
	const { onClose } = props
	const name = 'bulkApps'
	const apps = context.school.applications
	const { translate, findPlatformById } = context
	const [users, setUsers] = useState(null)
	const [usersPassword, setUsersPassword] = useState({})
	const [selectedUsers, setSelectedUsers] = useState([])
	const [currentAppli, setCurrentAppli] = useState(null)
	const [bulkApplisAction, setBulkApplisAction] = useState(BULKACTIONTYPE.INSERT)
	const [requiredFields, setRequiredFields] = useState([])
	const [loading, setLoading] = useState(false)
	const [usersInfo, setUsersInfo] = useState([])

	useEffect(() => {
		setLoading(false)
	}, [requiredFields])

	/**
	 * Récupère tous les utilisateurs selon leur ID
	 * @returns {*[]}
	 */
	function selectedUserIds() {
		const selected = []
		context.grid.forEachNodeAfterFilter(node => {
			if (node.isSelected()) selected.push(node.data['ID utilisateur'])
		})
		return selected
	}

	useEffect(() => {
		Queries.getPlatformsUsers(context, selectedUserIds()).then(usersInfoData => {
			const usersData = {}
			usersInfoData.forEach(({ user: { userId, tabuleoPassword }, platforms }) => {
				usersData[userId] = { tabuleoPassword, platforms }
			})
			const formValues = initUsers(usersData)
			setUsersInfo(usersInfoData)
			setUsersPassword(usersData)
			setUsers(formValues)
			setSelectedUsers(formValues)
		})
	}, [])

	/**
	 * Initialise les donnée du formulaire
	 * @param usersPassword
	 * @returns {{}}
	 */
	function initUsers(usersData) {
		const users = []
		context.grid.forEachNodeAfterFilter(node => {
			if (node.isSelected()) {
				const idUser = node.data['ID utilisateur']
				const { tabuleoPassword: password, platforms } = usersData[idUser]
				users.push({
					idUser,
					nom: `${node.data['Nom']} ${node.data['Prénom']}`,
					login: node.data['Login'],
					password,
					code: '',
					platforms,
				})
			}
		})
		return users
	}

	/**
	 * Récupere les preferences d'une application donnée
	 * @param appName
	 * @returns {T|string}
	 */
	function getAppPref(appName) {
		return apps.find(app => app.name === appName) || ''
	}

	/**
	 * Change l'onglet ouvert et controle le segmented control "Ajout, Suppression"
	 * @param action
	 */
	function onSegmentedChange(action) {
		setCurrentAppli('')
		setRequiredFields([])
		setSelectedUsers([...users])
		setBulkApplisAction(action)
	}

	/**
	 * Génère l'en-tete du tableau
	 * @returns {unknown[]}
	 */
	function TableHeader({ setFieldValue, validateForm }) {
		const codeCommun = getAppPref(currentAppli)['commonCode']
		const headers = ['nom', 'login', 'password', 'code']
		const myheaders = bulkApplisAction === BULKACTIONTYPE.DELETE ? headers.filter(head => ['nom', 'login'].includes(head)) : headers
		return myheaders.map((head, key) => {
			return (
				<th className={'align-middle'} key={key}>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
						<div className="capitalize">
							{head} {requiredFields.includes(head) && <Required />}
						</div>
						{['password', 'code'].includes(head) && (
							<button
								disabled={head === 'code' && codeCommun}
								className={'cascade-button'}
								onClick={e => {
									e.preventDefault()
									const firstInputValue = document.getElementById(`users[0].${head}`)?.value
									if (firstInputValue) setInputsValue(head, firstInputValue, setFieldValue, validateForm)
								}}
							>
								↓
							</button>
						)}
					</div>
				</th>
			)
		})
	}

	/**
	 * Parse les données du formulaire et maj en bdd
	 * @param e
	 */
	function handleSubmit(e) {
		setLoading(true)
		const { users: submitUsers } = e
		const idApp = apps.find(appPref => appPref.name === currentAppli).idApplication
		const { addToast, refresh, translate } = context
		const userIds = []
		const bddUsers = submitUsers.map(({ idUser, login, password, code }) => {
			userIds.push(idUser)
			return {
				idUser,
				loginPlateforme: login,
				passwordPlateforme: password,
				keys: [code] || [],
			}
		})
		const userData = bulkApplisAction === BULKACTIONTYPE.INSERT && bddUsers

		Queries.updateBulk(context, userIds, bulkApplisAction, userData, 'app', idApp)
			.then(() => {
				addToast(translate('modals.specific.bulk_apps.success', { users: users.length }), { appearance: 'success' })
				onClose(name)
				refresh()
			})
			.catch(err => {
				setLoading(false)
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
				onClose(name)
			})
	}

	/**
	 * Génère un mot de passe aléatoire selon des régles definis
	 * @returns {*}
	 */
	function getRandomPassword(platformId) {
		const vowels = 'aeiouy'
		const upperConsonants = 'BCDFGHJKLMNPQRSTVWXZ'
		const consonants = 'bcdfghjklmnpqrstvwxz'

		function selectRandomChar(randomChars) {
			return randomChars.charAt(Math.floor(Math.random() * randomChars.length))
		}
		return selectRandomChar(upperConsonants) + selectRandomChar(vowels) + selectRandomChar(consonants) + selectRandomChar(vowels) + Math.floor(Math.random() * (10000 - 1000) + 1000) + (platformId === 'PLTF_EDULIB' ? '!' : '')
	}

	/**
	 * Génère la liste des applications qu'on peut ajouter aux utilisateurs
	 * @param apps
	 * @returns {*[]}
	 */
	function appListToAdd(appNames) {
		let ApplisForComboInsert = []
		const users = context.grid.getSelectedRows()
		ApplisForComboInsert = appNames.filter(appName => users.find(user => user[appName] === null || user[appName] === '0')).sort()

		return ApplisForComboInsert
	}

	/**
	 * Génère la liste des applications qu'on peut supprimer aux utilisateurs
	 * @param apps
	 * @returns {*[]}
	 */
	function appListToDelete(appNames) {
		const ApplisForComboDelete = []
		context.grid.getSelectedRows().forEach(user => {
			for (let column in user) {
				if (appNames.includes(column) && !ApplisForComboDelete.includes(column) && user[column] === '1') {
					ApplisForComboDelete.push(column)
				}
			}
		})
		return ApplisForComboDelete.sort()
	}

	/**
	 * la Combo-list des applications
	 * @returns {unknown[]}
	 * @constructor
	 */
	function ComboApplisUAI() {
		const appsName = apps.map(app => app.name)
		const applisCombo = bulkApplisAction === BULKACTIONTYPE.INSERT ? appListToAdd(appsName) : appListToDelete(appsName)

		return applisCombo.map((value, idx) => (
			<option name={value} key={idx} value={value}>
				{value}
			</option>
		))
	}

	function findPlatformInUser(platforms, id) {
		return platforms?.find(({ platformId }) => platformId === id)
	}

	/**
	 * Modifie le mot de passe des applications en le remplacant soit par un mot de passe aléatoire
	 * soit par le mot de passe de tabuleo connect
	 * @param newCurrentApp
	 * @param newSelectedUsers
	 * @param requiredPassword
	 */
	function setAppValues(newCurrentApp, newSelectedUsers, requiredPassword) {
		const appPref = getAppPref(newCurrentApp)
		const platformId = appPref.platformId
		const codeCommun = appPref.commonCode
		const randomPwds = appPref.connectDiffPassword
		const code = codeCommun !== undefined && codeCommun !== null ? codeCommun : ''

		if (selectedUsers.length > 0) {
			const updatedSelectedUsers = newSelectedUsers.map(({ password, idUser, platforms, ...user }) => {
				const platformPassword = findPlatformInUser(platforms, appPref?.platformId)?.platformPassword
				const { tabuleoPassword } = usersPassword[idUser]
				const newPassword = requiredPassword ? (randomPwds ? getRandomPassword(platformId) : tabuleoPassword) : ''
				console.log({ appPref, newPassword, platformPassword, newCurrentApp, randomPwds, requiredPassword })
				return { ...user, password: platformPassword ? platformPassword : newPassword, code, idUser, platformPassword }
			})
			setSelectedUsers(updatedSelectedUsers)
		}
	}

	/**
	 * Sélectionne les utilisateurs a afficher dans le tableau en fonction de l'application choisie.
	 * @param currentApp
	 * @returns {[]}
	 */
	function checkSelectedUser(currentApp) {
		const selectedUsersId = []
		context.grid.getSelectedRows().forEach(user => {
			for (let elmt in user) {
				bulkApplisAction === BULKACTIONTYPE.INSERT ? elmt === currentApp && user[elmt] !== '1' && selectedUsersId.push(user['ID utilisateur']) : elmt === currentApp && user[elmt] === '1' && selectedUsersId.push(user['ID utilisateur'])
			}
		})
		return users.filter(({ idUser }) => selectedUsersId.includes(idUser))
	}

	/**
	 * Permet de modifier la valeur d'un champ spécifique du formulaire
	 * @param input
	 * @param value
	 * @param setFieldValue
	 */
	function setInputsValue(input, value, setFieldValue, validateForm) {
		// selectedUsers.forEach((user, idx) => {
		// 	setFieldValue(`users.${idx}.${input}`, value, false)
		// })
		// validateForm()
		const newArray = selectedUsers.map((user, idx) => {
			return { ...user, [input]: value }
		})
		setSelectedUsers(newArray)
	}

	/**
	 * Défini les champs requis.
	 * @param param
	 * @returns {[]}
	 */
	function defineRequiredFields({ urlSchemeLoginRequired, urlSchemePwdRequired, urlSchemeCodeRequired, applicationCodeRequired }) {
		const tab = []

		urlSchemeLoginRequired && tab.push('login')
		urlSchemePwdRequired && tab.push('password')
		;(urlSchemeCodeRequired || applicationCodeRequired) && tab.push('code')

		return tab
	}

	function appChange(e) {
		const newCurrentApp = e.target.value

		if (newCurrentApp) {
			const selectedUsers = checkSelectedUser(newCurrentApp)
			let requiredFields = []

			if (bulkApplisAction !== BULKACTIONTYPE.DELETE) {
				requiredFields = defineRequiredFields(getAppPref(newCurrentApp))
				const passwordIsRequired = requiredFields.includes('password')
				setAppValues(newCurrentApp, selectedUsers, passwordIsRequired)
			}

			setCurrentAppli(newCurrentApp)
			setRequiredFields([...requiredFields])
		} else {
			setSelectedUsers([...users])
			setCurrentAppli('')
			setRequiredFields([])
		}
	}

	function CustomField({ fieldName, fieldValue, index, platformPassword }) {
		const name = `users[${index}].${fieldName}`
		return <FastField name={name}>{({ field }) => <BulmaForm.Input disabled={!!platformPassword} color={fieldValue || !requiredFields.includes(fieldName) ? 'success' : 'danger'} required={requiredFields.includes(fieldName)} type="text" id={name} placeholder={fieldName} {...field} />}</FastField>
	}

	function AppCredentials({ user, idx }) {
		const { nom, login, password, code, platformPassword } = user
		return (
			<tr key={idx}>
				<td className={'align-middle'}>
					<BulmaForm.Label column>{nom}</BulmaForm.Label>
				</td>
				<td className={'align-middle'}>{bulkApplisAction === BULKACTIONTYPE.INSERT ? <CustomField fieldName={'login'} fieldValue={login} index={idx} /> : login}</td>
				{bulkApplisAction === BULKACTIONTYPE.INSERT && (
					<>
						<td className={'align-middle'}>
							<CustomField fieldName={'password'} fieldValue={password} index={idx} platformPassword={platformPassword} />
						</td>
						<td className={'align-middle'}>
							<CustomField fieldName={'code'} fieldValue={code} index={idx} />
						</td>
					</>
				)}
			</tr>
		)
	}

	return (
		<Modal className={`is-large ${!context.isLightTheme && 'is-dark'}`} show={props.show} showClose onClose={() => props.onClose(name)} closeOnBlur>
			<Formik validateOnChange enableReinitialize initialValues={{ users: selectedUsers }} onSubmit={handleSubmit}>
				{({ setFieldValue, values: { users } }) => (
					<Form>
						<Modal.Card className={'fade-in-bottom'}>
							<Modal.Card.Body>
								<header className="is-flex" style={{ justifyContent: 'space-between' }}>
									<div>
										<Heading size={5}>{translate('modals.generic.mass_update')}</Heading>
										<Heading size={6} subtitle>
											{translate('modals.specific.bulk_apps.applications')}
										</Heading>
									</div>
									<Button color={'light'} type={'reset'} onClick={() => props.onClose(name)}>
										<Icon icon={'fal fa-times fa-2x'} />
									</Button>
								</header>
								<Hr />
								<div style={{ margin: '0 25%' }}>
									<SegmentedControl id="F" defaultValue={bulkApplisAction} mini onChange={onSegmentedChange}>
										<SegmentedOption value={BULKACTIONTYPE.INSERT}>{translate('global.add_alt')}</SegmentedOption>
										<SegmentedOption value={BULKACTIONTYPE.DELETE}>{translate('global.remove_alt')}</SegmentedOption>
									</SegmentedControl>
								</div>
								<BulmaForm.Field>
									<BulmaForm.Control className={'is-expanded'}>
										<div className="select is-fullwidth">
											<select name="applis" onChange={e => appChange(e, setFieldValue)}>
												<option name="test" value="test">
													&lt;{translate('modals.specific.bulk_apps.select')}&gt;
												</option>
												{ComboApplisUAI()}
											</select>
										</div>
									</BulmaForm.Control>
								</BulmaForm.Field>
								<Hr />
								<Heading subtitle marginless size={5} textColor={'primary'}>
									{selectedUsers.length} {translate('global.users')}
								</Heading>
								<div className="table-container">
									{users.length < 1 ? (
										<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
											<Loader style={{ width: '4rem', height: '4rem' }} />
										</div>
									) : (
										<Table striped className={`is-narrow ${!context.isLightTheme && 'is-dark'}`} marginless>
											<thead>
												<tr>
													<TableHeader setFieldValue={setFieldValue} />
												</tr>
											</thead>
											<tbody>
												{users.map((user, idx) => {
													return <AppCredentials idx={idx} user={user} />
												})}
											</tbody>
										</Table>
									)}
									<br />
								</div>
							</Modal.Card.Body>
							<Modal.Card.Foot className="is-flex is-fixed" style={{ justifyContent: 'space-between' }}>
								<Button color={'danger'} type={'reset'} onClick={() => props.onClose(name)}>
									{translate('global.cancel')}
								</Button>
								<Button color={'primary'} type={'submit'} loading={loading} disabled={!currentAppli}>
									{translate('global.confirm')}
								</Button>
							</Modal.Card.Foot>
						</Modal.Card>
					</Form>
				)}
			</Formik>
		</Modal>
	)
}

export default BulkApps
