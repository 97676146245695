import React, { useCallback, useContext, useEffect, useState } from 'react'
import GlobalContext from '../../contexts/GlobalContext'
import { Button, Form, Heading, Tag } from 'react-bulma-components'
import Icon from '../utils/Icon'
import Hr from '../utils/Hr'
import Query from '../../tools/Query'

function SettingsDeploy() {
	const context = useContext(GlobalContext)
	const {
		school,
		school: { informations: { uai } = '' },
		isLightTheme,
		addToast,
		translate,
		updateSchoolParams,
	} = context
	const initialSchoolDep = { ...school?.deployment }
	const [editing, setEditing] = useState(false)
	const [loading, setLoading] = useState(false)

	const WIFI_QUALITY = [
		{
			icon: 'fad fa-wifi',
			tag: <Tag color={'success'}>{translate('settings.deploy.good')}</Tag>,
			value: '3',
			name: 'Bonne',
		},
		{
			icon: 'fad fa-wifi-2',
			tag: <Tag color={'warning'}>{translate('settings.deploy.medium')}</Tag>,
			value: '2',
			name: 'Moyenne',
		},
		{
			icon: 'fad fa-wifi-1',
			tag: <Tag color={'danger'}>{translate('settings.deploy.low')}</Tag>,
			value: '1',
			name: 'Mauvaise',
		},
		{
			icon: 'fad fa-wifi-slash',
			tag: <Tag color={'dark'}>{translate('settings.deploy.no_wifi')}</Tag>,
			value: '',
			name: 'Pas de wifi',
		},
	]

	/**
	 * Champs lié à l'établissement :
	 * - le MDM
	 * - matériel utilisé
	 * - flotte gérée ou non (0 : non géré, 1 : géré, 2 : pas de flotte)
	 * - un commentaire libre
	 * - Identifiants wifi et sa qualité
	 * - la liste de tous les mdm et matériel dispo
	 */
	const [mdm, setMdm] = useState(initialSchoolDep.mdm)
	const [material, setMaterial] = useState(initialSchoolDep.materialType)
	const [supervised, setSupervised] = useState(initialSchoolDep.managedFleet)
	const [comment, setComment] = useState(initialSchoolDep.depComment)
	const [quality, setQuality] = useState(WIFI_QUALITY.find(quality => quality.value === initialSchoolDep.depWifiQuality) || WIFI_QUALITY[3])
	const [ssid, setSsid] = useState(initialSchoolDep.depSsid)
	const [pass, setPass] = useState(initialSchoolDep.depPassword)
	const [allMaterials, setAllMaterials] = useState([])
	const [allMdms, setAllMdms] = useState([])

	const supervisedLabels = {
		0: 'Flotte non gérée',
		1: 'Flotte gérée',
		2: 'Pas de flotte',
	}

	/**
	 * Récupère la liste des MDM et des matériels pour associer selon l'ID.
	 * @type {function(): void}
	 */
	const fetchMdmsAndMaterials = useCallback(() => {
		setLoading(true)
		Query.getMdmsAndMaterials(context)
			.then(({ mdms, materials }) => {
				setAllMaterials(materials)
				setAllMdms(mdms)
				setMdm(mdms.find(({ ID_MDM }) => ID_MDM === Number(initialSchoolDep.mdm)) ?? { ID_MDM: 0, Libelle: translate('settings.deploy.no_value') })
				setMaterial(materials.find(({ ID_Type_materiel }) => ID_Type_materiel === Number(initialSchoolDep.materialType)) ?? { ID_Type_materiel: 0, Libelle: translate('settings.deploy.no_value') })
				setLoading(false)
			})
			.catch(err => {
				setLoading(false)
			})
	}, [context, initialSchoolDep.materialType, initialSchoolDep.mdm])

	/**
	 * Récupère les infos au départ.
	 */
	useEffect(() => {
		if (uai && allMdms.length === 0 && allMaterials.length === 0) {
			fetchMdmsAndMaterials()
		}
	}, [allMaterials.length, allMdms.length, fetchMdmsAndMaterials, uai])

	/**
	 * Applique les modifications.
	 * @param formEvent
	 */
	function handleSubmit(formEvent) {
		formEvent.preventDefault()

		if (!editing) {
			return setEditing(true)
		}

		setLoading(true)

		const params = {
			deployment: {
				...initialSchoolDep,
				depSsid: ssid,
				depPassword: pass,
				depWifiQuality: quality.value,
				depComment: comment,
				managedFleet: supervised,
			},
		}

		mdm.ID_MDM !== 0 && (params.deployment.mdm = mdm.ID_MDM)
		material.ID_Type_materiel !== 0 && (params.deployment.materialType = material.ID_Type_materiel)
		Query.updateParams(context, uai, params)
			.then(() => {
				setLoading(false)
				setEditing(false)
				updateSchoolParams(params)
				addToast('Les informations de déploiement ont bien été mises à jour.', { appearance: 'success' })
			})
			.catch(() => {
				setLoading(false)
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
			})
	}

	return (
		<form onSubmit={handleSubmit}>
			<div className="is-flex" style={{ justifyContent: 'space-between' }}>
				<Heading textColor={!isLightTheme && 'light'} size={5} style={{ marginBottom: '0' }}>
					{translate('settings.deploy.deploy_info')}
				</Heading>
				{editing ? (
					<Button size={'small'} color={'success'} type={'submit'} loading={loading}>
						<Icon key={'save'} icon={'fal fa-check'} />
						<span className="is-text-icon">Sauvegarder</span>
					</Button>
				) : (
					<Button size={'small'} color={'info'} type={'submit'}>
						<Icon key={'edit'} icon={'fal fa-money-check-edit'} />
						<span className="is-text-icon">{translate('global.update')}</span>
					</Button>
				)}
			</div>
			<Hr />
			<section className="mb-8 flex">
				<div className="w-1/3 sm:w-full md:w-1/2 lg:w-1/3">
					<article className="shadow-lg rounded-xl mx-2">
						<header className="bg-gradient-to-br from-blue-400 to-indigo-500 rounded-t-xl px-4 py-2 text-blue-50">
							<Icon key={quality.value} icon={quality.icon} />
							<span className="is-text-icon tracking-wider">Wifi</span>
						</header>
						<div className="bg-gray-50 has-text-black p-4 rounded-b-xl h-64">
							{!editing ? (
								<>
									<p>SSID:</p>
									<p>
										<code>{ssid}</code>
									</p>
									<br />
									<p>{translate('settings.deploy.key')}</p>
									<p>
										<code>{pass}</code>
									</p>
									<br />
									{quality.tag}
								</>
							) : (
								<>
									<Form.Field>
										<Form.Label>SSID</Form.Label>
										<Form.Control>
											<Form.Input size={'small'} value={ssid} name={'ssid'} type={'text'} onChange={e => setSsid(e.target.value)} />
										</Form.Control>
									</Form.Field>
									<Form.Field>
										<Form.Label>Clé</Form.Label>
										<Form.Control>
											<Form.Input size={'small'} value={pass} name={'pass'} type={'text'} onChange={e => setPass(e.target.value)} />
										</Form.Control>
									</Form.Field>
									<Form.Field>
										<Form.Label>Qualité</Form.Label>
										<div className="select is-fullwidth is-small">
											<select name="quality" id="quality" onChange={e => setQuality(WIFI_QUALITY.find(quality => quality.value === e.target.value))} defaultValue={quality.value}>
												{WIFI_QUALITY.map((quality, k) => (
													<option key={k} value={quality.value}>
														{quality.name}
													</option>
												))}
											</select>
										</div>
									</Form.Field>
								</>
							)}
						</div>
					</article>
				</div>
				<div className="w-1/3 sm:w-full md:w-1/2 lg:w-1/3">
					<article className="shadow-lg rounded-xl mx-2">
						<header className="bg-gradient-to-br from-pink-500 to-red-600 rounded-t-xl px-4 py-2 text-blue-50">
							<Icon icon={'fas fa-tablet-alt'} />
							<span className="is-text-icon tracking-wider">{translate('settings.deploy.equipment')}</span>
						</header>
						<div className="bg-gray-50 has-text-black p-4 rounded-b-xl h-64">
							{!editing ? (
								<>
									<p>
										<strong>{material?.Libelle}</strong>
										<span> - {supervisedLabels[supervised]}</span>
									</p>
									<figure className="w-full">
										<img src={material?.Image ?? 'https://images-tabuleo.s3.eu-west-3.amazonaws.com/empty.png'} alt={material?.Libelle} className="object-contain h-48 mx-auto" />
									</figure>
								</>
							) : (
								<>
									<Form.Field>
										<Form.Control>
											<div className="select is-fullwidth">
												<select name="material" id="material" onChange={e => setMaterial(JSON.parse(e.target.value))} defaultValue={JSON.stringify(material)}>
													{allMaterials.map(material => (
														<option value={JSON.stringify(material)}>{material?.Libelle}</option>
													))}
												</select>
											</div>
										</Form.Control>
									</Form.Field>
									<Form.Field>
										<Form.Control>
											<input id="supervised" type="checkbox" className="switch is-rounded is-success is-small" checked={supervised === 1} name={'supervised'} onChange={() => setSupervised(supervised === 1 ? 2 : 1)} />
											<label htmlFor="supervised" />
											Flotte gérée
										</Form.Control>
									</Form.Field>
									<Form.Field>
										<Form.Control>
											<input id="not-supervised" type="checkbox" className="switch is-rounded is-success is-small" checked={supervised === 0} name={'not-supervised'} onChange={() => setSupervised(supervised === 0 ? 2 : 0)} />
											<label htmlFor="not-supervised" />
											Flotte non gérée
										</Form.Control>
									</Form.Field>
								</>
							)}
						</div>
					</article>
				</div>
				<div className="w-1/3 sm:w-full md:w-1/2 lg:w-1/3">
					<article className="shadow-lg rounded-xl mx-2">
						<header className="bg-gradient-to-br from-green-400 to-green-500 rounded-t-xl px-4 py-2 text-blue-50">
							<Icon icon={'fas fa-wrench'} />
							<span className="is-text-icon tracking-wider">MDM</span>
						</header>
						<div className="bg-gray-50 has-text-black p-4 rounded-b-xl h-64">
							{!editing ? (
								<>
									<p>
										<strong>{mdm?.Libelle}</strong>
									</p>
									<figure className="w-full">
										<img src={mdm?.Image ?? 'https://images-tabuleo.s3.eu-west-3.amazonaws.com/empty.png'} alt={mdm?.Libelle} className="object-contain h-48 mx-auto" />
									</figure>
								</>
							) : (
								<Form.Field>
									<Form.Control>
										<div className="select is-fullwidth">
											<select name="mdm" id="mdm" onChange={e => setMdm(JSON.parse(e.target.value))} defaultValue={JSON.stringify(mdm)}>
												{allMdms.map(mdm => (
													<option value={JSON.stringify(mdm)}>{mdm?.Libelle}</option>
												))}
											</select>
										</div>
									</Form.Control>
								</Form.Field>
							)}
						</div>
					</article>
				</div>
			</section>
			<Heading size={6}>{translate('settings.deploy.comment')}</Heading>
			<Form.Field>
				<Form.Control>
					<Form.Textarea value={comment} onChange={e => setComment(e.target.value)} disabled={!editing} />
				</Form.Control>
			</Form.Field>
		</form>
	)
}

export default SettingsDeploy
