import {ContextMenu, ContextMenuTrigger, MenuItem} from 'react-contextmenu'
import Icon from '../utils/Icon'
import React, {useContext, useState} from 'react'
import FileUtils, {CONTEXT_ACTIONS} from '../utils/FileUtils'
import GlobalContext from '../../contexts/GlobalContext'
import {Form, Tag} from 'react-bulma-components'
import Query from '../../tools/Query'
import Hr from '../utils/Hr'

/**
 * Affichage du fichier dans le gestionnaire sous forme de ligne.
 * @param {number} idx énième ligne
 * @param {boolean} selected Si le fichier est sélectionner ou non
 * @param {function} pushSelectedFile Sélectionne le fichier
 * @param {object} file Fichier
 * @return {JSX.Element}
 * @constructor
 */
function FileRow({ idx, selected, pushSelectedFile, file }) {
	const { showModal, user, schools: allSchools } = useContext(GlobalContext)
	const isAdmin = user.profile === 'Administrateur'

	/**
	 * Nom, taille, date d'upload, établissements affectés et utilisateur qui a upload.
	 */
	const { name, size, date, schools, uploadedBy } = file

	/**
	 * Indique si l'utilisateur courant a la possibilité de modifier le fichier.
	 * @type {boolean}
	 */
	const canEdit = FileUtils.canEditFile(user, file)

	/**
	 * Sélectionne le fichier
	 */
	function selectFile() {
		pushSelectedFile(file)
	}

	/**
	 * Prévisualise le fichier ou ouvre le dossier.
	 */
	function previewFileOrOpenDirectory() {
		if (!FileUtils.isDirectory(file)) {
			showModal('filesManager', true, { action: CONTEXT_ACTIONS.PREVIEW, file: { name } })
		}
	}

	const className = selected ? 'is-selected ' : ''

	return (
		<>
			<ContextMenuTrigger
				id={'menu'}
				renderTag="tr"
				key={idx}
				attributes={{
					className,
					onClick: canEdit && selectFile,
					file: JSON.stringify(file),
					onDoubleClick: previewFileOrOpenDirectory,
				}}
			>
				<td className="text-center">{canEdit && <input type="checkbox" name="selected" checked={selected} />}</td>
				<td>
					<Icon key={name} icon={`fad ${FileUtils.getIcons(file)}`} />
					<span className="is-text-icon">{name}</span>
				</td>
				<td>{size === 0 ? '-' : FileUtils.humanFileSize(size)}</td>
				<td>{uploadedBy}</td>
				{isAdmin && (
					<td>
						{schools?.length > 0 && (
							<div className="field is-grouped is-grouped-multiline">
								{schools.length === allSchools.length ? (
									<Form.Control>
										<Tag color="success">Tous</Tag>
									</Form.Control>
								) : schools.length > 1 ? (
									<Form.Control>
										<Tag color={'dark'}>{schools.length} établissements</Tag>
									</Form.Control>
								) : (
									<Form.Control>
										<Tag color={'dark'}>{schools[0]}</Tag>
									</Form.Control>
								)}
							</div>
						)}
					</td>
				)}
				<td>{date !== '' ? date : '-'}</td>
			</ContextMenuTrigger>
		</>
	)
}

/**
 * Menu custom du clique droit.
 * @returns {JSX.Element}
 * @constructor
 */
function RightClickMenu({ fetchFiles, usedDiskStorage }) {
	/**
	 * ID du fichier sur lequel il faut effectuer l'action
	 */
	const [file, setFile] = useState(null)

	const context = useContext(GlobalContext)
	const { showModal, shouldMount, user, addToast, translate, isLightTheme } = context

	/**
	 * Affiche la modal et passe l'action.
	 * @param e
	 * @param action
	 */
	function handleClick(e, { action }) {
		showModal('filesManager', true, {
			action,
			file,
			fetchFiles,
			usedDiskStorage,
		})
	}

	/**
	 * Récupère l'URL de téléchargement du fichier et ouvre ce lien.
	 */
	function downloadFile(e) {
		e.preventDefault()

		Query.getDownloadURL(context, file.name)
			.then(url => {
				const a = document.createElement('a')
				a.href = url
				a.click()
			})
			.catch(() => {
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
			})
	}

	return (
		<ContextMenu id={'menu'} className={`context-menu ${isLightTheme ? '' : 'is-dark'}`} collect={props => props} onShow={e => setFile(JSON.parse(e.detail.target.attributes.file.value))}>
			{shouldMount('AffectToEstab', 'Lecture') && !FileUtils.isDirectory(file ?? {}) && (
				<>
					<MenuItem data={{ action: CONTEXT_ACTIONS.AFFECT }} className={'context-menu__item'} onClick={handleClick}>
						<Icon icon={'far fa-university'} />
						<span className="is-text-icon">Affecter</span>
					</MenuItem>
					<Hr style={{ margin: '0.125rem' }} />
				</>
			)}

			{file && (
				<MenuItem data={{ action: CONTEXT_ACTIONS.DOWNLOAD }} className={'context-menu__item'} onClick={downloadFile}>
					<Icon icon={'far fa-arrow-to-bottom'} />
					<span className="is-text-icon">Télécharger</span>
				</MenuItem>
			)}
			<MenuItem data={{ action: CONTEXT_ACTIONS.DETAILS }} className={'context-menu__item'} onClick={handleClick}>
				<Icon icon={'far fa-info-circle'} />
				<span className="is-text-icon">Détails</span>
			</MenuItem>
			{FileUtils.canEditFile(user, file) && (
				<MenuItem data={{ action: CONTEXT_ACTIONS.DELETE }} className={'context-menu__item'} onClick={handleClick}>
					<Icon icon={'far fa-trash-alt'} color={'danger'} />
					<span className="is-text-icon has-text-danger">Supprimer</span>
				</MenuItem>
			)}
		</ContextMenu>
	)
}

export { RightClickMenu }
export default FileRow
