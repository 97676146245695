import { Box, Button, Heading, Section, Tag } from 'react-bulma-components'
import React, { useContext } from 'react'
import GlobalContext from '../../../contexts/GlobalContext'
import FileUtils, { CONTEXT_ACTIONS } from '../../utils/FileUtils'

function UserDocuments() {
	const {
		isLightTheme,
		translate,
		userPage: { documents }
	} = useContext(GlobalContext)

	return (
		<Box style={{ backgroundColor: isLightTheme ? '' : '#222' }}>
			<Heading size={5} textColor={isLightTheme ? '' : 'grey-lighter'}>
				{translate('user.documents')}
				<section
					className="mt-4 sm:grid-cols-2 lg:grid-cols-3 gap-4 grid">{documents ? documents.map(document =>
						<Document
							key={document.id} {...document} />) :
					<p className="text-sm text-gray-500">Aucun document ne vous a été affecté.</p>}</section>
			</Heading>
		</Box>
	)
}

const icons = {
	pdf: {
		fa: 'fas fa-file-pdf',
		color: '#f5150a'
	},
	epub: {
		fa: 'fas fa-book',
		color: '#81b315'
	}
}

function Document({ documentName: name, documentSize: size }) {
	const context = useContext(GlobalContext)
	const fileLabel = name.split('.')

	const fileExtension = fileLabel.pop()
	const fileName = fileLabel.join('.')

	const icon = icons[fileExtension.toLowerCase()]

	function open() {
		context.showModal('filesManager', true, { action: CONTEXT_ACTIONS.PREVIEW, file: { name } })
	}

	return (
		<Section className={'border rounded-lg p-4 user-app-box'}>
			<header className="flex justify-between">
				<Heading size={6} subtitle textColor="black" className="font-extrabold mb-2" marginless>
					{fileName}
				</Heading>
				<Tag style={{ backgroundColor: icon?.color, color: '#fff' }}>
							<span className="icon mr-1">
								<i className={icon?.fa} />
							</span>
					{fileExtension.toUpperCase()}
				</Tag>
			</header>
			<span className="text-gray-500 text-sm">{FileUtils.humanFileSize(size)}</span>
			<Button color="primary" size="small" fullwidth rounded className="is-light mt-4" onClick={open}>
				Ouvrir
			</Button>
		</Section>
	)
}

export default UserDocuments
