import React from "react";
import {Hero, Heading, Container, Image} from "react-bulma-components";
import GlobalContext from "../../contexts/GlobalContext";
import logo from "../../res/images/t_rond.png";

export default class LoadingScreen extends React.Component {

    static contextType = GlobalContext;

    render() {
        const isLightTheme = this.context
            ? this.context.isLightTheme
            : this.props.isLightTheme;

        return (
            <Hero size={'fullheight'} color={isLightTheme ? 'light' : 'black'}>
                <Hero.Body>
                    <Container textAlignment={'centered'}>
                        <Heading size={2} textColor={isLightTheme ? 'black' : 'light'}>
                            <div className="fade-in-fwd" id="loader"/>
                            <Image className={'fade-in-fwd'} src={logo} size={96} style={{ margin: 'auto'}}/>
                            <br/>
                            <span className="fade-in-bottom">Tabuleo Connect</span>
                        </Heading>
                    </Container>
                </Hero.Body>
            </Hero>
        );
    }
}