import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Box, Button, Form, Heading, Loader, Progress, Section, Table } from 'react-bulma-components'
import GlobalContext from '../contexts/GlobalContext'
import Hr from './utils/Hr'
import DragAndDrop from './utils/DragAndDrop'
import Icon from './utils/Icon'
import FileRow, { RightClickMenu } from './documents/FileRow'
import FileUtils, { CONTEXT_ACTIONS } from './utils/FileUtils'
import Query from '../tools/Query'
import { v4 as uuidv4 } from 'uuid'
import jszip from 'jszip'
import FileSaver from 'file-saver'

/**
 * Types des filtres :
 * - Ascendant : A -> Z / 0 -> 9
 * - Descendant : Z -> A / 9 -> 0
 * - Null : état initial
 * @type {{ASCENDING: string, DESCENDING: string, NONE: null}}
 */
const FILTERS = {
	ASCENDING: 'asc',
	DESCENDING: 'desc',
	NONE: null,
}

const MEGA_BYTES = 1_000_000
// const GIGA_BYTES = 1_000 * MEGA_BYTES
// const TERA_BYTES = 1_000 * GIGA_BYTES

/**
 * Gestionnaire des documents
 * @returns {JSX.Element}
 * @constructor
 */
function DocumentManager() {
	/**
	 * Gestion de l'input pour envoyer des fichiers. Alternatiev au drag and drop.
	 * @type {React.MutableRefObject<null>}
	 */
	const inputFileRef = useRef(null)
	/**
	 * Recherche de fichier selon leur nom.
	 */
	const [search, setSearch] = useState('')
	/**
	 * Est en train de fetch les fichiers
	 */
	const [isFetching, setIsFetching] = useState(true)
	/**
	 * Liste des fichiers
	 */
	const [files, setFiles] = useState(null)
	/**
	 * Espace disque utilisé
	 */
	const [usedDiskStorage, setUsedDiskStorage] = useState(0)
	/**
	 * Espace disque maximum
	 */
	const [maxDiskStorage, setMaxDiskStorage] = useState(0)
	/**
	 * Liste des fichiers sélectionnés par l'utilisateur
	 */
	const [selected, setSelected] = useState([])

	/**
	 * Filtre appliqué
	 */
	const [filters, setFilters] = useState({
		name: FILTERS.NONE,
		date: FILTERS.NONE,
		size: FILTERS.NONE,
		schools: FILTERS.NONE,
		uploadedBy: FILTERS.NONE,
	})

	const context = useContext(GlobalContext)
	const { isLightTheme, translate, addToast, updateToast, removeToast, shouldMount, showModal, user } = context
	const isAdmin = user.profile === 'Administrateur'

	/**
	 * Récupère les fichiers stockés sur le serveur
	 * @type {function(): void}
	 */
	const fetchFiles = useCallback(() => {
		setIsFetching(true)
		Query.getFiles(context)
			.then(({ files, maxBucketSize }) => {
				setIsFetching(false)
				setFiles(files)

				let diskStorage = 0
				files.forEach(file => (diskStorage += file.size))
				setUsedDiskStorage(diskStorage)
				setMaxDiskStorage(maxBucketSize)
			})
			.catch(err => addToast(translate('global.errors.occurred'), { appearance: 'error' }))
	}, [addToast, context, translate])

	/**
	 * Fetch les fichiers au chargement.
	 */
	useEffect(() => {
		if (!files) {
			fetchFiles()
		}
	}, [fetchFiles, files])

	/**
	 * Upload un fichier sur le serveur de documents.
	 * Va afficher une modal selon la progression.
	 * @param filesEvent
	 */
	async function uploadFileToCDN(filesEvent) {
		let toastId,
			uploadedFiles = []

		function send(file, uploadQuery) {
			return new Promise(async (resolve, reject) => {
				//upload chez aws S3 grace a l'url
				if (uploadQuery) {
					try {
						const { name, type, size } = file

						if (!isAdmin) {
							if (type !== 'application/pdf') {
								addToast("Vous n'avez pas la permission d'envoyer ce type de fichier.", {
									appearance: 'error',
								})
								return reject()
							}

							if (size > 10 * MEGA_BYTES) {
								addToast('Vous ne pouvez pas envoyer un fichier de plus de 10Mo.', {
									appearance: 'error',
								})
								return reject()
							}
						}

						if (size + usedDiskStorage >= maxDiskStorage) {
							removeToast(toastId)
							addToast('La capacité maximale de stockage a été atteinte.', {
								appearance: 'error',
							})
							return reject(-1)
						}

						addToast(
							<>
								<p className="flex place-items-center space-x-2 mb-1">
									<span>Upload de fichiers en cours (0/{filesEvent.length})</span>
									<Loader />
								</p>
								<Progress size="small" color="info" max={0} value={0} />
							</>,
							{ appearance: 'info', autoDismiss: false },
							toast => {
								toastId = toast
							}
						)

						await Query.uploadFile(context, uploadQuery.url, uploadQuery.fields, file, ({ total, loaded }) => {
							updateToast(toastId, {
								content: (
									<>
										<p className="flex place-items-center space-x-2 mb-1">
											<span>
												Upload de fichiers en cours ({uploadedFiles.length}/{filesEvent.length})
											</span>
											<Loader />
										</p>
										<Progress size="small" color="info" max={total} value={loaded} />
									</>
								),
							})
						})

						const addedFile = {
							key: uuidv4(),
							name,
							type,
							size,
							uploadedBy: `${user.firstname} ${user.lastname} (${user.profil})`,
							date: new Date().toLocaleString(),
						}

						uploadedFiles.push(addedFile)

						files.push(addedFile)
						setFiles([...files])

						setUsedDiskStorage(usedDiskStorage => usedDiskStorage + size)
					} catch (err) {
						addToast(translate('global.errors.occurred'), { appearance: 'error' })
						reject(err)
					}
				}

				updateToast(toastId, {
					content: (
						<>
							<p className="flex place-items-center space-x-2 mb-1">
								<span>
									Upload de fichiers en cours ({uploadedFiles.length}/{filesEvent.length})
								</span>
								<Loader />
							</p>
							<Progress size="small" color="info" max={0} value={0} />
						</>
					),
				})

				resolve()
			})
		}

		for (const file of filesEvent) {
			if (FileUtils.isDirectory(file)) {
				addToast('Vous ne pouvez pas envoyer de dossier.', { appearance: 'warning' })
			} else {
				const uploadQuery = await Query.getUploadFileURL(context, file.name)
				try {
					await send(file, uploadQuery)
				} catch (e) {
					if (e === -1) {
						return
					}
				}
			}
		}

		//envoi des fichiers ajoutés
		if (uploadedFiles.length > 0) {
			await Query.addFilesinDB(context, uploadedFiles)
		}

		fetchFiles()
		updateToast(toastId, {
			content: (
				<>
					<p>Envoie terminé.</p>
				</>
			),
			appearance: 'success',
			autoDismiss: true,
		})
	}

	/**
	 * Sélectionne un fichier
	 * @param file
	 */
	function pushSelectedFile(file) {
		if (!file) {
			if (selected.length !== files.length) {
				const select = []
				for (const file of files) {
					select.push(file)
				}
				setSelected(select)
			} else {
				setSelected([])
			}
		} else {
			selected.find(selectedFile => selectedFile.key === file.key)
				? selected.splice(
						selected.findIndex(selectedFile => selectedFile.key === file.key),
						1
				  )
				: selected.push(file)

			setSelected([...selected])
		}
	}

	/**
	 * Lors d'un clique sur l'entête, les filtres vont suivre cette ordre:
	 *    Null (état initial) -> Ascendant -> Descendant -> Null
	 * Il n'est pas possible d'appliquer deux filtres en même temps
	 * @param type
	 */
	function setFilter(type) {
		const newFilters = {}

		for (const type of Object.keys(filters)) {
			newFilters[type] = FILTERS.NONE
		}

		let actualFilter = filters[type]

		switch (actualFilter) {
			case FILTERS.NONE:
				newFilters[type] = FILTERS.ASCENDING
				break
			case FILTERS.ASCENDING:
				newFilters[type] = FILTERS.DESCENDING
				break
			case FILTERS.DESCENDING:
				newFilters[type] = FILTERS.NONE
				break
			default:
				break
		}

		setFilters(newFilters)
	}

	/**
	 * Tri les fichiers selon les filtres.
	 * @param fileA
	 * @param fileB
	 * @returns {number}
	 */
	function sortFiles(fileA, fileB) {
		const filter = Object.entries(filters).find(([_, value]) => value !== FILTERS.NONE)

		if (!filter) {
			return FileUtils.isDirectory(fileA) || FileUtils.isDirectory(fileB)
		} else {
			const [type, value] = filter

			const fileOne = value === FILTERS.ASCENDING ? fileA[type] : fileB[type]
			const fileTwo = value === FILTERS.ASCENDING ? fileB[type] : fileA[type]

			switch (type) {
				case 'name':
				case 'uploadedBy':
					return fileOne.localeCompare(fileTwo)

				case 'schools':
					// On check si aucun établissement est affecté
					const firstSchoolLength = (fileOne ?? []).length
					const secondSchoolLength = (fileTwo ?? []).length

					return firstSchoolLength - secondSchoolLength

				case 'date':
					const [firstDate, firstTime] = fileOne.split(' ')
					const [firstDay, firstMonth, firstYear] = firstDate.split('-')
					const [firstHour, firstMinute, firstSeconds] = firstTime.split(':')

					const first = new Date(firstYear, firstMonth - 1, firstDay, firstHour, firstMinute, firstSeconds)

					const [secondDate, secondTime] = fileTwo.split(' ')
					const [secondDay, secondMonth, secondYear] = secondDate.split('-')
					const [secondHour, secondMinute, secondSeconds] = secondTime.split(':')

					const second = new Date(secondYear, secondMonth - 1, secondDay, secondHour, secondMinute, secondSeconds)

					return first - second

				default:
					return ~~(fileOne - fileTwo)
			}
		}
	}

	async function downloadSelectedFiles() {
		// const output = createWriteStream('output.zip')
		const zip = new jszip()
		const output = zip.folder('Documents Tabuleo')

		const downloadsUrl = await Promise.all(
			selected.map(file =>
				Query.getDownloadURL(context, file.name).then(url => ({
					name: file.name,
					url,
				}))
			)
		)

		const filesBlob = await Promise.all(
			downloadsUrl.map(({ url, name }) =>
				fetch(url)
					.then(response => response.blob())
					.then(blob => ({
						name,
						blob,
					}))
			)
		)

		for (const { name, blob } of await Promise.all(filesBlob)) {
			output.file(name, blob)
		}

		output.generateAsync({ type: 'blob' }).then(content => {
			FileSaver.saveAs(content, 'Documents Tabuleo.zip')
		})
	}

	return (
		<Section style={{ overflow: 'hidden auto', height: 'calc(100vh - 3.5rem)' }}>
			<Heading textColor={!isLightTheme ? 'light' : ''} className={'fade-in-right'}>
				{translate('document_manager.title')}
			</Heading>
			<Hr />
			<DragAndDrop handleDrop={uploadFileToCDN}>
				<Box backgroundColor={!isLightTheme ? 'black' : ''} paddingless className={'fade-in-bottom file-manager'}>
					<section className="is-flex nav" style={{ justifyContent: 'space-between', placeItems: 'center', padding: '0.5rem 1rem' }}>
						<div className="flex space-x-2 place-items-center">
							<Form.Control iconLeft size={'small'}>
								<Form.Input placeholder="Rechercher" size={'small'} value={search} type={'text'} name={'search'} onChange={e => setSearch(e.target.value)} backgroundColor={isLightTheme ? '' : 'dark'} />
								<Icon icon={'fal fa-search'} className={'is-small is-left'} />
							</Form.Control>
							{shouldMount('AvailableStorage', 'Lecture') && files && <DiskPercentage filesCounter={files.length} usedDiskStorage={usedDiskStorage} maxDiskStorage={maxDiskStorage} />}
						</div>
						<section className="buttons">
							{selected.length > 0 && (
								<div>
									{shouldMount('AffectToEstab', 'Lecture') && (
										<Button
											className="has-tooltip-arrow has-tooltip-top"
											data-tooltip={'Affecter'}
											textColor={isLightTheme ? '' : 'light'}
											onClick={() =>
												showModal('filesManager', true, {
													action: CONTEXT_ACTIONS.AFFECT,
													selected,
													fetchFiles,
												})
											}
										>
											<Icon icon={'far fa-university'} />
										</Button>
									)}
									<Button className="has-tooltip-arrow has-tooltip-top" data-tooltip={'Télécharger'} textColor={isLightTheme ? '' : 'light'} onClick={downloadSelectedFiles}>
										<Icon icon={'far fa-arrow-to-bottom'} />
									</Button>
									<Button className="has-tooltip-arrow has-tooltip-top" data-tooltip={'Supprimer'} onClick={() => showModal('filesManager', true, { action: CONTEXT_ACTIONS.DELETE, selected, fetchFiles, callback: () => setSelected([]) })} textColor={'danger'}>
										<Icon icon={'far fa-trash'} />
									</Button>
									<Button className="has-tooltip-arrow has-tooltip-top" data-tooltip={'Effacer la sélection'} onClick={() => setSelected([])} textColor={isLightTheme ? '' : 'light'}>
										<Icon icon={'far fa-times'} />
									</Button>
									<Button disabled textColor={isLightTheme ? '' : 'light'}>
										|
									</Button>
								</div>
							)}
							<>
								<input type="file" style={{ display: 'none' }} multiple ref={inputFileRef} onChange={e => uploadFileToCDN(e.target.files)} />
								<Button className="has-tooltip-arrow has-tooltip-top" data-tooltip={'Envoyer des fichiers'} onClick={() => inputFileRef.current.click()} textColor={isLightTheme ? '' : 'light'}>
									<Icon icon={'far fa-file-plus'} />
								</Button>
							</>
							{/*<Button className="has-tooltip-arrow has-tooltip-top" data-tooltip={'Créer un dossier'} onClick={() => showModal('filesManager', true, { action: CONTEXT_ACTIONS.CREATE_DIR, fetchFiles })} textColor={isLightTheme ? '' : 'light'}>*/}
							{/*	<Icon icon={'far fa-folder-plus'} />*/}
							{/*</Button>*/}
							<Button data-tooltip={'Synchroniser'} onClick={fetchFiles} className={`has-tooltip-arrow has-tooltip-top`} textColor={isLightTheme ? '' : 'light'}>
								<Icon icon={'far fa-sync-alt'} className={isFetching ? 'fa-spin' : ''} />
							</Button>
							<Button className="has-tooltip-arrow has-tooltip-top" data-tooltip={'Aide'} onClick={() => showModal('filesManager', true, { action: CONTEXT_ACTIONS.HELP })} textColor={isLightTheme ? '' : 'light'}>
								<Icon icon={'fas fa-info-circle'} />
							</Button>
						</section>
					</section>
					<div className="table-container">
						<Table className={`${isLightTheme ? '' : 'is-dark'}`} marginless>
							<thead>
								<tr className="is-size-6">
									<td style={{ width: '5%' }}>
										<input type="checkbox" name="checked" onClick={() => pushSelectedFile()} checked={selected.length > 0} />
										<small className="pl-1 is-size-7">({selected.length})</small>
									</td>
									<th onClick={() => setFilter('name')} style={{ width: '30%' }}>
										Nom
										<span className="is-size-7">{filters.name === FILTERS.ASCENDING ? <Icon key={'name-down'} icon="fal fa-arrow-down" /> : filters.name === FILTERS.DESCENDING ? <Icon key={'name-up'} icon="fal fa-arrow-up" /> : ''}</span>
									</th>

									<td onClick={() => setFilter('size')}>
										Poids
										<span className="is-size-7">{filters.size === FILTERS.ASCENDING ? <Icon key={'name-down'} icon="fal fa-arrow-down" /> : filters.size === FILTERS.DESCENDING ? <Icon key={'name-up'} icon="fal fa-arrow-up" /> : ''}</span>
									</td>
									<td onClick={() => setFilter('uploadedBy')}>
										Ajouté par
										<span className="is-size-7">{filters.uploadedBy === FILTERS.ASCENDING ? <Icon key={'name-down'} icon="fal fa-arrow-down" /> : filters.uploadedBy === FILTERS.DESCENDING ? <Icon key={'name-up'} icon="fal fa-arrow-up" /> : ''}</span>
									</td>
									{isAdmin && (
										<th onClick={() => setFilter('schools')} style={{ width: '20%' }}>
											Etablissements assignés
											<span className="is-size-7">{filters.schools === FILTERS.ASCENDING ? <Icon key={'name-down'} icon="fal fa-arrow-down" /> : filters.schools === FILTERS.DESCENDING ? <Icon key={'name-up'} icon="fal fa-arrow-up" /> : ''}</span>
										</th>
									)}
									<td onClick={() => setFilter('date')}>
										Dernière modification
										<span className="is-size-7">{filters.date === FILTERS.ASCENDING ? <Icon key={'name-down'} icon="fal fa-arrow-down" /> : filters.date === FILTERS.DESCENDING ? <Icon key={'name-up'} icon="fal fa-arrow-up" /> : ''}</span>
									</td>
								</tr>
							</thead>
							<tbody>
								{files ? (
									files
										.filter(file => file.name.toLowerCase().includes(search.toLowerCase()) || file.uploadedBy.toLowerCase().includes(search.toLowerCase()) || (file.schools ?? []).some(schoolName => schoolName.toLowerCase().includes(search.toLowerCase())))
										.sort(sortFiles)
										.map((file, idx) => <FileRow file={file} idx={idx} pushSelectedFile={pushSelectedFile} selected={selected.find(selectedFile => selectedFile.key === file.key)} />)
								) : (
									<FileLoaderFactory />
								)}
							</tbody>
						</Table>
					</div>
				</Box>
			</DragAndDrop>
			<RightClickMenu fetchFiles={fetchFiles} usedDiskStorage={usedDiskStorage} />
		</Section>
	)
}

/**
 * Barre indiquant le pourcentage du disque utilité, en sommant la taille pour chaque fichier. La capacité maximale
 * est définie en dur en haut de ce fichier.
 * @param {number} filesCounter Combien de fichiers sont stockés
 * @param {number} usedDiskStorage Poids total occupé par les fichiers (en bytes)
 * @return {JSX.Element}
 * @constructor
 */
function DiskPercentage({ filesCounter, usedDiskStorage, maxDiskStorage }) {
	const percentageOfDiskUsed = (usedDiskStorage / maxDiskStorage) * 100

	return (
		<div>
			<small>
				{percentageOfDiskUsed.toFixed(2)}% du stockage utilisé ({filesCounter} fichiers)
			</small>
			<Progress color={percentageOfDiskUsed > 85 ? 'danger' : percentageOfDiskUsed > 70 ? 'warning' : 'success'} value={usedDiskStorage} max={maxDiskStorage} size="small" title={`${FileUtils.humanFileSize(usedDiskStorage)} sur ${FileUtils.humanFileSize(maxDiskStorage)}`} />
		</div>
	)
}

/**
 * Création de pleins de squelettes de fichier pendant le premier chargement.
 * @return {unknown[]}
 * @constructor
 */
function FileLoaderFactory() {
	const MAX_ROWS = 25

	const rows = []

	for (let i = 0; i < MAX_ROWS; i++) {
		rows.push(i)
	}

	return rows.map(i => <FileLoader idx={i} key={i} />)
}

/**
 * Loader affiché durant le temps de récupération des données.
 * @param idx
 * @returns {JSX.Element}
 * @constructor
 */
function FileLoader({ idx }) {
	return (
		<tr className={'row-loader'}>
			<td className={'text-loader'}>
				<div className="placeholder" style={{ animationDelay: 100 * idx + 'ms' }}>
					&nbsp;
				</div>
			</td>
			<td className={'text-loader'}>
				<div className="placeholder" style={{ animationDelay: 115 * idx + 'ms' }}>
					&nbsp;
				</div>
			</td>
			<td className={'text-loader'}>
				<div className="placeholder" style={{ animationDelay: 130 * idx + 'ms' }}>
					&nbsp;
				</div>
			</td>
			<td className={'text-loader'}>
				<div className="placeholder" style={{ animationDelay: 145 * idx + 'ms' }}>
					&nbsp;
				</div>
			</td>
			<td className={'text-loader'}>
				<div className="placeholder" style={{ animationDelay: 160 * idx + 'ms' }}>
					&nbsp;
				</div>
			</td>
			<td className={'text-loader'}>
				<div className="placeholder" style={{ animationDelay: 175 * idx + 'ms' }}>
					&nbsp;
				</div>
			</td>
		</tr>
	)
}

export default DocumentManager
