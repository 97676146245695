import React, { useContext, useState } from 'react'
import { Form, Button } from 'react-bulma-components'
import GlobalContext from '../../../../contexts/GlobalContext'
import Icon from '../../../utils/Icon'

/**
 * Option pour sauvegarder ou non la configuration de la grille.
 * Lorsque l'utilisateur désactive l'option, cela ne supprime pas la configuration qui a été sauvegardée.
 * @return {JSX.Element}
 * @constructor
 */
function GridSettings() {
	const { translate, addToast, grid, refresh } = useContext(GlobalContext)
	/**
	 * Activation ou non de l'option
	 */
	const [isSavingGridPref, setIsSavingGridPref] = useState(isActivated())

	/**
	 * Renvoie si le champs sgp (save grid preferences) est défini
	 * @return {boolean}
	 */
	function isActivated() {
		return !!~~localStorage.getItem('sgp')
	}

	/**
	 * Sauvegarde dans le storage local la préference utilisateur.
	 *
	 * "sgf" correspond à l'abbréviation de "saving grid pref".
	 * Si la variable est présente dans le local storage et qu'elle est non vide alors la préférence est active.
	 * Sinon elle ne l'est pas.
	 */
	function toggleSaveGridPreferences() {
		isSavingGridPref ? localStorage.setItem('sgp', '0') : localStorage.setItem('sgp', '1')

		setIsSavingGridPref(isActivated())
	}

	/**
	 * SUpprime les préférences sauvegardées dans le storage local.
	 */
	function resetGridPreferences() {
		localStorage.removeItem('gp')

		grid?.setFilterModel({
			Désactivé: false,
		})
		refresh()
		addToast(translate('modals.specific.user_preferences.save_grid_reset'), { appearance: 'info' })
	}

	return (
		<>
			<Form.Field>
				<Form.Label>{translate('modals.specific.user_preferences.save_grid_preferences')}</Form.Label>
				<Form.Control>
					<input id="grid" type="checkbox" name="grid" className="switch is-rounded is-success is-small" checked={isSavingGridPref} onChange={toggleSaveGridPreferences} />
					<label htmlFor="grid" />
				</Form.Control>
			</Form.Field>
			<p className="is-size-7">
				<Icon icon={'fal fa-exclamation-circle'} />
				<span className="is-text-icon">{translate('modals.specific.user_preferences.save_grid_preferences_help')}</span>
			</p>
			<br />
			<Button color={'warning'} size={'small'} onClick={resetGridPreferences}>
				{translate('global.reinitialize')}
			</Button>
		</>
	)
}

export default GridSettings
