import { Button, Form, Navbar } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import React from 'react'
import GlobalContext from '../../../contexts/GlobalContext'
import Query from '../../../tools/Query'
import latinize from 'latinize'

/**
 * Section dans la NavBar permettant de rechercher/changer d'établissement.
 */
export default class SchoolSearch extends React.Component {
	static contextType = GlobalContext

	/**
	 * Etat par défaut:
	 * 	- uai = UAI de l'éaablissement actuel
	 * 	- showDropdown = affichage de la liste
	 * 	- loadingSchool = icone de chargement
	 * 	- schools = ensemble des établissements
	 * 	- school = établissement actuel
	 * 	- found = établissement recherché trouvé
	 * @param props
	 */
	constructor(props) {
		super(props)

		this.state = {
			uai: false,
			showDropdown: false,
			loadingSchool: true,
			schools: undefined,
			school: undefined,
			found: false,
		}
	}

	/**
	 * On ajoute un événement, qui lors d'un click sur l'application va fermer la liste
	 * sauf si le click se fait sur un établissement de la liste.
	 */
	componentDidMount() {
		document.addEventListener('click', event => {
			if (this.state.showDropdown && event.target.tagName !== 'A' && event.target.name !== 'school') {
				this.setState(state => ({
					showDropdown: false,
				}))
			}
		})
	}

	/**
	 * Met à jour les établissements et l'établissement sélectionné.
	 * @param prevProps
	 * @param prevState
	 * @param snapshot
	 */
	componentDidUpdate(prevProps, prevState, snapshot) {
		const {
			reload,
			schools,
			school: { informations: { uai = '', name = '', town = '' } = '' },
		} = this.context

		if (reload) {
			this.refresh()
		}

		if (!this.state.schools) {
			this.setState({ schools: schools, loadingSchool: false })
		}

		if (uai && uai !== this.state.uai) {
			this.setState({
				uai: uai,
				school: `${uai} - ${name} - ${town}`,
				found: true,
			})
		}
	}
	fix
	escapeRegExp = string => {
		return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
	}

	/**
	 * Filtre parmi les établissements selon ce que l'utilisateur écris.
	 * @returns {JSX.Element|unknown[]}
	 */
	filterEtab = () => {
		const { school, schools, loadingSchool, found } = this.state
		const { translate, resetGridFilter } = this.context

		const escapedValue = latinize((school || '').replace(/[()]/gi, ''))
		const safeEscapedValue = this.escapeRegExp(escapedValue)
		const regex = new RegExp(safeEscapedValue, 'gi')
		let filtered = (schools || []).filter(etab => latinize(`${etab.Nom} ${etab.UAI} ${etab.Extension} ${etab.Commune}`).match(regex))
		// On affiche tout les établissement quand on en à déjà trouver un
		// et que l'on clique sur l'input
		if (filtered.length === 0 && found) {
			filtered = schools
		}

		return loadingSchool ? (
			<p className={'dropdown-item'}>{translate('navbar.loading_schools')}</p>
		) : filtered.length === 0 ? (
			<p className={'dropdown-item has-text-danger is-size-7'}>{translate('global.errors.school_not_found')}</p>
		) : (
			filtered.map((school, key) => (
				<a
					href={'/'}
					key={key}
					onClick={event => {
						resetGridFilter('table', 'SchoolSearch.changeEstab')
						this.setState({
							uai: school.UAI,
							school: `${school.UAI} - ${school.Nom} - ${school.Commune}`,
							found: true,
						})
						this.setEtab(event, school.UAI)
					}}
					className={'dropdown-item dropdown-school'}
				>
					<strong>{school.UAI}</strong> - {school.Nom} - {school.Commune} {school.Extension && `(${school.Extension})`} - {school.Effectif}
				</a>
			))
		)
	}

	/**
	 * Reinitialise le composant
	 */
	reset = _ => {
		this.setState({
			uai: false,
			showDropdown: false,
			loadingSchool: false,
			schools: undefined,
			school: '',
			found: false,
		})
	}

	/**
	 * Supprime l'établissement actuellement séléctionné
	 * @param _
	 */
	deleteActiveSchool = _ => {
		const {
			showModal,
			addToast,
			translate,
			setSchool,
			changeView,
			school: {
				informations: { uai },
			},
		} = this.context
		const reset = this.reset

		showModal('confirmAction', true, {
			content: <p>Êtes-vous sûr de vouloir supprimer l'établissement ?</p>,
			action: () => {
				Query.delete(this.context, uai)
					.then(() => {
						setSchool(null)
						reset()
						addToast(translate('modals.specific.school.deleted'), { appearance: 'success' })
						changeView('overview')
						window.location.reload()
					})
					.catch(e => {
						addToast(translate('global.errors.occurred'), { appearance: 'error' })
					})
			},
		})
	}

	/**
	 * Met à jour l'input.
	 * @param keyboardEvent
	 */
	handleChange = keyboardEvent => {
		this.setState({
			[keyboardEvent.target.name]: keyboardEvent.target.value,
			found: false,
		})
	}

	/**
	 * Met à jour dans le contexte le nouvel établissement choisi.
	 * @param clickEvent
	 * @param uai
	 */
	setEtab = (clickEvent = null, uai) => {
		if (clickEvent) {
			clickEvent.preventDefault()
		}

		const { loadSchool } = this.context

		this.setState({
			showDropdown: false,
			loadingSchool: true,
		})
		loadSchool(uai).then(() => this.setState({ loadingSchool: false }))
	}

	/**
	 * Rafraîchis l'application.
	 */
	refresh = () => {
		const {
			school: {
				informations: { uai },
			},
		} = this.context

		if (uai) {
			this.setEtab(null, uai)
		}
	}

	render() {
		const { shouldMount, showModal, schools, school: currentSchool, isLightTheme, translate, resetGridFilter } = this.context
		const { showDropdown, school, loadingSchool } = this.state

		return (
			shouldMount('NavBar_searchEtablissement', 'Lecture') && (
				<Navbar.Item renderAs={'div'}>
					<Form.Field className={'has-addons'}>
						{shouldMount('NavBar_addEtablissement', 'Lecture') && (
							<Form.Control>
								<Button size={'small'} color={'link'} onClick={() => showModal('addSchool', true)}>
									<Icon icon={'far fa-plus-hexagon'} />
								</Button>
							</Form.Control>
						)}
						<Form.Control className={'is-expanded'} iconLeft size={'small'} loading={loadingSchool}>
							<div className={`dropdown ${showDropdown ? 'is-active' : ''}`}>
								<div className="dropdown-trigger">
									<Form.Input
										disabled={!this.state.schools}
										onFocus={() => {
											this.setState({ showDropdown: true })
										}}
										autoComplete={'off'}
										size="small"
										className={`navbar-input school-input`}
										placeholder={translate('navbar.search_schools')}
										value={school}
										onChange={this.handleChange}
										name={'school'}
									/>
									<Icon icon={'fad fa-university'} className={'is-small is-left'} />
								</div>
								<div className={`dropdown-menu`} role="menu">
									<div className={`dropdown-content dropdown-menu-school ${!isLightTheme ? 'has-background-black' : ''}`}>{this.filterEtab()}</div>
								</div>
							</div>
						</Form.Control>
						<Form.Control>
							<Button
								size={'small'}
								color={'link'}
								onClick={() => {
									resetGridFilter('table', 'SchoolSearch.refreshEstab')
									this.refresh()
								}}
							>
								<Icon key={loadingSchool} icon={`far fa-sync-alt ${loadingSchool ? 'fa-spin' : ''}`} />
							</Button>
						</Form.Control>
						{schools?.find(s => s.UAI === currentSchool?.informations?.uai)?.Effectif === 0 && (
							<Form.Control>
								<Button size={'small'} color={'danger'} onClick={this.deleteActiveSchool}>
									<Icon key={loadingSchool} icon={`far fa-ban`} />
								</Button>
							</Form.Control>
						)}
					</Form.Field>
				</Navbar.Item>
			)
		)
	}
}
