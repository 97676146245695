import React, { Component } from 'react'
import GlobalContext from '../../../contexts/GlobalContext'
import * as PropTypes from 'prop-types'
import Query from '../../../tools/Query'

export default class ApplicationSwitch extends Component {
	static contextType = GlobalContext

	static propTypes = {
		/** L'identifiant de l'application, example: "KNE", "PV", ... **/
		applicationID: PropTypes.string,
	}

	/**
	 * Switch d'application
	 * @param props
	 */
	constructor(props) {
		super(props)

		this.state = {
			checked: props.getValue() === '1',
		}
	}

	findAppByIdProps = () => {
		const { applicationID } = this.props
		return this.context.school.applications.find(({ idApplication }) => idApplication === applicationID)
	}

	/**
	 * Determine si la modal doit être afficher ou non,
	 * Elle doit être afficher si elle respecte les règles suivantes :
	 * 	- Le bouton vient d'être activé
	 * 	- L'application n'as pas de code commun
	 * 	- L'application as un code par élève
	 */
	shouldDisplayModal = () => {
		// On cherche l'application en fonction du nom de la colonne
		const application = this.findAppByIdProps()
		return this.state.checked && application.urlSchemeCodeRequired
	}

	/**
	 * Génere un mot de passe si la clé connectDiffPassword est vrai,
	 * sinon reprend le même mot de passe que Tabuleo connect
	 */
	getAppPassword = async platformId => {
		const user = await Query.getUser(this.context, this.props.node.data['ID utilisateur'])
		const platformPassword = user.applications?.find(app => app.informations?.platformId === platformId)?.informations.platformPassword
		if (platformPassword) return [platformPassword]

		if (this.findAppByIdProps()?.connectDiffPassword == true) {
			return Query.getRandomPasswords(this.context, 1)
		} else {
			return [(await Query.getUser(this.context, this.props.node.data['ID utilisateur'])).informations.password]
		}
	}

	/**
	 * Récupère le code d'application si c'est un code commun
	 */
	getAppCode = () => {
		return this.findAppByIdProps().commonCode
	}

	handleChange = async () => {
		const { translate } = this.context
		this.setState(
			{
				checked: !this.state.checked,
			},
			async () => {
				const { applicationID } = this.props
				const application = this.findAppByIdProps()
				const platformId = application.platformId

				if (this.shouldDisplayModal()) {
					this.context.showModal('gridAddApp', true, {
						applicationID: applicationID,
						user: this.props.node.data,
						items: {
							login: this.props.node.data['Login'],
							password: (await this.getAppPassword(platformId))[0] + (platformId === 'PLTF_EDULIB' ? '!' : ''),
							codes: await this.getAppCode(),
						},
						application,
						// On désactive l'application quand l'utilisateur annule l'ajout d'application
						reset: () => this.setState({ checked: false }),
						updateCell: () => this.updateCell(),
					})
				} else {
					const applicationData = {
						informations: {
							idApplication: applicationID,
							status: this.state.checked ? 1 : 0,
						},
					}

					if (this.state.checked) {
						const password = (await this.getAppPassword(platformId))[0];
						applicationData.informations.passwordPlateforme = password + (platformId === 'PLTF_EDULIB' && !password.endsWith('!') ? '!' : '');
						applicationData.informations.loginPlateforme = this.props.node.data['Login'];						applicationData.informations.loginPlateforme = this.props.node.data['Login']
						applicationData.keys = this.getAppCode() ? [this.getAppCode()] : []
					}

					Query.updateUserInGrid(this.context, this.props.node.data['ID utilisateur'], 'applications', applicationData)
						.then(res => {
							this.updateCell()
							if (this.state.checked) {
								this.context.addToast(translate('table.application.enable_success', { name: application.name }), { appearance: 'success' })
							} else {
								this.context.addToast(translate('table.application.disable_success', { name: application.name }), { appearance: 'success' })
							}
							this.context.refresh()
						})
						.catch(err => {
							if (this.state.checked) {
								this.context.addToast(translate('modals.specific.user_apps.enable', { name: application.name }), { appearance: 'error' })
							} else {
								this.context.addToast(translate('modals.specific.user_apps.disable', { name: application.name }), { appearance: 'error' })
							}
						})
				}
			}
		)
	}

	updateCell = () => {
		this.props.setValue(this.state.checked ? '1' : '0')
	}

	render() {
		const key = 'app-' + Math.random() * Math.random()
		const checked = this.state.checked

		return (
			<div className={'has-text-centered'}>
				<input onChange={this.handleChange} id={key} type="checkbox" name="@" className="switch is-rounded is-success is-small" checked={checked} />
				<label htmlFor={key} />
			</div>
		)
	}
}
