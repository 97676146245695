import React, { FC, FormEvent, useContext, useState } from 'react'
import GlobalContext from '../../../contexts/GlobalContext'
import Icon from '../../utils/Icon'
// @ts-ignore
import { Box, Button, Columns, Heading, Section } from 'react-bulma-components'
import { SchoolApplicationSettings, UserApp, UserApplication } from './UserApp'
import _ from 'lodash'
import Queries from '../../../tools/Query'

interface ManualsPropsType {
}

export const Manuals: FC<ManualsPropsType> = () => {
	const context = useContext(GlobalContext)
	const { shouldMount, translate, isLightTheme, userPage, school } = context
	const appsSettings = school.applications

	const [loading, setLoading] = useState(false)
	const [edit, setEdit] = useState(false)
	const [errors, setErrors] = useState<Record<string, boolean>>({})
	const [userApps, setUserApps] = useState<UserApplication[]>(_.cloneDeep(userPage.applications))

	const checkErrors = (platformId: string, key: string, value: string) => {
		const {
			validations: {
				regexes: { login: regexLogin }
			}
		} = context

		let isInvalidLogin = false
		if (key === 'plateformeLogin' && value?.replace(new RegExp(regexLogin, 'g'), '') !== value) {
			isInvalidLogin = true
		}
		setErrors({ ...errors, [platformId]: isInvalidLogin })
	}

	type updateUserAppType = (plateformId: string, field: Partial<keyof UserApplication>, payload: any) => void

	const updateUserApp: updateUserAppType = (plateformId, field, payload) => {
		const newUserApps = [...userApps]
		for (let i = 0; i < newUserApps.length; i++) {
			if (newUserApps[i].informations.platformId === plateformId) {
				newUserApps[i].informations = { ...newUserApps[i].informations, [field]: payload }
			}
		}
		console.log(newUserApps)
		setUserApps(newUserApps)
		checkErrors(plateformId, field, payload)
	}

	const initState = () => {
		setEdit(false)
		setLoading(false)
		setErrors({})
	}

	const updateProfile = (formEvent: FormEvent) => {
		formEvent.preventDefault()

		if (!edit) {
			return setEdit(true)
		}
		const isValid = Object.values(errors).every(error => !error)

		if (!_.isEqual(userPage.applications, userApps) && isValid) {
			const { addToast, updateUserPage, translate, userPage } = context

			const applications = userApps.map(({
												   informations: {
													   applicationId,
													   platformId,
													   platformLogin,
													   platformPassword,
													   applicationKeys
												   }
											   }) => {
				const { urlSchemeLoginRequired = false, urlSchemePwdRequired = false } = findAppSettings(applicationId)
				if ((urlSchemeLoginRequired && !platformLogin) || (urlSchemePwdRequired && !platformPassword)) {
					return addToast(translate('global.errors.empty_fields'), { appearance: 'warning' })
				}

				return { applicationId, platformId, platformLogin, platformPassword, applicationKeys }
			})

			const { informations } = userPage
			const userParams = { informations, applications }
			setLoading(true)

			Queries.updateUserPage(context, informations.userId, userParams)
				.then(() => {
					initState()
					addToast(translate('user.update_success'), { appearance: 'success' })
					updateUserPage({ applications })
				})
				.catch(err => {
					addToast(translate('global.errors.occurred'), { appearance: 'error' })
					setEdit(false)
					setLoading(false)
				})
		} else {
			return setEdit(false)
		}
	}

	const findAppSettings = (appId: string) => {
		let mySchool = appsSettings.find(({ idApplication }: SchoolApplicationSettings) => idApplication === appId)
		// needs to fetch platformCodeYesNo and required for asking commonCode or not
		//@ts-ignore
		const platformSchool = school.platforms.find(({ platformId }) => platformId === mySchool.platformId)
		mySchool = {
			...mySchool,
			platformCodeRequired: platformSchool.platformCodeRequired,
			platformCodeYesNo: platformSchool.platformCodeYesNo,
			commonCode: platformSchool.commonCode
		}
		return mySchool
	}

	const isValid = Object.values(errors).every(error => !error)

	return (
		<Box style={{ backgroundColor: isLightTheme ? '' : '#222' }}>
			<form onSubmit={updateProfile}>
				<div className="is-flex"
					 style={{ justifyContent: shouldMount('FicheUser_profil', 'Ecriture') && 'space-between' }}>
					<Heading size={5} textColor={isLightTheme ? '' : 'grey-lighter'}>
						{translate('user.my_apps')}
					</Heading>
					{shouldMount('FicheUser_profil', 'Ecriture') &&
						(edit ? (
							<Button size={'small'} color={'success'} loading={loading} type={'submit'}
									disabled={!isValid}>
								<Icon key={'save'} icon={'fal fa-check'} />
								<span className="is-text-icon">{translate('global.save')}</span>
							</Button>
						) : (
							<Button size={'small'} color={'info'} type={'submit'}>
								<Icon key={'edit'} icon={'fal fa-user-edit'} />
								<span className="is-text-icon">{translate('global.update')}</span>
							</Button>
						))}
				</div>
				<section
					className="mt-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 grid">
					{userApps?.length > 0 ? (
						appsSettings
							.filter((appSettings: any) => userApps.find(userApp => userApp.informations.applicationId === appSettings.idApplication))
							.sort((appsSettingsA: any, appsSettingsB: any) => appsSettingsA.order - appsSettingsB.order)
							.map((appSettings: any, idx: number) => {
								const app = userApps.find(userApp => userApp.informations.applicationId === appSettings.idApplication)
								if (!app) return null
								const {
									informations: { applicationId }
								} = app
								return <UserApp updateAppData={updateUserApp} edit={edit} key={idx}
												appData={findAppSettings(applicationId)} userApp={app}
												isInvalidLogin={errors[applicationId]} />
							})
					) : (
						<Section>
							<Heading subtitle size={6}>
								{translate('user.no_apps')}
							</Heading>
						</Section>
					)}
				</section>
			</form>
		</Box>
	)
}
