import React from 'react'
import { Heading, Menu, Columns, Box, Section } from 'react-bulma-components'
import Icon from './utils/Icon'
import SettingsGeneral from './settings/General'
import { SettingsApps } from './settings/SettingsApps'
import GlobalContext from '../contexts/GlobalContext'
import Hr from './utils/Hr'
import SettingsContact from './settings/Contact'
import SettingsDeploy from './settings/Deploy'
import SettingsEcoleDirecte from './settings/EcoleDirecte'

export default class Settings extends React.Component {
	static contextType = GlobalContext

	constructor(props, context) {
		super(props)
		const { translate, shouldMount } = context
		this.menu = [
			{
				title: translate('settings.menu.manager'),
				subview: [
					{
						rights: 'SettingsGeneral',
						name: translate('settings.menu.general'),
						icon: 'fal fa-cog',
						value: 'general',
					},
					{
						rights: 'SettingsApplications',
						name: translate('settings.menu.platforms'),
						icon: 'fal fa-tablet',
						value: 'apps',
					},
					{
						rights: 'SettingsEcoleDirecte',
						name: 'Ecole directe',
						icon: 'fab fa-etsy',
						value: 'ecoleDirecte',
					},
				],
			},
			{
				title: translate('settings.menu.school'),
				subview: [
					{
						rights: 'SettingsDeployment',
						name: translate('settings.menu.deploy'),
						icon: 'fal fa-tools',
						value: 'deployment',
					},
					{
						rights: 'SettingsContact',
						name: translate('settings.menu.contact'),
						icon: 'fal fa-phone-office',
						value: 'contact',
					},
				],
			},
		]

		const availableViews = this.menu.flatMap(menu => menu.subview).filter(subview => shouldMount(subview.rights, 'Lecture'))
		const availableMenu = this.menu.filter(menu => menu.subview.some(subview => availableViews.includes(subview))).map(menu => menu.title)

		this.state = {
			view: availableViews[0].value,
			availableMenu,
		}
		this.views = {
			general: <SettingsGeneral />,
			apps: <SettingsApps />,
			ecoleDirecte: <SettingsEcoleDirecte />,

			deployment: <SettingsDeploy />,
			contact: <SettingsContact />,
		}
	}

	render() {
		const { isLightTheme, translate } = this.context
		const {
			school: { informations: { uai } = {} },
			shouldMount,
		} = this.context

		return (
			<Section style={{ height: 'calc(100vh - 3.5rem)', overflowY: 'auto' }}>
				<Heading textColor={!isLightTheme ? 'light' : ''} className={'fade-in-right'}>
					{translate('settings.title')}
				</Heading>
				<Hr />
				{!uai ? (
					<Heading className={'fade-in-fwd'} subtitle>
						{translate('global.errors.no_school')}
					</Heading>
				) : (
					<Columns>
						<Columns.Column className={'is-narrow is-one-quarter-widescreen'}>
							<Box backgroundColor={!isLightTheme ? 'black' : ''} className={'fade-in-bottom'} style={{ animationDelay: '50ms' }}>
								<Menu className={'params-menu'}>
									{this.menu
										.filter(view => this.state.availableMenu.includes(view.title))
										.map((view, idx) => (
											<Menu.List key={idx} title={view.title}>
												{view.subview
													.filter(subview => shouldMount(subview.rights, 'Lecture'))
													.map((sub, idx) => (
														<Menu.List.Item key={idx} active={this.state.view === sub.value} onClick={() => this.setState({ view: sub.value })}>
															<>
																<Icon icon={sub.icon} />
																<span className="is-text-icon">{sub.name}</span>
															</>
														</Menu.List.Item>
													))}
											</Menu.List>
										))}
								</Menu>
							</Box>
						</Columns.Column>
						<Columns.Column>
							<Box backgroundColor={!isLightTheme ? 'black' : ''} className={'fade-in-bottom'} style={{ animationDelay: '100ms' }}>
								{this.views[this.state.view]}
							</Box>
						</Columns.Column>
					</Columns>
				)}
			</Section>
		)
	}
}
