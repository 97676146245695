import React from 'react'
import { WrapperDiv, SmallWrapperDiv, MiniWrapperDiv, OptionWrapper, SelectedOptionWrapper } from './SegmentedControl.styles'
import GlobalContext from '../../contexts/GlobalContext'

class SegmentedControl extends React.Component {
	static contextType = GlobalContext

	constructor(props) {
		super(props)
		this.state = {
			selectedValue: this.props.defaultValue,
		}
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		if (this.state.selectedValue !== nextProps.defaultValue) {
			this.setState({ selectedValue: nextProps.defaultValue })
			return true
		}
		return false
	}

	setSelectedValue = value => {
		if (value !== this.state.selectedValue) {
			this.setState({
				selectedValue: value,
			})

			this.props.onChange(value)
		}
	}

	render() {
		const { defaultValue, children, onChange, ...rest } = this.props

		const WrapperDivComp = this.props.small ? SmallWrapperDiv : this.props.mini ? MiniWrapperDiv : WrapperDiv

		const optionsMarkup = this.props.children.map(o => {
			const { value, children } = o.props
			const selected = this.state.selectedValue === value

			return (
				<SegmentedOption key={value} value={value} selected={selected} setSelectedValue={this.setSelectedValue} {...rest}>
					{children}
				</SegmentedOption>
			)
		})

		return (
			<WrapperDivComp {...rest} dark={!this.context.isLightTheme} className={`segmented ${!this.context.isLightTheme && 'is-dark'}`}>
				{optionsMarkup}
			</WrapperDivComp>
		)
	}
}

class SegmentedOption extends React.Component {
	static contextType = GlobalContext

	handleClick = () => {
		this.props.setSelectedValue(this.props.value)
	}

	render() {
		const OptionWrapperComponent = this.props.selected ? SelectedOptionWrapper : OptionWrapper

		const { children, selected, setSelectedValue, value, ...rest } = this.props

		return (
			<OptionWrapperComponent dark={!this.context.isLightTheme} onClick={this.handleClick} {...rest}>
				{children}
			</OptionWrapperComponent>
		)
	}
}

export { SegmentedControl, SegmentedOption }
