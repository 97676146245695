import React from 'react'
import { Button, Columns, Form, Heading } from 'react-bulma-components'
import Icon from '../utils/Icon'
import GlobalContext from '../../contexts/GlobalContext'
import Hr from '../utils/Hr'
import Queries from '../../tools/Query'

export default class SettingsGeneral extends React.Component {
	static contextType = GlobalContext

	constructor(props, context) {
		super(props)
		const { regexEstab, regexGlobal } = context.school
		this.hasMadeChange = false
		this.schoolRegex = regexEstab
		this.globalRegex = regexGlobal
		this.state = {
			edit: false,
			loading: false,
			valueRegexLogin: this.schoolRegex?.find(regex => regex.field === 'login')?.regex?.replaceAll('\\', '') || this.globalRegex?.find(regex => regex.field === 'login')?.special?.regex?.replaceAll('\\', '') || '',
			adminDuration: context.school.informations.adminDuration,
			extension: context.school.informations.extension,
			connectRndPwd: context.school.informations.connectRndPwd,
			ecoleDirecteApiKey: context.school.deployment.ecoleDirecteApiKey,
		}
	}

	handleChange = ({ target }, label) => {
		this.hasMadeChange = true
		const { name, value, checked } = target

		this.setState({
			[name]: name === 'connectRndPwd' ? checked : value,
			[`isUsingGlobalRegex_${label}`]: value === '',
		})
	}

	findRegexSpeciales = (field, context) => {
		const { school } = context
		const specials = school.RegexSpeciales ? [...school.RegexSpeciales] : null

		const regex = specials && specials.find(item => item.Colonne === field)

		return regex ? regex.RegexSpeciale && regex.RegexSpeciale : ''
	}

	saveParams = event => {
		event.preventDefault()

		if (!this.state.edit) {
			return this.setState({ edit: true })
		} else if (!this.hasMadeChange) {
			return this.setState({ edit: false })
		}

		const { school, updateSchoolParams, addToast, translate } = this.context
		const { extension, adminDuration, connectRndPwd, valueRegexLogin, ecoleDirecteApiKey } = this.state

		const newParams = {
			informations: {
				extension,
				adminDuration,
				connectRndPwd,
				ecoleDirecteApiKey,
			},
			regexEstab: [
				{
					field: 'login',
					regex: valueRegexLogin,
				},
			],
		}

		this.setState({ loading: true })

		Queries.updateParams(this.context, school.informations.uai, newParams)
			.then(() => {
				const { informations, regexEstab } = newParams

				updateSchoolParams({ informations: { ...school.informations, ...informations }, regexEstab })
				addToast(translate('settings.update_success'), { appearance: 'success' })

				this.setState({ loading: false, edit: false })
			})
			.catch(() => {
				addToast(translate('global.errors.occurred'), { appearance: 'error' })

				this.setState({ loading: false })
			})
	}

	render() {
		const { isLightTheme, translate, school } = this.context
		const { edit, loading, connectRndPwd, extension, adminDuration, valueRegexLogin } = this.state

		const defaultLoginRegex = school.regexGlobal.find(regex => regex.field === 'login')?.special?.replaceAll('\\', '')

		return (
			<form onSubmit={this.saveParams}>
				<div className="is-flex" style={{ justifyContent: 'space-between' }}>
					<Heading textColor={!isLightTheme ? 'light' : ''} size={5} style={{ marginBottom: '0' }}>
						{translate('settings.general.title')}
					</Heading>
					{edit ? (
						<Button size={'small'} color={'success'} loading={loading} type="submit">
							<Icon key={'save'} icon={'fal fa-check'} />
							<span className="is-text-icon">{translate('global.save')}</span>
						</Button>
					) : (
						<Button size={'small'} color={'info'} type="submit">
							<Icon key={'edit'} icon={'fal fa-money-check-edit'} />
							<span className="is-text-icon">{translate('global.update')}</span>
						</Button>
					)}
				</div>
				<Hr />
				<Heading subtitle size={6}>
					{translate('settings.title')}
				</Heading>
				<Columns>
					<Columns.Column size={'two-fifths'}>
						<Form.Field>
							<Form.Label textColor={!isLightTheme ? 'grey' : ''} size={'small'}>
								{translate('settings.general.login_extension')}
							</Form.Label>
							<Form.Control>
								<Form.Input backgroundColor={!isLightTheme ? 'dark' : ''} textColor={!isLightTheme ? 'light' : ''} size={'small'} disabled={!edit} value={extension} name={'extension'} onChange={this.handleChange} />
							</Form.Control>
						</Form.Field>
					</Columns.Column>
					<Columns.Column>
						<Form.Field>
							<Form.Label textColor={!isLightTheme ? 'grey' : ''} size={'small'}>
								{translate('settings.general.random_password')}
							</Form.Label>
							<input id="connectRndPwd" type="checkbox" className="switch is-rounded is-success is-small" checked={connectRndPwd} name={'connectRndPwd'} disabled={!edit} onChange={this.handleChange} />
							<label htmlFor="connectRndPwd" />
						</Form.Field>
					</Columns.Column>
					<Columns.Column size={'two-fifths'}>
						<Form.Field>
							<Form.Label textColor={!isLightTheme ? 'grey' : ''} size={'small'}>
								{translate('settings.general.admin_duration')}
							</Form.Label>
							<Form.Control>
								<Form.Input backgroundColor={!isLightTheme ? 'dark' : ''} textColor={!isLightTheme ? 'light' : ''} size={'small'} value={adminDuration} name={'adminDuration'} disabled={!edit} onChange={this.handleChange} />
							</Form.Control>
							<Form.Help>{translate('settings.general.admin_duration_help')}</Form.Help>
						</Form.Field>
					</Columns.Column>
				</Columns>
				<Heading subtitle size={6}>
					{translate('settings.general.exceptions')}
				</Heading>
				<Form.Field>
					<Form.Label textColor={!isLightTheme ? 'grey' : ''} size={'small'}>
						{translate('global.login')}
					</Form.Label>
					<Form.Field>
						<Form.Control>
							<Form.Input placeholder={translate('settings.general.default') + ' : ' + defaultLoginRegex} backgroundColor={!isLightTheme ? 'dark' : ''} textColor={!isLightTheme ? 'light' : ''} size={'small'} value={valueRegexLogin} disabled={!edit} onChange={e => this.handleChange(e, 'login')} name={'valueRegexLogin'} />
						</Form.Control>
					</Form.Field>
				</Form.Field>
			</form>
		)
	}
}
