import React from 'react'
import { Columns, Box, Heading, Section, Content, Notification } from 'react-bulma-components'
import Icon from './utils/Icon'
import { Doughnut } from 'react-chartjs-2'
import GlobalContext from '../contexts/GlobalContext'
import Hr from './utils/Hr'
import appsColor from '../config/apps_color.json'

export default class Dashboard extends React.Component {
	static contextType = GlobalContext

	constructor(props) {
		super(props)
		this.state = {
			uai: undefined,
			apps: undefined,
			roles: undefined,
			admin: undefined,
			connected: undefined,
		}
	}

	uai() {
		const {
			school: {
				informations: { uai },
			},
		} = this.context
		return uai
	}

	componentDidMount() {
		if (this.uai()) {
			this.initData()
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.uai && !this.uai()) {
			this.setState({ uai: undefined })
		}

		if (this.uai() && prevState.uai !== this.uai()) {
			this.initData()
		}
	}

	initData = () => {
		this.setState({
			uai: this.uai(),
			apps: this.getAppsData(),
			roles: this.getRolesData(),
			admin: this.getAdminData(),
			connected: this.getConnected(),
		})
	}

	getAppsData = () => {
		const { isLightTheme, grid, students, school } = this.context

		const apps = Object.values(school.applications)

		let countedApps = {}
		apps.map(app => {
			countedApps = {
				...countedApps,
				[app.name]: 0,
			}
			return app
		})

		if (grid && grid.getSelectedRows() > 0) {
			grid.getSelectedRows().map(user => {
				apps.map(app => {
					user[app.name] && ++countedApps[app.name]

					return app
				})
				return user
			})
		} else {
			students.forEach(user => {
				apps.map(app => {
					user[app.name] && ++countedApps[app.name]

					return app
				})
			})
		}

		const sortedApps = []
		for (const app in countedApps) {
			sortedApps.push([app, countedApps[app]])
		}

		sortedApps.sort((a, b) => b[1] - a[1])

		const sumOfLasts = sortedApps.slice(5).reduce((counter, app) => counter + app[1], 0)

		let res = {}
		sortedApps.slice(0, 5).map(app => {
			res = {
				...res,
				[app[0]]: {
					value: app[1],
					color: appsColor[app[0]],
				},
			}

			return app
		})
		res = {
			...res,
			Autres: {
				value: sumOfLasts,
				color: '#aaaaaa',
			},
		}

		return {
			labels: Object.keys(res)
				.filter(app => res[app].value !== 0)
				.map(app => `${app} (${res[app].value})`),
			datasets: [
				{
					label: 'Utilisateurs',
					data: Object.values(res)
						.filter(app => app.value !== 0)
						.map(res => res.value),
					backgroundColor: Object.values(res)
						.filter(app => app.value !== 0)
						.map(res => res.color),
					borderWidth: 1,
					borderColor: isLightTheme ? '#ffffff' : '#262626',
				},
			],
		}
	}

	getRolesData = () => {
		let labels = {}

		const { grid, students, isLightTheme } = this.context

		if (grid && grid.getSelectedRows() > 0) {
			grid.getSelectedRows().map(user => {
				const role = user.Profil

				Object.keys(labels).includes(role) ? ++labels[role] : (labels = { ...labels, [role]: 1 })

				return user
			})
		} else {
			students.forEach(user => {
				const role = user.Profil

				Object.keys(labels).includes(role) ? ++labels[role] : (labels = { ...labels, [role]: 1 })
			})
		}

		return {
			labels: Object.keys(labels).map(label => `${label} (${labels[label]})`),
			datasets: [
				{
					data: Object.values(labels),
					backgroundColor: ['#12E288', '#0ead69', '#0C975B', '#0A7144', '#074B2D'],
					hoverBackgroundColor: ['#12E288', '#0ead69', '#0C975B', '#0A7144', '#074B2D'],
					borderColor: isLightTheme ? '#ffffff' : '#262626',
				},
			],
		}
	}

	getAdminData = () => {
		let enabled = 0
		let disabled = 0

		const { grid, students, isLightTheme, translate } = this.context

		if (grid && grid.getSelectedRows() > 0) {
			grid.getSelectedRows().map(user => {
				user['Mode admin'] === 1 ? ++enabled : ++disabled

				return user
			})
		} else {
			students.forEach(user => {
				user['Mode admin'] === 1 ? ++enabled : ++disabled
			})
		}

		return {
			labels: [`${translate('global.enabled')} (${enabled})`, `${translate('global.disabled')} (${disabled})`],
			datasets: [
				{
					data: [enabled, disabled],
					backgroundColor: ['#a53860', '#da627d'],
					hoverBackgroundColor: ['#a53860', '#da627d'],
					borderColor: isLightTheme ? '#ffffff' : '#262626',
				},
			],
		}
	}

	getConnected = () => {
		let connected = 0
		let vanished = 0

		const { grid, students, isLightTheme, translate } = this.context

		if (grid && grid.getSelectedRows() > 0) {
			grid.getSelectedRows().map(user => {
				user['Dernière connexion'] ? ++connected : ++vanished

				return user
			})
		} else {
			students.forEach(user => {
				user['Dernière connexion'] ? ++connected : ++vanished
			})
		}

		return {
			labels: [`${translate('global.connected')} (${connected})`, `${translate('global.never_connected')} (${vanished})`],
			datasets: [
				{
					data: [connected, vanished],
					backgroundColor: ['#fdb833', '#fff056'],
					hoverBackgroundColor: ['#fdb833', '#fff056'],
					borderColor: isLightTheme ? '#ffffff' : '#262626',
				},
			],
		}
	}

	render() {
		const { isLightTheme, translate } = this.context

		return (
			<Section style={{ maxHeight: 'calc(100vh - 3.5rem)', overflowY: 'scroll', overflowX: 'hidden' }}>
				<Heading textColor={!isLightTheme ? 'light' : ''} className={'fade-in-right'}>
					{translate('dashboard.title')}
				</Heading>
				<Hr />
				{!(this.state.uai && this.context.school.applications) ? (
					<Heading className={'fade-in-fwd'} subtitle>
						{translate('global.errors.no_school')}
					</Heading>
				) : (
					<Columns multiline>
						<Columns.Column size={'half'}>
							<Box backgroundColor={!isLightTheme ? 'black' : ''} className={'fade-in-bottom'}>
								<Heading subtitle size={6} textColor={!isLightTheme ? 'light' : ''}>
									<Icon icon={'fad fa-tablet'} style={{ color: '#68BDE0' }} />
									<span className="is-text-icon">{translate('dashboard.user_per_apps')}</span>
								</Heading>
								<Hr />
								<Doughnut data={this.state.apps} />
							</Box>
						</Columns.Column>
						<Columns.Column size={'half'}>
							<Box backgroundColor={!isLightTheme ? 'black' : ''} className={'fade-in-bottom'} style={{ animationDelay: '100ms' }}>
								<Heading subtitle size={6} textColor={!isLightTheme ? 'light' : ''}>
									<Icon icon={'fad fa-graduation-cap'} style={{ color: '#074B2D' }} />
									<span className="is-text-icon">{translate('dashboard.role_repartition')}</span>
								</Heading>
								<Hr />
								<Doughnut data={this.state.roles} />
							</Box>
						</Columns.Column>
						<Columns.Column size={'half'}>
							<Box backgroundColor={!isLightTheme ? 'black' : ''} className={'fade-in-bottom'} style={{ animationDelay: '200ms' }}>
								<Heading subtitle size={6} textColor={!isLightTheme ? 'light' : ''}>
									<Icon icon={'fad fa-qrcode'} style={{ color: '#fdb833' }} />
									<span className="is-text-icon">{translate('dashboard.users_connected')}</span>
								</Heading>
								<Hr />
								<Doughnut data={this.state.connected} />
							</Box>
						</Columns.Column>
						<Columns.Column size={'half'}>
							<Box backgroundColor={!isLightTheme ? 'black' : ''} className={'fade-in-bottom'} style={{ animationDelay: '300ms' }}>
								<Heading subtitle size={6} textColor={!isLightTheme ? 'light' : ''}>
									<Icon icon={'fad fa-star-exclamation'} style={{ color: '#da627d' }} />
									<span className="is-text-icon">{translate('dashboard.admin_mode')}</span>
								</Heading>
								<Hr />
								<Doughnut data={this.state.admin} />
							</Box>
						</Columns.Column>
					</Columns>
				)}
			</Section>
		)
	}
}
