import React, { ChangeEvent, ReactNode, useContext, useEffect, useReducer, useState } from 'react'
import Query from '../../../tools/Query'
// @ts-ignore
import { Button, Form, Heading, Tag } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import Hr from '../../utils/Hr'
import GlobalContext from '../../../contexts/GlobalContext'
import Queries from '../../../tools/Query'
import { Schools } from '../../modals/specific/Schools'
import { Apps } from '../../modals/specific/Apps'
import { io } from 'socket.io-client'
import { Application, GlobalNotificationsProps, Profile, ReducerActionType, ReducerType, Historical, School } from './types'

const GlobalNotifications: React.FC<GlobalNotificationsProps> = ({ show, onClose, changeView }) => {
	const initialState: ReducerType = {
		arrayUai: ['all'],
		subject: '',
		arrayProfiles: [],
		arrayApplications: [],
		title: '',
		bodyInput: '',
		linkInput: '',
		count: 1,
	}

	function _reducer(state: ReducerType, { key, value }: ReducerActionType): ReducerType {
		return { ...state, [key]: value }
	}

	const context = useContext(GlobalContext)
	const { schools } = context

	const [state, dispatch] = useReducer(_reducer, initialState)

	const [nbUser, setNbUser] = useState<number | null>(0)
	const [nbToken, setNbToken] = useState<number | null>(0)
	const [viewTotalUser, setViewTotalUser] = useState<number>(1)
	const [tokensUsers, setTokensUsers] = useState<Array<string>>([])
	const [dataProfil, setDataProfil] = useState<Profile[]>([])
	const [dataApp, setDataApp] = useState<Application[]>([])
	const [allApp, setAllApp] = useState(dataApp.length === (dataApp || []).length)
	const [dataOperator, setDataOperator] = useState<Historical>()

	const [error, setError] = useState<string | null>(null)
	const [page, setPage] = useState(0)

	const [isDeployUAI, setIsDeployUAI] = useState(false)
	const [isDeployApp, setIsDeployApp] = useState(true)

	const allUAI = state.arrayUai.length === 1 && state.arrayUai[0] === 'all'

	// @ts-ignore
	const searchOperator = dataOperator?.subject ? Object.values(dataOperator.subject)[0] : {}
	const logicalOperator = dataOperator?.subject ? Object.values(dataOperator.subject)[1] : {}

	const [progress, setProgress] = useState<number>(0)
	const [isTotalTokenBar, setisTotalTokenBar] = useState<number>(0)
	const [isConfirmSendNotification, setisConfirmSendNotification] = useState<boolean>(false)

	function formatNumber(number: number) {
		return new Intl.NumberFormat('fr-FR').format(number)
	}

	function sendNotification() {
		const { addToast, translate } = context

		const successCount = 0
		const failureCount = 0

		// Envoi la notification et passe les éléments en param pour enregistrer la notif en BDD
		Queries.sendNotificationMassive(context, tokensUsers, state.title, state.bodyInput, state.linkInput, state.arrayUai, state.arrayProfiles, state.arrayApplications, state.subject, logicalOperator, searchOperator, nbUser, nbToken, successCount, failureCount)
			.then(res => {
				addToast(
					translate('modals.generic.bulk_success', {
						action: 'Notification',
						users: nbUser,
					}),
					{ appearance: 'success' }
				)
				setTimeout(() => changeView(), 2000)
			})
			.catch(err => {
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
			})

		setisTotalTokenBar(nbToken!)
		setisConfirmSendNotification(true)

		const socket = io(process.env.REACT_APP_URLSERVER!, { transports: ['websocket'] })

		socket.on('progressBar', args => {
			setProgress(args)
		})
	}

	const calculProgressPourcentage = (progress / isTotalTokenBar) * 100

	function getToken() {
		state.count = 0
		Queries.getTokensUserNotification(context, state.arrayUai, state.arrayApplications, state.subject, state.arrayProfiles, state.count)
			.then(res => {
				if (res) {
					setTokensUsers(res)
					setPage(page + 1)
				}
			})
			.catch(err => {
				setError('Aucun Token trouvé')
			})
	}

	function getUserToken() {
		Queries.getTokensUserNotification(context, state.arrayUai, state.arrayApplications, state.subject, state.arrayProfiles, state.count).then(res => {
			if (res && res.length > 0) {
				const { nbUsers, nbTokens } = res[0]
				setNbToken(nbTokens)
				setNbUser(nbUsers)
				setPage(page + 1)
			} else {
				setError('Aucun Token trouvé')
			}
		})
	}

	const stepRenderer: Array<ReactNode> = [
		<article>
			<header className="flex justify-between">
				<Heading size={5}>Filtres</Heading>
			</header>
			<Hr />
			<Form.Field className="my-8 bg-gray-50 p-4 rounded">
				<div className="flex justify-between" onClick={() => (isDeployUAI ? setIsDeployUAI(false) : setIsDeployUAI(true))}>
					<Form.Label>{allUAI ? 'Tous les' : state.arrayUai.length} UAI</Form.Label>
					<Icon key={isDeployUAI ? 'up' : 'down'} icon={isDeployUAI ? 'fal fa-chevron-up' : 'fal fa-chevron-down'} />
				</div>
				<div className="flex justify-between my-4">
					<Form.Field>
						<Form.Control>
							<input
								id="all-estab"
								name="all-estab"
								type="checkbox"
								checked={allUAI}
								className="switch is-rounded is-success is-small mt-2"
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									dispatch({
										key: 'arrayUai',
										// @ts-ignore
										value: e.target.checked ? ['all'] : [],
									})
								}}
							/>
							<label htmlFor="all-estab">Tous les établissements</label>
						</Form.Control>
					</Form.Field>
					{allUAI && <Tag color="success">Envoyer à tous les établissements</Tag>}
				</div>
				{!allUAI && schools.length && (
					<>
						<div className="flex justify-between my-4">
							<div className="flex flex-wrap gap-2 w-full">
								{state.arrayUai.map((uai: any, k: number) => (
									<Form.Control key={k}>
										<Tag.Group className="has-addons">
											<Tag color={'dark'}>{uai}</Tag>
											<Tag
												remove
												className="cursor-pointer"
												onClick={() => {
													state.arrayUai.splice(state.arrayUai.indexOf(uai), 1)
													dispatch({
														key: 'arrayUai',
														// @ts-ignore
														value: [...state.arrayUai],
													})
												}}
											/>
										</Tag.Group>
									</Form.Control>
								))}
							</div>
						</div>
						{!allUAI && !isDeployUAI && (
							<Schools
								addSchool={affectedSchool => {
									if (!state.arrayUai.includes(affectedSchool)) {
										dispatch({
											key: 'arrayUai',
											// @ts-ignore
											value: [...state.arrayUai, affectedSchool],
										})
									}
								}}
							/>
						)}
					</>
				)}
			</Form.Field>

			<Form.Field className="my-8 bg-gray-50 p-4 rounded">
				<Form.Label>
					Matières (utilisation des opérateurs :{' '}
					{dataOperator?.subject &&
						Object.values(dataOperator.subject)
							.map(i => i)
							.join(' et ')}
					)
				</Form.Label>
				<Form.Control>
					<Form.Input
						value={state.subject}
						name={'matieres'}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							dispatch({
								key: 'subject',
								value: e.target.value,
							})
						}}
					/>
				</Form.Control>
			</Form.Field>

			<Form.Control className={'is-expanded my-8 bg-gray-50 p-4 rounded'}>
				<div className="flex justify-between">
					<Form.Label>{state.arrayProfiles.length === 0 ? 'Tous les' : state.arrayProfiles.length} Profils</Form.Label>
				</div>
				<fieldset className="flex flex-col space-y-1 mt-4 ml-4">
					<label htmlFor="">
						<input
							type="checkbox"
							name="profile"
							checked={state.arrayProfiles.length === 0}
							onChange={e =>
								e.target.checked &&
								dispatch({
									key: 'arrayProfiles',
									// 	@ts-ignore
									value: [],
								})
							}
						/>
						<span className="pl-1 font-semibold">Tous les profils</span>
					</label>
					{dataProfil.map(p => (
						<label htmlFor="">
							<input
								type="checkbox"
								checked={state.arrayProfiles.includes(p.IDprofil)}
								name="profile"
								id=""
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									if (e.target.checked) {
										dispatch({
											key: 'arrayProfiles',
											// @ts-ignore
											value: [...state.arrayProfiles, p.IDprofil],
										})
									} else {
										dispatch({
											key: 'arrayProfiles',
											// @ts-ignore
											value: [...state.arrayProfiles.filter(pro => pro !== p.IDprofil)],
										})
									}
								}}
							/>
							<span className="pl-1">{p.Nom}</span>
						</label>
					))}
				</fieldset>
			</Form.Control>

			<Form.Control className={'is-expanded my-8 bg-gray-50 p-4 rounded'}>
				<div className="flex justify-between" onClick={() => (isDeployApp ? setIsDeployApp(false) : setIsDeployApp(true))}>
					<Form.Label>{allApp ? 'Toutes les' : state.arrayApplications.length} Applications</Form.Label>
					<Icon key={allApp || isDeployApp ? 'up' : 'down'} icon={allApp || isDeployApp ? 'fal fa-chevron-up' : 'fal fa-chevron-down'} />
				</div>
				{allApp || state.arrayApplications.length === dataApp.length ? (
					<div className="flex justify-between my-4">
						<Form.Field>
							<Form.Control>
								<input
									id="all-app"
									name="all-app"
									type="checkbox"
									checked={allApp}
									className="switch is-rounded is-success is-small mt-2"
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										setAllApp(!allApp)
										dispatch({
											key: 'arrayApplications',
											// @ts-ignore
											value: [],
										})
									}}
								/>
								<label htmlFor="all-app">Aucun filtre pour les applications</label>
							</Form.Control>
						</Form.Field>
						{allApp && <Tag color="success">Envoyer à tous les utilisateurs</Tag>}
					</div>
				) : (
					<>
						<div className="flex justify-between my-4">
							<div className="flex flex-wrap gap-2 w-full">
								<div className="flex items-start">
									<Form.Field>
										<Form.Control>
											<div className="flex items-center">
												<input
													id="all-app"
													name="all-app"
													type="checkbox"
													checked={allApp}
													className="switch is-rounded is-success is-small mt-2 mr-2"
													onChange={(e: ChangeEvent<HTMLInputElement>) => {
														setAllApp(!allApp)
														dispatch({
															key: 'arrayApplications',
															// @ts-ignore
															value: [],
														})
													}}
												/>
												<label htmlFor="all-app">Aucun filtre pour les applications</label>
											</div>
										</Form.Control>
									</Form.Field>
								</div>
								<div className="flex flex-wrap gap-2 w-full">
									{state.arrayApplications.map((AppId, k) => {
										const app = dataApp.find(app => app.idApplication === AppId)
										return (
											<Form.Control key={k} className="mt-2">
												{' '}
												{/* Added mt-2 for margin */}
												<Tag.Group className="has-addons">
													<Tag color={'dark'}>{app ? app.name : AppId}</Tag>
													<Tag
														remove
														className="cursor-pointer"
														onClick={() => {
															const newArray = state.arrayApplications.filter(id => id !== AppId)
															dispatch({
																key: 'arrayApplications',
																// @ts-ignore
																value: newArray,
															})
														}}
													/>
												</Tag.Group>
											</Form.Control>
										)
									})}
								</div>
							</div>
						</div>
						{isDeployApp && !allApp && (
							<Apps
								dataApp={dataApp}
								addApp={affectedApp => {
									const affect = dataApp.find(app => app.idApplication === affectedApp)
									if (!state.arrayApplications.includes(affect?.idApplication!)) {
										dispatch({
											key: 'arrayApplications',
											//@ts-ignore
											value: [...state.arrayApplications, affect?.idApplication],
										})
									}
								}}
							/>
						)}
					</>
				)}
			</Form.Control>
			<div className="flex justify-between my-4 bg-gray-50 p-4">
				<Form.Field>
					<Form.Control>
						<input
							id="view-token"
							name="view-token"
							type="checkbox"
							checked={viewTotalUser === 1 ? true : false}
							className="switch is-rounded is-success is-small mt-2"
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								viewTotalUser === 1 ? setViewTotalUser(0) : setViewTotalUser(1)
							}}
						/>
						<label htmlFor="view-token">Voir le total des utilisateurs</label>
					</Form.Control>
				</Form.Field>
				{viewTotalUser === 1 ? <Tag color="success">Voir le total des utilisateurs</Tag> : null}
			</div>
			<br />
			<div className="is-flex" style={{ justifyContent: 'space-between' }}>
				<Button color={'primary'} className="ml-auto" onClick={getUserToken}>
					Suivant
				</Button>
			</div>
		</article>,
		<>
			{/*	/!*Todo: Pouvoir récupérer et utiliser une notification précédente*!/*/}
			{/*<header className="flex justify-between">*/}
			{/*	<Heading size={5}>Description</Heading>*/}
			{/*	<Button onClick={() => (isDeployHistorical ? setIsDeployHistorical(false) : setIsDeployHistorical(true))} color={editing ? 'success' : 'info'} size={'small'} type={'submit'} loading={loading}>*/}
			{/*		<Icon key={isDeployHistorical ? 'save' : 'edit'} icon={isDeployHistorical ? 'fal fa-check' : 'fal fa-money-check-edit'} />*/}
			{/*		{isDeployHistorical ? <p>Valider</p> : <p>Historique</p>}*/}
			{/*	</Button>*/}
			{/*</header>*/}
			{/*{isDeployHistorical && <div className="flex w-full h-96 bg-neutral-200"></div>}*/}
			{viewTotalUser === 1 && (
				<div className="flex space-x-2 mt-4">
					<Tag color="primary">
						<i className="fas fa-users" />
						<span className="pl-2">{nbUser === null ? 0 : formatNumber(nbUser)} utilisateurs trouvés</span>
					</Tag>
					<Tag color="primary">
						<i className="fas fa-tablet" />
						<span className="pl-2">{nbToken === null ? 0 : formatNumber(nbToken)} appareils trouvés</span>
					</Tag>
				</div>
			)}

			<Form.Field className="pt-4">
				<Form.Control>
					<Form.Input
						value={state.title}
						name={'title'}
						placeholder="<Titre de la notification>*"
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							dispatch({
								key: 'title',
								value: e.target.value,
							})
						}}
						required
					/>
				</Form.Control>
			</Form.Field>
			<Form.Field>
				<Form.Control>
					<Form.Textarea
						value={state.bodyInput}
						name={'bodyInput'}
						placeholder="<Description de la notification>*"
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							dispatch({
								key: 'bodyInput',
								value: e.target.value,
							})
						}}
						required
					/>
				</Form.Control>
			</Form.Field>
			<Form.Field>
				<Form.Control>
					<Form.Input
						value={state.linkInput}
						name={'link'}
						placeholder="<Lien de redirection au clic>"
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							dispatch({
								key: 'linkInput',
								value: e.target.value,
							})
						}}
					/>
				</Form.Control>
			</Form.Field>

			<div className="flex items-center justify-center my-6">
				<figure className="relative w-[42rem] font-sans">
					<img src="https://help.apple.com/assets/65E217CA88BD34A06A01BA1C/65E217CD5B9E652ABE0361D7/en_US/f5c416605390846ebf3aa6b43e55b62d.png" alt="" />
					<div className="absolute bottom-16 w-full">
						<div className="relative mx-auto flex h-fit w-1/3 items-center space-x-2 rounded-xl bg-white/50 px-2 py-1.5 backdrop-blur-lg">
							<img className="aspect-square h-5 w-5 rounded object-contain" src="https://play-lh.googleusercontent.com/AhiksQ_naXPmDfp4CfZgAVNwg30il3spb83CTJGh8l7NMpL0cAkns-XF5EJqRv-sQK4" alt="" />
							<article className="flex-1">
								<header className="flex">
									<p className="line-clamp-1 flex-1 text-[0.50rem] font-semibold leading-[0.65rem] tracking-tight text-black">{state.title}</p>
									<small className="self-start text-[0.4rem] font-light text-gray-800 opacity-60" style={{ color: 'rgb(31 41 55)' }}>
										il y a 2 min
									</small>
								</header>
								<p className="line-clamp-4 hyphens-auto text-[0.5rem] leading-[0.55rem] tracking-tight text-black">{state.bodyInput}</p>
							</article>
						</div>
					</div>
				</figure>
			</div>
			<br />
			<div className="is-flex" style={{ justifyContent: 'space-between' }}>
				<Button color={'is-danger'} onClick={() => resetState()}>
					Retour
				</Button>
				{state.title === '' || state.bodyInput === '' ? (
					<Button color={'warning'} className="ml-auto">
						*Titre et Description obligatoires
					</Button>
				) : (
					<Button color={'primary'} className="ml-auto" onClick={() => getToken()}>
						Suivant
					</Button>
				)}
			</div>
		</>,
		<>
			{isConfirmSendNotification ? (
				<div className="w-full bg-gray-200 rounded-lg overflow-hidden p-4 space-y-6 ">
					<p className="text-xl font-bold flex justify-center items-center">Envoi des notifications...</p>
					<div className="flex justify-center items-center p-2 space-x-4">
						<p className="text-xl flex justify-center items-center">
							{progress}/{isTotalTokenBar} Tokens
						</p>
						<div className="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] text-purple-500" role="status"></div>
					</div>

					<div className="bg-gray-300 rounded-lg my-4">
						<div
							className="bg-purple-800 h-4 transition-all ease-out duration-1000 rounded-lg my-4"
							style={{
								width: `${calculProgressPourcentage}%`,
							}}
						/>
					</div>
				</div>
			) : (
				<>
					<header className="flex items-center justify-center ">
						<Heading size={5}>Récapitulatif</Heading>
					</header>

					<Hr />
					<div className="space-y-4 mb-8">
						<div className="flex space-x-2">
							<Tag color="primary">
								<i className="fas fa-users" />
								<span className="pl-2">{nbUser === null ? 0 : formatNumber(nbUser)} utilisateurs trouvés</span>
							</Tag>
							<Tag color="primary">
								<i className="fas fa-tablet" />
								<span className="pl-2">{nbToken === null ? 0 : formatNumber(nbToken)} appareils trouvés</span>
							</Tag>
						</div>
						<br />
						<div className="relative flex h-fit w-1/3 items-center space-x-2 rounded-xl bg-gray-200 px-2 py-1.5 backdrop-blur-lg">
							<img className="aspect-square h-5 w-5 rounded object-contain" src="https://play-lh.googleusercontent.com/AhiksQ_naXPmDfp4CfZgAVNwg30il3spb83CTJGh8l7NMpL0cAkns-XF5EJqRv-sQK4" alt="" />
							<article className="flex-1">
								<header className="flex">
									<p className="line-clamp-1 flex-1 text-[0.50rem] font-semibold leading-[0.65rem] tracking-tight text-black">{state.title}</p>
									<small className="self-start text-[0.4rem] font-light text-gray-800 opacity-60" style={{ color: 'rgb(31 41 55)' }}>
										il y a 2 min
									</small>
								</header>
								<p className="line-clamp-4 hyphens-auto text-[0.5rem] leading-[0.55rem] tracking-tight text-black">{state.bodyInput}</p>
							</article>
						</div>
						<p>
							&rarr;{' '}
							<a href={state.linkInput} target="_blank">
								{state.linkInput}
							</a>
						</p>
						<br />
						<h2>
							<strong>{allUAI ? null : state.arrayUai.length} UAI</strong>
							<br />
							{allUAI ? <p>Tous les UAI</p> : state.arrayUai.join(', ')}
						</h2>
						<h2>
							<strong>Etablissement(s) associé(s)</strong>
							<br />
							{allUAI ? (
								<p>Tous les établissements</p>
							) : (
								schools
									.filter((s: School) => state.arrayUai.includes(s.UAI))
									.map((n: School) => n.Nom)
									.join(', ')
							)}
						</h2>
						<br />
						<h2>
							<strong>Matière(s)</strong>
							<br />
							{state.subject || 'Aucun filtre de matière'}
						</h2>
						<h2>
							<strong>{state.arrayProfiles.length} Profil(s)</strong>
							<br />
							{dataProfil
								.filter(p => state.arrayProfiles.includes(p.IDprofil))
								.map(p => p.Nom)
								.join(', ')}
						</h2>
						<h2>
							<strong>{allApp ? null : state.arrayApplications.length} Application(s)</strong>
							<br />
							{state.arrayApplications.join(', ')}
						</h2>
					</div>
					<div className="is-flex" style={{ justifyContent: 'space-between' }}>
						<Button color={'is-danger'} onClick={() => setPage(page - 1)}>
							Retour
						</Button>
						{nbUser === 0 ? (
							<Button color={'danger'} className="ml-auto">
								Aucun utilisateur trouvé
							</Button>
						) : (
							<Button
								color={'primary'}
								className="ml-auto"
								onClick={() =>
									context.showModal('confirmAction', true, {
										content: `Confirmez-vous l'envoi de notification à ${nbUser} utilisateur(s)`,
										action: () => sendNotification(),
									})
								}
							>
								Confirmer l'envoi
							</Button>
						)}
					</div>
				</>
			)}
		</>,
	]

	function resetState() {
		setPage(page - 1)
		dispatch({
			key: 'count',
			value: 1,
		})
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const [profileResponse, appsResponse, notificationsResponse] = await Promise.all([Queries.getUsersProfile(context), Query.getGlobalSettings(context, 'apps'), Queries.getHistoriqueNotification()])

				setDataProfil(profileResponse as Profile[])
				setDataApp(appsResponse as Application[])

				if (notificationsResponse) {
					setDataOperator(notificationsResponse)
				} else {
					console.error("Impossible de récupérer l'historique")
				}
			} catch (error) {
				console.error('Une erreur est survenue:', error)
			}
		}
		fetchData()
	}, [context])

	return (
		<>
			<div className="font-bold mb-10 flex space-x-8 items-center justify-center">
				{[1, 2, 3].map(stepNumber => {
					return (
						<>
							<div className={`${stepNumber === page + 1 ? 'bg-purple-300' : 'has-background-grey-lighter'} w-10 h-10 flex items-center justify-center rounded-full`}>
								<span>{stepNumber}</span>
							</div>
							{stepNumber <= 2 && <Icon key={'arrow2'} icon={'fal fa-arrow-right'} />}
						</>
					)
				})}
			</div>
			<section>{stepRenderer[page]}</section>
		</>
	)
}

export default GlobalNotifications
