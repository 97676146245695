import { Button, Content, Form, Heading, Loader, Modal, Tag } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import Hr from '../../utils/Hr'
import React, { useContext, useEffect, useState } from 'react'
import GlobalContext from '../../../contexts/GlobalContext'
import FileUtils, { CONTEXT_ACTIONS } from '../../utils/FileUtils'
import { ReactReader } from 'react-reader'
import FileViewer from 'react-file-viewer-extended'
import Query from '../../../tools/Query'
import { Doughnut } from 'react-chartjs-2'
import PdfViewer from '../../documents/PdfViewer'
import { Schools } from '../specific/Schools';

/**
 * Modal lié au gestionnaire de fichier, et permet d'effectuer différentes actions (renommer, supprimer, créer...)
 * @param {boolean} show Affiche ou non la modal
 * @param {CONTEXT_ACTIONS} action Type de l'action (voir CONTEXT_ACTIONS)
 * @param {function} onClose Fonction qui ferme la modal
 * @param {string} container Container (bucket) sur lequel est effectué l'action
 * @param {object} file Fichier inspecté
 * @param {string[]} selected Liste des fichiers sélectionnés (tableaux de leur hash)
 * @param {function} fetchFiles Synchronise les fichiers
 * @param {function} callback Appel un callback
 * @param {number} usedDiskStorage Espace occupé sur le disque
 * @return {JSX.Element}
 * @constructor
 */
function FileManager({ show, action, onClose, container, file, selected, fetchFiles, callback, usedDiskStorage }) {
	const name = 'filesManager'

	const context = useContext(GlobalContext)
	const { isLightTheme, translate, addToast, loadSchool, user, schools } = context

	/**
	 * Liste des fichiers que l'utilisateur peut modifier.
	 * @type {object[]}
	 */
	const files = selected ? selected.filter(file => FileUtils.canEditFile(user, file)) : [file]

	/**
	 * Saisie des champs
	 */
	const [value, setValue] = useState('')

	/**
	 * Etablissements affecté au fichier.
	 */
	const [fileSchools, setFileSchools] = useState(file?.schools || [])

	/**
	 * Chargement pendant une requête...
	 */
	const [isLoading, setIsLoading] = useState(false)

	/**
	 * Récupération du lien de téléchargement
	 */
	const [fileUrl, setFileUrl] = useState(null)

	/**
	 * Espace disque maximum
	 */
	const [maxDiskStorage, setMaxDiskStorage] = useState(null)

	const [enableForAllSchools, setEnableForAllSchools] = useState(schools.length === (file?.schools || []).length)

	useEffect(() => {
		if (!enableForAllSchools) {
			setFileSchools(fileSchools || [])
		}
	}, [enableForAllSchools])

	useEffect(() => {
		if (!fileUrl) {
			switch (action) {
				case CONTEXT_ACTIONS.DETAILS:
				case CONTEXT_ACTIONS.PREVIEW:
					Query.getDownloadURL(context, file.name)
						.then(setFileUrl)
						.catch(err => setFileUrl(false))
					break
			}
		}

		if (!maxDiskStorage && action === CONTEXT_ACTIONS.DETAILS) {
			Query.getFiles(context)
				.then(({ maxBucketSize }) => setMaxDiskStorage(maxBucketSize))
				.catch(err => setMaxDiskStorage(0))
		}
	}, [fileUrl, action, context, maxDiskStorage, file?.name])

	/**
	 * Effectue une modification selon l'action
	 */
	function handleSubmit() {
		switch (action) {
			case CONTEXT_ACTIONS.AFFECT:
				const schools = fileSchools.map(school => school.split('-')[0].trim())
				const filesKey = files.map(file => file.key)
				setIsLoading(true)

				Query.affectSchools(context, filesKey, enableForAllSchools || schools)
					.then(() => {
						setIsLoading(false)
						addToast('Les établissements ont bien été affectés aux fichiers.', { appearance: 'success' })
						fetchFiles()
						onClose(name)
					})
					.catch(() => {
						addToast(translate('global.errors.occurred'), { appearance: 'error' })
						setIsLoading(false)
					})
				onClose(name)
				break

			case CONTEXT_ACTIONS.EDIT:
				break

			case CONTEXT_ACTIONS.DETAILS:
				onClose(name)
				break

			case CONTEXT_ACTIONS.DELETE:
				setIsLoading(true)
				if (file) {
					Query.deleteFileInDB(context, file.key)
						.then(() => {
							addToast(`Le fichier ${file.name} a bien été supprimé.`, { appearance: 'info' })
							setIsLoading(false)
							fetchFiles()
							onClose(name)
						})
						.catch(() => {
							addToast(translate('global.errors.occurred'), { appearance: 'error' })
							setIsLoading(false)
						})
				} else {
					Query.deleteFilesInDB(
						context,
						files.map(file => file.key)
					)
						.then(() => {
							addToast(`${files.length} fichiers ont bien été supprimé.`, { appearance: 'info' })
							setIsLoading(false)
							callback()
							fetchFiles()
							onClose(name)
						})
						.catch(() => {
							addToast(translate('global.errors.occurred'), { appearance: 'error' })
							setIsLoading(false)
						})
				}
				break

			default:
				break
		}
	}

	/**
	 * Renvoie la vue selon l'action.
	 * @return {JSX.Element}
	 */
	function getView() {
		switch (action) {
			case CONTEXT_ACTIONS.AFFECT:
				return (
					<section>
						<Heading subtitle size={6} marginless>
							Affecter à {enableForAllSchools ? 'tous les' : fileSchools.length} établissements
						</Heading>
						<div className="field is-grouped is-grouped-multiline my-4">
							{enableForAllSchools || fileSchools.length === schools.length ? (
								<Tag color="success">Disponible pour tous les établissements</Tag>
							) : (
								fileSchools.map((school, k) => (
									<Form.Control key={k}>
										<Tag.Group className="has-addons">
											<Tag color={'dark'}>{school}</Tag>
											<Tag
												remove
												className="cursor-pointer"
												onClick={() => {
													fileSchools.splice(fileSchools.indexOf(school), 1)
													setFileSchools([...fileSchools])
												}}
											/>
										</Tag.Group>
									</Form.Control>
								))
							)}
						</div>
						<Hr />
						<Form.Field>
							<Form.Control>
								<input
									id="all-estab"
									name="all-estab"
									type="checkbox"
									checked={enableForAllSchools}
									className="switch is-rounded is-success is-small"
									onChange={() => {
										setEnableForAllSchools(!enableForAllSchools)
										setFileSchools([])
									}}
								/>
								<label htmlFor="all-estab">Disposer pour les établissements</label>
							</Form.Control>
						</Form.Field>
						<Schools
							addSchool={affectedSchool => {
								if (!fileSchools.includes(affectedSchool)) {
									setFileSchools([...fileSchools, affectedSchool])
								}
							}}
						/>
					</section>
				)

			case CONTEXT_ACTIONS.PREVIEW:
				const ext = FileUtils.getExtension(file.name)

				const isEpub = ext === 'epub'
				const isPdf = ext === 'pdf'

				if (fileUrl === null) {
					return (
						<div className="is-flex" style={{ justifyContent: 'center', placeItems: 'center' }}>
							<p>Récupération du fichier...</p>
							<Loader />
						</div>
					)
				} else if (fileUrl === false) {
					return (
						<div style={{ position: 'relative', height: '85%' }}>
							<p>{translate('global.errors.occurred')}</p>
						</div>
					)
				} else {
					return (
						<div style={{ position: 'relative', height: '88%' }}>
							{isEpub ? (
								<ReactReader
									loadingView={
										<div className="is-flex" style={{ justifyContent: 'center', placeItems: 'center' }}>
											<p className="mr-1">Récupération du fichier (cela peut prendre quelques instant...)</p>
											<Loader />
										</div>
									}
									title={file.name}
									url={fileUrl}
								/>
							) : isPdf ? (
								<PdfViewer url={fileUrl} />
							) : (
								<FileViewer filePath={fileUrl} fileType={ext} />
							)}
						</div>
					)
				}

			case CONTEXT_ACTIONS.CREATE_DIR:
			case CONTEXT_ACTIONS.EDIT:
				const placeholder = CONTEXT_ACTIONS.CREATE_DIR ? 'Nom du dossier' : 'Nouveau nom de fichier (avec extension)'
				return (
					<Form.Field>
						<Form.Control>
							<Form.Input type={'text'} name={'input'} placeholder={placeholder} onChange={e => setValue(e.target.value)} value={value} />
						</Form.Control>
					</Form.Field>
				)

			case CONTEXT_ACTIONS.DETAILS:
				if (!file) {
					return <Loader />
				} else {
					const { key, name, uploadedBy, size, schools, originalName, description, date } = file
					return (
						<section>
							<article className="border rounded-lg shadow-sm p-4 w-auto my-4">
								<div className="flex justify-between mb-6">
									<div>
										<Heading subtitle size={4} textColor={'primary'} marginless>
											<Icon icon={`fad ${FileUtils.getIcons(file)}`} />
											<span className="is-text-icon">{name}</span>
										</Heading>
										<Heading subtitle size={6} textColor={'black'} marginless className="mb-2">
											{FileUtils.humanFileSize(size)}
										</Heading>
										<p className="is-size-7 has-text-grey">{key}</p>
									</div>
									<div className="w-1/3">
										<Doughnut
											options={{
												legend: {
													display: false,
												},
											}}
											data={{
												labels: [`Autres fichiers : ${FileUtils.humanFileSize(usedDiskStorage - size)}`, `${name} : ${FileUtils.humanFileSize(size)}`],
												datasets: [
													{
														data: [usedDiskStorage - size, size],
														backgroundColor: ['#48c774', '#731c8e'],
													},
												],
											}}
										/>
									</div>
								</div>
								<p>Nom original : {originalName}</p>
								<p>
									Ajouté par <strong>{uploadedBy}</strong> le {date}
								</p>
								<Hr />
								{schools?.length > 0 ? (
									<section>
										<p className="mb-2">{schools.length} établissement(s) affecté(s) :</p>
										<div className="field is-grouped is-grouped-multiline max-h-32 overflow-y-scroll">
											{schools.map((school, idx) => (
												<Form.Control key={idx}>
													<Tag
														color={'dark'}
														className="cursor-pointer hover:underline"
														onClick={() => {
															onClose(name)
															loadSchool(school)
														}}
													>
														{school}
													</Tag>
												</Form.Control>
											))}
										</div>
									</section>
								) : (
									<p>Aucun établissement affecté.</p>
								)}
								<Hr />
								<Form.Field>
									<Form.Control>
										<Form.Textarea readOnly value={description} placeholder={'Description'} />
									</Form.Control>
								</Form.Field>
							</article>
						</section>
					)
				}

			case CONTEXT_ACTIONS.DELETE:
				return (
					<section>
						<p>Êtes-vous sûr de vouloir supprimer le(s) document(s) ?</p>
						<div className="is-flex space-x-2 w-full overflow-auto">
							{files.map((file, idx) => (
								<article key={idx} className="border rounded-lg shadow-sm p-4 w-auto my-4">
									<Icon icon={`fad ${FileUtils.getIcons(file)}`} />
									<span className="is-text-icon">{file.name}</span>
								</article>
							))}
						</div>
					</section>
				)

			case CONTEXT_ACTIONS.HELP:
				return (
					<article>
						<Heading size={5}>Utilisation</Heading>
						<p>
							Pour ajouter des documents, il vous suffit de les <strong>glisser dans la liste</strong> ci-dessous. Pour les affecter à des utilisateurs, vous devez aller dans l'onglet{' '}
							<strong className="mr-1">
								<Icon icon={'fad fa-table'} />
								Utilisateurs
							</strong>
							situé sur le menu latéral, sélectionner des utilisateurs, puis dans le bouton{' '}
							<button className="button is-info is-small mr-1">
								<Icon icon={'fad fa-user-edit'} />
								<span className={'text-icon'}>{translate('global.update_alt')}</span>
								<Icon icon={'fal fa-angle-down'} />
							</button>
							et enfin cliquer sur "<strong>Documents</strong>".
						</p>
						<p>
							En tant que <strong>Gestionnaire</strong> et <strong>Administrateur établissement</strong>, vous êtes limité à
						</p>
						<Content>
							<ul>
								<li>
									Type de documents: <strong>pdf</strong>
								</li>
								<li>
									Poids du document: <strong>10Mo</strong>
								</li>
							</ul>
						</Content>
					</article>
				)

			default:
				return <p>Cette modification n'est pas encore possible...</p>
		}
	}

	let className = isLightTheme ? '' : 'is-dark '
	className += [CONTEXT_ACTIONS.PREVIEW, CONTEXT_ACTIONS.HELP].includes(action) ? 'is-large' : ''

	return (
		<Modal className={className} show={show} showClose onClose={() => onClose(name)} closeOnBlur>
			<Modal.Card className={'fade-in-bottom'}>
				<Modal.Card.Body style={{ height: action === CONTEXT_ACTIONS.PREVIEW ? '80vh' : 'auto' }}>
					<header className="is-flex" style={{ justifyContent: 'space-between' }}>
						<Heading size={5}>Gestionnaire de fichier</Heading>
						<Button color={'light'} onClick={() => onClose(name)}>
							<Icon icon={'fal fa-times fa-2x'} />
						</Button>
					</header>
					<Hr />
					{getView()}
					<br />
					{action !== CONTEXT_ACTIONS.PREVIEW && (
						<div className="is-flex" style={{ justifyContent: 'right' }}>
							<Button color={action === CONTEXT_ACTIONS.DELETE ? 'danger' : 'primary'} onClick={handleSubmit} loading={isLoading}>
								{translate('global.confirm')}
							</Button>
						</div>
					)}
				</Modal.Card.Body>
			</Modal.Card>
		</Modal>
	)
}
export default FileManager
