import React, { FC, SyntheticEvent, useContext, useEffect, useState } from 'react'
import GlobalContext from '../../../contexts/GlobalContext'
// @ts-ignore
import { Button, Columns, Form, Heading, Image, Modal } from 'react-bulma-components'
import Required from '../../utils/Required'
import Hr from '../../utils/Hr'
import Icon from '../../utils/Icon'
import { Formik } from 'formik'
import Query from '../../../tools/Query'
import { PlatformSettings } from '../../settings/global/GlobalPlatforms/types'

export type Application = {
	idApplication: string
	commonCode: string
	applicationCodeRequired: string
	applicationCodeYesNo: string
	boLogin: string
	boPassword: string
	boUrl: string
	connectDiffPassword: string
	exportFile: string
	exportType: string
	forExport: string
	name: string
	order: string
	platformId: string
	urlOnLine: string
	urlSchemeCodeRequired: string
	urlSchemeCodeYesNo: string
	urlSchemeLoginRequired: string
	urlSchemePwdRequired: string
	urls: {
		URLschemeOpenBook: string
		URLschemeToken: string
		appStore: string
		dmgLink: string
		exeLink: string
		picturePath: string
		playStore: string
		urlClean: string
		urlLine: string
		urlOpen: string
		urlScheme: string
	}
}

export type AddUserApplicationDto = {
	codes: string
	login: string
	password: string
	idApplication: string
	platformId: string
}

interface Props {
	onClose: (name: string) => void
	cancel: () => void
	apps: string[]
	show: boolean
	passwords: string[]
	values: Record<string, any>
	options: string[]
}

type AppsByPlatformType = { platform: { name: string; id: string }; applications: Application[] }

export const UnitaryAddApps: FC<Props> = ({ cancel, onClose, apps, show, passwords, values, options }) => {
	const NAME = 'unitaryAddApps'

	const context = useContext(GlobalContext)
	const { school, translate, isLightTheme, addToast, refresh, toggleUserAdd, setGridFilter } = context

	const [appsByPlatform, setAppsByPlatform] = useState<AppsByPlatformType[]>([])

	const [loading, setLoading] = useState<boolean>(false)

	useEffect(() => {
		Query.getGlobalSettings(context, 'platforms')
			.then(fetchedPlatforms => {
				const appsByPlatform: AppsByPlatformType[] = []

				for (const platform of fetchedPlatforms as PlatformSettings[]) {
					const appsForPlatform: Application[] = []

					for (const app of apps) {
						const application = getApp(app)

						if (application.platformId === platform.idPlateforme) {
							appsForPlatform.push(application)
						}
					}
					appsForPlatform.length > 0 &&
						appsByPlatform.push({
							platform: { name: platform.name, id: platform.idPlateforme },
							applications: appsForPlatform,
						})
				}

				setAppsByPlatform(appsByPlatform)
			})
			.catch(err => {
				setLoading(false)
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
			})
	}, [])

	function getApp(appName: string): Application {
		let mySchool = school.applications.find((app: Application) => app.name === appName)
		// needs to fetch platformCodeYesNo and required for asking commonCode or not
		//@ts-ignore
		const platformSchool = school.platforms.find(({ platformId }) => platformId === mySchool.platformId)
		mySchool = { ...mySchool, applicationCodeRequired: platformSchool.platformCodeRequired, applicationCodeYesNo: platformSchool.platformCodeYesNo }
		return mySchool
	}

	function getInitialValues(): any {
		const passwordsCopied = [...passwords]
		const items: Record<string, string> = {}

		const loginsByPlatform: Record<string, { login: string; password: string; codes: string }> = {}

		for (const app of apps) {
			const { platformId, urlSchemeLoginRequired, urlSchemePwdRequired, applicationCodeYesNo, connectDiffPassword, commonCode } = getApp(app)

			if (!loginsByPlatform[platformId]) {
				const randomPassword = passwordsCopied.pop() + (platformId === 'PLTF_EDULIB' ? '!' : '')
				loginsByPlatform[platformId] = {
					login: urlSchemeLoginRequired ? values.login : '',
					password: urlSchemePwdRequired ? (connectDiffPassword ? randomPassword : values.password) : '',
					codes: applicationCodeYesNo ? commonCode : '',
				}
			}

			const { login, password, codes } = loginsByPlatform[platformId]

			items[`${platformId}-login`] = login
			items[`${platformId}-password`] = password
			items[`${platformId}-codes`] = codes
		}

		return items
	}

	function addUser(event: SyntheticEvent): void {
		setLoading(true)

		const applications: AddUserApplicationDto[] = Object.entries(event).reduce((acc: AddUserApplicationDto[], [field, value]) => {
			const [platformId, fieldName] = field.split('-') as [string, keyof AddUserApplicationDto]

			const appsForPlatform = appsByPlatform.find(({ platform }) => platform.id === platformId)

			if (!appsForPlatform) {
				return acc
			}

			for (const { idApplication } of appsForPlatform.applications) {
				const fieldAlreadyExist = acc.find(acc => acc.idApplication === idApplication)

				fieldAlreadyExist
					? (fieldAlreadyExist[fieldName] = value)
					: acc.push({
							platformId,
							idApplication,
							login: '',
							password: '',
							codes: '',
							[fieldName]: value,
					  })
			}

			return acc
		}, [])

		Query.addUser(context, { applications, values, options })
			.then(() => {
				addToast(translate('modals.specific.add_user.created', { appearance: 'success' }))
				toggleUserAdd()
				onClose(NAME)
				refresh()
				setGridFilter('table', { Login: { filterType: 'text', type: 'equal', filter: values.login } }, null)
			})
			.catch(() => {
				addToast(translate('global.errors.occurred', { appearance: 'error' }))
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<Modal className={`is-large ${!isLightTheme && 'is-dark'}`} show={show} showClose onClose={() => onClose(NAME)} closeOnBlur>
			<Modal.Card className={'fade-in-bottom'}>
				<Modal.Card.Body>
					<Formik onSubmit={addUser} initialValues={getInitialValues()}>
						{({ handleSubmit, handleChange, values, errors }) => (
							<form onSubmit={handleSubmit}>
								<header className="is-flex" style={{ justifyContent: 'space-between' }}>
									<div>
										<Heading size={5}>{translate('modals.specific.add_user.final_title')}</Heading>
										<Heading size={6} subtitle>
											{translate('modals.specific.add_user.managing_apps')}
										</Heading>
									</div>
									<Button
										color={'light'}
										type={'reset'}
										onClick={() => {
											cancel()
											onClose(NAME)
										}}
									>
										<Icon icon={'fal fa-times fa-2x'} />
									</Button>
								</header>
								<Hr />
								<Columns multiline>
									<Columns.Column size={'one-quarter'} />
									<Columns.Column size={'one-quarter'}>
										<Heading size={6}>
											{translate('global.login')}
											<Required />
										</Heading>
									</Columns.Column>
									<Columns.Column size={'one-quarter'}>
										<Heading size={6}>
											{translate('global.password')}
											<Required />
										</Heading>
									</Columns.Column>
									<Columns.Column size={'one-quarter'}>
										<Heading size={6}>
											{translate('global.code')}
											<Required />
										</Heading>
									</Columns.Column>
									{appsByPlatform.map(({ platform, applications }, key) => {
										return (
											<>
												<Columns.Column className="border-b has-text-weight-bold" size="full">
													{platform.name}
												</Columns.Column>
												{applications.map(({ idApplication, urlSchemeCodeRequired, applicationCodeRequired, name, urlSchemeLoginRequired, urlSchemePwdRequired, applicationCodeYesNo, urlSchemeCodeYesNo, urls, platformId }, index) => {
													const codeRequired = urlSchemeCodeRequired || applicationCodeRequired

													return (
														<>
															<Columns.Column size={'one-quarter'}>
																<figure className={'is-flex'} style={{ placeItems: 'center' }}>
																	<Image src={urls.picturePath} size={32} />
																	<figcaption style={{ paddingLeft: '0.5rem' }}>{name}</figcaption>
																</figure>
															</Columns.Column>
															<Columns.Column size={'one-quarter'}>
																<Form.Field>
																	<Form.Control size={'small'}>
																		{/* @ts-ignore */}
																		<Form.Input disabled={index > 0} color={!values[`${platformId}-login`] && urlSchemeLoginRequired ? 'danger' : 'success'} hidden={!urlSchemeLoginRequired} type={'text'} name={`${platformId}-login`} required={urlSchemeLoginRequired} value={values[`${platformId}-login`]} onChange={handleChange} isInvalid={errors[`${platformId}-login`]} />
																	</Form.Control>
																</Form.Field>
															</Columns.Column>
															<Columns.Column size={'one-quarter'}>
																<Form.Field>
																	<Form.Control size={'small'}>
																		{/* @ts-ignore */}
																		<Form.Input disabled={index > 0} color={!values[`${platformId}-password`] && urlSchemePwdRequired ? 'danger' : 'success'} hidden={!urlSchemePwdRequired} type={'text'} name={`${platformId}-password`} required={urlSchemePwdRequired} value={values[`${platformId}-password`]} onChange={handleChange} isInvalid={errors[`${platformId}-password`]} />
																	</Form.Control>
																</Form.Field>
															</Columns.Column>
															<Columns.Column size={'one-quarter'}>
																<Form.Field>
																	<Form.Control size={'small'}>
																		{/* @ts-ignore */}
																		<Form.Input color={!values[`${platformId}-codes`] && codeRequired ? 'danger' : 'success'} disabled={!urlSchemeCodeYesNo} hidden={!(applicationCodeYesNo || urlSchemeCodeYesNo)} required={codeRequired} type={'text'} name={`${platformId}-codes`} value={values[`${platformId}-codes`]} onChange={handleChange} isInvalid={errors[`${platformId}-codes`]} />
																	</Form.Control>
																</Form.Field>
															</Columns.Column>
														</>
													)
												})}
											</>
										)
									})}
								</Columns>
								<br />
								<div className="is-flex" style={{ justifyContent: 'space-between' }}>
									<Button
										color={'danger'}
										type={'reset'}
										onClick={() => {
											cancel()
											onClose(NAME)
										}}
									>
										{translate('global.cancel')}
									</Button>
									<Button color={'primary'} type={'submit'} loading={loading}>
										{translate('global.add')}
									</Button>
								</div>
							</form>
						)}
					</Formik>
				</Modal.Card.Body>
			</Modal.Card>
		</Modal>
	)
}
