import React, { Component } from 'react'
import { SegmentedControl, SegmentedOption } from '../../utils/SegmentedControl'
import GlobalContext from '../../../contexts/GlobalContext'

export default class SelectedFilterComponent extends Component {
	static contextType = GlobalContext

	constructor(props) {
		super(props)
		this.state = {
			currentValue: props.defaultValue ?? null,
		}
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return this.state.currentValue !== nextState.currentValue
	}

	onParentModelChanged = () => {
		this.valueChanged(this.getValue(this.props, this.context))
	}

	getValue = (props, context) => {
		return context.grid.getFilterModel()[props.filterParams.colDef.field] ?? null
	}

	valueChanged = value => {
		if (value !== this.state.currentValue) {
			this.setState({ currentValue: value }, () => {
				this.props.parentFilterInstance(instance => {
					instance.setModel(value)})
			})
		}
	}

	render() {
		const { translate } = this.context

		return (
			<SegmentedControl
				id="F"
				defaultValue={this.state.currentValue}
				mini
				onChange={e => {
					this.valueChanged(e)
				}}
			>
				<SegmentedOption value={null}>{translate('global.all')}</SegmentedOption>
				<SegmentedOption value={true}>{translate('global.yes')}</SegmentedOption>
				<SegmentedOption value={false}>{translate('global.no')}</SegmentedOption>
			</SegmentedControl>
		)
	}
}
