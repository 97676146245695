import { Button, Form, Heading, Modal } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import Hr from '../../utils/Hr'
import React, { useContext, useState } from 'react'
import GlobalContext from '../../../contexts/GlobalContext'
import { SegmentedControl, SegmentedOption } from '../../utils/SegmentedControl'
import Queries from '../../../tools/Query'

function UserAddTeaching({ show, onClose, userId, teachings, estabTeachings }) {
	const name = 'userAddTeaching'

	const context = useContext(GlobalContext)
	const { translate, addToast, toggleUserPage, loadUserPage } = context

	const [tab, setTab] = useState('insert')

	const [updatingValue, setUpdatingValue] = useState()

	const [classeInput, setClasseInput] = useState()
	const [hasCustomInputClasse, setHasCustomInputClasse] = useState(false)

	const [disciplineInput, setDisciplineInput] = useState()
	const [hasCustomInputDiscipline, setHasCustomInputDiscipline] = useState(false)

	const [hasMadeChange, setHasMadeChange] = useState(false)

	const [status, setStatus] = useState()

	function close() {
		onClose(name)

		if (hasMadeChange) {
			toggleUserPage()
			loadUserPage(userId)
		}
	}

	function handleChange(e) {
		const { name, value } = e.target

		const setters = {
			classe: setClasseInput,
			discipline: setDisciplineInput,
		}

		if (tab === 'delete') {
			setUpdatingValue(value)
			return
		}

		if (tab === 'insert') {
			switch (value) {
				case 'add':
					if (name === 'classe') {
						setClasseInput('')
						setHasCustomInputClasse(true)
					} else {
						setDisciplineInput('')
						setHasCustomInputDiscipline(true)
					}
					break
				default:
					if (name === 'classe') {
						setHasCustomInputClasse(false)
					} else {
						setHasCustomInputDiscipline(false)
					}
					setters[name](value)
					break
			}
		}

		if (tab === 'update') {
			if (name === 'updating') {
				const [classe, discipline] = value.split('|')
				setClasseInput(classe)
				setDisciplineInput(discipline)
				return
			}
			switch (value) {
				case 'add':
					if (name === 'classe') {
						setClasseInput('')
						setHasCustomInputClasse(true)
					}
					if (name === 'discipline') {
						setDisciplineInput('')
						setHasCustomInputDiscipline(true)
					}
					break
				default:
					setters[name](value)
					break
			}
		}
	}

	function handleSubmit() {
		const body = {
			informations: {},
			applications: [],
			teaching: teachings,
		}

		switch (tab) {
			case 'delete':
				body.teaching = teachings.filter(({ class: c, discipline: d }) => `${c}|${d}` !== updatingValue)
				break

			case 'insert':
				body.teaching.push({ class: classeInput, discipline: disciplineInput })
				break

			case 'update':
				body.teaching = teachings.map(({ class: c, discipline: d }) => (`${c}|${d}` === updatingValue ? { class: classeInput, discipline: disciplineInput } : { class: c, discipline: d }))
				break

			default:
				break
		}

		setStatus('loading')

		Queries.updateUserPage(context, userId, body)
			.then(() => {
				addToast(translate('user.update_success'), { appearance: 'success' })
				setStatus()
				setHasMadeChange(true)
			})
			.catch(() => {
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
				setStatus()
			})
	}

	function handleTabChange(value) {
		setTab(value)

		if (value === 'update') {
			if (!updatingValue) {
				setUpdatingValue(teachings[0].class + '|' + teachings[0].discipline)
				setClasseInput(teachings[0].class)
				setDisciplineInput(teachings[0].discipline)
			} else {
				const [classe, discipline] = updatingValue.split('|')
				setClasseInput(classe)
				setDisciplineInput(discipline)
			}
			setHasCustomInputClasse(true)
			setHasCustomInputDiscipline(true)
		} else {
			setHasCustomInputClasse(false)
			setHasCustomInputDiscipline(false)
		}
	}

	return (
		<Modal show={show} showClose onClose={close} closeOnBlur className="is-large">
			<Modal.Card className={'fade-in-bottom'}>
				<Modal.Card.Body>
					<header className="is-flex" style={{ justifyContent: 'space-between' }}>
						<Heading size={5}>{translate('user.teaching')}</Heading>
						<Button color={'light'} onClick={close}>
							<Icon icon={'fal fa-times fa-2x'} />
						</Button>
					</header>
					<Hr />
					<SegmentedControl id="F" defaultValue={tab} mini onChange={handleTabChange} style={{ marginBotton: '1rem' }}>
						<SegmentedOption value="update">{translate('global.replace_alt')}</SegmentedOption>
						<SegmentedOption value="insert">{translate('global.add_alt')}</SegmentedOption>
						<SegmentedOption value="delete">{translate('global.remove_alt')}</SegmentedOption>
					</SegmentedControl>
					{(tab === 'update' || tab === 'delete') && (
						<Form.Field>
							<Form.Label>{translate('modals.generic.select_value')}</Form.Label>
							<Form.Control className={'is-expanded'}>
								<div className="select is-fullwidth">
									<select name="updating" onChange={handleChange}>
										<option value="null">&lt;{translate('modals.generic.select_value')}&gt;</option>
										{teachings
											.sort((a, b) => a.class.localeCompare(b.class))
											.map(({ class: classe, discipline }, idx) => (
												<option selected={updatingValue === `${classe}|${discipline}`} key={idx} value={`${classe}|${discipline}`}>
													{classe} | {discipline}
												</option>
											))}
									</select>
								</div>
							</Form.Control>
						</Form.Field>
					)}
					{(tab === 'insert' || tab === 'update') && (
						<Form.Field>
							<Form.Label>{translate('global.class')}</Form.Label>
							<Form.Control className={'is-expanded'}>
								<div className="select is-fullwidth">
									<select name="classe" onChange={handleChange}>
										<option value="null">&lt;{translate('modals.generic.select_value')}&gt;</option>
										{tab !== 'delete' && <option value="add">&lt;{translate('global.add')}&gt;</option>}
										<option disabled>━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━</option>
										{estabTeachings?.classes
											.sort((a, b) => a.localeCompare(b))
											.map((c, idx) => (
												<option key={idx} value={c}>
													{c}
												</option>
											))}
									</select>
								</div>
							</Form.Control>
						</Form.Field>
					)}
					{hasCustomInputClasse && (
						<Form.Field>
							<Form.Control>
								<Form.Input value={classeInput} type={'text'} name={'customClasse'} placeholder={translate('global.class')} onChange={e => setClasseInput(e.target.value)} autoFocus />
							</Form.Control>
						</Form.Field>
					)}
					{(tab === 'insert' || tab === 'update') && (
						<Form.Field>
							<Form.Label>{translate('global.subject')}</Form.Label>
							<Form.Control className={'is-expanded'}>
								<div className="select is-fullwidth">
									<select name="discipline" onChange={handleChange}>
										<option value="null">&lt;{translate('modals.generic.select_value')}&gt;</option>
										{tab !== 'delete' && <option value="add">&lt;{translate('global.add')}&gt;</option>}
										<option disabled>━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━</option>
										{estabTeachings?.disciplines
											.sort((a, b) => a.localeCompare(b))
											.map((c, idx) => (
												<option key={idx} value={c}>
													{c}
												</option>
											))}
									</select>
								</div>
							</Form.Control>
						</Form.Field>
					)}
					{hasCustomInputDiscipline && (
						<Form.Field>
							<Form.Control>
								<Form.Input value={disciplineInput} type={'text'} name={'customDiscipline'} placeholder={translate('global.subject')} onChange={e => setDisciplineInput(e.target.value)} autoFocus />
							</Form.Control>
						</Form.Field>
					)}
					<br />
					<div className="is-flex" style={{ justifyContent: 'right' }}>
						<Button color={'primary'} onClick={handleSubmit} loading={status === 'loading'}>
							{translate('global.confirm')}
						</Button>
					</div>
				</Modal.Card.Body>
			</Modal.Card>
		</Modal>
	)
}

export default UserAddTeaching
