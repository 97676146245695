import { AgGridReact } from 'ag-grid-react'
import AG_GRID_LOCALE_FR from '../../config/ag-grid-locale-fr'
import AG_GRID_LOCALE_EN from '../../config/ag-grid-locale-en'
import MultipleChoiceFilter from './filters/MultipleChoiceFilter'
import SelectedFilter from './filters/SelectedFilter'
import SelectedFilterComponent from './filters/SelectedFilterComponent'
import { escape, isEqual } from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import GlobalContext from '../../contexts/GlobalContext'
import Queries from '../../tools/Query'
import StandardCheckbox from './filters/StandardCheckbox'
import VerouilleCheckbox from './filters/VerouilleCheckbox'
import ApplicationSwitch from './cells/ApplicationSwitch'
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.min.css'
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.min.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.min.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.min.css'
import { AppColumnHeader } from './headers/AppColumnHeader'

/**
 * Différentes valeurs possible pour le type d'une cellule
 * - COMBOBOX : liste de choix
 * - CHECKBOX : bouton on/off
 * @type {{CHECKBOX: string, COMBOBOX: string}}
 */
const ColumnTypes = {
	COMBOBOX: 'combobox',
	CHECKBOX: 'checkbox',
}

/**
 * Api de la grille et de ses colonnes
 */
let grid, gridColumns

/**
 * Sauvegarde locale des filtres de la grille pour les garder
 * lors d'un rafraîchissement.
 * @type {{}}
 */

/**
 * Grille listant l'ensemble des utilisateurs, et permettant d'effectuer des modifications.
 */
const Grideo = ({ setParentState }) => {
	/**
	 * Lien entre nom de la colonne affichée (clé) et nom de la colonne en BDD (valeur).
	 */
	const [dbColumns, setDbColumns] = useState(null)
	/**
	 * Différents profiles pour les utiliseurs, et l'id correspondant.
	 * 	- Eleve, 1
	 * 	- Enseignant, 2
	 * 	...
	 */
	const [profiles, setProfiles] = useState(null)

	/**
	 * Défini si l'utilisateur a souhaité sauvegardé la configuration de la grille.
	 * @type {boolean}
	 */
	const hasGridPreferences = !!~~localStorage.getItem('sgp')

	const context = useContext(GlobalContext)

	const {
		translate,
		addToast,
		shouldMount,
		isLightTheme,
		lang,
		students,
		usersFilters,
		setGridFilter,
		setGrid,
		school: { applications = [], users },
		loadUserPage,
		showUserPage,
		toggleUserPage,
		reloadGrid,
		setSelectedUSersInGrid,
		selectedUsersInGrid,
	} = context

	const [selectedUsers, setSelectedUsers] = useState([])

	/**
	 * Défini si un utilisateur a la permission d'écrire dans la grille.
	 * @returns {boolean}
	 */
	const canWriteInTable = useCallback(() => {
		return shouldMount('Table', 'Ecriture') ?? false
	}, [shouldMount])

	/**
	 * A l'initialisation de la grille, nous définissons nos variables globales.
	 * @param api
	 * @param columnApi
	 */
	function prepareGrid({ api, columnApi }) {
		grid = api
		gridColumns = columnApi

		grid.closeToolPanel()

		setGrid(grid)

		generateColumnDefs().then(() => {
			autoResizeColumns()
			setSelectedUsers(selectedUsersInGrid)
			restoreSelectedUsers(selectedUsersInGrid)
		})
	}

	useEffect(() => {
		if (grid && !isEqual(grid.getFilterModel(), usersFilters)) {
			grid.setFilterModel(usersFilters)
		}
	}, [grid, usersFilters])

	/**
	 * Restore selected users in the grid, this method is optimized since it will trigger aggrid's
	 * onSelectionChanged event once
	 * @param users
	 */
	const restoreSelectedUsers = users => {
		let latestSelectedNode
		grid.forEachNode((node, index) => {
			if (users.includes(node.data['ID utilisateur']) && !node.data.Désactivé) {
				latestSelectedNode = index
			}
		})
		grid.forEachNode((node, index) => node.setSelected(users.includes(node.data['ID utilisateur']) && !node.data.Désactivé, false, index !== latestSelectedNode))
	}

	/**
	 * récupères la liste des applications actives pour les utilisateurs.
	 * @param students
	 * @type {function(): {}}
	 */
	const getUsersApps = useCallback(() => {
		const applis = {}
		const apps = applications.map(({ name: appName }) => appName)
		apps.forEach(app => (applis[app] = students.find(user => user[app] == true) !== undefined))
		return applis
	}, [applications, students])

	/**
	 * Retourne une application en fonction de son nom
	 * @type {function(*=): function(): (null|*)}
	 */
	const findApplicationIDByName = useCallback(
		name => () => {
			const application = applications.find(({ name: appName }) => appName === name)
			if (!application) {
				return null
			}

			return application.idApplication
		},
		[applications],
	)

	/**
	 * Génère les colonnes d'ag grid selon les paramètres BDD.
	 *
	 * @type {function(): Promise<*|undefined>}
	 */
	const generateColumnDefs = useCallback(async () => {
		grid.showLoadingOverlay()

		/**
		 * Liste des profils Eleve, Enseignant ...
		 * @type {unknown}
		 */
		const profiles = await Queries.getUsersProfile(context)

		/**
		 * Entêtes par défaut paramétrées en BDD
		 * @type {unknown}
		 */
		const defaultHeaders = await Queries.getUsersHeader(context)
		/**
		 * Même chose qu'avant mais juste leur nom affiché.
		 */
		const defaultHeadersNames = defaultHeaders.map(({ ColonneAffichee }) => ColonneAffichee)
		/**
		 * Toutes les entêtes pour l'utilisateur
		 * @type {string[]}
		 */
		const usersHeaders = Object.keys(students?.[0] || {})

		/**
		 * Application à afficher, on n'affiche pas celles qui sont inutilisées.
		 * @type {{}}
		 */
		const appsToDisplay = getUsersApps()
		/**
		 * Nom de toutes les applications
		 * @type {*[]}
		 */
		const appsNames = applications.map(({ name: appName }) => appName)

		const dbColumns = {}
		/**
		 * Par défaut, nous ajoutons une colonne pour la sélection des utilisateurs.
		 * @type {{floatingFilterComponentParams: {suppressFilterButton: boolean}, headerName: string, floatingFilter: boolean, headerCheckboxSelection: boolean, resizable: boolean, editable: boolean, checkboxSelection: boolean, headerCheckboxSelectionFilteredOnly: boolean, floatingFilterComponent: string, sortable: boolean, filter: string, hide: boolean, field: string}[]}
		 */
		const columnDefs = [
			{
				headerName: 'Sélection',
				field: 'Selection',
				editable: false,
				hide: false,
				filter: 'SelectedFilter',
				filterParams: {
					newRowsAction: 'keep',
				},
				floatingFilter: true,
				floatingFilterComponent: 'SelectedFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true },
				sortable: true,
				resizable: true,
				headerCheckboxSelectionFilteredOnly: true,
				checkboxSelection: true,
				headerCheckboxSelection: true,
			},
		]

		if (!usersHeaders) {
			return grid.setColumnDefs(columnDefs)
		}

		/**
		 * Pour chaque entête d'un utilisateur
		 */
		for (const header of defaultHeadersNames) {
			/**
			 * Si cette entête est comprise dans celles sauvegardées en BDD,
			 * alors nous alons utiliser ces paramètres.
			 */
			if (usersHeaders.includes(header)) {
				const { TypeColonne, ColonneAffichee, Editable, Visible, ColonneBDD, modificationKey, TypeFiltre } = defaultHeaders.find(({ ColonneAffichee }) => ColonneAffichee === header)

				dbColumns[ColonneAffichee] = modificationKey

				switch (TypeColonne) {
					case ColumnTypes.COMBOBOX:
						columnDefs.push({
							headerName: ColonneAffichee,
							field: ColonneAffichee,
							editable: canWriteInTable() && Boolean(Editable),
							hide: !Boolean(Visible),
							filter: true,
							filterParams: {
								newRowsAction: 'keep',
							},
							suppressMenu: true,
							floatingFilter: true,
							sortable: true,
							resizable: true,
							cellEditor: 'agRichSelect',
							cellEditorParams: { values: profiles.map(({ Nom }) => Nom) },
							headerCheckboxSelectionFilteredOnly: true,
						})
						break

					case ColumnTypes.CHECKBOX:
						columnDefs.push({
							headerName: ColonneAffichee,
							field: ColonneAffichee,
							editable: false,
							hide: !Boolean(Visible),
							cellRendererFramework: Boolean(Editable) ? StandardCheckbox : VerouilleCheckbox,
							cellRendererParams: {
								canWriteInTable: () => canWriteInTable(),
								modificationKey,
							},
							filter: 'SelectedFilter',
							filterParams: {
								newRowsAction: 'keep',
							},
							floatingFilter: true,
							floatingFilterComponent: 'SelectedFilterComponent',
							floatingFilterComponentParams: { suppressFilterButton: true },
							sortable: true,
							resizable: true,
						})
						break

					default:
						columnDefs.push({
							headerName: ColonneAffichee,
							field: ColonneAffichee,
							editable: canWriteInTable() && Boolean(Editable),
							hide: !Boolean(Visible),
							filter: Boolean(TypeFiltre) ? true : 'agTextColumnFilter',
							filterParams: ColonneBDD === 'IDimport' && {
								newRowsAction: 'keep',
								comparator: (a, b) => {
									const valA = parseInt(a)
									const valB = parseInt(b)

									if (valA === valB) return 0

									return valA > valB ? 1 : -1
								},
							},
							floatingFilter: true,
							cellClass: ColonneBDD === 'Login' && 'ag-login',
							headerCheckboxSelectionFilteredOnly: true,
							sortable: true,
							resizable: true,
						})
				}
			}
		}

		/**
		 * On parcours les entête non sauvegardé
		 */
		for (const header of usersHeaders) {
			if (!defaultHeadersNames.includes(header)) {
				dbColumns[header] = header
				switch (true) {
					/**
					 * S'il s'agit d'une application.
					 */
					case appsNames.includes(header):
						columnDefs.push({
							headerName: header,
							headerComponent: 'AppColumnHeader',
							headerComponentParams: {
								appName: header,
							},
							field: header,
							editable: canWriteInTable() && false,
							hide: !appsToDisplay[header],
							cellRendererFramework: canWriteInTable() ? ApplicationSwitch : VerouilleCheckbox,
							cellRendererParams: {
								applicationID: findApplicationIDByName(header)(),
							},
							filter: 'SelectedFilter',
							filterParams: {
								newRowsAction: 'keep',
							},
							floatingFilter: true,
							floatingFilterComponent: 'SelectedFilterComponent',
							floatingFilterComponentParams: { suppressFilterButton: true },
							suppressMenu: false,
							resizable: true,
						})
						break

					/**
					 * S'il s'agit de la première option
					 */
					case /Option[1]{1}$/.test(header):
						columnDefs.push({
							headerName: header,
							field: header,
							filter: 'MultipleChoiceFilter',
							floatingFilter: true,
							filterParams: {
								newRowsAction: 'keep',
								choices: searchThroughTableAfterFilter(new RegExp('Option')),
								searchThroughTable: () => searchThroughTableAfterFilter(new RegExp('Option')),
							},
							editable: canWriteInTable(),
							resizable: true,
						})
						break

					/**
					 * S'il s'agit des autres options
					 */
					case /Option(?!(?:1)$)\d+/.test(header):
						columnDefs.push({
							headerName: header,
							field: header,
							filter: false,
							editable: canWriteInTable(),
							suppressMenu: true,
							resizable: true,
						})
						break

					/**
					 * Sinon
					 */
					default:
						columnDefs.push({
							headerName: header,
							field: header,
							hide: true,
							resizable: true,
						})
						break
				}
			}
		}

		grid.setColumnDefs(columnDefs)
		grid.hideOverlay()

		/**
		 * Si l'utilisateur a l'option pour récupérer la configuration de la grille, alors on lui applique
		 * l'état sauvegardé en local, s'il existe. La configuration s'applique uniquement pour certaines colonnes (de Sélection à Commentaire).
		 *
		 * @author Hugo D
		 */
		if (hasGridPreferences && localStorage.getItem('gp')) {
			const authorizedColumns = ['Selection', 'ID utilisateur', 'N° import', 'Mode admin', 'UAI', 'Nom', 'Prénom', 'Login', 'Password', 'Niveau', 'Classe', 'Désactivé', 'Désactivé le', 'Profil', 'Verrouille', 'Dernière connexion', 'N° tablette', 'Commentaire']

			const gridPreferences = JSON.parse(localStorage.getItem('gp') || {})

			const authorizedColumsState = gridPreferences.filter(column => authorizedColumns.includes(column.colId))

			for (const column of columnDefs) {
				if (!authorizedColumns.includes(column.field)) {
					authorizedColumsState.push({
						colId: column.headerName,
					})
				}
			}

			gridColumns.setColumnState(authorizedColumsState)
		}

		const hasExistingFilters = Object.keys(usersFilters).length > 0

		grid.setFilterModel(
			hasExistingFilters
				? usersFilters
				: {
						Désactivé: false,
					},
		)

		setDbColumns(dbColumns)
		setProfiles(profiles)
	}, [applications, canWriteInTable, context, usersFilters, findApplicationIDByName, getUsersApps, hasGridPreferences, students])

	useEffect(() => {
		if (reloadGrid) {
			generateColumnDefs().then(() => {
				autoResizeColumns()
				restoreSelectedUsers(selectedUsers)
			})
		}
	}, [usersFilters, reloadGrid, generateColumnDefs])

	/**
	 * Ajuste automatique l'ensemble des colonnes de la grille.
	 */
	function autoResizeColumns() {
		gridColumns.autoSizeAllColumns()
	}

	/**
	 * Cherche une valeur dans la grille parmi les utilsiateurs filtrés.
	 * @param regex
	 * @returns {{null: boolean}}
	 */
	function searchThroughTableAfterFilter(regex) {
		const res = {}

		grid.forEachNodeAfterFilter(({ data }) => {
			for (const column in data) {
				const value = data[column]
				if (regex.test(column) && value) {
					res[value] = true
				}
			}
		})

		return res
	}

	/**
	 * Met à jour une valeur lors du changement d'une valeur dans la grille.
	 * @param oldValue Ancienne valeur
	 * @param newValue Nouvelle valeur
	 * @param rowIndex Numéro de la ligne modifiée
	 * @param field Nom de la coonne
	 * @param cellEditor Type de mofication pour la cellule
	 * @param data Ensemble des données de la ligne
	 */
	function onEditCellChanged({ oldValue, newValue, rowIndex, colDef: { editable, field, cellEditor }, data }) {
		if (newValue === oldValue || !editable) {
			return
		}

		let type, finalValue
		const editedColumn = dbColumns[field] || field
		const TYPES = {
			PROFILE: 'informations',
			OPTIONS: 'options',
			APPLICATIONS: 'applications',
		}

		const isLevelAndIsEmpty = editedColumn === 'Niveau' && (newValue.trim() === '' || !newValue)
		newValue = editedColumn === 'comment' ? escape(newValue) : newValue
		if (isLevelAndIsEmpty) {
			addToast(translate('global.errors.field_required', { field: translate('global.level') }), { appearance: 'warning' })
			return grid.startEditingCell({ rowIndex, colKey: editedColumn })
		}

		switch (true) {
			case cellEditor === 'agRichSelect':
				type = TYPES.PROFILE
				finalValue = profiles.find(({ Nom }) => Nom === newValue).IDprofil
				break

			case editedColumn.includes('Option'):
				type = TYPES.OPTIONS
				const options = []

				for (const column in data) {
					if (/Option[0-9*]/.test(column)) {
						const idOption = data[column]?.trim()

						if (options.includes(idOption) && idOption !== '' && idOption) {
							addToast('Cette option existe déjà pour cet utilisateur.', { appearance: 'warning' })
							return grid.startEditingCell({ rowIndex, colKey: editedColumn })
						} else if (idOption !== '') {
							options.push(idOption)
						}
					}
				}

				finalValue = options.filter(opt => opt !== undefined)

				break

			// "Description" correspond à la colonne Educadhoc-Code
			case editedColumn.includes('-Code'):
				const appId = applications?.find(({ name }) => name === editedColumn.split('-')[0]).idApplication
				type = TYPES.APPLICATIONS
				finalValue = {
					informations: {
						idApplication: appId,
						status: 2,
						keys: [newValue],
					},
				}
				break

			default:
				type = TYPES.PROFILE
				finalValue = newValue
				break
		}

		switch (type) {
			case TYPES.PROFILE:
				finalValue = { [editedColumn]: finalValue }
				break

			case TYPES.OPTIONS:
				finalValue = finalValue.map((idOption, ordre) => {
					return { idOption, ordre: ordre + 1 }
				})
				break

			case TYPES.APPLICATIONS:
			default:
				break
		}

		Queries.updateUserInGrid(context, data['ID utilisateur'], type, finalValue)
			.then(() => {
				addToast(translate('table.success', { value: editedColumn }), { appearance: 'success' })
			})
			.catch(() => {
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
				grid.startEditingCell({ rowIndex, colKey: field })
			})
	}

	/**
	 * Active ou désactive les boutons selon les utilisateurs sélectionnés.
	 */
	function onSelectionChanged() {
		const selected = grid.getSelectedRows()

		const disabled = selected.reduce((acc, user) => {
			if (user.Désactivé) {
				acc++
			}
			return acc
		}, 0)
		const enabled = selected.length - disabled

		//TODO: apres la modification d'un nombre important d'utilisateur
		// freeze de l'interface a cause de ca :
		selected.length > 0
			? setParentState({
					selected,
					enable_BulkButton: true,
					allEnabled: enabled === selected.length,
					allDisabled: disabled === selected.length,
					allSame: disabled === selected.length || enabled === selected.length,
				})
			: setParentState({
					selected,
					enable_BulkButton: false,
					allDisabled: false,
					allEnabled: false,
				})

		const selectedUsers = selected.map(user => user['ID utilisateur'])

		// local
		setSelectedUsers(selectedUsers)
		// context
		setSelectedUSersInGrid(selectedUsers)
	}

	/**
	 * Remet tous les boutons à l'état initial : désactivé.
	 */
	function resetButtons() {
		setParentState({
			selected: null,
			enable_BulkButton: false,
			enable_PurgeButton: false,
			enable_DisableButton: false,
		})
	}

	/**
	 * Si l'utilisateur clique sur le login, cela va charger sa fiche utilisateur.
	 * @param clickEvent
	 */
	function onCellClicked(clickEvent) {
		if (clickEvent && clickEvent.colDef.field === 'Login') {
			grid.showLoadingOverlay()

			if (showUserPage) {
				toggleUserPage()
			}

			const userId = clickEvent.data['ID utilisateur']

			loadUserPage(userId).then(() => grid.hideOverlay())
		}
	}

	/**
	 * Si l'utilisateur souhaite sauvegarder la configuration de la grille, alors à chaque modification
	 * de la configuration d'ag grid, on sauvegarde en local l'état des colonnes.
	 * Celui-ci sera appliqué lors de la prochaine génération des colonnes.
	 * On ne sauvegarde pas la configuration de la grille quand aucun élève n'est présent dans le bahut
	 * car seul la séléction est disponibles sans utilisateur.
	 * @param params
	 */
	function saveGridPreferences(params) {
		if (hasGridPreferences && students.length > 0) {
			localStorage.setItem('gp', JSON.stringify(params.columnApi.getColumnState()))
		}
	}

	/**
	 * Rendu
	 * Liste des props :
	 * 	- localeText  = langue de la grille selon un fichier de traduction
	 * 	- statusBar = Définie les paramètres de la barre de statut.
	 * 	- suppressFieldDotNotation = Ne traite pas les valeurs ayant un "." dedans
	 * 	- suppressRowClickSelection = Force à cocher la checkbox pour sélectionner une ligne.
	 * 	- popupParent = Définie le parent dans lequel afficher les popups.
	 * 	- sideBar = Affiche la barre à droite pour filtrer, sélectionner ...
	 * 	- debounceVerticalScrollbar = Rend la scrollbar plus fluide sur les anciens navigateurs
	 * 	- applyColumnDefOrder = Applique l'ordre des colonnes après modification
	 * 	- animateRows = Transition fluide des lignes lors d'un filtre ...
	 * 	- rowBuffer = Nombre de lignes rendu même si elles ne sont pas visibles.
	 * 	- onGridReady = Fonction à appeler lorsque la grille est montée.
	 * 	- rowSelection = Sélectionner un ou plusieurs utilisateurs.
	 * 	- columnDefs = Définition des colonnes.
	 * 	- rowData = Liste des données de la grille
	 * 	- frameworkComponents = Filtres customisés.
	 * 	- onFilterChanged = Fonction à appeler lorsqu'un filtre change.
	 * 	- onRowDataChanged = Fonction à appeler lors d'un changement total des données
	 * 	- onCellValueChanged = Fonction à appeler lors de la modification d'une cellule.
	 * 	- onSelectionChanged = Fonction à appeler lors d'un changement des utilisateurs sélectionnés.
	 * 	- onCellClicked = Fonction à appeler lors d'un clique sur une cellule.
	 * @returns {JSX.Element}
	 */
	return (
		<div className={'agrid-container'}>
			<section className={`ag-theme-balham${isLightTheme ? '' : '-dark'} agrid`} style={{ height: '100%' }}>
				<AgGridReact
					localeText={lang === 'fr' ? AG_GRID_LOCALE_FR : AG_GRID_LOCALE_EN}
					statusBar={{
						statusPanels: [
							{
								statusPanel: 'agTotalAndFilteredRowCountComponent',
								align: 'left',
							},
							{
								statusPanel: 'agFilteredRowCountComponent',
								align: 'left',
							},
							{ statusPanel: 'agSelectedRowCountComponent', align: 'left' },
						],
					}}
					suppressFieldDotNotation
					suppressRowClickSelection
					popupParent={document.body}
					sideBar
					debounceVerticalScrollbar
					applyColumnDefOrder
					animateRows
					rowBuffer={30}
					onGridReady={prepareGrid}
					rowSelection="multiple"
					columnDefs={grid?.columnController?.columnsDefs || []}
					rowData={students || []}
					frameworkComponents={{
						MultipleChoiceFilter: MultipleChoiceFilter,
						SelectedFilter: SelectedFilter,
						SelectedFilterComponent: SelectedFilterComponent,
						AppColumnHeader: AppColumnHeader
					}}
					onFilterChanged={() => {
						const gridFilter = grid.getFilterModel()

						setGridFilter('table', gridFilter, null, 'Grideo.onFilterChanged')
					}}
					onRowDataChanged={resetButtons}
					onCellValueChanged={onEditCellChanged}
					onSelectionChanged={onSelectionChanged}
					onCellClicked={onCellClicked}
					onColumnMoved={saveGridPreferences}
					onColumnPinned={saveGridPreferences}
					onColumnResized={saveGridPreferences}
					onColumnVisible={saveGridPreferences}
				/>
			</section>
		</div>
	)
}

export default Grideo
