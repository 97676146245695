import { Button, Heading, Modal, Table, Loader } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import Hr from '../../utils/Hr'
import React, { useContext, useEffect, useState } from 'react'
import GlobalContext from '../../../contexts/GlobalContext'
import FileUtils from '../../utils/FileUtils'
import { SegmentedControl, SegmentedOption } from '../../utils/SegmentedControl'
import Queries from '../../../tools/Query'

function BulkDocs({ show, onClose }) {
	const name = 'filesManager'

	const context = useContext(GlobalContext)
	const { isLightTheme, translate, grid, addToast, school } = context
	const currentUai = school?.informations.uai

	/**
	 * Possibilité des actions pour affecter ou désaffecter des documents aux utilisateurs.
	 * @type {{SET: string, REMOVE: string}}
	 */
	const SEGMENTED = {
		SET: 'insert',
		REMOVE: 'delete',
	}

	const [files, setFiles] = useState(null)

	/**
	 * Récupère l'ensemble des fichiers lié à l'établissement.
	 */
	useEffect(() => {
		if (!files) {
			Queries.getFiles(context, currentUai)
				.then(({ files }) => setFiles(files))
				.catch(() => {
					addToast(translate('global.errors.occurred'), { appearance: 'error' })
				})
		}
	}, [addToast, context, files, currentUai, translate])

	/**
	 * Liste des utilisateurs sélectionné pour recevoir ou perdre un document.
	 */
	const [selectedUsers, setSelectedUsers] = useState(getSelectedUsers())
	/**
	 * Liste des fichiers sélectionnés par l'utilisateur
	 */
	const [selected, setSelected] = useState([])

	/**
	 * Choix de l'action à effectuer.
	 */
	const [segmented, setSegmented] = useState(SEGMENTED.SET)

	/**
	 * Sélectionne un fichier
	 * @param file
	 */
	function pushSelectedFile(file) {
		selected.find(selectedFile => selectedFile.key === file.key)
			? selected.splice(
					selected.findIndex(selectedFile => selectedFile.key === file.key),
					1
			  )
			: selected.push(file)

		setSelected([...selected])
	}

	/**
	 * Change l'action et cherche les nouveaux utilisateurs qui ont/n'ont pas les documents sélectionnés.
	 * @param value
	 */
	function onSegmentedChange(value) {
		setSegmented(value)
		setSelectedUsers(getSelectedUsers())
	}

	/**
	 * Récupère la liste des utilisateurs qui ont/n'ont pas les documents sélectionnés.
	 * TODO: check si chaque utilisateur a ou n'a pas les documents sélectionnés.
	 * @return {[]}
	 */
	function getSelectedUsers() {
		const users = []
		grid.forEachNodeAfterFilter(node => {
			if (node.isSelected()) {
				users.push(node.data)
			}
		})
		return users
	}

	/**
	 * Requête le serveur pour appliquer l'affection ou la désaffection selon l'action.
	 */
	function handleSubmit() {
		Queries.affectUsers(
			context,
			segmented,
			selected.map(file => file.key),
			selectedUsers.map(user => user['ID utilisateur'])
		)
			.then(() => {
				addToast('Les documents ont bien été affectés aux utilisateurs.', { appearance: 'success' })
				onClose(name)
			})
			.catch(() => addToast(translate('global.errors.occurred'), { appearance: 'error' }))
	}

	let className = 'is-large '
	className += isLightTheme ? 'is-light' : 'is-dark '

	return (
		<Modal className={className} show={show} showClose onClose={() => onClose(name)} closeOnBlur>
			<Modal.Card className={'fade-in-bottom'}>
				<Modal.Card.Body>
					<header className="is-flex" style={{ justifyContent: 'space-between' }}>
						<Heading size={5}>Affectation des documents</Heading>
						<Button color={'light'} onClick={() => onClose(name)}>
							<Icon icon={'fal fa-times fa-2x'} />
						</Button>
					</header>
					<Hr />
					<div style={{ margin: '0 25%' }}>
						<SegmentedControl id="F" defaultValue={segmented} mini onChange={onSegmentedChange} style={{ marginBotton: '1rem' }}>
							<SegmentedOption value={SEGMENTED.SET}>{translate('global.add_alt')}</SegmentedOption>
							<SegmentedOption value={SEGMENTED.REMOVE}>{translate('global.remove_alt')}</SegmentedOption>
						</SegmentedControl>
					</div>
					<section>
						<Heading subtitle size={6} marginless className="flex place-items-center space-x-2">
							<span>Documents disponibles</span>
							<span className="has-tooltip-arrow has-tooltip-right" data-tooltip="Cliquer pour sélectionner, double-cliquer pour voir le document.">
								<Icon icon={'fal fa-question-circle'} />
							</span>
						</Heading>
						<section className="flex space-x-2 flex-wrap">
							{files ? (
								files.map((file, idx) => {
									const isSelected = selected.find(selectedFile => selectedFile.key === file.key)

									return (
										<button key={idx} className={`border rounded-lg shadow-sm p-4 w-auto my-4 hover:border-gray-400 cursor-pointer space-x-2 ${isSelected ? 'border-green-500 text-green-500' : ''} focus:outline-none`} onClick={() => pushSelectedFile(file)}>
											<span className={isSelected ? 'text-green-500' : 'has-text-primary'}>
												<Icon icon={`fad ${FileUtils.getIcons(file)}`} />
												<span className="is-text-icon">{file.name}</span>
											</span>
											<span className="is-size-7">{FileUtils.humanFileSize(file.size)}</span>
										</button>
									)
								})
							) : (
								<Loader />
							)}
						</section>
						<Hr />
						<Heading subtitle marginless size={6}>
							{selectedUsers.length} {translate('global.users')}
						</Heading>
						<div className="table-container">
							<Table striped className={`is-narrow ${!isLightTheme && 'is-dark'}`} marginless>
								<thead>
									<tr>
										<th>Nom</th>
										<th>Prénom</th>
										<th>Niveau</th>
										<th>Classe</th>
									</tr>
								</thead>
								<tbody>
									{selectedUsers.map((student, key) => (
										<tr key={key}>
											<td>{student.Nom}</td>
											<td>{student.Prénom}</td>
											<td>{student.Niveau}</td>
											<td>{student.Classe}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</div>
					</section>
					<br />
					<div className="is-flex" style={{ justifyContent: 'right' }}>
						<Button color={'primary'} onClick={handleSubmit}>
							{translate('global.confirm')}
						</Button>
					</div>
				</Modal.Card.Body>
			</Modal.Card>
		</Modal>
	)
}

export default BulkDocs
