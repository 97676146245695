import React from 'react'
import { Button, Form, Heading, Modal, Select } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import GlobalContext from '../../../contexts/GlobalContext'
import Hr from '../../utils/Hr'
import Queries from '../../../tools/Query'

/**
 * Fenêtre pour envoyer une notification
 */
export default class SendNotification extends React.Component {
	static contextType = GlobalContext

	constructor(props) {
		super(props)
		this.name = 'sendNotification'
		this.state = {
			loading: false,
			titleInput: '',
			bodyInput: '',
			linkInput: '',
			actionInput: '',
			actionDataInput: '',
			submitDisable: true,
		}
	}

	/**
	 * Récupère les utilisateurs filtrés.
	 * @returns {[]}
	 */
	getSelectedAfterFilter = () => {
		const users = []
		this.context.grid.forEachNodeAfterFilter(node => {
			if (node.isSelected()) {
				users.push(node.data['ID utilisateur'])
			}
		})
		return users
	}

	/**
	 * Gère la saisie pour un titre de notification.
	 * @param keyboardEvent
	 */
	handleTitleChange = keyboardEvent => {
		const value = keyboardEvent.target.value
		this.setState({
			titleInput: value,
			submitDisable: value === '',
		})
	}

	/**
	 * Gère la saisie pour un contenu de notification.
	 * @param keyboardEvent
	 */
	handleBodyChange = keyboardEvent => {
		const value = keyboardEvent.target.value
		this.setState({
			bodyInput: value,
		})
	}

	/**
	 * Gère la saisie pour une action.
	 * @param keyboardEvent
	 */
	handleActionChange = keyboardEvent => {
		const value = keyboardEvent.target.value
		this.setState({
			actionInput: value,
		})
	}

	handleLinkChange = keyboardEvent => {
		const value = keyboardEvent.target.value
		this.setState({
			linkInput: value,
		})
	}

	/**
	 * Gère la saisie des custom data pour une action selectionné.
	 * @param keyboardEvent
	 */
	handleActionDataChange = keyboardEvent => {
		const value = keyboardEvent.target.value
		this.setState({
			actionDataInput: value,
		})
	}

	/**
	 * Met à jour les utilisateurs.
	 * @param formEvent
	 */
	onSubmit = formEvent => {
		formEvent.preventDefault()

		const { onClose } = this.props
		const { addToast, refresh, translate } = this.context
		const { titleInput, bodyInput, actionInput, actionDataInput, linkInput } = this.state

		const selected = this.getSelectedAfterFilter()

		this.setState({ loading: true })

		Queries.sendNotification(this.context, selected, titleInput, bodyInput, linkInput, actionInput, actionDataInput)
			.then(res => {
				addToast(
					translate('modals.generic.bulk_success', {
						action: 'Notification',
						users: selected.length,
					}),
					{ appearance: 'success' }
				)
				onClose(this.name)
			})
			.catch(err => {
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
				onClose(this.name)
			})
	}

	render() {
		const { translate } = this.context

		return (
			<Modal className={!this.context.isLightTheme ? 'is-dark' : ''} show={this.props.show} showClose onClose={() => this.props.onClose(this.name)} closeOnBlur>
				<Modal.Card className={'fade-in-bottom'}>
					<Modal.Card.Body>
						<form onSubmit={this.onSubmit}>
							<header className="is-flex" style={{ justifyContent: 'space-between' }}>
								<div>
									<Heading size={5}>{translate('modals.generic.mass_update')}</Heading>
									<Heading size={6} subtitle>
										Notification
									</Heading>
								</div>
								<Button color={'light'} type={'button'} onClick={() => this.props.onClose(this.name)}>
									<Icon icon={'fal fa-times fa-2x'} />
								</Button>
							</header>
							<Hr />
							<Heading subtitle marginless size={5} textColor={'primary'}>
								{this.getSelectedAfterFilter().length} {translate('global.users')}
							</Heading>
							<Form.Field>
								<Form.Control>
									<Form.Input required={true} value={this.state.titleInput} type={'text'} name={'titleInput'} id={'titleInput'} placeholder={translate('modals.specific.notification.title')} onChange={this.handleTitleChange} autoFocus />
								</Form.Control>
							</Form.Field>
							<Form.Field>
								<Form.Control>
									<Form.Textarea value={this.state.bodyInput} type={'textarea'} name={'bodyInput'} id={'bodyInput'} placeholder={translate('modals.specific.notification.body')} onChange={this.handleBodyChange} />
								</Form.Control>
							</Form.Field>
							<Form.Field>
								<Form.Control>
									<Form.Input value={this.state.linkInput} type={'text'} name={'linkInput'} id={'linkInput'} placeholder={translate('modals.specific.notification.link')} onChange={this.handleLinkChange} />
								</Form.Control>
							</Form.Field>
							<Form.Field>
								<Form.Control>
									<Form.Select value={this.state.actionInput} name={'actionInput'} id={'actionInput'} onChange={this.handleActionChange}>
										<option value={''}>Notifier</option>
										<option value={'copy'}>{translate('modals.specific.notification.copy')}</option>
									</Form.Select>
								</Form.Control>
							</Form.Field>
							{this.state.actionInput && (
								<Form.Field className={'fade-in-bottom'}>
									<Form.Control>
										<Form.Textarea value={this.state.actionDataInput} name={'actionDataInput'} id={'actionDataInput'} onChange={this.handleActionDataChange} />
									</Form.Control>
								</Form.Field>
							)}
							<br />
							<div className="is-flex" style={{ justifyContent: 'space-between' }}>
								<Button type={'reset'} color={'danger'} onClick={() => this.props.onClose(this.name)}>
									{translate('global.cancel')}
								</Button>
								<Button loading={this.state.loading} type={'submit'} color={'primary'} disabled={this.state.submitDisable}>
									{translate('global.confirm')}
								</Button>
							</div>
						</form>
					</Modal.Card.Body>
				</Modal.Card>
			</Modal>
		)
	}
}
