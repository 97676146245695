import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { Button, Loader } from 'react-bulma-components'
import Icon from '../utils/Icon'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export default function PdfViewer({ url }) {
	const [numPages, setNumPages] = useState(null)
	const [pageNumber, setPageNumber] = useState(1) //setting 1 to show fisrt page
	const [scale, setScale] = useState(1.5)

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages)
		setPageNumber(1)
	}

	function inputToPage(event) {
		event.preventDefault()

		const { value } = event.target
		const number = Number.parseInt(value)

		setPageNumber(Number.isNaN(number) ? pageNumber : number)
	}

	return (
		<section className="flex justify-center flex-col mb-12">
			<div className="fixed bottom-4 left-0 right-0 z-20 flex flex-initial justify-center">
				<section className="flex place-items-center justify-center divide-x-2 py-3 rounded-full w-auto bg-white shadow-lg border">
					<article className="flex justify-center place-items-center space-x-8 px-4">
						<Button color="primary" className="w-8" rounded type="button" disabled={pageNumber <= 1} onClick={() => setPageNumber(page => page - 1)}>
							&larr;
						</Button>
						<p className="flex place-items-center ">
							Page
							<input type="text" min={1} max={numPages} value={pageNumber} onChange={inputToPage} onFocus={e => e.target.select()} className="mx-2 border w-24 py-1 px-2 rounded focus:outline-none focus:border-gray-400" /> sur {numPages || '--'}
						</p>
						<Button color="primary" className="w-8" rounded type="button" disabled={pageNumber >= numPages} onClick={() => setPageNumber(page => page + 1)}>
							&rarr;
						</Button>
					</article>
					<article className="flex justify-center place-items-center space-x-8 px-4">
						<Button color="info" className="w-4" rounded size="small" type="button" disabled={scale <= 0.5} onClick={() => setScale(scale => scale - 0.1)}>
							<Icon icon="fal fa-search-minus" />
						</Button>
						<p className="text-gray-500 text-sm">Zoom {(scale * 100).toFixed(0)}%</p>
						<Button color="info" className="w-4" rounded size="small" type="button" disabled={scale >= 3} onClick={() => setScale(scale => scale + 0.1)}>
							<Icon icon="fal fa-search-plus" />
						</Button>
					</article>
				</section>
			</div>
			<Document
				className="flex justify-center"
				file={url}
				options={{ workerSrc: '/pdf.worker.js' }}
				onLoadSuccess={onDocumentLoadSuccess}
				loading={
					<div className="flex justify-center space-x-4 place-items-center">
						<p>Chargement de votre document...</p>
						<Loader />
					</div>
				}
			>
				<Page pageNumber={pageNumber} scale={scale} />
			</Document>
		</section>
	)
}
