import React, { FC, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import GlobalContext from '../../../contexts/GlobalContext'
// @ts-ignore
import { Button, Columns, Form, Heading, Image, Modal, Tag } from 'react-bulma-components'
import Hr from '../../utils/Hr'
import { Formik } from 'formik'
import { Schools } from './Schools'
import Query from '../../../tools/Query'
import { PlatformSettings } from '../../settings/global/GlobalPlatforms/types'
import { setFormatDateByFrequency } from './ScheduledTaskEdit'

interface Props {
	onClose: (name: string) => void
	show: boolean
	fetchTasks: () => void
}

export const ScheduledTaskCreate: FC<Props> = ({ onClose, show, fetchTasks }) => {
	const NAME = 'createTask'

	const context = useContext(GlobalContext)
	const { translate, isLightTheme, school, addToast } = context
	const [loading, setLoading] = useState<boolean>(false)
	const [uais, setUais] = useState<string[]>([])
	const [platforms, setPlatforms] = useState<PlatformSettings[]>([])

	const fetchPlatforms = useCallback(() => {
		Query.getGlobalSettings(context, 'platforms')
			.then(fetchedPlatforms => {
				if (fetchedPlatforms) setPlatforms(fetchedPlatforms as PlatformSettings[])
				setLoading(false)
			})
			.catch(err => {
				setLoading(false)
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
			})
	}, [addToast, context, translate])

	useEffect(() => {
		fetchPlatforms()
	}, [])

	const createScheduledTask = async (values: Record<string, any>) => {
		setLoading(true)
		try {
			const res = await Query.createTokenScheduledTask(context, {
				eventName: values.label,
				platformId: values.platformId,
				startDate: values.startDate.replace('T', ' '),
				intervalField: values.frequency,
				uais: uais.includes('all') ? ['all'] : uais,
			})
			if (res) {
				setLoading(false)
				onClose(values.name)
				fetchTasks()
			}
		} catch (e) {
			console.error(e)
		}
		setLoading(false)
		addToast(translate('settings.update_success'))
	}

	return (
		<Modal className={`is-large ${!isLightTheme && 'is-dark'}`} show={show} showClose onClose={() => onClose(NAME)} closeOnBlur>
			<Modal.Card className={'fade-in-bottom'}>
				<Modal.Card.Body>
					<Formik
						onSubmit={createScheduledTask}
						initialValues={{
							label: '',
							frequency: 'DAY',
							startDate: null,
							platformId: null,
							uais: null,
						}}
					>
						{({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
							<form onSubmit={handleSubmit}>
								<header className="is-flex" style={{ justifyContent: 'space-between' }}>
									<div>
										<Heading size={5}>Ajouter une purge planifiée</Heading>
									</div>
								</header>
								<Hr />

								<Form.Field>
									<Form.Label>Libellé</Form.Label>
									<Form.Control>
										<Form.Input name={'label'} onChange={handleChange} value={values.label} required />
									</Form.Control>
								</Form.Field>

								<Form.Field>
									<Form.Label>Fréquence</Form.Label>
									<Form.Control>
										<Form.Select name={'frequency'} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFormatDateByFrequency(e, handleChange, setFieldValue)} value={values.frequency} required>
											<option value={'IMMEDIATE'}>Immediate</option>
											<option value={'DAY'}>Tous les jours</option>
											<option value={'WEEK'}>Toutes les semaines</option>
											<option value={'MONTH'}>Tous les mois</option>
											<option value={'YEAR'}>Tous les ans</option>
										</Form.Select>
									</Form.Control>
								</Form.Field>

								<Form.Field>
									<Form.Label>Première exécution</Form.Label>
									<Form.Control>
										<Form.Input type={'datetime-local'} max={'9999-12-30T23:59:59'} name={'startDate'} onChange={handleChange} value={values.startDate} required />
									</Form.Control>
								</Form.Field>

								<Form.Field>
									<Form.Label>Plateforme</Form.Label>
									<Form.Control>
										<Form.Select name={'platformId'} onChange={handleChange} value={values.platformId} required>
											<option selected></option>
											{platforms.map(platform => {
												return <option value={platform.idPlateforme}>{platform.idPlateforme}</option>
											})}
										</Form.Select>
									</Form.Control>
								</Form.Field>

								<Form.Field>
									<Form.Label>UAIS</Form.Label>
									<div className="field is-grouped is-grouped-multiline my-4">
										{uais.map(uai => {
											return (
												<Form.Control>
													<Tag.Group className="has-addons">
														<Tag color={'dark'}>{uai === 'all' ? 'Tous les établissements' : uai}</Tag>
														<Tag
															remove
															className="cursor-pointer"
															onClick={() => {
																uais.splice(uais.indexOf(uai), 1)
																setUais([...uais])
															}}
														/>
													</Tag.Group>
												</Form.Control>
											)
										})}
									</div>

									<Schools
										addSchool={uai => {
											if (!uais.includes(uai)) {
												setUais([...uais, uai])
											}
										}}
									/>
								</Form.Field>

								<div className="is-flex" style={{ justifyContent: 'space-between' }}>
									<Button
										color={'danger'}
										type={'reset'}
										onClick={() => {
											onClose(NAME)
										}}
									>
										{translate('global.cancel')}
									</Button>
									<Button disabled={uais.length < 1} color={'primary'} type={'submit'} loading={loading} data-tooltip={uais.length < 1 ? 'Choisir un uai' : ' Mettre à jour'}>
										{translate('global.add')}
									</Button>
								</div>
							</form>
						)}
					</Formik>
				</Modal.Card.Body>
			</Modal.Card>
		</Modal>
	)
}
