import React, {Component} from 'react';

export default class VerouilleCheckbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value:  Number(props.value) === 1
        };
    }

    render() {
        const key = Math.random() * Math.random();
        return (
            <div className={'has-text-centered'}>
                <input
                    disabled
                    id={key}
                    type="checkbox"
                    name="switchRoundedDefault"
                    className="switch is-rounded is-success is-small"
                    checked={this.state.value}/>
                <label htmlFor={key}/>
                {/*<input disabled type='checkbox' checked={this.state.value}/>*/}
            </div>
        );
    }
}