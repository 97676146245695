import { useContext } from 'react'
import GlobalContext from '../../../contexts/GlobalContext'

type AppColumnHeaderProps = {
	displayName: string
	appName: string
}

export function AppColumnHeader(props: AppColumnHeaderProps) {
	const { school: { applications } } = useContext(GlobalContext)

	const app = applications?.find((app: any) => app.name === props.appName)

	if (!app) {
		return <>{props.appName}</>
	}

	return <figure className="flex items-center space-x-2">
		<img src={app.urls.picturePath} alt="" className="w-5 h-5" />
		<span>{props.appName}</span>
	</figure>
}