import React, { useContext, useState, FC, ChangeEvent, FormEvent } from 'react'
// @ts-ignore
import { Button, Form } from 'react-bulma-components'
import Icon from '../../../utils/Icon'
import GlobalContext from '../../../../contexts/GlobalContext'
import Queries from '../../../../tools/Query'

/**
 * Onglet "Mon Compte" dans les paramètres utilisateurs.
 * Permet de changer le mot de passe.
 */

const AccountSettings: FC<void> = () => {
	const context = useContext(GlobalContext)
	const { translate, user, addToast } = context
	const [actualPassword, setActualPassword] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [comfirmNewPassword, setComfirmNewPassword] = useState('')
	const [matchingError, setMatchingError] = useState<string>('')
	const [showActualPassword, setShowActualPassword] = useState<boolean>(false)
	const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
	const [showComfirmPassword, setShowComfirmPassword] = useState<boolean>(false)
	const isStudentOrTeacher = ['Eleve', 'Enseignant'].includes(user.profile)

	function handleComfirmPassword(): void {
		// Regex qui force 8 caractères, une majuscule et un digit
		const paswd = /^(?=.*\d)(?=.*[A-Z])(?!.*\s)(.{8,})/
		if (newPassword !== comfirmNewPassword) {
			return setMatchingError(translate('modals.specific.user_preferences.not_matching'))
		}
		if (newPassword.match(paswd) === null) {
			return setMatchingError(translate('modals.specific.user_preferences.password_rules'))
		}
		setMatchingError('')
	}

	function handleSubmit(formEvent: FormEvent<HTMLFormElement>): void {
		formEvent.preventDefault()
		if (matchingError === '') {
			Queries.modifyPassword(context, actualPassword, user.id, newPassword)
				.then(() => {
					addToast(translate('modals.specific.user_preferences.password_change_success'), { appearance: 'success' })
				})
				.catch(err => {
					console.error(err)
					addToast(err, { appearance: 'error' })
				})
		} else {
			addToast(translate('modals.specific.user_preferences.password_change_fail'), { appearance: 'error' })
		}
	}

	return (
		<>
			{isStudentOrTeacher ? (
				<p> Mon compte. </p>
			) : (
				<form onSubmit={handleSubmit}>
					<Form.Field>
						<Form.Label>{translate('modals.specific.user_preferences.actual_password')}</Form.Label>
						<Form.Control iconLeft iconRight>
							<Form.Input className="" type={showActualPassword ? 'text' : 'password'} value={actualPassword} onChange={(event: ChangeEvent<HTMLInputElement>) => setActualPassword(event.target.value)} name="actualPassword" required />
							<Icon icon={'fal fa-fingerprint'} className={'is-left'} />
							<Icon key={showActualPassword ? 'show' : 'hidden'} onClick={() => setShowActualPassword(!showActualPassword)} className={'icon is-icon-clickable is-right'} icon={showActualPassword ? 'far fa-eye-slash' : 'far fa-eye'} />
						</Form.Control>
					</Form.Field>
					<Form.Field>
						<Form.Label>{translate('modals.specific.user_preferences.new_password')}</Form.Label>
						<Form.Control iconLeft iconRight>
							<Form.Input className="" type={showNewPassword ? 'text' : 'password'} value={newPassword} onChange={(event: ChangeEvent<HTMLInputElement>) => setNewPassword(event.target.value)} name="newPassword" required minlength="8" onBlur={handleComfirmPassword} />
							<Icon icon={'fal fa-fingerprint'} className={'is-left'} />
							<Icon key={showNewPassword ? 'show' : 'hidden'} onClick={() => setShowNewPassword(!showNewPassword)} className={'icon is-icon-clickable is-right'} icon={showNewPassword ? 'far fa-eye-slash' : 'far fa-eye'} />
						</Form.Control>
					</Form.Field>
					<Form.Field>
						<Form.Label>{translate('modals.specific.user_preferences.confirm_new_password')}</Form.Label>
						<Form.Control iconLeft iconRight>
							<Form.Input className="" type={showComfirmPassword ? 'text' : 'password'} value={comfirmNewPassword} onChange={(event: ChangeEvent<HTMLInputElement>) => setComfirmNewPassword(event.target.value)} name="comfirmNewPassword" required minlength="8" onKeyUp={handleComfirmPassword} />
							<Icon icon={'fal fa-fingerprint'} className={'is-left'} />
							<Icon key={showComfirmPassword ? 'show' : 'hidden'} onClick={() => setShowComfirmPassword(!showComfirmPassword)} className={'icon is-icon-clickable is-right'} icon={showComfirmPassword ? 'far fa-eye-slash' : 'far fa-eye'} />
							<Form.Help color="danger">{matchingError}</Form.Help>
						</Form.Control>
					</Form.Field>
					<Form.Field>
						<Form.Control>
							<Button type="submit" onClick={handleComfirmPassword}>
								{translate('modals.specific.user_preferences.change_password')}
							</Button>
						</Form.Control>
					</Form.Field>
				</form>
			)}
		</>
	)
}

export default AccountSettings
