import React, { useContext, useState, useCallback, useEffect, useRef } from 'react'
import GlobalContext from '../../../contexts/GlobalContext'
import Query from '../../../tools/Query'
import { Image, Heading, Button, Columns, Form, Content } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import _ from 'lodash'
import GlobalUtils from '../../utils/GlobalUtils'

function ComboPlatform({ value, onChange, name }) {
	const context = useContext(GlobalContext)
	const { isLightTheme, addToast, translate } = context
	const [platforms, setPlatforms] = useState(null)
	const [loading, setLoading] = useState(true)

	const fetchPlatforms = useCallback(() => {
		Query.getGlobalSettings(context, 'platforms')
			.then(platforms => {
				setPlatforms(platforms)
				setLoading(false)
			})
			.catch(err => {
				setLoading(false)
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
			})
	}, [addToast, context, translate])

	useEffect(() => {
		if (!platforms) fetchPlatforms()
	}, [platforms, fetchPlatforms])

	return (
		<Form.Control className={'is-expanded'} size={'small'}>
			<div className={`${isLightTheme ? '' : 'has-background-dark'} select is-small is-fullwidth ${loading ? 'is-loading' : ''}`}>
				<select name={name} className={isLightTheme ? '' : 'has-background-dark'} value={value} onChange={onChange} required>
					<option key={'-1'} value={''}>
						{''}
					</option>
					{platforms &&
						platforms.map(({ name, idPlateforme }, key) => (
							<option key={key} value={idPlateforme}>
								{name}
							</option>
						))}
				</select>
			</div>
		</Form.Control>
	)
}

/**
 * Vue globale sur toutes les applications.
 * Elles sont triées dans l'ordre selon un champs renseignée en BDD.
 * La modification est unique à chaque application, il n'y a pas de modifications générales, hormis pour l'ordre.
 *
 * @return {JSX.Element}
 * @constructor
 */
function GlobalApps() {
	const context = useContext(GlobalContext)
	const { addToast, translate } = context

	const [loading, setLoading] = useState(true)

	/**
	 * Ensemble des applications, avec en clé leur ID (inchangeable).
	 * @type {object}
	 */
	const [apps, setApps] = useState(null)

	/**
	 * Verifie si la value n'est pas deja utilisee
	 */
	function alreadyUsedValue(current, name, value) {
		return !!apps.find(app => app[name] === value && current[name] !== value)
	}

	/**
	 * Requête l'API pour récupère les apps.
	 * @type {(function(): void)|*}
	 */
	const fetchApps = useCallback(() => {
		Query.getGlobalSettings(context, 'apps')
			.then(apps => {
				setApps(apps)
				setLoading(false)
			})
			.catch(err => {
				setLoading(false)
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
			})
	}, [addToast, context, translate])

	const lastAppPosition = apps => {
		return apps.sort((a, b) => a.order - b.order).slice(-1)[0].order + 1
	}

	/**
	 * Récupère l'ensemble des applications au premier chargement.
	 */
	useEffect(() => {
		if (!apps) fetchApps()
	}, [apps, fetchApps])

	return loading ? (
		<Skeleton />
	) : (
		<div>
			<Heading size={4}>{apps.length} applications</Heading>
			<section className="space-y-4">
				<a
					id={'new-app-high'}
					href={'#new-app'}
					onClick={() => {
						const newApp = {
							name: '',
							order: lastAppPosition(apps),
							idPlateforme: '',
							newApplication: true,
							idApplication: '',
							newIdApplication: '',
							showTokenStatus: false,
							applicationCodeYesNo: false,
							applicationCodeRequired: false,
							urls: {
								picturePath: '',
								dmgLink: '',
								exeLink: '',
								playStore: '',
								appStore: '',
								urlScheme: '',
								urlClean: '',
								urlOpen: '',
								urlLine: '',
							},
						}
						setApps([...apps, newApp])
					}}
					className="border p-1 w-full rounded-lg text-center hover:bg-gray-100 transition block"
				>
					+
				</a>
				{apps
					.sort((appA, appB) => appA.order - appB.order)
					.map((app, index) => (
						<AppConfigurator key={app.idApplication} id={app.idApplication} app={app} index={index} updateApps={fetchApps} alreadyUsedValue={(name, value) => alreadyUsedValue(app, name, value)} />
					))}
				<a
					id={'new-app'}
					href={'#new-app'}
					onClick={() => {
						const newApp = {
							name: '',
							order: lastAppPosition(apps),
							idPlateforme: '',
							newApplication: true,
							idApplication: '',
							newIdApplication: '',
							showTokenStatus: false,
							applicationCodeYesNo: false,
							applicationCodeRequired: false,
							urls: {
								picturePath: '',
								dmgLink: '',
								exeLink: '',
								playStore: '',
								appStore: '',
								urlScheme: '',
								urlClean: '',
								urlOpen: '',
								urlLine: '',
							},
						}
						setApps([...apps, newApp])
					}}
					className="border p-1 w-full rounded-lg text-center hover:bg-gray-100 transition block"
				>
					+
				</a>
			</section>
		</div>
	)
}

/**
 * Réglage de chaque champs pour l'app spécifié.
 * @param id
 * @param {string} dmgLink
 * @param {string} exeLink
 * @param {string} playStore
 * @param {string} appStore
 * @param {string} urlScheme
 * @param {string} urlClean
 * @param {string} urlOpen
 * @param {string} urlLine
 * @param {string} picturePath
 * @param {string} name
 * @param {number} order
 * @param {string} exportFile
 * @param {string} exportType
 * @param {number} forExport
 * @param {number} urlSchemeLoginRequired
 * @param {number} urlSchemePwdRequired
 * @param {number} urlSchemeCodeRequired
 * @param {number} codeByStudentRequired
 * @param {number} applicationCodeRequired
 * @param {number} applicationCodeYesNo
 * @param {number} urlSchemeCodeYesNo
 * @returns {JSX.Element}
 * @constructor
 */
function AppConfigurator({
	id,
	app: {
		urls: { dmgLink, exeLink, playStore, appStore, urlScheme, urlClean, urlOpen, urlLine, picturePath },
		idApplication,
		idPlateforme,
		newApplication,
		name,
		order,
		showTokenStatus,
		applicationCodeYesNo,
		applicationCodeRequired,
	},
	updateApps,
	alreadyUsedValue,
}) {
	const inputFileRef = useRef()

	const context = useContext(GlobalContext)
	const { translate, addToast, showModal } = context

	const initialValues = {
		name,
		order,
		idPlateforme,
		idApplication,
		newIdApplication: idApplication,
		newApplication,
		showTokenStatus,
		applicationCodeYesNo,
		applicationCodeRequired,
		urls: {
			picturePath,
			dmgLink,
			exeLink,
			playStore,
			appStore,
			urlScheme,
			urlClean,
			urlOpen,
			urlLine,
		},
	}

	/**
	 * Défini tous les champs uniformisé lors de l'initialisation.
	 */
	const [values, setValues] = useState(_.cloneDeep(initialValues))
	const [loading, setLoading] = useState(false)
	const [editing, setEditing] = useState(name === '')
	/**
	 * Défini si l'application est déroulée pour afficher tous les champs.
	 */
	const [displayed, setDisplayed] = useState(name === '')

	useEffect(() => {
		if (newApplication && values.newIdApplication !== values.idApplication) {
			setValues({ ...values, idApplication: values.newIdApplication })
		}
	}, [newApplication, setValues, values])

	function handleChangeOrder(value) {
		const oldOrder = values.order
		const newOrder = oldOrder + value
		const computedOrder = newOrder <= 0 ? 0 : newOrder

		const appUpdated = {
			...values,
			order: computedOrder,
		}
		setValues(appUpdated)
	}

	function handleChangeUrl(event) {
		const { name, value } = event.target
		setValues({
			...values,
			urls: {
				...values.urls,
				[name]: value,
			},
		})
	}

	function handleChangeInput(event, type = 'input') {
		const { name, value, checked } = event.target
		const linkedValue = { name: '', value: false }

		if (name === 'applicationCodeRequired') {
			linkedValue.name = 'applicationCodeYesNo'
			linkedValue.value = checked || values.applicationCodeRequired
		} else if (name === 'applicationCodeYesNo') {
			linkedValue.name = 'applicationCodeRequired'
			linkedValue.value = checked && values.applicationCodeRequired
		}

		setValues({
			...values,
			[name]: type === 'input' ? value : checked,
			[linkedValue.name]: linkedValue.value,
		})
	}
	/**
	 * Gestion de la modification des champs.
	 * @param event
	 * @param type
	 */

	/**
	 * Envoie la nouvelle image d'app sur S3.
	 * @param filesEvent
	 */
	async function uploadNewImageApp(filesEvent) {
		const [file] = filesEvent.target.files

		//demande l'url d'upload
		const { url, fields } = await Query.getUploadFileURL(context, file.name, true)
		try {
			// send file on s3
			await Query.uploadFile(context, url, fields, file)

			const event = {
				target: {
					name: 'picturePath',
					value: `https://images-tabuleo.s3.eu-west-3.amazonaws.com/${file.name}`,
				},
			}

			handleChangeUrl(event)

			addToast("L'image a bien été upload. Veuillez confirmez vos modifications.", { appearance: 'success' })
		} catch (e) {
			addToast(translate('global.errors.occurred'), { appearance: 'error' })
		}
	}

	/**
	 * Rentre en mode édition, déroule l'application si elle ne l'était pas encore.
	 */
	function setInEditMode() {
		setEditing(true)
		setDisplayed(true)
	}

	/**
	 * Détecte si l'utilisateur a fait des modifications.
	 * @returns {boolean}
	 */
	function hasMadeChanges() {
		return !_.isEqual(initialValues, values)
	}

	/**
	 * Applique les modifications en base, affiche un écran de confirmation avec les différences.
	 * @param formEvent
	 */
	function saveChanges(formEvent) {
		formEvent.preventDefault()

		if (!editing) {
			return setInEditMode()
		}

		if (!hasMadeChanges()) {
			return setEditing(false)
		}

		const { urls, ...restOfValues } = values

		const finalValues = {
			...restOfValues,
			...urls,
		}

		const { urls: initialUrls, ...restOfInitialValues } = initialValues

		const finalInitialValues = {
			...restOfInitialValues,
			...initialUrls,
		}

		function formatDiff(key, diff) {
			return typeof diff === 'boolean' ? (diff ? 'ACTIVE' : 'DESACTIVE') : diff
		}

		showModal('confirmAction', true, {
			content: (
				<>
					<p>Êtes-vous sûr d'appliquer les modifications suivantes ?</p>
					<Content>
						<ul className="mt-2 is-size-7 has-text-grey-darker">
							{Object.entries(GlobalUtils.diffBetweenObjects(finalValues, finalInitialValues)).map(([key, value], idx) => (
								<li key={idx}>
									<span className="font-semibold">{key}</span> :<br />{' '}
									<span className="font-mono">
										<span className="has-text-danger">&times; {formatDiff(key, finalInitialValues[key])}</span>
										<br />
										<span className="has-text-success">&#8594; {formatDiff(key, value)}</span>
									</span>
								</li>
							))}
						</ul>
					</Content>
				</>
			),
			action: () => {
				setLoading(true)
				Query.updateGlobalSettings(context, 'apps', values)
					.then(() => {
						addToast(`L'application ${idApplication} a bien été configurée.`, { appearance: 'success' })
						updateApps()
						setLoading(false)
						setEditing(false)
					})
					.catch(err => {
						addToast(err, { appearance: 'error' })
						setLoading(false)
					})
					.finally(() => {
						values.newApplication = false
						values.idApplication = values.newIdApplication
						setValues({ ...values })
					})
			},
		})
	}

	return (
		<article>
			<form onSubmit={saveChanges} className="flex">
				<p className="flex place-items-center justify-center flex-col has-text-grey mr-4">
					{editing && (
						<Button type={'button'} className="w-8 h-8" size={'small'} color={'light'} rounded onClick={() => handleChangeOrder(-1)}>
							<Icon icon={'far fa-caret-up'} />
						</Button>
					)}
					<span>{values.order}</span>
					{editing && (
						<Button type={'button'} className="w-8 h-8" size={'small'} color={'light'} rounded onClick={() => handleChangeOrder(1)}>
							<Icon icon={'far fa-caret-down'} />
						</Button>
					)}
				</p>
				<section className="border shadow-md p-4 rounded-lg">
					<header className="flex place-items-center justify-between">
						<figure className="flex">
							<div className="w-16 h-16">
								{editing && (
									<div className="cursor-pointer absolute z-20 w-16 h-16 flex justify-center place-items-center bg-gray-900 opacity-0 hover:opacity-100 bg-opacity-70" onClick={() => inputFileRef.current.click()}>
										<Icon icon={'far fa-upload'} color={'link'} />
									</div>
								)}
								<Image src={picturePath} alt={name} size={64} />
								{editing && <input type={'file'} className="sr-only" ref={inputFileRef} onChange={uploadNewImageApp} />}
							</div>
							{editing ? (
								<>
									<Form.Field className="ml-2">
										<Form.Label size={'small'}>Nom de l'app</Form.Label>
										<Form.Control size={'small'}>
											<Form.Input color={alreadyUsedValue('name', values.name) && 'danger'} size={'small'} type={'text'} value={values.name} name={'name'} onChange={handleChangeInput} required />
										</Form.Control>
										{alreadyUsedValue('name', values.name) && <Form.Help color="danger">Nom de l'app non disponible</Form.Help>}
									</Form.Field>
									<Form.Field className="ml-2">
										<Form.Label size={'small'}>ID de la plateforme</Form.Label>
										<ComboPlatform value={values.idPlateforme} onChange={handleChangeInput} name="idPlateforme" />
									</Form.Field>
									<Form.Field className="ml-2">
										<Form.Label size={'small'}>ID de l'application</Form.Label>
										<Form.Control size={'small'}>
											<Form.Input color={alreadyUsedValue('idApplication', values.newIdApplication) && 'danger'} size={'small'} type={'text'} value={values.newIdApplication} name={'newIdApplication'} onChange={handleChangeInput} required />
										</Form.Control>
										{alreadyUsedValue('idApplication', values.newIdApplication) && <Form.Help color="danger">ID de l'app non disponible</Form.Help>}
									</Form.Field>
								</>
							) : (
								<Heading className="ml-4" size={5}>
									{name}
									<br />
									<span className="has-text-weight-bold is-size-7 has-text-primary">{idPlateforme} </span>
									<span className="is-size-7 has-text-grey">/ {idApplication}</span>
								</Heading>
							)}
						</figure>
						<div className="buttons">
							<Button color={editing ? 'success' : 'info'} size={'small'} type={'submit'} loading={loading}>
								<Icon key={editing ? 'save' : 'edit'} icon={editing ? 'fal fa-check' : 'fal fa-money-check-edit'} />
								<span>{editing ? translate('global.save') : translate('global.update')}</span>
							</Button>
							<Button color={'primary'} size={'small'} type={'button'} onClick={() => setDisplayed(!displayed)}>
								<Icon key={displayed ? 'up' : 'down'} icon={`far fa-angle-${displayed ? 'up' : 'down'}`} />
								<span>{displayed ? translate('settings.minus') : translate('settings.plus')}</span>
							</Button>
						</div>
					</header>
					<article className={'transition-all duration-300 overflow-hidden'} style={{ height: displayed ? '590px' : 0 }}>
						<Columns className="pt-8" multiline>
							<Columns.Column size={'half'}>
								<Heading size={7} className={'border-b pb-2'}>
									Export CSV
								</Heading>
								<Form.Field>
									<Form.Label size={'small'}>{translate('settings.name_and_type')}</Form.Label>
									<Form.Field className={'has-addons'}>
										<Form.Control size={'small'} className="is-expanded">
											<Form.Input size={'small'} name={'exportFile'} type={'text'} fullwidth placeholder={`[UAI]_${idApplication}_[Profil]`} value={values.exportFile} disabled={!editing} onChange={handleChangeInput} />
										</Form.Control>
										<Form.Control size={'small'}>
											<div className="select is-small">
												<select name={'exportType'} disabled={!editing} id="type" onChange={handleChangeInput} defaultValue={values.exportType}>
													<option value="CSV">.csv</option>
													<option value="XSLX">.xlsx</option>
												</select>
											</div>
										</Form.Control>
									</Form.Field>
								</Form.Field>
							</Columns.Column>
							<Columns.Column size={'half'}>
								<Heading size={7} className={'border-b pb-2'}>
									{translate('settings.field')}
								</Heading>
								<div className="flex justify-between flex-wrap items-center">
									<Form.Field className="ml-6 justify-start mt-4">
										<Form.Label size={'small'}>{translate('settings.grid_display')}</Form.Label>
										<input id={`${id}_showTokenStatus`} type="checkbox" className="ml-6 switch is-rounded is-success is-small" checked={values.showTokenStatus} name={'showTokenStatus'} disabled={!editing} onChange={e => handleChangeInput(e, 'checkbox')} />
										<label htmlFor={`${id}_showTokenStatus`} />
									</Form.Field>
								</div>
							</Columns.Column>
							<Columns.Column size={'full'}>
								<Heading size={7} className={'border-b pb-2'}>
									URLs Scheme
								</Heading>
								<Columns>
									<Columns.Column size={'half'}>
										<Form.Field>
											<Form.Label size={'small'}>{translate('settings.menu.general')}</Form.Label>
											<Form.Control size={'small'}>
												<Form.Input size={'small'} value={values.urls.urlScheme} type={'text'} placeholder={'URL Scheme générale'} name={'urlScheme'} disabled={!editing} onChange={handleChangeUrl} />
											</Form.Control>
										</Form.Field>
										<Form.Field>
											<Form.Label size={'small'}>{translate('global.online')}</Form.Label>
											<Form.Control size={'small'}>
												<Form.Input size={'small'} value={values.urls.urlLine} type={'text'} placeholder={'URL en ligne'} name={'urlLine'} disabled={!editing} onChange={handleChangeUrl} />
											</Form.Control>
										</Form.Field>
									</Columns.Column>
									<Columns.Column size={'half'}>
										<Form.Field>
											<Form.Label size={'small'}>Open</Form.Label>
											<Form.Control size={'small'}>
												<Form.Input size={'small'} value={values.urls.urlOpen} type={'text'} placeholder={"URL Scheme d'open"} name={'urlOpen'} disabled={!editing} onChange={handleChangeUrl} />
											</Form.Control>
										</Form.Field>
										<Form.Field>
											<Form.Label size={'small'}>Clean</Form.Label>
											<Form.Control size={'small'}>
												<Form.Input size={'small'} value={values.urls.urlClean} type={'text'} placeholder={'URL Scheme de clean'} name={'urlClean'} disabled={!editing} onChange={handleChangeUrl} />
											</Form.Control>
										</Form.Field>
									</Columns.Column>
								</Columns>
							</Columns.Column>
							<Columns.Column size={'one-third'}>
								<Heading size={6} className={'border-b pb-2'}>
									<Icon icon={'fab fa-apple'} />
									<span className="ml-1 is-size-7 text-black">iOS</span>
								</Heading>
								<Form.Field>
									<Form.Label size={'small'}>{translate('settings.link_app_store')}</Form.Label>
									<Form.Field className={'has-addons'}>
										<Form.Control size={'small'} className={'is-expanded'} iconLeft>
											<Form.Input size={'small'} name={'appStore'} type={'text'} fullwidth placeholder={'App Store Link'} value={values.urls.appStore} disabled={!editing} onChange={handleChangeUrl} />
											<Icon icon={'fab fa-app-store-ios'} className={'is-left'} />
										</Form.Control>
										<Form.Control size={'small'}>
											<a className={'is-small button is-primary'} href={values.urls.appStore} target={'_blank'} rel={'noopener noreferrer'}>
												<Icon icon={'fal fa-external-link'} />
											</a>
										</Form.Control>
									</Form.Field>
								</Form.Field>
								<Form.Field>
									<Form.Label size={'small'}>{translate('settings.link_dmg')}</Form.Label>
									<Form.Field className={'has-addons'}>
										<Form.Control size={'small'} className="is-expanded" iconLeft>
											<Form.Input size={'small'} name={'dmgLink'} type={'text'} fullwidth placeholder={'DMG Link'} value={values.urls.dmgLink} disabled={!editing} onChange={handleChangeUrl} />
											<Icon icon={'fas fa-box-open'} className={'is-left'} />
										</Form.Control>
										<Form.Control size={'small'}>
											<a className={'is-small button is-primary'} href={values.urls.dmgLink} target={'_blank'} rel={'noopener noreferrer'}>
												<Icon icon={'fal fa-external-link'} />
											</a>
										</Form.Control>
									</Form.Field>
								</Form.Field>
							</Columns.Column>
							<Columns.Column size={'one-third'}>
								<Heading size={6} className={'border-b pb-2'}>
									<Icon icon={'fab fa-android'} className={'text-green-600'} />
									<span className="ml-1 is-size-7 text-green-600">Android</span>
								</Heading>
								<Form.Field>
									<Form.Label size={'small'}>{translate('settings.link_play_store')}</Form.Label>
									<Form.Field className={'has-addons'}>
										<Form.Control size={'small'} className="is-expanded" iconLeft>
											<Form.Input size={'small'} name={'playStore'} type={'text'} fullwidth placeholder={'Play Store Link'} value={values.urls.playStore} disabled={!editing} onChange={handleChangeUrl} />
											<Icon icon={'fab fa-google-play'} className={'is-left'} />
										</Form.Control>
										<Form.Control size={'small'}>
											<a className={'is-small button is-primary'} href={values.urls.playStore} target={'_blank'} rel={'noopener noreferrer'}>
												<Icon icon={'fal fa-external-link'} />
											</a>
										</Form.Control>
									</Form.Field>
								</Form.Field>
							</Columns.Column>
							<Columns.Column size={'one-third'}>
								<Heading size={6} className={'border-b pb-2'}>
									<Icon icon={'fab fa-windows'} className={'text-blue-600'} />
									<span className="ml-1 is-size-7 text-blue-600">Windows</span>
								</Heading>
								<Form.Field>
									<Form.Label size={'small'}>{translate('settings.link_exe')}</Form.Label>
									<Form.Field className={'has-addons'}>
										<Form.Control size={'small'} className="is-expanded" iconLeft>
											<Form.Input size={'small'} name={'exeLink'} type={'text'} fullwidth placeholder={'Play Store Link'} value={values.urls.exeLink} disabled={!editing} onChange={handleChangeUrl} />
											<Icon icon={'fas fa-file-invoice'} className={'is-left'} />
										</Form.Control>
										<Form.Control size={'small'}>
											<a className={'is-small button is-primary'} href={values.urls.exeLink} target={'_blank'} rel={'noopener noreferrer'}>
												<Icon icon={'fal fa-external-link'} />
											</a>
										</Form.Control>
									</Form.Field>
								</Form.Field>
							</Columns.Column>
						</Columns>
					</article>
				</section>
			</form>
		</article>
	)
}

export function Skeleton() {
	const fake = []

	for (let i = 0; i < 8; i++) {
		fake.push(<div className="w-full h-24 bg-gray-100 rounded-lg animate-pulse" />)
	}

	return (
		<>
			<div className="w-64 h-6 rounded-lg bg-gray-200 animate-pulse mb-4" />
			<div className="space-y-4">{fake}</div>
		</>
	)
}

export default GlobalApps
