import { Box, Button, Form, Heading } from 'react-bulma-components'
import React, { useContext, useState } from 'react'
import GlobalContext from '../../../contexts/GlobalContext'
import Icon from '../../utils/Icon'

export function UserTeaching() {
	const {
		isLightTheme,
		translate,
		userPage: {
			informations: { teaching, userId },
			estabTeachings,
		},
		shouldMount,
		showModal,
	} = useContext(GlobalContext)
	const teachings = []
	const [filterTeachings, setFilterTeachings] = useState('')

	if (teaching?.automaticallyAdded) {
		teachings.push(...teaching.automaticallyAdded.map(t => ({ ...t, type: 'auto' })))
	}

	if (teaching?.manuallyAdded) {
		teachings.push(...teaching.manuallyAdded.map(t => ({ ...t, type: 'manual' })))
	}

	return (
		<Box style={{ backgroundColor: isLightTheme ? '' : '#222' }}>
			<div className="is-flex" style={{ justifyContent: shouldMount('FicheUser_profil', 'Ecriture') && 'space-between' }}>
				<Heading size={5} textColor={isLightTheme ? '' : 'grey-lighter'}>
					{translate('user.teaching')}
				</Heading>
				{shouldMount('FicheUser_profil', 'Ecriture') && (
					<Button
						size={'small'}
						color={'info'}
						type="submit"
						onClick={() => {
							showModal('userAddTeaching', true, { userId, teachings, estabTeachings })
						}}
					>
						<Icon key={'edit'} icon={'fal fa-user-edit'} />
						<span className="is-text-icon">{translate('global.update')}</span>
					</Button>
				)}
			</div>
			<Form.Field>
				<Form.Control iconLeft size={'small'}>
					<Form.Input backgroundColor={isLightTheme ? '' : 'dark'} placeholder={translate('global.search')} size={'small'} name={'search-opt'} onChange={e => setFilterTeachings(e.target.value)} value={filterTeachings} type="text" />
					<Icon icon={'fal fa-search'} className={'is-small is-left'} />
				</Form.Control>
			</Form.Field>
			{teachings.flat().length > 0 ? (
				<ul className="mt-4 space-y-2 *:flex *:items-center *:space-x-2 list-disc list-inside">
					{teachings
						.flat()
						.filter(teaching => teaching.class.toLowerCase().includes(filterTeachings.toLowerCase()) || teaching.discipline.toLowerCase().includes(filterTeachings.toLowerCase()))
						.sort((a, b) => a.class.localeCompare(b.class))
						.map((t, idx) => (
							<Teaching key={idx} {...t} idx={idx} />
						))}
				</ul>
			) : (
				<p className="text-sm text-gray-500">Cet utilisateur n'a pas d'enseignements renseignés.</p>
			)}
		</Box>
	)
}

function Teaching({ class: classe, discipline, type, idx }) {
	const isFromEcoleDirecte = type === 'auto'

	return (
		<li>
			<span className="text-xs text-gray-400 tabular-nums">{idx + 1})</span>
			<span className="font-extrabold text-sm text-gray-800 font-mono">{classe}</span>
			<span className="text-xs text-gray-400 tabular-nums">-</span>
			<span className={isFromEcoleDirecte ? 'text-black tracking-wide flex uppercase justify-center rounded-full px-2 py-1 leading-none bg-purple-100 text-xs' : 'text-xs text-gray-500 bg-gray-100 rounded-full px-2 py-0.5 uppercase'}>{discipline}</span>
		</li>
	)
}
