import { Button, Form, Heading } from 'react-bulma-components'
import Hr from '../utils/Hr'
import React, { useContext, useEffect, useState } from 'react'
import GlobalContext from '../../contexts/GlobalContext'
import Icon from '../utils/Icon'
import Query from '../../tools/Query'
import FileUtils from '../utils/FileUtils'
import Queries from '../../tools/Query'

function EcoleDirecte() {
	const context = useContext(GlobalContext)
	const { translate, isLightTheme, school, updateSchoolParams, addToast, updateToast, removeToast, loadSchool, setGridFilter, grid, getActiveUai } = context

	const [loading, setLoading] = useState(false)
	const [loadingConfirmImport, setLoadingConfirmImport] = useState(false)
	const [syncing, setSyncing] = useState(false)
	const [fetchingEcoleDirect, setFetchingEcoleDirect] = useState(false)
	const [fetchingEcoleDirectTeaching, setFetchingEcoleDirectTeaching] = useState()
	const initialUai = getActiveUai()

	const [uai, setUai] = useState('')
	const [token, setToken] = useState(school.deployment.ecoleDirecteApiKey)
	const [profil, setProfil] = useState('all')
	const [showToken, setShowToken] = useState(false)
	const [editing, setEditing] = useState(false)
	const [nextYear, setNextYear] = useState(false)

	const [theDudeIsSure, setTheDureIsSure] = useState(false)
	let toastId
	/**
	 * Applique les modifications.
	 * @param formEvent
	 */
	function handleSubmit(formEvent) {
		formEvent.preventDefault()
		const { deployment } = school

		if (!editing) {
			return setEditing(true)
		}

		setLoading(true)

		const params = { deployment: { ...school?.deployment, ecoleDirecteApiKey: token } }

		Query.updateParams(context, initialUai, params)
			.then(() => {
				setLoading(false)
				setEditing(false)
				updateSchoolParams(params)
				addToast('Le token a bien été enregistré.', { appearance: 'success' })
			})
			.catch(err => {
				setLoading(false)
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
			})
	}

	function ConfirmAnywayToast({ onClick, title, loading, linesErrors }) {
		return (
			<>
				<span>{title}</span>
				<a onClick={() => FileUtils.saveErrorReport(linesErrors)}>Télécharger le rapport d'erreur</a>
				<div className="flex justify-end">
					<button className="flex place-items-center px-2 py-2 bg-red-200/50 text-red-800 rounded-md hover:bg-red-300" onClick={onClick}>
						{loading ? (
							<Icon key={'loading'} icon={'fad fa-spinner-third fa-spin'} />
						) : (
							<>
								<Icon icon="fad fa-exclamation-triangle" /> Importer le reste
							</>
						)}
					</button>
				</div>
			</>
		)
	}

	function confirmImportAnyway(importNumber, excludeUsers, error, linesErrors) {
		setLoadingConfirmImport(true)
		updateToast(toastId, {
			content: <ConfirmAnywayToast onClick={() => confirmImportAnyway(importNumber, excludeUsers)} linesErrors={linesErrors} loading={true} title={`Erreur: ${error[0]}`} />,
			autoDismiss: true,
			appearance: 'error',
		})
		Queries.confirmImportFile(context, importNumber, '1', excludeUsers)
			.then(res => {
				const filterOnImport = {
					'N° import': {
						values: [res.importNumber.toString()],
						filterType: 'set',
					},
				}

				loadSchool(initialUai).then(() => {
					setGridFilter('table', filterOnImport, 'EcoleDirecte.sync')
					toastId && removeToast(toastId)
				})
			})
			.catch(e => {
				console.error(e)
				removeToast(toastId)
				addToast('Une erreur est survenue lors de la Synchro', { appearance: 'error' })
			})
	}

	/**
	 * Permet de faire la synchronisation d'ecole direct, récupère les utilisateurs d'ecole direct
	 * et les importes chez nous en retraitant certaines informations (coté API)
	 * @returns {Promise<void>}
	 */
	async function syncEcoleDirecte() {
		setSyncing(true)
		Query.syncingEcoleDirecte(context, initialUai, token, uai, nextYear, profil)
			.then(e => {
				setSyncing(false)
				setTheDureIsSure(false)
				if (e.nbLinesToImport > 0) {
					addToast(e.nbLinesToImport > 1 ? `Les ${e.nbLinesToImport} utilisateurs ont bien été importés.` : `L'utilsateur à bien été importé.`, { appearance: 'success' })

					const filterOnImport = {
						'N° import': {
							values: [e.importNumber.toString()],
							filterType: 'set',
						},
					}

					loadSchool(initialUai).then(() => {
						setGridFilter('table', filterOnImport, 'EcoleDirecte.sync')
					})
				} else {
					return addToast('Aucun nouvel utilisateur à ajouter.', { appearance: 'success' })
				}
			})
			.catch(e => {
				setSyncing(false)
				setTheDureIsSure(false)
				const { importNumber, message } = e
				const error = JSON.stringify(message).split('Ligne')
				const linesErrors = error.splice(1, error.length - 1)
				const exludeUsers = linesErrors.map(text => text.split('=')[1].split(',')[0].trim())

				addToast(<ConfirmAnywayToast onClick={() => confirmImportAnyway(importNumber, exludeUsers, error, linesErrors)} linesErrors={linesErrors} confirmImport={loadingConfirmImport} title={`Erreur: ${error[0]}`} />, { appearance: 'error', autoDismiss: false }, toast => {
					toastId = toast
				})
			})
	}

	async function fetchEcoleDirecteCsvForImport() {
		setFetchingEcoleDirect(true)
		Query.fetchEcoleDirecteDataToCsv(context, initialUai, uai, nextYear, profil)
			.then(csv => {
				setFetchingEcoleDirect(false)
				const hiddenElement = document.createElement('a')
				const bom = '\uFEFF'
				hiddenElement.href = URL.createObjectURL(new Blob([bom + csv], { type: 'text/csv;charset=utf-8' }))
				hiddenElement.target = '_blank'
				hiddenElement.download = `${initialUai}_ecole-directe.csv`
				hiddenElement.click()
			})
			.catch(e => {
				setFetchingEcoleDirect(false)
				addToast(e ?? translate('global.errors.occurred'), { appearance: 'error' })
			})
	}

	async function fetchEcoleDirecteEnseignementsCsv() {
		setFetchingEcoleDirectTeaching(true)
		Query.fetchEcoleDirecteEnseignementsToCsv(context, initialUai, uai ? uai : school.informations.uai, nextYear)
			.then(csv => {
				setFetchingEcoleDirectTeaching(false)
				const hiddenElement = document.createElement('a')
				const bom = '\uFEFF'
				hiddenElement.href = URL.createObjectURL(new Blob([bom + csv], { type: 'text/csv;charset=utf-8' }))
				hiddenElement.target = '_blank'
				hiddenElement.download = `${initialUai}_ecole-directe.csv`
				hiddenElement.click()
			})
			.catch(e => {
				setFetchingEcoleDirectTeaching(false)
				addToast(e ?? translate('global.errors.occurred'), { appearance: 'error' })
			})
	}

	function onChangeProfil(e) {
		setProfil(e.target.value)
	}

	return (
		<form onSubmit={handleSubmit}>
			<div className="is-flex" style={{ justifyContent: 'space-between' }}>
				<Heading textColor={!isLightTheme ? 'light' : ''} size={5} style={{ marginBottom: '0' }}>
					Ecole Directe
				</Heading>
				<Button loading={loading} color={editing ? 'success' : 'info'} size={'small'} type={'submit'}>
					<Icon key={editing ? 'save' : 'edit'} icon={editing ? 'fal fa-check' : 'fal fa-money-check-edit'} />
					<span>{editing ? translate('global.save') : translate('global.update')}</span>
				</Button>
			</div>
			<Hr />
			<section>
				<Form.Field>
					<Form.Label>{translate('settings.ecole_directe.token')}</Form.Label>
					<Form.Control iconLeft iconRight>
						<Form.Input placeholder={'Token'} type={showToken ? 'text' : 'password'} name={'token'} value={token} disabled={!editing} onChange={e => setToken(e.target.value)} />
						<Icon className="icon is-left" icon={'fal fa-key-skeleton'} />
						<Icon key={showToken ? 'show' : 'hidden'} onClick={() => setShowToken(!showToken)} className={'icon is-icon-clickable is-right'} icon={showToken ? 'far fa-eye-slash' : 'far fa-eye'} />
					</Form.Control>
				</Form.Field>
				<Form.Field>
					<Form.Label>{translate('settings.ecole_directe.uai')}</Form.Label>
					<Form.Control iconLeft iconRight>
						<Form.Input placeholder={''} type={'text'} name={'token'} value={uai} onChange={e => setUai(e.target.value)} />
						<Icon className="icon is-left" icon={'fal fa-university'} />
					</Form.Control>
				</Form.Field>
				<Form.Field>
					<Form.Label>{translate('settings.ecole_directe.year')}</Form.Label>
					<Form.Control>
						<input id={'switchN/N+1'} name={'switchN/N+1'} type="checkbox" value={nextYear} className="switch is-rounded is-success is-small" onChange={e => setNextYear(e.target.checked)} />
						<label htmlFor={'switchN/N+1'} />
					</Form.Control>
				</Form.Field>
				<Form.Field className="bg-gradient-to-br from-red-500 to-red-600 rounded-lg p-4 shadow-lg">
					<Heading textColor={'light'} size={5}>
						{translate('settings.ecole_directe.warning')}
					</Heading>
					<hr className="bg-red-200" />
					<p className="has-text-light">{translate('settings.ecole_directe.synchro')}</p>
					<br />
					<div className={'flex'}>
						<p className="has-text-light">{translate('settings.ecole_directe.type_user')}</p>
						<div className={'select is-small ml-4'}>
							<select name="profil" className={isLightTheme ? '' : 'has-background-dark'} value={profil} onChange={onChangeProfil}>
								<option value={'all'}>{translate('settings.ecole_directe.all')}</option>
								<option value={'teachers'}>{translate('settings.ecole_directe.teacher')}</option>
								<option value={'students'}>{translate('settings.ecole_directe.student')}</option>
							</select>
						</div>
					</div>
					<Form.Control className="flex justify-between">
						<button
							type="button"
							className="tw-button mt-4 bg-red-100 text-red-800 rounded-lg px-4 py-2 flex justify-center place-items-center transition duration-100 hover:bg-red-200"
							disabled={!token}
							onClick={() => {
								theDudeIsSure ? syncEcoleDirecte() : setTheDureIsSure(true)
							}}
						>
							{syncing ? (
								<Icon key={'loading'} icon={'fad fa-spinner-third fa-spin'} />
							) : (
								<>
									{theDudeIsSure && <Icon icon="fad fa-exclamation-triangle" />}

									<span className="is-text-icon">{theDudeIsSure ? translate('settings.ecole_directe.synchronize') : translate('settings.ecole_directe.continue')}</span>
								</>
							)}
						</button>
						<button type="button" target={'_blank'} className="tw-button mt-4 bg-red-100 text-red-800 rounded-lg px-4 py-2 flex justify-center place-items-center transition duration-100 hover:bg-red-200" disabled={!token} onClick={fetchEcoleDirecteCsvForImport}>
							{fetchingEcoleDirect ? (
								<Icon key={'loading'} icon={'fad fa-spinner-third fa-spin'} />
							) : (
								<>
									<Icon icon={'far fa-arrow-to-bottom'} />
									<span className="is-text-icon">{translate('settings.ecole_directe.download')}</span>
								</>
							)}
						</button>
					</Form.Control>
				</Form.Field>
				<div className="is-flex mt-8" style={{ justifyContent: 'space-between' }}>
					<Heading textColor={!isLightTheme ? 'light' : ''} size={5} style={{ marginBottom: '0' }}>
						Export des enseignements (Profs)
					</Heading>
				</div>
				<Hr />
				<button type="button" target={'_blank'} className=" tw-button mt-4 bg-gray-100 rounded-lg px-4 py-2 flex justify-center place-items-center transition duration-100 hover:bg-gray-200" disabled={!token} onClick={fetchEcoleDirecteEnseignementsCsv}>
					{fetchingEcoleDirectTeaching ? (
						<Icon key={'loading'} icon={'fad fa-spinner-third fa-spin'} />
					) : (
						<>
							<Icon icon={'far fa-arrow-to-bottom'} />
							<span className="is-text-icon">Télécharger le fichier initial </span>
						</>
					)}
				</button>
			</section>
		</form>
	)
}

export default EcoleDirecte
