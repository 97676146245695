import React from 'react'
import { Navbar } from 'react-bulma-components'
import Icon from '../utils/Icon'
import logo from '../../res/images/t_rond.png'
import ecoleDirecteLogo from '../../res/images/ecole_directe.png'
import GlobalContext from '../../contexts/GlobalContext'
import Timer from './navbar/Timer'
import UserDropdown from './navbar/UserDropdown'
import SchoolSearch from './navbar/SchoolSearch'
import UserSearch from './navbar/UserSearch'

/**
 * Navbar de l'application.
 * Permettant :
 * 	- rechercher un utilisateur
 * 	- rechercher et charger un établissement
 * 	- afficher l'heure
 * 	- liste des actions utilisateurs possible (paramètre / se déconnecter)
 */
export default class NavBar extends React.Component {
	static contextType = GlobalContext

	/**
	 * Liste des états:
	 * 	- active = Bouton pour activer/désactiver la navbar en format petit écran.
	 * @param props
	 */
	constructor(props) {
		super(props)
		this.state = {
			active: false,
		}
	}

	/**
	 * Roule ou déroule la navbar en format mobile.
	 */
	toggleNav = () => {
		this.setState(state => ({
			active: !state.active,
		}))
	}

	render() {
		const { isLightTheme, translate, school } = this.context
		const { active } = this.state
		const { showSidebar, toggleSidebar } = this.props

		return (
			<div className="flex flex-col">
				<Navbar color={isLightTheme ? 'dark' : 'black'} fixed={'top'} active={active}>
					<Navbar.Brand>
						<Navbar.Item renderAs="div" style={{ marginRight: '25px' }}>
							<div className={`sidebar-toggler ${showSidebar ? 'active' : ''}`} onClick={toggleSidebar}>
								<span />
							</div>
						</Navbar.Item>
						<Navbar.Item renderAs="div">
							<img src={logo} alt="Tabuleo" />
						</Navbar.Item>
						{school.informations?.uai && (
							<div className={'is-flex'}>
								<Navbar.Item className={'fade-in-left'} renderAs="div">
									<Icon icon={'fad fa-chevron-right'} />
								</Navbar.Item>
								{school.deployment.ecoleDirecteApiKey && (
									<>
										<Navbar.Item className={'fade-in-left'} renderAs="div">
											<img src={ecoleDirecteLogo} alt="Ecole Directe" />
										</Navbar.Item>
										<Navbar.Item className={'fade-in-left'} renderAs="div">
											<Icon icon={'fad fa-chevron-right'} />
										</Navbar.Item>
									</>
								)}

								<Navbar.Item className={'fade-in-left'} style={{ animationDelay: '100ms' }} renderAs="div">
									<img src={`https://images-tabuleo.s3.eu-west-3.amazonaws.com/${this.context.school?.informations.uai}?key=${new Date().getTime()}`} alt={translate('navbar.missing_school_mark')} style={{ maxHeight: '2.5rem' }} />
								</Navbar.Item>
							</div>
						)}
						<Navbar.Burger onClick={this.toggleNav} />
					</Navbar.Brand>
					<Navbar.Menu>
						<UserSearch />
						<Navbar.Container position="end">
							<SchoolSearch />
							<Timer />
							<UserDropdown />
						</Navbar.Container>
					</Navbar.Menu>
				</Navbar>
				{school.informations && school.deployment.ecoleDirecteApiKey && <div className="text-white tracking-wide flex uppercase justify-center text-sm p-2 leading-none w-full bg-gradient-to-r from-[#0f8fd1] to-[#0e3e85]">établissement école directe</div>}
			</div>
		)
	}
}
