import React from 'react'
import { Box, Button, Columns, Form, Heading, Image } from 'react-bulma-components'
import logo from '../../../res/images/t_carre.png'
import Icon from '../../utils/Icon'
import appStore from '../../../res/images/apple.png'
import playStore from '../../../res/images/google.png'
import GlobalContext from '../../../contexts/GlobalContext'
import Queries from '../../../tools/Query'
import { Formik } from 'formik'
import * as yup from 'yup'

export default class UserProfile extends React.Component {
	static contextType = GlobalContext

	constructor(props, context) {
		super(props)
		this.state = {
			showPassword: false,
			edit: false,
			loading: false,
			...context.userPage.informations,
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { userPage } = this.context

		if (prevState.userId !== userPage.informations.userId) {
			this.setState({
				...userPage,
			})
		}
	}

	updateProfile = values => {
		const { edit, error } = this.state
		if (!edit) {
			return this.setState({ edit: true })
		}

		const { addToast, updateUserPage, translate, userPage } = this.context
		const { login, password, firstName, lastName, email } = userPage.informations

		// On vérifie qu'aucun champs n'est vide
		if (lastName.trim() === '' || firstName.trim() === '' || password.trim() === '' || login.trim() === '') {
			return addToast(translate('global.errors.empty_fields'), { appearance: 'warning' })
		}

		// Si aucune modification : on annule
		if (lastName === values.lastName && firstName === values.firstName && login === values.login && password === values.password && email === values.email) {
			return this.setState({ edit: false })
		}

		if (error) {
			return addToast(translate('global.errors.invalid_username'), { appearance: 'warning' })
		}

		this.setState({ loading: true })

		const userParams = {
			informations: { ...values },
			applications: [],
		}

		Queries.updateUserPage(this.context, userPage.informations.userId, userParams)
			.then(() => {
				addToast(translate('user.update_success'), { appearance: 'success' })
				this.setState({ edit: false, loading: false })
				const userData = {
					Login: login,
					Nom: lastName,
					Prénom: firstName,
					Password: password,
					email: email,
				}
				updateUserPage({ informations: { ...userPage.informations, ...values } })
				this.context.grid.applyTransaction({ update: [userData] })
				this.context.grid.refreshClientSideRowModel() // Met à jour les filtres, groupes etc...
			})
			.catch(() => {
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
				this.setState({ edit: false, loading: false })
			})
	}

	render() {
		const { shouldMount, translate, userPage, isLightTheme, validations } = this.context
		const { edit, showPassword, loading } = this.state
		const { login, password, firstName, lastName, email } = validations.user
		const { qrCode } = userPage.informations
		const { urlsTabuleo } = userPage

		return (
			<Box style={{ backgroundColor: isLightTheme ? '' : '#222' }}>
				<Formik
					validationSchema={yup.object({ login, password, firstName, lastName, email })}
					onSubmit={values => this.updateProfile(values)}
					initialValues={{
						login: this.state.login,
						firstName: this.state.firstName,
						lastName: this.state.lastName,
						password: this.state.password,
						email: this.state.email
					}}
				>
					{({ values, errors, handleChange, handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<div className="is-flex" style={{ justifyContent: shouldMount('FicheUser_profil', 'Ecriture') && 'space-between' }}>
								<Heading size={5} textColor={isLightTheme ? '' : 'grey-lighter'}>
									{translate('user.login_title')}
								</Heading>
								{shouldMount('FicheUser_profil', 'Ecriture') &&
									(edit ? (
										<Button size={'small'} color={'success'} loading={loading} type="submit" disabled={Object.keys(errors).length > 0}>
											<Icon key={'save'} icon={'fal fa-check'} />
											<span className="is-text-icon">{translate('global.save')}</span>
										</Button>
									) : (
										<Button
											size={'small'}
											color={'info'}
											type="submit"
											onClick={() => {
												handleSubmit()
												if (!this.state.edit) {
													this.setState({ edit: true })
													this.forceUpdate()
												}
											}}
										>
											<Icon key={'edit'} icon={'fal fa-user-edit'} />
											<span className="is-text-icon">{translate('global.update')}</span>
										</Button>
									))}
							</div>
							<Columns mobile multiline>
								<Columns.Column
									className={'is-flex'}
									style={{
										flexDirection: 'column',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<Image src={logo} size={64} style={{ margin: '0.25rem auto' }} />
									<p className={isLightTheme ? 'has-text-grey-darker' : 'has-text-grey-lighter'}>Tabuléo connect</p>
								</Columns.Column>
								<Columns.Column>
									<Form.Field>
										<Form.Label textColor={isLightTheme ? '' : 'grey-lighter'}>{translate('global.username')}</Form.Label>
										<Form.Control iconLeft>
											<Form.Input backgroundColor={isLightTheme ? '' : 'dark'} color={errors.login && 'danger'} readOnly={!edit} name={'login'} value={values.login} onChange={handleChange} />
											<Icon icon={'fad fa-user-alt'} className={'is-small is-left'} />
											<Form.Help type={'invalid'} color={'danger'}>
												{errors.login}
											</Form.Help>
										</Form.Control>
									</Form.Field>
									<Form.Field>
										<Form.Label textColor={isLightTheme ? '' : 'grey-lighter'}>{translate('global.password')}</Form.Label>
										<Form.Control iconLeft iconRight>
											<Form.Input backgroundColor={isLightTheme ? '' : 'dark'} color={errors.password && 'danger'} readOnly={!edit} type={showPassword ? 'text' : 'password'} name={'password'} value={values.password} onChange={handleChange} />
											<Icon icon={'fad fa-fingerprint'} className={'is-small is-left'} />
											<Icon key={showPassword ? 'show' : 'hidden'} onClick={() => this.setState({ showPassword: !showPassword })} className={'icon is-icon-clickable is-right'} icon={showPassword ? 'far fa-eye-slash' : 'far fa-eye'} />
											<Form.Help type={'invalid'} color={'danger'}>
												{errors.password}
											</Form.Help>
										</Form.Control>
									</Form.Field>
								</Columns.Column>
								<Columns.Column>
									{edit ? (
										<>
											<Form.Field>
												<Form.Label textColor={isLightTheme ? '' : 'grey-lighter'}>{translate('global.lastname')}</Form.Label>
												<Form.Control iconLeft>
													<Form.Input backgroundColor={isLightTheme ? '' : 'dark'} color={errors.lastName && 'danger'} name={'lastName'} value={values.lastName} onChange={handleChange} />
													<Icon icon={'fad fa-id-card'} className={'is-small is-left'} />
													<Form.Help type={'invalid'} color={'danger'}>
														{errors.lastName}
													</Form.Help>
												</Form.Control>
											</Form.Field>
											<Form.Field>
												<Form.Label textColor={isLightTheme ? '' : 'grey-lighter'}>{translate('global.firstname')}</Form.Label>
												<Form.Control iconLeft>
													<Form.Input backgroundColor={isLightTheme ? '' : 'dark'} color={errors.firstName && 'danger'} name={'firstName'} value={values.firstName} onChange={handleChange} />
													<Icon icon={'fad fa-id-card'} className={'is-small is-left'} />
													<Form.Help type={'invalid'} color={'danger'}>
														{errors.firstName}
													</Form.Help>
												</Form.Control>
											</Form.Field>
											<Form.Field>
												<Form.Label textColor={isLightTheme ? '' : 'grey-lighter'}>{translate('global.email')}</Form.Label>
												<Form.Control iconLeft>
													<Form.Input backgroundColor={isLightTheme ? '' : 'dark'} color={errors.email && 'danger'} name={'email'} value={values.email} onChange={handleChange} />
													<Icon icon={'fad fa-mailbox'} className={'is-small is-left'} />
													<Form.Help type={'invalid'} color={'danger'}>
														{errors.email}
													</Form.Help>
												</Form.Control>
											</Form.Field>
										</>
									) : (
										<Image src={qrCode} size={152} style={{ margin: 'auto' }} />
									)}
								</Columns.Column>
							</Columns>
							<div className={'is-flex'} style={{ placeItems: 'baseline', marginTop: '1.5rem' }}>
								<figure className="image has-tooltip-arrow has-tooltip-top" data-tooltip="iOS" style={{ width: '12%', marginRight: '0.75rem' }}>
									<a href={urlsTabuleo.find(({ urlName }) => urlName === 'URLConnect_AppStore').urlValue} rel="noreferrer noopener" target={'_blank'}>
										<img style={{ maxWidth: '128px' }} src={appStore} alt="AppStore URL to Tabuleo Connect" />
									</a>
								</figure>
								<figure className="image has-tooltip-arrow has-tooltip-top" data-tooltip="Android" style={{ width: '12%', marginRight: '0.75rem' }}>
									<a href={urlsTabuleo.find(({ urlName }) => urlName === 'URLConnect_GooglePlay').urlValue} rel="noreferrer noopener" target={'_blank'}>
										<img style={{ maxWidth: '128px' }} src={playStore} alt="PlayStore URL to Tabuleo Connect" />
									</a>
								</figure>
								<a target={'_blank'} className="has-tooltip-arrow has-tooltip-top" data-tooltip="Windows" href={urlsTabuleo.find(({ urlName }) => urlName === 'URLConnect_EXE').urlValue} rel="noopener noreferrer">
									<Icon icon={'fab fa-windows fa-2x'} color={isLightTheme ? 'black' : 'grey-lighter'} />
								</a>
							</div>
						</form>
					)}
				</Formik>
			</Box>
		)
	}
}
