import React from 'react'
import { Button, Form, Section, Tag } from 'react-bulma-components'
import Icon from './utils/Icon'
import GlobalContext from '../contexts/GlobalContext'
import Grideo from './table/Grideo'
import DropdownPdf from './table/buttons/DropdownPdf'
import DropdownCsv from './table/buttons/DropdownCsv'
import DropdownBulk from './table/buttons/DropdownBulk'
import Queries from '../tools/Query'
import UnitaryAdd from './table/UnitaryAdd'
import UserPage from './table/UserPage'
import Query from '../tools/Query'

export default class Table extends React.Component {
	static contextType = GlobalContext

	constructor(props) {
		super(props)
		this.state = {
			showUserAdd: false,
		}
	}

	enableOrDisableUsers = disable => {
		const { showModal, addToast, refresh } = this.context
		const { selected } = this.state

		showModal('confirmAction', true, {
			content: `Êtes-vous sûr de vouloir ${disable ? 'désactiver' : 'activer'} ${selected.length} utilisateurs ?`,
			action: () => {
				const users = selected.map(user => ({ id: user['ID utilisateur'], lls: user['Lelivrescolaire.fr'], quizeo: user['Quizeo'] }))
				Queries.enableOrDisableUsers(this.context, { users, disable })
					.then(() => {
						addToast(`${users.length} utilisateurs ont bien été ${disable ? 'désactivés' : 'activés'}.`, {
							appearance: 'success',
						})
						refresh()
					})
					.catch(() => addToast("Une erreur s'est produite.", { appearance: 'error' }))
			},
		})
	}

	purgeUsers = () => {
		const { showModal, addToast, refresh } = this.context
		const { selected } = this.state

		showModal('confirmAction', true, {
			content: `Êtes-vous sûr de vouloir supprimer ${selected.length} utilisateurs.`,
			action: () => {
				const users = selected.map(user => user['ID utilisateur'])
				Queries.purgeUsers(this.context, { users })
					.then(() => {
						addToast(`${users.length} utilisateurs ont bien été supprimés.`, {
							appearance: 'success',
						})
						refresh()
					})
					.catch(() => addToast("Une erreur s'est produite lors de la suppression.", { appearance: 'error ' }))
			},
		})
	}

	render() {
		const { translate, shouldMount, toggleUserAdd, school, showUserAdd, showUserPage, removeGridFilter, usersFilters, resetGridFilter } = this.context
		const { enable_BulkButton, allSame, allEnabled, allDisabled, selected } = this.state

		return (
			<Section style={{ height: 'calc(100vh - 3.5rem)', overflowY: 'auto' }}>
				{shouldMount('AddUser', 'Lecture') && showUserAdd && <UnitaryAdd />}
				{shouldMount('FicheUser', 'Lecture') && showUserPage && <UserPage />}

				{shouldMount('ButtonBar', 'Lecture') && (
					<section className={'buttons'} style={{ flexWrap: 'initial' }}>
						{shouldMount('AddUser', 'Lecture') && (
							<Button disabled={!school?.informations?.uai} onClick={() => toggleUserAdd()} color={'info'} rounded>
								<Icon icon={'fad fa-user-plus'} />
							</Button>
						)}

						{shouldMount('ButtonBar_buttonExportCSV', 'Lecture') && <DropdownCsv enabled={enable_BulkButton} />}

						{shouldMount('ButtonBar_buttonExportPDF', 'Lecture') && <DropdownPdf enabled={enable_BulkButton} />}

						{shouldMount('ButtonBar_buttonActiveDesactiveUser', 'Lecture') && (
							<Button color={'danger'} disabled={!(enable_BulkButton && allSame)} onClick={() => this.enableOrDisableUsers(allEnabled)}>
								<Icon icon={'fad fa-snooze'} />
								<span className="text-icon">{!enable_BulkButton ? translate('global.disable') : allEnabled ? translate('global.disable') : translate('global.enable')}</span>
							</Button>
						)}

						{shouldMount('ButtonBar_buttonDelete', 'Lecture') && (
							<Button color={'danger'} disabled={!(enable_BulkButton && allDisabled)} onClick={this.purgeUsers}>
								<Icon icon={'fad fa-trash-alt'} />
								<span className="text-icon">{translate('global.remove')}</span>
							</Button>
						)}

						{shouldMount('ButtonBar_buttonBulkEdit', 'Lecture') && <DropdownBulk enabled={enable_BulkButton} selected={selected} />}
					</section>
				)}

				{/* Filtres */}
				{shouldMount('Filtres', 'Lecture') && usersFilters && (
					<div className="field is-grouped is-grouped-multiline">
						<Form.Control>
							<Tag.Group className={'fade-in-right'}>
								<Tag
									remove
									renderAs="a"
									color={'danger'}
									onClick={() => {
										resetGridFilter('table', 'Table.removeFilters')
									}}
								/>
							</Tag.Group>
						</Form.Control>

						{Object.keys(usersFilters).map((filter, k) => (
							<Form.Control key={k}>
								<Tag.Group className="has-addons fade-in-right" style={{ animationDelay: `${k * 100}ms` }}>
									<Tag color={'info'}>{filter}</Tag>
									<Tag color={'dark'} remove renderAs="a" onClick={() => removeGridFilter('table', filter, null, 'Table.removeTag')} />
								</Tag.Group>
							</Form.Control>
						))}
					</div>
				)}

				{/* Agrid */}
				{shouldMount('Table', 'Lecture') && <Grideo setParentState={newState => this.setState(newState)} />}
			</Section>
		)
	}
}
