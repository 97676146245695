import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Box, Button, Columns, Content, Form, Heading, Section, Tag } from 'react-bulma-components'
import 'bulma-timeline/dist/css/bulma-timeline.min.css'
import GlobalContext from '../contexts/GlobalContext'
import Hr from './utils/Hr'
import Icon from './utils/Icon'
import MonitoringUtils from './utils/MonitoringUtils'
import Query from '../tools/Query'
import _ from 'lodash'

export const Step = (itemId, eventId, order, state = MonitoringUtils.STATES.TODO, name, updated_at = new Date(), updated_by, sub_steps = [], comment) => ({
	itemId,
	eventId,
	order,
	state,
	name,
	updated_at,
	updated_by,
	sub_steps,
	comment,
})

export const SubStep = (itemId, eventId, state = MonitoringUtils.STATES.TODO, value = '', updated_at, updated_by) => ({
	itemId,
	eventId,
	state,
	value,
	updated_by,
	updated_at,
})

export function formatSteps(stepsDto, status) {
	return Object.entries(stepsDto).map(([name, { orderInLevel, modifiedDate, eventComment, modifiedBy, subLevels, itemStatus, itemId, eventId }]) => {
		/**
		 * On formatte les sous étapes.
		 * @type {{updated_at: *, updated_by: *, state: string, value: string}[]|*[]}
		 */
		const formattedSubSteps = subLevels ? Object.entries(subLevels).map(([subName, { modifiedDate: subModifiedDate, modifiedBy: subModifedBy, itemStatus, eventId, itemId }]) => SubStep(itemId, eventId, MonitoringUtils.getStatusFromStatusList(itemStatus, status), subName, new Date(subModifiedDate), subModifedBy)) : []

		/**
		 * Pour check le statut d'une étape.
		 * Par défaut, il est "Non complété".
		 * @type {STATES}
		 */
		let stepState = MonitoringUtils.STATES.TODO

		/**
		 * S'il y a des sous-étapes, alors
		 */
		if (formattedSubSteps.length > 0) {
			/**
			 * Si TOUTES les sous étapes ont été faites, alors l'étape est complétée.
			 */
			if (formattedSubSteps.every(({ state }) => MonitoringUtils.isDone(state))) {
				stepState = MonitoringUtils.STATES.DONE

				/**
				 * Sinon, s'il y a au moins une sous-étape complétée, l'étape est en cours.
				 */
			} else if (formattedSubSteps.some(({ state }) => MonitoringUtils.isDone(state))) {
				stepState = MonitoringUtils.STATES.DOING
			}
			/**
			 * S'il n'y a pas de sous étapes, on récupère l'état en fonction de l'étape globale.
			 */
		} else {
			stepState = MonitoringUtils.getStatusFromStatusList(itemStatus, status)
		}

		return Step(itemId, eventId, orderInLevel, stepState, name, new Date(modifiedDate), modifiedBy, formattedSubSteps, eventComment)
	})
}

export async function resetSchoolWorkflow(context, uai) {
	const { TODO, NOT_NEEDED } = MonitoringUtils.STATES

	const { steps, status } = await Query.getSchoolWorkflow(context, uai)

	const formattedSteps = formatSteps(steps, status)
	const allUpdatedSteps = []

	formattedSteps.forEach(({ name: parentName, itemId, state, sub_steps, comment, updated_by: _, updated_at: __, ...rest }) => {
		allUpdatedSteps.push({
			action: 'insert',
			data: {
				itemLabel: parentName,
				itemId,
				level: 1,
				parentId: itemId,
				eventComment: comment || '',
				statusId: state === NOT_NEEDED ? NOT_NEEDED : TODO,
				...rest,
			},
		})

		sub_steps.forEach(({ value, updated_by: _, updated_at: __, state: ___, ...rest }) => {
			allUpdatedSteps.push({
				action: parentName === 'Devis envoyé' ? 'delete' : 'insert',
				data: {
					statusId: TODO,
					itemLabel: value,
					parentId: itemId,
					level: 2,
					...rest,
				},
			})
		})
	})

	await Query.updateSchoolWorkflow(context, uai, allUpdatedSteps)
}

/**
 * Suivi de l'établissement tout au long de l'année.
 * On y retrouve chaque étape du départ jusqu'à la fin.
 * Ce sont Aurélie et Anaïs qui vont indiquer les tâches en cours, celles finies, et les éventuels commentaires.
 * @return {JSX.Element}
 * @constructor
 */
function SchoolMonitoring({ uai }) {
	const context = useContext(GlobalContext)
	const { isLightTheme, translate, school, addToast, showModal } = context

	/**
	 * Etape prévisualisé, affichant toutes les informations la concernant.
	 */
	const [previewedStep, setPreviewedStep] = useState(null)

	/**
	 * Liste des étapes
	 */
	const [steps, setSteps] = useState(null)

	/**
	 * Chargement...
	 */
	const [loading, setLoading] = useState(false)

	/**
	 * Récupère les étapes en BDD et les formate.
	 * @type {function(): void}
	 */
	const sync = useCallback(() => {
		setLoading(true)
		Query.getSchoolWorkflow(context, uai ?? school.informations.uai)
			.then(({ steps, status }) => {
				/**
				 * On formate les objets reçus par la BDD selon nos objets à nous.
				 * @type {{itemId: *, eventId: *, updated_at: Date, name: *, updated_by: *, sub_steps: *[], comment: *, state: number, order: *}[]}
				 */
				const formattedSteps = formatSteps(steps, status)

				setLoading(false)
				setSteps(formattedSteps)
				setPreviewedStep(previewedStep ? formattedSteps.find(({ name }) => previewedStep.name === name) : formattedSteps.find(({ state }) => state === MonitoringUtils.STATES.DOING))
			})
			.catch(() => addToast(translate('global.errors.occurred'), { appearance: 'error' }))
	}, [addToast, context, previewedStep, school.informations?.uai, translate, uai])

	/**
	 * Les tâches "Non nécessaires" ne sont pas modifiées.
	 * Les sous-tâches ne sont pas supprimées juste décochées, sauf pour "Devis envoyée" où elles sont supprimées.
	 */
	function resetWorkflow() {
		showModal('confirmAction', true, {
			content: 'Êtes-vous sûr de vouloir remettre à zéro cet établissement ?',
			action: async () => {
				try {
					setLoading(true)
					await resetSchoolWorkflow(context, uai ?? school.informations.uai)
					sync()
				} catch (e) {
					addToast(e.toString(), { appearance: 'error' })
				}
			},
		})
	}

	/**
	 * Récupère les étapes à l'initialisation.
	 */
	useEffect(() => {
		if (!steps) {
			sync()
		}
	}, [steps, sync])

	return (
		<Section className="fade-in-fwd">
			{!uai && (
				<>
					<Heading textColor={!isLightTheme && 'light'} className={'fade-in-right'}>
						Votre suivi par Tabuléo
					</Heading>
					<Hr />
				</>
			)}
			{!(school.informations?.uai || uai) ? (
				<Heading className={'fade-in-fwd'} subtitle>
					{translate('global.errors.no_school')}
				</Heading>
			) : (
				<Box className="fade-in-bottom " paddingless>
					{steps ? (
						<Columns>
							<Columns.Column size={'one-third'} className="border-r overflow-y-auto" style={{ height: 'calc(100vh - 12rem)' }}>
								<div className="flex justify-between">
									<Button color={'info'} light size={'small'} loading={loading} rounded className="mx-4 my-2 " onClick={sync}>
										<Icon icon={'far fa-sync'} />
										<span>Synchroniser</span>
									</Button>
									<Button color={'danger'} light size={'small'} loading={loading} rounded className="mx-4 my-2" onClick={resetWorkflow}>
										<Icon icon={'far fa-exclamation-triangle'} />
										<span>Remise à zéro</span>
									</Button>
								</div>

								<TimeLine steps={steps} setPreview={setPreviewedStep} />
							</Columns.Column>
							<Columns.Column size={'two-thirds'} className="overflow-y-auto" style={{ height: 'calc(100vh - 12rem)' }}>
								<article className="p-4">{previewedStep && <PreviewedStep uai={uai ?? school.informations.uai} syncing={loading} key={previewedStep} step={previewedStep} sync={sync} />}</article>
							</Columns.Column>
						</Columns>
					) : (
						<Skeleton />
					)}
				</Box>
			)}
		</Section>
	)
}

/**
 * Historique de chaque étape, indiquant l'état, la dernière date de modification,
 * et les éventuels commentaires.
 * Un utilisateur peut cliquer sur une étape pour en avoir le détail.
 * @param {Step[]} steps
 * @param {function} setPreview
 * @return {JSX.Element}
 */
function TimeLine({ steps, setPreview }) {
	return (
		<div className="mt-2 timeline is-centered">
			<header className="timeline-header">
				<span className="tag is-medium is-primary">Début</span>
			</header>
			{steps
				.sort((a, b) => a.order - b.order)
				.map((step, key) => {
					const { state, name, comment } = step

					const stateColor = MonitoringUtils.getStateColor(state, true)

					return (
						<React.Fragment key={key}>
							<div className={`timeline-item is-grey`} />
							<header className="timeline-header">
								<button className={`button is-small is-rounded ${stateColor} ${MonitoringUtils.isNotNeeded(state) ? 'line-through' : ''}`} onClick={() => setPreview(step)}>
									{comment && <Icon icon={'far fa-engine-warning'} />}
									<span>{name}</span>
								</button>
							</header>
						</React.Fragment>
					)
				})}

			<div className="timeline-item" />
			<header className="timeline-header">
				<span className="tag is-medium is-primary">Fin</span>
			</header>
		</div>
	)
}

/**
 * Détail d'une étape, avec possibilité de la modifier.
 * @param {string} UAI
 * @param {number} eventId
 * @param {number} itemId
 * @param {STATES} state
 * @param {string} name
 * @param {string} comment
 * @param {object} sub_steps
 * @param {Date} updated_at
 * @param {string} updated_by
 * @param {function} sync
 * @param {boolean} syncing
 * @return {JSX.Element}
 * @constructor
 */
function PreviewedStep({ uai, step: { eventId, itemId, state, name, comment, sub_steps, updated_at, updated_by }, sync, syncing }) {
	const context = useContext(GlobalContext)
	const { translate, addToast } = context

	/**
	 * Etape actuelle
	 */
	const [current, setCurrent] = useState(name)

	/**
	 * Défini si l'utilisateur est en mode édition ou non.
	 */
	const [editing, setEditing] = useState(name !== 'Devis signé')

	/**
	 * Etat courant de l'étape
	 */
	const [stepState, setStepState] = useState(state)

	/**
	 * Liste des sous étapes
	 */
	const [subSteps, setSubSteps] = useState([...sub_steps])

	/**
	 * Valeur du champs commentaire
	 */
	const [commentValue, setCommentValue] = useState(comment || '')

	/**
	 * Défini si l'étape est NON nécessaire.
	 */
	const [isNotNeeded, setIsNotNeeded] = useState(MonitoringUtils.isNotNeeded(state))

	/**
	 * Défini si l'étape est complétée
	 */
	const [isCompleted, setIsCompleted] = useState(MonitoringUtils.isDone(state))

	/**
	 * Etapes formatés pour l'envoi en BDD
	 */
	const [formattedSteps, setFormattedSteps] = useState([])

	const [, forceRender] = useState({})

	/**
	 * Met à jour les variable lors du changement d'étape
	 */
	useEffect(() => {
		if (current !== name) {
			setEditing(name !== 'Devis signé')
			setCurrent(name)
			setStepState(state)
		}

		setCommentValue(comment || '')
		setSubSteps([...sub_steps])
		setIsNotNeeded(MonitoringUtils.isNotNeeded(stepState))
		setIsCompleted(MonitoringUtils.isDone(stepState))
	}, [comment, current, name, state, stepState, sub_steps])

	/**
	 * Applique les modifications en BDD et met à jour le suivi.
	 */
	function handleSubmit(beforeEvent, isChecked) {
		if (typeof beforeEvent !== 'string') {
			beforeEvent?.preventDefault()
		}

		if (beforeEvent?.currentTarget?.value === '') {
			subSteps.splice(
				subSteps.findIndex(({ eventId }) => eventId === 0),
				1
			)
			setSubSteps([...subSteps])
			return
		}

		let notNeeded = isNotNeeded
		let completed = isCompleted

		if (beforeEvent === 'not-needed') {
			notNeeded = isChecked
			setIsNotNeeded(isChecked)
		}

		if (beforeEvent === 'completed') {
			completed = isChecked
			setIsCompleted(isChecked)
		}

		/**
		 * Ajout de toutes les sous étapes.
		 */
		for (const subStep of subSteps) {
			const item = {
				action: notNeeded ? 'delete' : 'insert',
				data: {
					itemLabel: subStep.value,
					parentId: eventId,
					level: 2,
					itemId: subStep.itemId ?? 0,
					eventId: subStep.eventId ?? 0,
					statusId: MonitoringUtils.isDone(subStep.state) ? 1 : 2,
				},
			}

			formattedSteps.push(item)
		}

		const { DONE, DOING, TODO, NOT_NEEDED } = MonitoringUtils.STATES

		const updatedStepState = notNeeded ? NOT_NEEDED : subSteps.length > 0 ? (subSteps.every(sub => MonitoringUtils.isDone(sub.state)) ? DONE : TODO) : completed ? DONE : TODO

		/**
		 * Ajout de l'étape principale
		 */
		formattedSteps.push({
			action: 'insert',
			data: {
				eventId,
				itemId,
				itemLabel: name,
				level: 1,
				eventComment: commentValue,
				parentId: itemId,
				statusId: updatedStepState,
			},
		})

		if (_.uniqBy(formattedSteps, "data.itemLabel").length !== formattedSteps.length) {
			setFormattedSteps([])
			return addToast('Une sous-étape existe avec le même nom.', { appearance: 'warning' })
		}

		Query.updateSchoolWorkflow(context, uai, formattedSteps)
			.then(({ data, status }) => {
				setStepState(() => {
					if (!notNeeded && subSteps.length > 0) {
						return subSteps.every(sub => MonitoringUtils.isDone(sub.state)) ? DONE : subSteps.some(sub => MonitoringUtils.isDone(sub.state)) ? DOING : TODO
					} else {
						return notNeeded ? NOT_NEEDED : completed ? DONE : TODO
					}
				})

				if (status === 200) {
					addToast('Le workflow a bien été mis à jour.', { appearance: 'success' })
				} else {
					addToast(
						data.errors.map(error => (
							<>
								{error}
								<br />
							</>
						)),
						{ appearance: 'warning' }
					)
				}

				sync()
				forceRender({})
			})
			.catch(err => {
				addToast(
					err.errors
						? err.errors.map(error => (
								<>
									{error}
									<br />
								</>
						  ))
						: translate('global.errors.occurred'),
					{ appearance: 'error' }
				)
			})

		setFormattedSteps([])
	}

	/**
	 * Renvoie un Tag indiquant l'état de l'étape.
	 * @param {boolean} isIcon
	 * @param {number|STATES} subStepState
	 * @return {JSX.Element}
	 */
	function StateTag({ isIcon = false, subStepState = -1 }) {
		const tagState = subStepState === -1 ? stepState : subStepState

		return (
			<Tag color={MonitoringUtils.getStateColor(tagState)}>
				{MonitoringUtils.isDone(tagState) && (isIcon ? <Icon icon={'far fa-check'} /> : 'Complété')}
				{MonitoringUtils.isDoing(tagState) && (isIcon ? <Icon icon={'far fa-circle-notch fa-spin'} /> : 'En cours ...')}
				{MonitoringUtils.isNotStarted(tagState) && (isIcon ? <Icon icon={'far fa-times'} /> : 'Non commencé')}
				{MonitoringUtils.isNotNeeded(tagState) && 'Non nécessaire'}
			</Tag>
		)
	}

	/**
	 * Ajoute une sous étape vide.
	 */
	async function addSubStep() {
		if (document.querySelector('input[name="sub-value[0]"]')) {
			return handleSubmit()
		}

		const initialSubStepName = ''

		if (sub_steps.find(({ value }) => value === initialSubStepName)) {
			return addToast('Une sous-étape existe avec le même nom.', { appearance: 'warning' })
		}

		subSteps.push(SubStep(0, 0, MonitoringUtils.STATES.TODO, initialSubStepName, new Date()))

		setSubSteps([...subSteps])

		// handleSubmit()
	}

	useEffect(() => {
		document.querySelector('input[name="sub-value[0]"]')?.focus()
	}, [subSteps])

	/**
	 * Supprime une sous étape.
	 * Si elle existait auparavant, elle est ajoutée au étapes à supprimer (formatedStep) en BDD.
	 * @param {number} idx
	 * @param {number} eventId
	 */
	function removeSubStep(idx, eventId) {
		const substep = subSteps.find(step => eventId === step.eventId)
		if (substep) {
			formattedSteps.push({
				action: 'delete',
				data: {
					eventId: eventId,
				},
			})
		}

		subSteps.splice(idx, 1)

		setSubSteps([...subSteps])

		handleSubmit()
	}

	return (
		<article className="border rounded-lg shadow-lg w-full p-5">
			<form onSubmit={handleSubmit}>
				<div className="flex items-center justify-between mb-1">
					<Heading marginless subtitle textColor={'black'} size={4}>
						{name}
					</Heading>
				</div>
				<StateTag />
				<p className="py-4">
					<em className="is-size-7">
						Dernière modification le {updated_at?.toLocaleString()} par {updated_by}
					</em>
				</p>
				{editing && (
					<Form.Field className="my-4">
						<Form.Control>
							<input
								type="checkbox"
								id={'not_needed'}
								className="switch is-rounded is-danger is-small"
								disabled={syncing}
								checked={isNotNeeded}
								name={`not_needed`}
								onChange={e => {
									handleSubmit('not-needed', e.target.checked)
								}}
							/>
							<label htmlFor={'not_needed'} className="has-text-weight-bold has-text-danger">
								Etape non nécessaire
							</label>
						</Form.Control>
					</Form.Field>
				)}
				<Hr />
				{editing ? (
					<>
						{subSteps.length === 0 ? (
							<Form.Field className="my-4">
								<Form.Control>
									<input
										type="checkbox"
										id={'completed'}
										className="switch is-rounded is-success is-small"
										disabled={syncing || isNotNeeded}
										checked={isCompleted}
										name={`completed`}
										onChange={e => {
											handleSubmit('completed', e.target.checked)
										}}
									/>
									<label htmlFor={'completed'} className="has-text-weight-bold has-text-success">
										Etape complétée
									</label>
								</Form.Control>
							</Form.Field>
						) : (
							<Content>
								<article>
									{subSteps
										.sort((a, b) => {
											if (a.eventId === 0) {
												return 1
											}

											return a.eventId - b.eventId
										})
										.map(({ state, value, eventId, updated_at: substep_updated_at, updated_by: substep_updated_by }, idx) => {
											const isDone = MonitoringUtils.isDone(state)

											return (
												<fieldset key={idx} className="h-12">
													<div className="flex place-items-center">
														{eventId !== 0 && (
															<Form.Field marginless>
																<Form.Control>
																	<input
																		disabled={syncing}
																		type="checkbox"
																		id={idx}
																		className="switch is-rounded is-success is-small"
																		checked={isDone}
																		name={`sub-checked[${eventId}]`}
																		onChange={e => {
																			subSteps[idx].state = e.target.checked ? MonitoringUtils.STATES.DONE : MonitoringUtils.STATES.TODO

																			setSubSteps([...subSteps])

																			handleSubmit()
																		}}
																	/>
																	<label htmlFor={idx} />
																</Form.Control>
															</Form.Field>
														)}
														<Form.Field marginless className="has-addons flex-grow">
															<Form.Control className="is-expanded">
																<Form.Input
																	disabled={syncing}
																	name={`sub-value[${eventId}]`}
																	type={'text'}
																	value={value}
																	size={'small'}
																	placeholder={'Sous-étape'}
																	required
																	onSubmit={e => e.preventDefault()}
																	onSubmitCapture={e => e.preventDefault()}
																	onChange={e => {
																		subSteps[idx].value = e.target.value

																		setSubSteps([...subSteps])
																	}}
																	onBlur={handleSubmit}
																/>
															</Form.Control>
															{eventId !== 0 && (
																<Form.Control>
																	<Button
																		disabled={syncing}
																		color={'danger'}
																		size={'small'}
																		type={'button'}
																		onClick={() => {
																			removeSubStep(idx, eventId)
																		}}
																	>
																		<Icon icon={'fas fa-trash'} />
																	</Button>
																</Form.Control>
															)}
														</Form.Field>
													</div>
												</fieldset>
											)
										})}
								</article>
							</Content>
						)}
						<Form.Field className="pb-12">
							<Form.Control>
								<Button color={'primary'} size={'small'} type={'button'} onClick={addSubStep} disabled={syncing}>
									<Icon icon={'fal fa-plus'} />
									<span>Ajouter une sous-étape</span>
								</Button>
							</Form.Control>
						</Form.Field>
					</>
				) : (
					subSteps.length > 0 && (
						<Content>
							<Heading size={6}>Sous-étapes :</Heading>
							<ul>
								{subSteps.map(({ state, value, updated_at, updated_by }, key) => (
									<li key={key}>
										<StateTag isIcon subStepState={state} /> {value}{' '}
										<em className="is-size-7">
											(le {updated_at.toLocaleString()} par {updated_by})
										</em>
									</li>
								))}
							</ul>
						</Content>
					)
				)}
				<Heading size={6}>Commentaire</Heading>
				{editing ? (
					<Form.Field>
						<Form.Control>
							<Form.Textarea
								name={'comment'}
								placeholder={'Rédiger un commentaire'}
								disabled={syncing}
								value={commentValue}
								onChange={e => {
									setCommentValue(e.target.value)
								}}
								onBlur={handleSubmit}
							/>
						</Form.Control>
					</Form.Field>
				) : (
					<Content textSize={7} textColor={'grey-dark'}>
						<p>{comment || 'Aucun commentaire'}</p>
					</Content>
				)}
			</form>
		</article>
	)
}

function Skeleton() {
	const fakeTasks = []
	const sizes = ['w-16', 'w-24', 'w-32', 'w-48']

	for (let i = 0; i < 11; i++) {
		fakeTasks.push(
			<React.Fragment>
				<div className={`timeline-item is-grey`} />
				<header className="timeline-header">
					<button className={`button is-small is-light is-rounded animate-pulse ${sizes[Math.ceil(Math.random() * 4) - 1]}`} />
				</header>
			</React.Fragment>
		)
	}

	return (
		<Columns>
			<Columns.Column size={'one-third'} className="border-r overflow-y-auto" style={{ height: 'calc(100vh - 12rem)' }}>
				<Button color={'info'} light size={'small'} rounded className="mx-4 my-2 has-tooltip-arrow has-tooltip-right">
					<Icon icon={'far fa-sync'} />
					<span>Synchroniser</span>
				</Button>
				<div className="-mt-1 timeline is-centered">
					<header className="timeline-header">
						<span className="tag is-medium is-primary w-32 animate-pulse" />
					</header>
					{fakeTasks}
					<div className="timeline-item" />
					<header className="timeline-header">
						<span className="tag is-medium is-primary w-16 animate-pulse" />
					</header>
				</div>
			</Columns.Column>
			<Columns.Column size={'two-thirds'}>
				<article className="border rounded-lg shadow-lg w-full p-5">
					<div className="space-y-2">
						<Heading marginless subtitle textColor={'black'} size={4} className={'h-8 w-48 bg-gray-100 rounded-lg animate-pulse'} />
						<div className="h-6 w-24 bg-gray-200 rounded-lg animate-pulse" />
					</div>
					<div />
					<Hr />
					<Content>
						<Heading size={6}>Sous-étapes :</Heading>
						<ul>
							<li className="flex place-items-center space-x-2">
								<div className="h-8 w-8 rounded-lg bg-gray-200 animate-pulse" />
								<div className="w-24 h-4 rounded-lg bg-gray-100 animate-pulse" />
							</li>
							<li className="flex place-items-center space-x-2">
								<div className="h-8 w-8 rounded-lg bg-gray-200 animate-pulse" />
								<div className="w-16 h-4 rounded-lg bg-gray-100 animate-pulse" />
							</li>
							<li className="flex place-items-center space-x-2">
								<div className="h-8 w-8 rounded-lg bg-gray-200 animate-pulse" />
								<div className="w-48 h-4 rounded-lg bg-gray-100 animate-pulse" />
							</li>
						</ul>
					</Content>
					<Heading size={6}>Commentaire</Heading>
					<Content textSize={7} textColor={'grey-dark'}>
						<p className="h-4 w-96 bg-gray-100 animate-pulse rounded-lg" />
					</Content>
				</article>
			</Columns.Column>
		</Columns>
	)
}

export default SchoolMonitoring
