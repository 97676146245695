import React from 'react'
import Icon from '../../utils/Icon'
import GlobalContext from '../../../contexts/GlobalContext'
import availableLanguages from '../../../config/pdf_languages.json'
import { Form, Loader } from 'react-bulma-components'
import Queries from '../../../tools/Query'
import FileSaver from 'file-saver'

export default class DropdownPdf extends React.Component {
	static contextType = GlobalContext

	constructor(props) {
		super(props)
		this.state = {
			lang: availableLanguages[0].value,
			option: 'default',
		}
	}

	getInformationsUsersForAvery = () => {
		const { grid } = this.context
		const header = { col: [{ value: 'prenom' }, { value: 'nom' }, { value: 'classe' }, { value: 'level' }] }
		let users = grid?.getSelectedRows()
		if (users) {
			const datas = users.map(user => {
				const averyUser = { prenom: user['Prénom'], nom: user['Nom'], classe: user['Classe'], level: user['Niveau'] }
				return { col: Object.values(averyUser).map(val => ({ value: val })) }
			})
			return { header: header, data: datas }
		}
		return {}
	}

	changeLang = event => {
		this.setState({
			lang: event.target.value,
		})
	}

	changeOption = event => {
		this.setState({
			option: event.target.value,
		})
	}

	generatePDF = async (event, type) => {
		event.preventDefault()

		const { grid, addToast, updateToast, translate, removeToast } = this.context
		const { lang, option } = this.state
		const students = grid.getSelectedRows()

		if (students.length < 1 || students.length > 1000) {
			return addToast(translate('table.pdf.max_min'), {
				appearance: 'warning',
			})
		}

		const formattedStudents = []

		grid.forEachNodeAfterFilterAndSort(({ data, selected }) => selected && formattedStudents.push(data['ID utilisateur']))

		if (formattedStudents.length > 100 && option === 'unitary') {
			return addToast(translate('table.pdf.max_unitary'), {
				appearance: 'warning',
			})
		}

		addToast(
			<p>
				{translate('table.pdf.generating')} <Loader />
			</p>,
			{ appearance: 'info', autoDismiss: false },
			async toastId => {
				Queries.generatePDF(this.context, type, lang, option, formattedStudents)
					.then(res => {
						const { option } = this.state
						const { uai } = this.context.school.informations
						FileSaver.saveAs(res, option === 'default' ? (formattedStudents.length === 1 ? `${uai} ${students[0].Nom} ${students[0].Prénom}.pdf` : `${uai} ${translate(`table.pdf.${type}`)}.pdf`) : `${uai} ${translate(`table.pdf.${type}`)}.zip`)
						removeToast(toastId)
					})
					.catch(err => {
						updateToast(toastId, {
							appearance: 'warning',
							autoDismiss: true,
							content: translate('global.errors.occurred'),
						})
					})
			}
		)
	}

	render() {
		const { grid, shouldMount, translate } = this.context
		return (
			<div className="dropdown is-hoverable" style={{ marginRight: '0.5rem' }}>
				<div className="dropdown-trigger">
					<button className="button is-info" disabled={!this.props.enabled} aria-haspopup="true" aria-controls="dropdown-menu">
						<Icon icon={'fad fa-file-pdf'} />
						<span className={'text-icon'}>{translate('table.pdf.title')}</span>
						<Icon icon={'fal fa-angle-down'} />
					</button>
				</div>
				<div className="dropdown-menu" id="dropdown-menu" role="menu">
					<div className={`dropdown-content fade-in-fwd-faster ${!this.context.isLightTheme ? 'has-background-black' : ''}`} hidden={!this.props.enabled}>
						<div className="dropdown-item">
							<Form.Control iconLeft className={'is-expanded'} size={'small'}>
								<div className="select is-small is-fullwidth">
									<select name="lang" onChange={this.changeLang}>
										{availableLanguages.map((lang, key) => (
											<option key={key} value={lang.value}>
												{lang.name}
											</option>
										))}
									</select>
									<Icon icon={'fad fa-language'} className={'is-small is-left'} />
								</div>
							</Form.Control>
						</div>
						<div className="dropdown-item">
							<Form.Control iconLeft className={'is-expanded'} size={'small'}>
								<div className="select is-small is-fullwidth">
									<select name="option" onChange={this.changeOption}>
										<option key="default" value="default">
											{translate('global.default')}
										</option>
										<option key="unitary" value="unitary">
											{translate('global.unitary')}
										</option>
										<option key="level" value="level">
											{`${translate('global.by')} ${translate('global.level')}`}
										</option>
										<option key="class" value="class">
											{`${translate('global.by')} ${translate('global.class')}`}
										</option>
									</select>
									<Icon icon={'fad fa-users-class'} className={'is-small is-left'} />
								</div>
							</Form.Control>
						</div>
						<hr className="dropdown-divider" />
						{shouldMount('buttonExportPDF_deploy', 'Lecture') && (
							<a href="#" className="dropdown-item is-flex" onClick={event => this.generatePDF(event, 'deploy', translate('table.pdf.deploy'))}>
								<span className="text-icon">{translate('table.pdf.deploy')}</span>
								<span className="has-tooltip-arrow has-tooltip-right" data-tooltip={translate('table.pdf.deploy_help')}>
									<Icon icon={'fal fa-question-circle'} />
								</span>
							</a>
						)}
						{shouldMount('buttonExportPDF_geree', 'Lecture') && (
							<a href="#" className="dropdown-item is-flex" onClick={event => this.generatePDF(event, 'supervisedEquipment', translate('table.pdf.supervisedEquipment'))}>
								<span className="text-icon">{translate('table.pdf.supervisedEquipment')}</span>
								<span className="has-tooltip-arrow has-tooltip-right" data-tooltip={translate('table.pdf.supervised_help')}>
									<Icon icon={'fal fa-question-circle'} />
								</span>
							</a>
						)}
						{shouldMount('buttonExportPDF_nonGeree', 'Lecture') && (
							<a href="#" className="dropdown-item is-flex" onClick={event => this.generatePDF(event, 'personalEquipment', translate('table.pdf.personalEquipment'))}>
								<span className="text-icon">{translate('table.pdf.personalEquipment')}</span>
								<span className="has-tooltip-arrow has-tooltip-right" data-tooltip={translate('table.pdf.personal_help')}>
									<Icon icon={'fal fa-question-circle'} />
								</span>
							</a>
						)}
						{shouldMount('buttonExportPDF_epub', 'Lecture') && (
							<a href="#" className="dropdown-item is-flex" onClick={event => this.generatePDF(event, 'epub', translate('table.pdf.epub'))}>
								<span className="text-icon">{translate('table.pdf.epub')}</span>
								<span className="has-tooltip-arrow has-tooltip-right" data-tooltip={translate('table.pdf.epub_help')}>
									<Icon icon={'fal fa-question-circle'} />
								</span>
							</a>
						)}
						{shouldMount('buttonExportPDF_labelsBox', 'Lecture') && (
							<a href="#" className="dropdown-item is-flex" onClick={event => this.generatePDF(event, 'labelBox', translate('table.pdf.labelBox'))}>
								<span className="text-icon">{translate('table.pdf.labelBox')}</span>
								<span className="has-tooltip-arrow has-tooltip-right" data-tooltip={translate('table.pdf.labels_help')}>
									<Icon icon={'fal fa-question-circle'} />
								</span>
							</a>
						)}
						{shouldMount('buttonExportPDF_labelsFrame', 'Lecture') && (
							<a href="#" className="dropdown-item is-flex" onClick={event => this.generatePDF(event, 'labelFrame', translate('table.pdf.labelFrame'))}>
								<span className="text-icon">{translate('table.pdf.labelFrame')}</span>
								<span className="has-tooltip-arrow has-tooltip-right" data-tooltip={translate('table.pdf.labels_help')}>
									<Icon icon={'fal fa-question-circle'} />
								</span>
							</a>
						)}
					</div>
				</div>
			</div>
		)
	}
}
