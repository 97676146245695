import React, {FC, FormEventHandler, useContext, useState} from 'react'
// @ts-ignore
import {Button, Form, Heading, Image, Table} from 'react-bulma-components'
import Icon from '../utils/Icon'
import Hr from '../utils/Hr'
import GlobalContext from '../../contexts/GlobalContext'
import Queries from '../../tools/Query'
import _ from 'lodash'
import {SchoolPlatformSettings} from '../table/user/UserApp'

export const SettingsApps: FC = () => {
	const context = useContext(GlobalContext)
	const {
		translate,
		isLightTheme,
		school: { informations, platforms },
		updateSchoolParams,
		addToast,
	} = context
	const [editing, setEditing] = useState(false)
	const [loading, setLoading] = useState(false)

	const initialValues: Record<string, SchoolPlatformSettings> = {}
	platforms.forEach((platform: SchoolPlatformSettings) => (initialValues[platform.platformId] = platform))
	const [values, setValues] = useState(initialValues)

	const hasMadeChanges = () => {
		return !_.isEqual(values, initialValues)
	}

	type handleType = (event: React.ChangeEvent<HTMLInputElement>) => void
	type updateValuesType = (name: string, value: number | string | boolean) => void

	const updateValues: updateValuesType = (name, value) => {
		const [app, field] = name.split('.')
		setValues(values => ({
			...values,
			[app]: {
				...values[app],
				[field]: value,
			},
		}))
	}

	const handleChange: handleType = ({ target: { name, value } }) => {
		updateValues(name, value)
	}

	const handleSwitch: handleType = ({ target: { name, checked } }) => {
		updateValues(name, checked)
	}

	const handleSubmit: FormEventHandler<HTMLFormElement> = formEvent => {
		formEvent.preventDefault()

		if (!editing) {
			return setEditing(true)
		}

		if (!hasMadeChanges()) {
			return setEditing(false)
		}

		setLoading(true)

		Queries.updateParams(context, informations.uai, { platforms: Object.values(values)})
			.then(() => {
				updateSchoolParams({ platforms: Object.values(values) })
				addToast(translate('settings.update_success'), { appearance: 'success' })
				setLoading(false)
				setEditing(false)
			})
			.catch(() => {
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
				setLoading(false)
			})
	}

	return (
		<form onSubmit={handleSubmit}>
			<div className="is-flex" style={{ justifyContent: 'space-between' }}>
				<Heading textColor={!isLightTheme ? 'light' : ''} size={5} style={{ marginBottom: '0' }}>
					{translate('settings.platforms.title')}
				</Heading>
				{editing ? (
					<Button size={'small'} color={'success'} loading={loading} type={'submit'}>
						<Icon icon={'fal fa-check'} />
						<span className="is-text-icon">{translate('global.save')}</span>
					</Button>
				) : (
					<Button size={'small'} color={'info'} type={'submit'}>
						<Icon icon={'fal fa-money-check-edit'} />
						<span className="is-text-icon">{translate('global.update')}</span>
					</Button>
				)}
			</div>
			<Hr />
			<div className="table-container" style={{ maxHeight: '60vh', overflowY: 'auto' }}>
				<Table striped textAlignment={'left'} className={!isLightTheme ? 'is-dark' : ''}>
					<thead>
						<tr>
							<th className="w-96" />
							<th className={'w-64 has-text-size-6'}>{translate('settings.platforms.specific_password')}</th>
							<th className={'has-text-size-6'}>{translate('global.common_code')}</th>
						</tr>
					</thead>
					<tbody>
						{platforms.sort((a: SchoolPlatformSettings, b: SchoolPlatformSettings) => a.platformOrder - b.platformOrder).map(({ platformId, platformName, platformUrlschemeCodeYesNo: needsIndividualCode, platformCodeYesNo: needsCommonCode, commonCode }: SchoolPlatformSettings, idx: number) => {

							return (
								<tr key={idx}>
									<td className="is-flex w-96" style={{ alignItems: 'center' }}>
										<p className='pl-4'>{platformName}</p>
									</td>
									<td>
										<Form.Field>
											<Form.Control>
												<input id={`${platformId}.connectDiffPassword`} name={`${platformId}.connectDiffPassword`} type="checkbox" checked={!!values[platformId]?.connectDiffPassword} className="switch is-rounded is-success is-small" disabled={!editing} onChange={handleSwitch} />
												<label htmlFor={`${platformId}.connectDiffPassword`} />
											</Form.Control>
										</Form.Field>
									</td>
									<td>
										{editing ? (
											needsCommonCode ? (
												<Form.Field>
													<Form.Control>
														<Form.Input textColor={!isLightTheme ? 'light' : ''} backgroundColor={!isLightTheme ? 'dark' : ''} size={'small'} type={'text'} onChange={handleChange} name={`${platformId}.commonCode`} placeholder={'Cette application nécessite un code commun.'} value={values[platformId].commonCode} />
													</Form.Control>
												</Form.Field>
											) : null
										) : (
											<>{needsIndividualCode ? <span className="is-size-7 italic">{translate('settings.platforms.by_user')}</span> : !needsCommonCode ? <span className="is-size-7 italic">{translate('settings.platforms.no')}</span> : commonCode}</>
										)}
									</td>
								</tr>
							)
						})}
					</tbody>
				</Table>
			</div>
		</form>
	)
}
