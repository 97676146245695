import { Button, Form, Navbar } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import React from 'react'
import GlobalContext from '../../../contexts/GlobalContext'
import Queries from '../../../tools/Query'

/**
 * Recherche utilisateur dans la Navbar.
 */
export default class UserSearch extends React.Component {
	static contextType = GlobalContext

	/**
	 * Liste des états:
	 * 	- loadingUser = Icone de chargement pendant la recherche en BDD
	 * 	- searchUser = valeur de la recherche tapée par l'utilisateur
	 * @param props
	 */
	constructor(props) {
		super(props)
		this.state = {
			loadingUser: false,
			searchUser: '',
		}
	}

	/**
	 * Saisie du champs par l'utilisateur
	 * @param keyboardEvent
	 */
	handleChange = keyboardEvent => {
		this.setState({
			[keyboardEvent.target.name]: keyboardEvent.target.value,
		})
	}

	/**
	 * Recherche en BDD de l'utilisateur. Puis affiche une modal avec les résultats.
	 * @param formEvent
	 */
	searchUser = formEvent => {
		formEvent.preventDefault()

		if (this.state.searchUser) {
			this.setState({ loadingUser: true })

			Queries.searchForOneUser(this.context, this.state.searchUser).then(res => {
				this.setState({
					loadingUser: false,
				})
				this.context.showModal('findUser', true, { users: res })
			})
		}
	}

	render() {
		const { translate, shouldMount } = this.context
		const { loadingUser, searchUser } = this.state

		return (
			shouldMount('NavBar_searchUser', 'Lecture') && (
				<Navbar.Container>
					<Navbar.Item renderAs="div">
						<form onSubmit={this.searchUser}>
							<Form.Field className={'has-addons'}>
								<Form.Control className={'navbar-control'} iconLeft size={'small'} loading={loadingUser}>
									<Form.Input size="small" className={'navbar-input'} placeholder={translate('navbar.search_user')} onChange={this.handleChange} name={'searchUser'} value={searchUser} />
									<Icon icon={'fad fa-user'} className={'is-small is-left'} />
								</Form.Control>
								<Form.Control>
									<Button color={'link'} size={'small'}>
										<Icon icon={'far fa-search'} />
									</Button>
								</Form.Control>
							</Form.Field>
						</form>
					</Navbar.Item>
				</Navbar.Container>
			)
		)
	}
}
