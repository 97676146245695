import React from 'react'
import Router from './router/Router.jsx'
import NavBar from './containers/NavBar.jsx'
import SideMenu from './containers/SideMenu.tsx'
import { Columns } from 'react-bulma-components'
import Modals from './modals/Modals.jsx'
import SignIn from './SignIn'
import GlobalContext from '../contexts/GlobalContext'

/**
 * Vue global de l'application.
 */
class Main extends React.Component {
	static contextType = GlobalContext

	/**
	 * Taille de la sidebar.
	 * @type {number}
	 */
	sidemenuWidth = 16

	/**
	 * Si l'écran fait moins de 1280px de large, la sidebar est caché par défaut.
	 * @param props
	 */
	constructor(props) {
		super(props)
		this.state = {
			showSidebar: window.matchMedia('(min-width: 1280px)').matches,
		}
	}

	/**
	 * Affiche ou cache la sidebar.
	 */
	toggleSidebar = () => {
		this.setState(state => ({
			showSidebar: !state.showSidebar,
		}))
	}

	/**
	 * Rendu
	 * @returns {JSX.Element}
	 */
	render() {
		const { isLightTheme, auth, user } = this.context
		const { showSidebar } = this.state

		return auth ? (
			<>
				<NavBar showSidebar={showSidebar} toggleSidebar={this.toggleSidebar} />
				<Modals />

				<Columns multiline paddingless marginless style={{ height: 'calc(100vh - 3.5rem)' }}>
					<Columns.Column className={`is-sidebar ${showSidebar ? 'active' : ''}`} backgroundColor={isLightTheme ? 'dark' : 'black'} style={{ borderTop: `2px solid ${isLightTheme ? '#232535' : '#202020'}` }}>
						<SideMenu />
					</Columns.Column>
					<Columns.Column className={`is-main ${showSidebar ? 'active' : ''}`} backgroundColor={isLightTheme ? 'light' : 'black-ter'} paddingless style={{ maxWidth: showSidebar ? `calc(100vw - ${this.sidemenuWidth}%)` : '100vw' }}>
						<svg
							style={{
								width: showSidebar ? `calc(100vw - ${this.sidemenuWidth}%)` : '100vw',
								left: showSidebar ? `${this.sidemenuWidth}%` : '0',
							}}
							className={'back-svg'}
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 1440 320"
						>
							<path fill={isLightTheme ? '#efefef' : '#1f2324'} fillOpacity="0.8" d="M0,128L48,112C96,96,192,64,288,48C384,32,480,32,576,42.7C672,53,768,75,864,80C960,85,1056,75,1152,64C1248,53,1344,43,1392,37.3L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z" />
						</svg>
						<Router userProfile={user.profile} />
					</Columns.Column>
				</Columns>
			</>
		) : (
			<SignIn />
		)
	}
}

export default Main
