import React, { FC, useContext, useRef, useState } from 'react'
// @ts-ignore
import { Form, Image, Section } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import appStoreImage from '../../../res/images/apple.png'
import playStoreImage from '../../../res/images/google.png'
import GlobalContext from '../../../contexts/GlobalContext'

export type UserApplication = {
	informations: {
		applicationKeys: string[]
		platformId: string
		worksWithCas: number
		applicationId: string
		applicationName: string
		platformLogin: string
		platformPassword: string
		platformValidToken: string
	}
	urls: { urlName: string; urlValue: string }[]
}

export type SchoolPlatformSettings = {
	connectDiffPassword: boolean
	commonCode: number
	platformId: string
	platformName: string
	platformOrder: number
	platformCodeYesNo: boolean
	platformForExport: boolean
	platformExportFile: boolean
	platformExportType: boolean
	platformCodeRequired: boolean
	platformUrlschemeCodeYesNo: boolean
	platformUrlschemeCodeRequired: boolean
	platformUrlschemeLoginRequired: boolean
	platformUrlschemePasswordRequired: boolean
}

export type SchoolApplicationSettings = {
	idApplication: string
	commonCode: string
	platformCodeRequired: string
	platformCodeYesNo: string
	boLogin: string
	boPassword: string
	boUrl: string
	connectDiffPassword: string
	exportFile: string
	exportType: string
	forExport: string
	name: string
	order: number
	urlOnLine: string
	urlSchemeCodeRequired: string
	urlSchemeCodeYesNo: string
	urlSchemeLoginRequired: string
	urlSchemePwdRequired: string
	urls: {
		URLschemeOpenBook: string
		URLschemeToken: string
		appStore: string
		dmgLink: string
		exeLink: string
		picturePath: string
		playStore: string
		urlClean: string
		urlLine: string
		urlOpen: string
		urlScheme: string
	}
}

interface UserManualPropsType {
	userApp: UserApplication
	appData: SchoolApplicationSettings
	updateAppData: Function
	edit: boolean
	isInvalidLogin: boolean
}

export const UserApp: FC<UserManualPropsType> = props => {
	const initRequiredFields = () => {
		const tab = []
		urlSchemeLoginRequired && tab.push('login')
		urlSchemePwdRequired && tab.push('password')
		urlSchemeCodeRequired && tab.push('codes')
		return tab
	}

	const { userApp, appData, edit, isInvalidLogin } = props
	const {
		platformId,
		applicationId,
		platformLogin,
		platformPassword,
		applicationKeys,
		worksWithCas,
		applicationName,
		platformValidToken
	} = userApp.informations
	const { picturePath, dmgLink, exeLink, playStore, appStore } = appData.urls
	const {
		urlSchemeLoginRequired,
		urlSchemePwdRequired,
		urlSchemeCodeRequired,
		platformCodeYesNo,
		urlSchemeCodeYesNo
	} = appData
	const { isLightTheme, translate } = useContext(GlobalContext)
	const webUrl = userApp.urls.find(url => url.urlName === 'urlLine')?.urlValue ?? ''

	const kerboodleRef = useRef<HTMLFormElement>(null)
	const llsRef = useRef<HTMLFormElement>(null)
	const [showPassword, setShowPassword] = useState(false)
	const [requiredFields] = useState(initRequiredFields())
	const webUrlRendererProps = { webUrl, platformLogin, platformPassword, applicationKeys, applicationId }

	const updateData = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target
		props.updateAppData(platformId, name, value)
	}

	interface FormLlsPropsType {
		webUrl: string
		platformLogin: string
		platformPassword: string
	}

	const FormLls: FC<FormLlsPropsType> = ({ webUrl, platformLogin, platformPassword }) => {
		return (
			<form target="_blank" ref={llsRef} method="post" action={webUrl}>
				<input hidden name="login" value={platformLogin} />
				<input hidden name="password" value={platformPassword} />
				<a
					href="/"
					onClick={e => {
						e.preventDefault()
						llsRef.current?.submit()
					}}
					target={'_blank'}
					rel="noopener noreferrer"
				>
					<Icon className={'is-user-icon-clickable'} icon={'fal fa-globe fa-2x'} color={'primary'} />
				</a>
			</form>
		)
	}

	interface FormKerboodlePropsType extends FormLlsPropsType {
		applicationKeys: string[]
	}

	const FormKerboodle: FC<FormKerboodlePropsType> = ({
														   webUrl,
														   platformLogin,
														   platformPassword,
														   applicationKeys
													   }) => {
		return (
			<form target="_blank" ref={kerboodleRef} method="post" action={webUrl}>
				<input hidden name="authenticity_token[0]" value="" />
				<input hidden name="user[login]" value={platformLogin} />
				<input hidden name="user[password]" value={platformPassword} />
				<input hidden name="authenticity_token[1]" value="" />
				<input hidden name="user[institution_code]" value={applicationKeys} />
				<a
					href="/"
					onClick={e => {
						e.preventDefault()
						kerboodleRef.current?.submit()
					}}
					target={'_blank'}
					rel="noopener noreferrer"
				>
					<Icon className={'is-user-icon-clickable'} icon={'fal fa-globe fa-2x'} color={'primary'} />
				</a>
			</form>
		)
	}

	interface WebUrlRendererPropsType extends FormKerboodlePropsType {
		applicationId: string
	}

	const WebUrlRenderer: FC<WebUrlRendererPropsType> = ({
															 applicationId,
															 webUrl,
															 platformLogin,
															 platformPassword,
															 applicationKeys
														 }) => {
		const formKerboodleProps = { webUrl, platformLogin, platformPassword, applicationKeys }
		const formLlsProps = { webUrl, platformLogin, platformPassword }
		switch (applicationId) {
			case 'KER':
				return <FormKerboodle {...formKerboodleProps} />
			case 'LLS':
				return <FormLls {...formLlsProps} />
			default:
				return (
					<a href={webUrl} target={'_blank'}
					   rel={applicationId === 'EUROPRESSE' ? 'noopener' : 'noopener noreferrer'}>
						<Icon className={'is-user-icon-clickable'} icon={'fal fa-globe fa-2x'} color={'primary'} />
					</a>
				)
		}
	}

	return (
		<Section className={'border rounded-lg flex flex-col justify-between p-4 user-app-box'} backgroundColor={isLightTheme ? '' : 'black-ter'}>
			<div>
				<Image src={picturePath} size={48} style={{ margin: '0 auto' }} />
				<p className={`${isLightTheme ? 'has-text-grey-darker' : 'has-text-grey-lighter'} has-text-centered my-2 text-sm`}>{applicationName}</p>
				{!!(urlSchemeLoginRequired && (!worksWithCas || edit)) && (
					<Form.Field>
						<Form.Control iconLeft size={'small'}>
							<Form.Input color={edit && isInvalidLogin ? 'danger' : ''}
										backgroundColor={isLightTheme ? '' : 'dark'} onChange={updateData}
										size={'small'} name={'platformLogin'} id={'-login'} readOnly={!edit}
										value={platformLogin} required={requiredFields.includes('login')} />
							<Icon icon={'fad fa-user-alt'} className={'is-small is-left'} />
							{edit && isInvalidLogin && (
								<Form.Help type={'invalid'} color={'danger'}>
									{translate('global.errors.invalid')}
								</Form.Help>
							)}
						</Form.Control>
					</Form.Field>
				)}
				{!!(urlSchemePwdRequired && (!worksWithCas || edit)) && (
					<Form.Field>
						<Form.Control iconLeft iconRight size={'small'}>
							<Form.Input backgroundColor={isLightTheme ? '' : 'dark'} onChange={updateData}
										size={'small'} name={'platformPassword'} id={'-password'} readOnly={!edit}
										type={showPassword ? 'text' : 'password'} value={platformPassword}
										required={requiredFields.includes('password')} />
							<Icon icon={'fad fa-fingerprint'} className={'is-small is-left'} />
							<Icon key={showPassword ? 'show' : 'hidden'} onClick={() => setShowPassword(!showPassword)}
								  className={'icon is-icon-clickable is-right'}
								  icon={showPassword ? 'far fa-eye-slash' : 'far fa-eye'} />
						</Form.Control>
					</Form.Field>
				)}
				{!!(platformCodeYesNo && (!worksWithCas || edit)) && (
					<Form.Field>
						<Form.Control iconLeft size={'small'}>
							<Form.Input backgroundColor={isLightTheme ? '' : 'dark'} size={'small'}
										name={'applicationKey'} readOnly type={'text'} value={applicationKeys} />
							<Icon icon={'fad fa-key'} className={'is-small is-left'} />
						</Form.Control>
					</Form.Field>
				)}
				{!!urlSchemeCodeYesNo && (
					<Form.Field>
						<Form.Control iconLeft size={'small'}>
							<Form.Input backgroundColor={isLightTheme ? '' : 'dark'} onChange={updateData}
										size={'small'} name={'applicationKeys'} id={'-keys'} readOnly={!edit}
										type={'text'} value={applicationKeys} required={urlSchemeCodeRequired} />
							<Icon icon={'fad fa-key'} className={'is-small is-left'} />
						</Form.Control>
					</Form.Field>
				)}
			</div>
			<section className="links w-full space-y-8 mt-4">
				<article className="grid grid-cols-2 gap-2">
					<figure className="image has-tooltip-arrow has-tooltip-top"
							data-tooltip={`iOS ${!appStore ? '(Non disponible)' : ''}`} style={{ margin: 'auto' }}>
						<a href={appStore} target={'_blank'} rel="noreferrer noopener">
							<img style={{ maxWidth: '128px' }} className={!appStore ? 'is-disabled' : ''}
								 src={appStoreImage} alt="AppStore URL to Tabuleo Connect" />
						</a>
					</figure>
					<figure className="image mx-auto has-tooltip-arrow has-tooltip-top"
							data-tooltip={`Android ${!playStore ? '(Non disponible)' : ''}`} style={{ margin: 'auto' }}>
						<a href={playStore} target={'_blank'} rel="noopener noreferrer">
							<img style={{ maxWidth: '128px' }} className={!playStore ? 'is-disabled' : ''}
								 src={playStoreImage} alt="PlayStore URL to Tabuleo Connect" />
						</a>
					</figure>
				</article>
				<article className="flex justify-evenly">
					<a href={exeLink} className="has-tooltip-arrow has-tooltip-top"
					   data-tooltip={`Windows ${!exeLink ? '(Non disponible)' : ''}`} rel="noopener noreferrer"
					   target={'_blank'}>
						<Icon className={`is-user-icon-clickable ${!exeLink && 'is-disabled'}`}
							  data-tooltip="Tooltip Text" icon={'fab fa-windows fa-2x'}
							  color={isLightTheme ? 'dark' : 'light'} />
					</a>
					<a href={dmgLink} className="has-tooltip-arrow has-tooltip-top"
					   data-tooltip={`MacOS ${!dmgLink ? '(Non disponible)' : ''}`} rel="noopener noreferrer"
					   target={'_blank'}>
						<Icon className={`is-user-icon-clickable ${!dmgLink && 'is-disabled'}`}
							  data-tooltip="Tooltip Text" icon={'fal fa-desktop-alt fa-2x'}
							  color={isLightTheme ? 'dark' : 'light'} />
					</a>
					<div className="has-tooltip-arrow has-tooltip-top"
						 data-tooltip={`En ligne ${!((webUrl && applicationId !== 'LLS') || platformValidToken === 'OK') ? '(Non disponible)' : ''}`}>
						{(webUrl && applicationId !== 'LLS') || platformValidToken === 'OK' ? (
							<WebUrlRenderer {...webUrlRendererProps} />
						) : (
							<span className="is-disabled">
									<Icon icon={'fal fa-globe fa-2x'} color={'danger'} />
								</span>
						)}
					</div>
				</article>
			</section>
		</Section>
	)
}
