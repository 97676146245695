import styled from 'styled-components'

// Defining default and dark colors
const colors = {
	default: {
		wrapperBackground: '#ffffff',
		optionColor: '#0099ff',
		selectedOptionBackground: '#0099ff',
		selectedOptionColor: '#ffffff',
		border: '#0099ff',
	},
	dark: {
		wrapperBackground: '#1d72aa',
		optionColor: '#D0D1CD',
		selectedOptionBackground: '#1d72aa',
		selectedOptionColor: '#EDF2F4',
	},
}

// Defining styled components
const WrapperDiv = styled.div`
	background-color: ${props => (props.dark ? colors.dark.wrapperBackground : colors.default.wrapperBackground)};
	border: 1px solid ${colors.default.border};
	border-radius: 5px;
	padding: 1rem;
	display: flex;
	margin-top: 3px;
	position: relative;
	width: ${props => (props.width !== undefined ? props.width : '100%')};
	> div {
		padding: 1em;
	}
`

const SmallWrapperDiv = styled(WrapperDiv)`
	display: inline-flex;
	border: 1px solid #d6dbde;
	> div {
		padding: 0.5em;
	}
`

const MiniWrapperDiv = styled(SmallWrapperDiv)`
	padding: 0;
	font-size: 0.8em;
	background-color: #ddeaf0;
`

const OptionWrapper = styled.div`
	color: ${props => (props.dark ? colors.dark.optionColor : colors.default.optionColor)};
	flex: 1 1 auto;
	text-align: center;
	cursor: pointer;
	border-radius: 5px;
	&:hover {
		background-color: ${props => (props.dark ? colors.dark.hoverBackgroundColor : colors.default.hoverBackgroundColor)};
	}
`

const SelectedOptionWrapper = styled(OptionWrapper)`
	background-color: ${props => (props.dark ? colors.dark.selectedOptionBackground : colors.default.selectedOptionBackground)};
	color: ${props => (props.dark ? colors.dark.selectedOptionColor : colors.default.selectedOptionColor)};
`

export { WrapperDiv, SmallWrapperDiv, MiniWrapperDiv, OptionWrapper, SelectedOptionWrapper }
