import React, {useContext, useEffect, useRef, useState} from 'react'
import {Heading} from 'react-bulma-components'
import Icon from '../utils/Icon'
import Hr from '../utils/Hr'
import GlobalContext from '../../contexts/GlobalContext'
import Query from '../../tools/Query'
// import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet'

/**
 * Latitute et longitude de Paris.
 * C'est l'emplacement par défaut au quel cas celui de l'établissement n'est pas trouvé.
 * @type {number[]}
 */
// const DEFAULT_LAT_AND_LON = [48.8534, 2.3488]

/**
 * Récupération des informations de contact grâce au WS Dolibarr.
 * @return {JSX.Element}
 * @constructor
 */
export default function SettingsContact() {
	const inputFileRef = useRef()
	const context = useContext(GlobalContext)
	const { isLightTheme, translate, school, addToast, shouldMount } = context
	const { uai, name } = school?.informations

	/**
	 * Détail des informations de contact de l'établissement.
	 */
	const [schoolContact, setSchoolContact] = useState(null)

	/**
	 * On récupère ces informations au premier chargement.
	 */
	useEffect(() => {
		if (schoolContact === null) {
			Query.getContact(context, uai)
				.then(setSchoolContact)
				.catch(() => setSchoolContact(false))
		}
	}, [schoolContact, context, uai])

	/**
	 * Envoie la nouvelle image d'app sur S3.
	 * @param filesEvent
	 */
	async function uploadNewImageApp(filesEvent) {
		const [file] = filesEvent.target.files

		const filename = uai

		//demande l'url d'upload
		const { url, fields } = await Query.getUploadFileURL(context, filename, true)
		try {
			// send file on s3
			await Query.uploadFile(context, url, fields, file)

			addToast('Le logo établissement a bien été envoyé.', { appearance: 'success' })

			addToast(translate('modals.specific.school.add_reload'), { appearance: 'warning' })
			setTimeout(() => {
				window.location.reload()
			}, 5000)
		} catch (e) {
			addToast(translate('global.errors.occurred'), { appearance: 'error' })
		}
	}

	return (
		<>
			<div className="is-flex" style={{ justifyContent: 'space-between' }}>
				<Heading textColor={!isLightTheme && 'light'} size={5} style={{ marginBottom: '0' }}>
					{translate('settings.contact.title')}
				</Heading>
			</div>
			<Hr />
			{schoolContact ? (
				<>
					<section className="flex flex-wrap w-full mb-4 place-items-start">
						<figure className="mr-4">
							<div className="w-64 h-32">
								{shouldMount('SettingsContact', 'Ecriture') && (
									<div className="cursor-pointer absolute z-20 flex justify-center w-64 h-32 place-items-center bg-gray-900 opacity-0 hover:opacity-100 bg-opacity-70" onClick={() => inputFileRef.current.click()}>
										<Icon icon={'far fa-upload'} color={'link'} />
									</div>
								)}
								<img src={`https://images-tabuleo.s3.eu-west-3.amazonaws.com/${uai}`} className="object-contain w-full h-full" alt={'Logo établissement manquant'} />
								{shouldMount('SettingsContact', 'Ecriture') && <input type={'file'} className="sr-only" ref={inputFileRef} onChange={uploadNewImageApp} />}
							</div>
							<figcaption className="sr-only">Logo établissement</figcaption>
						</figure>
						<header>
							<Heading size={4} textColor={!isLightTheme && 'grey-light'}>
								{name} <span className="is-size-6 has-text-grey">{uai}</span>
							</Heading>
							<Heading subtitle size={6}>
								{schoolContact.address}, {schoolContact.zip} {schoolContact.town} ({schoolContact.state})
							</Heading>
							<p>
								<Icon key={'icon-phone'} icon={'fal fa-phone'} className={'has-background-primary rounded has-text-light'} />
								<a href={`tel:${schoolContact.phone}`} className="has-text-link is-text-icon">
									{schoolContact.phone}
								</a>
							</p>
							<p>
								<Icon key={'icon-phone'} icon={'fal fa-globe'} className={'has-background-primary rounded has-text-light'} />
								{schoolContact.url ? (
									<a href={`${!schoolContact.url.includes('http') ? 'http://' : ''}${schoolContact.url}`} target={'_blank'} rel="noopener noreferrer" className="has-text-link is-text-icon">
										{schoolContact.url}
									</a>
								) : (
									<span className={'is-text-icon'}>Non renseigné</span>
								)}
							</p>
						</header>
					</section>
					<section className="flex flex-wrap w-full mb-4 -ml-2 flex-grow">
						{schoolContact.contacts.map((contact, key) => (
							<PersonalCardDetail key={key} user={contact} />
						))}
					</section>
					{/*<Map address={schoolContact.address} zip={schoolContact.zip} county={schoolContact.state} />*/}
				</>
			) : schoolContact === null ? (
				<Skeleton />
			) : (
				<p>{translate('settings.contact.not_in_bdd')}</p>
			)}
		</>
	)
}

/**
 * Squelette de chargement.
 * @return {JSX.Element}
 * @constructor
 */
function Skeleton() {
	const { isLightTheme } = useContext(GlobalContext)

	/**
	 * Couleurs de fond pendant le chargement.
	 * @type {{gray: (string), map: (string), primary: (string)}}
	 */
	const backgroundsColor = {
		gray: isLightTheme ? 'bg-gray-100' : 'bg-gray-800',
		primary: isLightTheme ? 'bg-purple-100' : 'bg-purple-800',
		map: isLightTheme ? 'bg-green-50' : 'bg-green-900',
	}

	return (
		<>
			<section className="flex w-full mb-4 place-items-start">
				<div className={`mr-4 w-32 h-32 ${backgroundsColor.gray} animate-pulse rounded-lg`} />
				<header>
					<div className="flex space-x-2 place-items-end mb-1">
						<div className={`w-64 h-8 ${backgroundsColor.gray} rounded-lg animate-pulse`} />
						<div className={`w-16 h-6 ${backgroundsColor.gray} rounded-lg animate-pulse`} />
					</div>

					<div className={`w-96 h-6 ${backgroundsColor.gray} rounded-lg mb-4 animate-pulse`} />
					<div className={`w-48 h-6 ${backgroundsColor.primary} rounded-lg mb-1 animate-pulse`} />
					<div className={`w-64 h-6 ${backgroundsColor.primary} rounded-lg animate-pulse`} />
				</header>
			</section>
			<section className="flex w-full mb-4">
				<article className="rounded-md mr-4 min-w-48 w-1/3">
					<div className={`h-48 ${backgroundsColor.gray} rounded animate-pulse`} />
				</article>
				<article className="rounded-md mr-4 min-w-48 w-1/3">
					<div className={`h-48 ${backgroundsColor.gray} rounded animate-pulse`} />
				</article>
				<article className="rounded-md min-w-48 w-1/3">
					<div className={`h-48 ${backgroundsColor.gray} rounded animate-pulse`} />
				</article>
			</section>
			<div className={`w-full h-96 ${backgroundsColor.map} rounded-lg animate-pulse`} />
		</>
	)
}

/**
 * Carte affichée pour chaque contact de l'établissement.
 * @param firstname Prénom
 * @param lastname Nom
 * @param poste Poste au sein de l'établissement
 * @param email Email
 * @param phone_pro Numéro de téléphone
 * @return {JSX.Element}
 * @constructor
 */
function PersonalCardDetail({ user: { firstname, lastname, poste, email, phone_pro } }) {
	const { isLightTheme } = useContext(GlobalContext)

	return (
		<article className="w-full lg:w-1/2 xl:w-1/3 mb-4">
			<div className={`border rounded-md shadow-md mx-2 ${isLightTheme ? '' : 'border-gray-500'}`}>
				<div className="p-4">
					<header className="has-text-grey-darker flex place-items-center">
						<figure className={'has-background-primary rounded-full mr-2 w-8 h-8 flex place-items-center justify-center'}>
							<Icon icon={'fas fa-user-tie'} color={'light'} />
						</figure>
						<div>
							<p className={isLightTheme ? '' : 'has-text-grey-light'}>
								{firstname} <strong className={isLightTheme ? '' : 'has-text-grey-light'}>{lastname}</strong>
							</p>
							<p className={'has-text-grey is-size-7'}>{poste || 'Non renseigné'}</p>
						</div>
					</header>
				</div>
				<footer className={`is-size-7 border-t p-2 flex flex-col ${isLightTheme ? '' : 'border-gray-500'}`}>
					<span className="has-text-grey">
						<Icon icon={'fas fa-mobile'} />
						<span className="pl-1">{phone_pro || 'Non renseigné'}</span>
					</span>
					<a href={`mailto:${email}`} className="has-text-link">
						<Icon icon={'fas fa-at'} />
						<span className="pl-1">{email || 'Non renseigné'}</span>
					</a>
				</footer>
			</div>
		</article>
	)
}

/**
 * Géo-localise l'établissement grâce à son adresse.
 * Si l'API trouve plusieurs localisations, elles sont affichées dans un select.
 * @param address
 * @param zip
 * @param county
 * @return {JSX.Element}
 * @constructor
 */
// function Map({ address, zip, county }) {
// 	const context = useContext(GlobalContext)
// 	const { isLightTheme } = context
//
// 	const [places, setPlaces] = useState([])
//
// 	const [geoPos, setGeoPos] = useState(null)
//
// 	useEffect(() => {
// 		if (!geoPos) {
// 			Query.getLatAndLong(context, { address, county, zip }).then(foundPlaces => {
// 				if (foundPlaces.length > 0) {
// 					const { lat, lon } = foundPlaces[0]
//
// 					setPlaces(foundPlaces)
// 					setGeoPos([Number(lat), Number(lon)])
// 				}
// 			})
// 		}
// 	})
//
// 	return (
// 		<>
// 			<Form.Field>
// 				<Form.Label textColor={isLightTheme ? '' : 'grey'}>{places.length} emplacements trouvés</Form.Label>
// 				<Form.Control>
// 					<div className="select is-fullwidth">
// 						<select
// 							className={isLightTheme ? '' : 'has-background-dark'}
// 							name=""
// 							id=""
// 							onChange={e => {
// 								const [lat, lon] = e.target.value.split(',')
//
// 								setGeoPos([Number(lat), Number(lon)])
// 							}}
// 						>
// 							{places.map(place => (
// 								<option value={`${place?.lat},${place?.lon}`}>{place?.display_name}</option>
// 							))}
// 						</select>
// 					</div>
// 				</Form.Control>
// 			</Form.Field>
// 			<MapContainer center={[0, 0]} zoom={13} scrollWheelZoom={true} className={'w-full h-96'}>
// 				<ChangeMapPos geoPos={geoPos} />
// 				<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
// 			</MapContainer>
// 		</>
// 	)
// }

/**
 * Utilisateur du hook UseMap de react-leaflet pour changer
 * la position du marqueur et de la carte.
 * @param geoPos
 * @return {JSX.Element}
 * @constructor
 */
// function ChangeMapPos({ geoPos }) {
// 	const map = useMap()
//
// 	map.setView(geoPos ?? DEFAULT_LAT_AND_LON, geoPos ? 13 : 8)
//
// 	return <Marker position={geoPos ?? DEFAULT_LAT_AND_LON} />
// }
