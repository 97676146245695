import React from 'react'
import { Modal, Heading, Button } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import GlobalContext from '../../../contexts/GlobalContext'
import Hr from '../../utils/Hr'

/**
 * Affiche un rapport d'erreur dans une fenêtre modale.
 */
export default class Error extends React.Component {
	static contextType = GlobalContext

	constructor(props) {
		super(props)
		this.name = 'error'
	}

	render() {
		const { translate, isLightTheme } = this.context
		const { onClose, text, show } = this.props

		return (
			<Modal className={!isLightTheme ? 'is-dark' : ''} show={show} showClose onClose={() => onClose(this.name)} closeOnBlur>
				<Modal.Card className={'fade-in-bottom'}>
					<Modal.Card.Body>
						<header className="is-flex" style={{ justifyContent: 'space-between' }}>
							<Heading size={5}>{translate('modals.generic.error_title')}</Heading>
							<Button color={'light'} onClick={() => onClose(this.name)}>
								<Icon icon={'fal fa-times fa-2x'} />
							</Button>
						</header>
						<Hr />
						<article dangerouslySetInnerHTML={{ __html: text }} />
						<br />
						<div className="is-flex" style={{ justifyContent: 'right' }}>
							<Button color={'primary'} onClick={() => onClose(this.name)}>
								{translate('global.close')}
							</Button>
						</div>
					</Modal.Card.Body>
				</Modal.Card>
			</Modal>
		)
	}
}
