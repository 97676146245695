import React, { useContext, useState } from 'react'
import { Heading, Menu, Columns, Box, Section } from 'react-bulma-components'
import Icon from './utils/Icon'
import GlobalContext from '../contexts/GlobalContext'
import Hr from './utils/Hr'
import GlobalNotifications from './notifications/global/GlobalNotifications'
import GlobalHistorical from './notifications/global/GlobalHistorical'

function Notifications({page}) {
	const context = useContext(GlobalContext)
	const { translate, isLightTheme } = context

	const [view, setView] = useState(page)

	const menu = [
		{
			name: 'Nouvelle notification',
			icon: 'fal fa-bullhorn',
			value: 'new',
		},
		{
			name: translate('notifications.menu.historical'),
			icon: 'fal fa-repeat',
			value: 'historique',
		}
	]

	const views = {
		new: <GlobalNotifications changeView={() => setView('historique')} />,
		historique: <GlobalHistorical />
	}

	return (
		<Section style={{ height: 'calc(100vh - 3.5rem)', overflowY: 'auto' }}>
			<Heading textColor={!isLightTheme ? 'light' : ''} className={'fade-in-right'}>
				{translate('notifications.title')}
			</Heading>
			<Hr />
			<Columns>
				<Columns.Column className={'is-narrow is-one-quarter-widescreen'}>
					<Box backgroundColor={!isLightTheme ? 'black' : ''} className={'fade-in-bottom'} style={{ animationDelay: '50ms' }}>
						<Menu className={'params-menu'}>
							<Menu.List>
								{menu.map(({ value, name, icon }, idx) => (
									<Menu.List.Item key={idx} active={view === value} onClick={() => setView(value)}>
										<Icon icon={icon} />
										<span className="is-text-icon">{name}</span>
									</Menu.List.Item>
								))}
							</Menu.List>
						</Menu>
					</Box>
				</Columns.Column>
				<Columns.Column>
					<Box backgroundColor={!isLightTheme ? 'black' : ''} className={'fade-in-bottom'} style={{ animationDelay: '100ms' }}>
						{views[view]}
					</Box>
				</Columns.Column>
			</Columns>
		</Section>
	)
}

export default Notifications
