import React, { Fragment } from 'react'
import { Modal, Form, Heading, Columns, Button } from 'react-bulma-components'
import GlobalContext from '../../../contexts/GlobalContext'
import { Formik } from 'formik'
import * as yup from 'yup'
import Query from '../../../tools/Query'
import Required from '../../utils/Required'
import * as PropTypes from 'prop-types'

export default class GridAddApp extends React.Component {
	static contextType = GlobalContext

	static propTypes = {
		/**
		 * L'identifiant de l'application
		 */
		applicationID: PropTypes.string,
		/**
		 * L'application à afficher dans le modal
		 */
		application: PropTypes.object,
		/**
		 * L'utilisateur à modifier
		 */
		user: PropTypes.object,
	}

	constructor(props) {
		super(props)
		this.name = 'gridAddApp'

		this.state = {
			requiredFields: this.setRequiredFields(props.application),
		}
	}

	componentDidMount() {
		this.initRequiredFields()
	}

	setRequiredFields = ({ urlSchemeLoginRequired, urlSchemePwdRequired, urlSchemeCodeRequired }) => {
		const tab = []

		urlSchemeLoginRequired && tab.push('login')
		urlSchemePwdRequired && tab.push('password')
		urlSchemeCodeRequired && tab.push('codes')

		return tab
	}

	initRequiredFields = () => {
		const validationSchema = {}
		const requiredFields = this.state.requiredFields

		requiredFields.forEach(field => (validationSchema[field] = yup.string().required(`Le ${field.toLowerCase()} est requis`)))

		return yup.object(validationSchema)
	}

	onSubmit = ({ login, password, codes }) => {
		const { application, user, applicationID, onClose, updateCell } = this.props
		const { translate, addToast, refresh } = this.context

		let applicationData = {
			informations: {
				idApplication: applicationID,
				passwordPlateforme: password,
				loginPlateforme: login,
				status: 1,
			}
		}

		if (codes) {
			applicationData.keys = [codes]
		}

		Query.updateUserInGrid(this.context, user['ID utilisateur'], 'applications', applicationData)
			.then(res => {
				addToast(`L'application ${application.name} a bien été activé.`, { appearance: 'success' })
				updateCell()
				onClose(this.name)
				refresh()
			})
			.catch(err => {
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
			})
	}

	render() {
		const { translate, isLightTheme } = this.context
		const { show, application, items, reset, onClose, user } = this.props

		return (
			<Modal className={`is-large ${!isLightTheme && 'is-dark'}`} show={show}>
				<Modal.Card className={'fade-in-bottom'}>
					<Modal.Card.Body>
						<Heading>Ajout des identifiants pour {application.name}</Heading>
						<Formik enableReinitialize onSubmit={this.onSubmit} validationSchema={this.initRequiredFields()} initialValues={items}>
							{({ handleSubmit, handleChange, values, errors }) => (
								<form onSubmit={handleSubmit}>
									<Columns>
										<Columns.Column size={'one-quarter'}>
											<p>{translate('global.user')}</p>
										</Columns.Column>
										<Columns.Column size={'one-quarter'}>
											<p>
												{translate('global.login')}
												{application.urlSchemeLoginRequired && <Required />}
											</p>
										</Columns.Column>
										<Columns.Column size={'one-quarter'}>
											<p>
												{translate('global.password')}
												{application.urlSchemePwdRequired && <Required />}
											</p>
										</Columns.Column>
										<Columns.Column size={'one-quarter'}>
											<p>
												{translate('global.code')}
												{application.urlSchemeCodeRequired && <Required />}
											</p>
										</Columns.Column>
										<Fragment>
											<Columns.Column size={'one-quarter'}>
												<p>
													{user.Nom} {user.Prénom}
												</p>
											</Columns.Column>
											<Columns.Column size={'one-quarter'}>
												<Form.Field>
													<Form.Control>
														<Form.Input type={'text'} name={'login'} value={values.login} required={application.urlSchemeLoginRequired} onChange={handleChange} />
													</Form.Control>
												</Form.Field>
											</Columns.Column>
											<Columns.Column size={'one-quarter'}>
												<Form.Field>
													<Form.Control>
														<Form.Input type={'text'} name={'password'} value={values.password} required={application.urlSchemePwdRequired} onChange={handleChange} />
													</Form.Control>
												</Form.Field>
											</Columns.Column>
											<Columns.Column size={'one-quarter'}>
												<Form.Field>
													<Form.Control>
														<Form.Input type={'text'} name={'codes'} value={values.codes} required={application.urlSchemeCodeRequired} onChange={handleChange} />
													</Form.Control>
												</Form.Field>
											</Columns.Column>
										</Fragment>
									</Columns>
									<br />
									<div className="is-flex is-fixed" style={{ justifyContent: 'space-between' }}>
										<Button
											color={'danger'}
											type={'reset'}
											onClick={() => {
												reset()
												onClose(this.name)
											}}
										>
											{translate('global.cancel')}
										</Button>
										<Button color={'primary'} type={'submit'} loading={this.state.loading}>
											{translate('global.confirm')}
										</Button>
									</div>
								</form>
							)}
						</Formik>
					</Modal.Card.Body>
				</Modal.Card>
			</Modal>
		)
	}
}
