import React, { useCallback, useContext, useEffect, useState } from 'react'
import GlobalContext from '../../../contexts/GlobalContext'
// @ts-ignore
import { Button, Form, Heading, Tag } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import Query from '../../../tools/Query'
import TimeAgo from 'javascript-time-ago'
import fr from 'javascript-time-ago/locale/fr'

interface TaskRowProps {
	task: Task
	fetchTasks: () => void
}

const TaskRow = ({ task, fetchTasks }: TaskRowProps) => {
	const context = useContext(GlobalContext)
	const { showModal, addToast, translate } = context

	TimeAgo.addDefaultLocale(fr)

	const timeAgo = new TimeAgo('en-US')

	const confirmDeletion = () => {
		showModal('confirmAction', true, {
			title: 'Suppression',
			content: 'Êtes-vous sûr de vouloir supprimer cette tâche ?',
			action: () => {
				Query.deleteTokenScheduledTask(context, task.eventId)
					.then(() => {
						addToast('Tâche supprimée avec succès', 'success')
						fetchTasks()
					})
					.catch(() => {
						addToast('Une erreur est survenue lors de la suppression de la tâche', 'danger')
					})
			},
		})
	}

	const getFormattedNextExecutionDate = (task: Task) => {
		// If the task has never been executed, we display the start date instead
		if (task.lastExecution == null) {
			return timeAgo.format(new Date(task.startDate))
		}

		const lastExecution = new Date(task.lastExecution)
		let nextExecutionTime = 0
		const MILLISECONDS_IN_MINUTE = 60000
		const MILLISECONDS_IN_DAY = 86400000
		const MILLISECONDS_IN_MONTH = 2629746000
		const MILLISECONDS_IN_YEAR = 31556952000
		switch (task.intervalField) {
			case 'MINUTE':
				nextExecutionTime = task.intervalValue * MILLISECONDS_IN_MINUTE
				break
			case 'DAY':
				nextExecutionTime = task.intervalValue * MILLISECONDS_IN_DAY
				break
			case 'WEEK':
				nextExecutionTime = task.intervalValue * MILLISECONDS_IN_DAY * 7
				break
			case 'MONTH':
				nextExecutionTime = task.intervalValue * MILLISECONDS_IN_MONTH
				break
			case 'YEAR':
				nextExecutionTime = task.intervalValue * MILLISECONDS_IN_YEAR
				break
			default:
				return ''
		}

		return timeAgo.format(new Date(lastExecution.getTime() + nextExecutionTime))
	}

	return (
		<tr className={'h-12 border border-grey-200'}>
			<td className={'pl-4 pr-2 py-4 whitespace-nowrap'}>
				<p className={'text-ellipsis overflow-hidden'}>{task.eventName}</p>
			</td>
			<td className={'py-4'}>
				<div>{task.enable == 1 ? 'ACTIVE' : 'INACTIVE'}</div>
			</td>
			<td className={'py-4'}>
				<div>{task.createdByFullName}</div>
			</td>
			<td className={'py-4'}>
				<div>{task.lastExecution && timeAgo.format(new Date(task.lastExecution))}</div>
			</td>
			<td className={'py-4'}>
				<div>{task.enable == 1 && getFormattedNextExecutionDate(task)}</div>
			</td>
			<td className={'py-4'}>
				<div id={'schools-tag'}>
					{task.procedureParameters.arrayUai.length == 1 && <Tag color={'dark'}> {task.procedureParameters.arrayUai[0] === 'all' ? 'Tous les établissements' : task.procedureParameters.arrayUai[0]} </Tag>}
					{task.procedureParameters.arrayUai.length > 1 && <Tag color={'dark'}>{task.procedureParameters.arrayUai.length} établissements</Tag>}
				</div>
			</td>
			<td className={'py-4 space-x-2'}>
				<button
					onClick={() =>
						showModal('editTask', true, {
							task,
							fetchTasks,
						})
					}
				>
					<Icon icon={'far fa-edit'} />
				</button>
				<button onClick={confirmDeletion}>
					<Icon icon={`has-text-danger fas fa-trash-alt`} />
				</button>
			</td>
		</tr>
	)
}

type Task = {
	enable: number
	startDate: string
	endDate: string
	eventId: number
	eventName: string
	createdDate: string
	updatedDate: string
	intervalField: 'YEAR' | 'MONTH' | 'DAY' | 'WEEK' | 'MINUTE'
	intervalValue: number
	lastExecution: string
	procedureName: string
	createdByFullName: string
	updatedByFullName: string
	procedureParameters: {
		arrayUai: string[]
		arrayPlatforms: string[]
	}
}

/**
 * Configuration des champs globaux lié à Tabuléo.
 * @returns {JSX.Element}
 * @constructor
 */
const GlobalTokens = () => {
	const context = useContext(GlobalContext)
	const { showModal, addToast, translate } = context
	const [loading, setLoading] = useState(true)
	const [tasks, setTasks] = useState<Task[]>([])

	const fetchTasks = async () => {
		setLoading(true)
		const tasks = ((await Query.getTokensScheduledTask(context)) as Task[]) || []
		setTasks([...tasks])
		setLoading(false)
	}

	useEffect(() => {
		fetchTasks()
	}, [])

	return (
		<div>
			<header className="flex justify-between pt-2 pb-4">
				<Heading size={5}>Paramètres purge </Heading>
				<div className={'flex space-x-4'}>
					<Button
						color={'primary'}
						onClick={() => {
							showModal('createTask', true, { fetchTasks })
						}}
					>
						<Icon icon={'fal fa-plus'} />
					</Button>
					<Button color={'primary'} onClick={fetchTasks}>
						<Icon icon={'fa fa-sync-alt'} />
					</Button>
				</div>
			</header>
			<section className="flex flex-wrap">
				<table className={'w-full table-auto'}>
					<thead className={'mb-2 border-b-2 border-gray-300 bg-gray-100'}>
						<tr className={'h-12'}>
							<td className={'auto-cols-auto font-bold text-black py-4 pl-2'}>Libellé</td>
							<td className={'auto-cols-auto font-bold text-black py-4'}>Statut</td>
							<td className={'auto-cols-auto font-bold text-black py-4'}>Auteur</td>
							<td className={'auto-cols-auto font-bold text-black py-4'}>Dernière exécution</td>
							<td className={'auto-cols-auto font-bold text-black py-4'}>Prochaine exécution</td>
							<td className={'auto-cols-auto font-bold text-black py-4'}>Établissements</td>
							<td className={'auto-cols-auto font-bold text-black py-4'}>Actions</td>
						</tr>
					</thead>
					{loading && <span className={'text-center w-full absolute'}>Chargement...</span>}
					<tbody>
						{tasks.map(task => (
							<TaskRow key={task.eventId} task={task} fetchTasks={fetchTasks} />
						))}
					</tbody>
				</table>
			</section>
		</div>
	)
}

export default GlobalTokens
