import React from 'react'
import { Box, Columns, Menu } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import UserProfile from './UserProfile'
import { Manuals } from './Manuals'
import GlobalContext from '../../../contexts/GlobalContext'
import { UserDevices } from './UserDevices'
import UserDocuments from './UserDocuments'
import { UserTeaching } from './UserTeaching'

export default class UserMenu extends React.Component {
	static contextType = GlobalContext

	constructor(props, context) {
		super(props)
		this.state = {
			view: 'profile'
		}

		const { translate } = context

		this.menu = [
			{
				rights: 'UserPage_Profile',
				value: 'profile',
				icon: 'fad fa-user-circle',
				name: translate('global.profile')
			},
			{
				rights: 'UserPage_Apps',
				value: 'manuals',
				icon: 'fad fa-atom',
				name: translate('user.my_apps')
			},
			{
				rights: 'UserPage_Teaching',
				value: 'teaching',
				icon: 'fad fa-award',
				name: translate('user.teaching')
			},
			{
				rights: 'UserPage_Devices',
				value: 'devices',
				icon: 'fad fa-tablet-rugged',
				name: translate('user.devices')
			},
			{
				rights: 'UserPage_Documents',
				value: 'docs',
				icon: 'fad fa-folders',
				name: translate('user.documents')
			},
		]
	}

	render() {
		const { shouldMount } = this.context
		const views = {
			profile: <UserProfile />,
			manuals: <Manuals />,
			devices: <UserDevices />,
			teaching: <UserTeaching />,
			docs: <UserDocuments />
		}

		return (
			<Columns mobile multiline className="pt-4">
				<Columns.Column size={'one-quarter'}>
					<Box>
						<Menu className={'params-menu'}>
							<Menu.List>
								{this.menu.map(
									(sub, idx) =>
										shouldMount(sub.rights, 'Lecture') && (
											<Menu.List.Item key={idx} active={sub.value === this.state.view}
															onClick={() => this.setState({ view: sub.value })}>
												<Icon icon={sub.icon} />
												<span className="is-text-icon">{sub.name}</span>
											</Menu.List.Item>
										)
								)}
							</Menu.List>
						</Menu>
					</Box>
				</Columns.Column>
				<Columns.Column size={'three-quarter'}>{views[this.state.view]}</Columns.Column>
			</Columns>
		)
	}
}
