import React from 'react'
import AddSchoolModal from './specific/AddSchool'
import FindUser from './specific/FindUser'
import Confirm from './generic/Confirm'
import GlobalContext from '../../contexts/GlobalContext'
import StandardEdit from './generic/StandardEdit'
import BulkOptions from './specific/BulkOptions'
import BulkApps from './specific/BulkApps'
import Error from './generic/Error'
import UnitaryAdd from './specific/UnitaryAdd'
import UserPreferences from './specific/UserPreferences'
import GridAddApp from './specific/GridAddApp'
import FileManager from './generic/FileManager'
import BulkDocs from './specific/BulkDocs'
import SendNotification from './specific/SendNotification'
import { UnitaryAddApps } from './specific/UnitaryAddApps'
import { ScheduledTaskCreate } from './specific/ScheduledTaskCreate'
import { ScheduledTaskEdit } from './specific/ScheduledTaskEdit'
import BulkMails from './specific/BulkMails'
import UserAddTeaching from './specific/UserAddTeaching'

/**
 * Gestionnaire des fenêtres modals.
 * Si vous souhaitez créer une nouvelle modale, créer la dans le dossier :
 * 	- generic = si elle peut être utilisée par plusieurs composants
 * 	- specific = si elle a une utilisation précise.
 *
 * 	Il faut ensuite l'importer ici et lui passer ses props comme fait avec les autre modals.
 */
export default class Modals extends React.Component {
	static contextType = GlobalContext

	/**
	 * Ferme une fenêtre
	 */
	closeModal = modalName => {
		this.context.showModal(modalName, false)
	}

	render() {
		const { modals } = this.context

		return (
			<>
				{/* Généric */}
				{modals.confirmAction && <Confirm show={modals.confirmAction} onClose={this.closeModal} content={modals.content} action={modals.action} />}
				{modals.standardEdit && <StandardEdit show={modals.standardEdit} onClose={this.closeModal} bulkAction={modals.bulkAction} />}
				{modals.error && <Error show={modals.error} onClose={this.closeModal} text={modals.text} />}
				{modals.filesManager && <FileManager show={modals.filesManager} onClose={this.closeModal} {...modals} />}

				{/* Spécific */}
				{modals.gridAddApp && <GridAddApp show={modals.gridAddApp} onClose={this.closeModal} {...modals} />}
				{modals.userPreferences && <UserPreferences show={modals.userPreferences} onClose={this.closeModal} />}
				{modals.unitaryAddApps && <UnitaryAddApps show={modals.unitaryAddApps} onClose={this.closeModal} {...modals} />}
				{modals.unitaryAdd && <UnitaryAdd show={modals.unitaryAdd} onClose={this.closeModal} type={modals.type} changeType={modals.changeType} />}
				{modals.bulkApps && <BulkApps show={modals.bulkApps} onClose={this.closeModal} />}
				{modals.bulkDocs && <BulkDocs show={modals.bulkDocs} onClose={this.closeModal} {...modals} />}
				{modals.userAddTeaching && <UserAddTeaching show={modals.userAddTeaching} onClose={this.closeModal} {...modals} />}
				{modals.bulkOptions && <BulkOptions show={modals.bulkOptions} onClose={this.closeModal} />}
				{modals.bulkMails && <BulkMails show={modals.bulkMails} onClose={this.closeModal} />}
				{modals.addSchool && <AddSchoolModal show={modals.addSchool} onClose={this.closeModal} />}
				{modals.findUser && <FindUser show={modals.findUser} onClose={this.closeModal} users={modals.users} />}
				{modals.sendNotification && <SendNotification show={modals.sendNotification} onClose={this.closeModal} />}
				{modals.createTask && <ScheduledTaskCreate show={modals.createTask} onClose={this.closeModal} fetchTasks={modals.fetchTasks} />}
				{modals.editTask && <ScheduledTaskEdit show={modals.editTask} onClose={this.closeModal} task={modals.task} fetchTasks={modals.fetchTasks} />}
			</>
		)
	}
}
