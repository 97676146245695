import React, { useContext, useEffect, useState } from 'react'
import GlobalContext from '../../../contexts/GlobalContext'
// @ts-ignore
import { Button, Content, Form, Heading } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import Queries from '../../../tools/Query'
import { Historical, Profile, School, Notification, Operator } from './types'

const GlobalHistorical = () => {
	const context = useContext(GlobalContext)
	const { schools } = context

	const [error, setError] = useState(null)
	const [expandedNotificationId, setExpandedNotificationId] = useState<number | null>(null)
	const [dataNotifications, setDataNotifications] = useState<Notification[]>([])
	const [dataProfil, setDataProfil] = useState<Profile[]>([])
	const [more, setMore] = useState(20)

	useEffect(() => {
		Queries.getHistoriqueNotification()
			.then((res: Historical) => {
				if (res && Array.isArray(res.notifications)) {
					setDataNotifications(res.notifications)
				} else {
					console.error("Impossible de récupérer l'historique")
				}
			})
			.catch(err => {
				console.error('Error fetching notifications:', err)
				setError(err)
			})
		Queries.getUsersProfile(context)
			.then(res => {
				// @ts-ignore
				setDataProfil(res)
			})
			.catch(err => {
				console.error(err)
			})
	}, [])

	const toggleNotification = (id: number) => {
		setExpandedNotificationId(expandedNotificationId === id ? null : id)
	}

	return (
		<>
			<header className="mb-8">
				<Heading size={5}>Historique des notifications</Heading>
			</header>
			<Content>
				{dataNotifications.length === 0 ? (
					<p>Aucune notification envoyée</p>
				) : (
					dataNotifications.slice(0, more).map((notif, key) => (
						<button key={key} className="even:bg-gray-50 w-full cursor-pointer" onClick={() => toggleNotification(notif.idNotification)}>
							<div className="px-4 py-2 rounded-lg hover:bg-gray-100">
								<div className="flex justify-between">
									<p className="font-bold text-gray-800">{notif.labelNotification}</p>
									<div className="flex space-x-4">
										<p className="text-sm">
											De {notif.createdBy} le {notif.createdDate}
										</p>
										<Icon key={expandedNotificationId === notif.idNotification ? 'down' : 'up'} icon={expandedNotificationId === notif.idNotification ? 'fal fa-chevron-down' : 'fal fa-chevron-right'} />
									</div>
								</div>

								<div className={`overflow-hidden text-left transition-all duration-500 ${expandedNotificationId === notif.idNotification ? 'max-h-full' : 'max-h-0'}`}>
									{expandedNotificationId === notif.idNotification && notif.parametersNotification && (
										<div>
											<div className="my-2 space-y-1">
												<strong>Description : </strong>
												<p>{notif.descriptionNotification}</p>
											</div>
											<p className="mt-5">
												<strong>Lien : </strong> {notif.linkNotification ? notif.linkNotification : <span>Aucun lien</span>}
											</p>
											{notif.parametersNotification.arrayUai && (
												<div className="mt-6">
													<h4 className="underline">Filtres : </h4>
													<p>
														<strong>UAI :</strong> {notif.parametersNotification.arrayUai.join(', ')}
													</p>
													<p>
														<strong>Etablissement(s) associé(s) :</strong>{' '}
														{schools
															.filter((s: School) => notif.parametersNotification.arrayUai.includes(s.UAI))
															.map((n: School) => n.Nom)
															.join(', ')}
													</p>
													<div className="grid grid-rows-2 grid-flow-col mt-6">
														<div className="flex flex-col row-span-2">
															{notif.parametersNotification.subject ? (
																<div className="">
																	<p>
																		<strong>Matière(s) :</strong> {notif.parametersNotification.subject.map(mat => mat.terms).join(', ')}
																	</p>
																	<p>
																		<strong>Opérateur logique :</strong> {notif.parametersNotification.subject.map(mat => mat.logicalOperator).join(', ')}
																	</p>
																	<p>
																		<strong>Opérateur de recherche :</strong> {notif.parametersNotification.subject.map(mat => mat.searchOperator).join(', ')}
																	</p>
																	<p>
																		<strong>Profil(s) :</strong>{' '}
																		{notif.parametersNotification.arrayProfiles
																			.map(profileId => {
																				const profile = dataProfil.find(p => p.IDprofil === profileId)
																				return profile ? profile.Nom : profileId
																			})
																			.join(', ')}
																	</p>
																	<p>
																		<strong>Application(s) :</strong> {notif.parametersNotification.arrayApplications.join(', ')}
																	</p>
																</div>
															) : (
																<div>
																	<strong>Matière(s) :</strong> Aucun filtre pour les matières
																	<p>
																		<strong>Profil(s) :</strong>{' '}
																		{notif.parametersNotification.arrayProfiles
																			.map(profileId => {
																				const profile = dataProfil.find(p => p.IDprofil === profileId)
																				return profile ? profile.Nom : profileId
																			})
																			.join(', ')}
																	</p>
																	<p>
																		<strong>Application(s) :</strong> {notif.parametersNotification.arrayApplications.join(', ')}
																	</p>
																</div>
															)}
														</div>
														{notif.reportNotification != null && (
															<div>
																<p>
																	<strong>Utilisateur(s) trouvé(s) :</strong> {notif.reportNotification.nbUser === null ? <span>Aucun utilisateur</span> : notif.reportNotification.nbUser}
																</p>
																<p>
																	<strong>Appareil(s) impacté(s) :</strong> {notif.reportNotification.nbToken === null ? <span>Aucun appareil</span> : notif.reportNotification.nbToken}
																</p>
																<p>
																	<strong>Notifications envoyées avec succès :</strong> {notif.reportNotification.successCount === null ? <span>Aucun utilisateur</span> : notif.reportNotification.successCount}
																</p>
																<p>
																	<strong>Notifications ayant échoué :</strong> {notif.reportNotification.failureCount === null ? <span>Aucun appareil</span> : notif.reportNotification.failureCount}
																</p>
															</div>
														)}
													</div>
												</div>
											)}
										</div>
									)}
								</div>
							</div>
						</button>
					))
				)}
			</Content>
			<div className="flex">
				<Button color={'primary'} className="ml-auto" onClick={() => setMore(more + 10)}>
					Afficher plus
				</Button>
			</div>
		</>
	)
}

export default GlobalHistorical
