import React, { FC, useContext } from 'react'
// @ts-ignore
import { Menu, Tag } from 'react-bulma-components'
import Icon from '../utils/Icon'
import GlobalContext from '../../contexts/GlobalContext'

const SideMenu: FC<any> = () => {
	const context = useContext(GlobalContext)
	const { translate, apiVersion, shouldMount, view, changeView, school, user, addToast } = context

	const uai = school?.informations?.uai

	const tabuleoTabs = [
		/**
		 * Vue globale : patch note, sync lib ...
		 */
		{
			icon: 'fad fa-telescope',
			name: translate('sidebar.overview'),
			value: 'overview',
			rights: 'Overview',
		},
		/**
		 * Suivi des établissements
		 */
		{
			icon: 'fad fa-tasks-alt',
			name: translate('sidebar.global_monitoring'),
			value: 'global_monitoring',
			rights: 'GlobalMonitoring',
		},
		/**
		 * Configuration de différents paramètres globaux
		 */
		{
			icon: 'fad fa-tools',
			name: 'Configuration',
			value: 'config',
			rights: 'Overview',
		},
		/**
		 * Centre de notifications
		 */
		{
			icon: 'fad fa-bell',
			name: 'Notifications',
			value: 'notifications',
			rights: 'notifications',
		},
	]
	const tabs = [
		/**
		 * Grille avec la liste des utilisateurs.
		 */
		{
			icon: 'fad fa-table',
			name: translate('sidebar.users'),
			value: 'users',
			rights: 'Table',
		},
		/**
		 * Dashboard avec les statistique sur l'établissement.
		 */
		{
			icon: 'fad fa-chart-network',
			name: translate('sidebar.dashboard'),
			value: 'dashboard',
			rights: 'Dashboard',
		},
		/**
		 * Suivi de l'établissement (prépartion en atelier ...)
		 */
		{
			icon: 'fad fa-tasks',
			name: translate('sidebar.monitoring'),
			value: 'monitoring',
			rights: 'Monitoring',
		},
		/**
		 * Paramètres de l'établissements (va être remonté au niveau de Tabuleo).
		 */
		{
			icon: 'fad fa-cogs',
			name: translate('sidebar.settings'),
			value: 'params',
			rights: 'ParamEtablissement',
		},
	]
	const utilitariesTabs = [
		/***
		 * Gestion des documents
		 */
		{
			icon: 'fad fa-folder-tree',
			name: translate('sidebar.document_manager'),
			value: 'document_manager',
			rights: 'FileManager',
		},
		/**
		 * Effectuer un import en masse d'utilisateur pour un établissement.
		 */
		{
			icon: 'fad fa-file-import',
			name: translate('sidebar.import'),
			value: 'import',
			rights: 'ImportModal',
		},
	]

	const envs: Record<string, string> = {
		'pra.tabuleo.fr': 'pré-production',
		'desktest.tabuleo.fr': 'test',
	}

	function downloadHelpFile() {
		const a = document.createElement('a')
		a.href = user.profile === 'Gestionnaire' ? 'https://confluence.external-share.com/content/72513/manager_gestionnaire' : 'https://confluence.external-share.com/content/72512/manager_admin_etablissement'
		a.target = '_blank'
		a.click()
	}

	return (
		<Menu>
			<Menu.List>
				{/* TABULEO */}
				{shouldMount('adminTabs', 'Lecture') && <Menu.List title={'Tabuleo'} />}
				{tabuleoTabs.map(
					(tab, idx) =>
						shouldMount(tab.rights, 'Lecture') && (
							<Menu.List.Item
								key={idx}
								active={view === tab.value}
								onClick={() => changeView(tab.value)}
								children={
									<>
										<Icon icon={tab.icon} />
										<span className="is-text-icon">{tab.name}</span>
										<Icon icon={'far fa-angle-right'} className={'is-pulled-right'} />
									</>
								}
							/>
						)
				)}

				{/* ETABLISSEMENT */}
				{uai && shouldMount('Table', 'Lecture') && <Menu.List className={'fade-in-left'} title={translate('sidebar.school')} />}
				{uai &&
					tabs.map(
						(tab, idx) =>
							shouldMount(tab.rights, 'Lecture') && (
								<Menu.List.Item
									style={{ animationDelay: idx * 100 + 'ms' }}
									className={'fade-in-top'}
									key={idx}
									active={view === tab.value}
									onClick={() => changeView(tab.value)}
									children={
										<>
											<Icon icon={tab.icon} />
											<span className="is-text-icon">{tab.name}</span>
											<Icon icon={'far fa-angle-right'} className={'is-pulled-right'} />
										</>
									}
								/>
							)
					)}

				{/* UTILITAIRE */}
				{shouldMount('buttonHelp', 'Lecture') && <Menu.List title={translate('sidebar.utilities')} />}
				{utilitariesTabs.map(
					(tab, idx) =>
						shouldMount(tab.rights, 'Lecture') && (
							<Menu.List.Item
								key={idx}
								active={view === tab.value}
								onClick={() => changeView(tab.value)}
								children={
									<>
										<Icon icon={tab.icon} />
										<span className="is-text-icon">{tab.name}</span>
										<Icon icon={'far fa-angle-right'} className={'is-pulled-right'} />
									</>
								}
							/>
						)
				)}
				{/* BOUTON DOCUMENTATION */}
				{shouldMount('buttonHelp', 'Lecture') && (
					<Menu.List.Item
						onClick={downloadHelpFile}
						children={
							<>
								<Icon icon={'fad fa-info-circle'} />
								<span className="is-text-icon">{translate('sidebar.help')}</span>
							</>
						}
					/>
				)}

				{/* BOUTON DOCUMENTATION */}
				{shouldMount('buttonSupport', 'Lecture') && (
					<Menu.List.Item
						target="_blank"
						href="https://support.tabuleo.fr/support/tickets/new"
						children={
							<>
								<Icon icon={'fad fa-headset'} />
								<span className="is-text-icon">{translate('sidebar.support')}</span>
							</>
						}
					/>
				)}

				<Menu.List title={`${process.env.REACT_APP_VERSION} / ${apiVersion}`} />
				{!window.location.hostname.includes('connect') && (
					<Tag className="uppercase" color="danger" textWeight="bold">
						⚠️ Environnement {envs[window.location.hostname] ?? 'local'}
					</Tag>
				)}
			</Menu.List>
		</Menu>
	)
}

export default SideMenu
