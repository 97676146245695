import React from 'react'
import { Modal, Heading, Button } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import GlobalContext from '../../../contexts/GlobalContext'
import Hr from '../../utils/Hr'

/**
 * Modale pour confirmer ou annuler une action.
 */
export default class Confirm extends React.Component {
	static contextType = GlobalContext

	/**
	 * name correspondant au nom donné à la fenêtre, pour l'afficher grâce au contexte.
	 * @param props
	 */
	constructor(props) {
		super(props)
		this.name = 'confirmAction'
	}

	do = () => {
		this.props.action()
		this.props.onClose()
	}

	render() {
		const { translate } = this.context

		return (
			<Modal className={!this.context.isLightTheme ? 'is-dark' : ''} show={this.props.show} showClose onClose={() => this.props.onClose(this.name)} closeOnBlur>
				<Modal.Card className={'fade-in-bottom'}>
					<Modal.Card.Body>
						<header className="is-flex" style={{ justifyContent: 'space-between' }}>
							<Heading size={5}>{translate('global.warning')}</Heading>
							<Button color={'light'} onClick={() => this.props.onClose(this.name)}>
								<Icon icon={'fal fa-times fa-2x'} />
							</Button>
						</header>
						<Hr />
						<p>{this.props.content}</p>
						<br />
						<div className="is-flex" style={{ justifyContent: 'space-between' }}>
							<Button color={'danger'} onClick={() => this.props.onClose(this.name)}>
								{translate('global.cancel')}
							</Button>
							<Button color={'primary'} onClick={this.do}>
								{translate('global.confirm')}
							</Button>
						</div>
					</Modal.Card.Body>
				</Modal.Card>
			</Modal>
		)
	}
}
