import React, { Component } from 'react'
import Queries from '../../../tools/Query'
import GlobalContext from '../../../contexts/GlobalContext'
import MatchingTable from '../../../config/fields_matching.json'

export default class StandardCheckbox extends Component {
	static contextType = GlobalContext

	constructor(props) {
		super(props)
		this.state = {
			value: Number(props.value) === 1,
		}
	}

	handleCheckboxChange = () => {
		const { canWriteInTable, colDef, data, modificationKey } = this.props
		const { addToast, translate } = this.context

		if (canWriteInTable()) {
			const nomCol = colDef.field

			Number(data[nomCol]) === 0 ? (data[nomCol] = 1) : (data[nomCol] = 0)

			this.setState({ value: data[nomCol] })

			const uid = data['ID utilisateur']
			const value = data[nomCol]

			Queries.updateUserInGrid(this.context, uid, 'informations', { [modificationKey]: value })
				.then(res => {
					addToast(translate('table.success', { value: nomCol }), { appearance: 'success' })
				})
				.catch(err => {
					addToast(translate('global.errors.required'), { appearance: 'error' })
				})
		}
	}

	render() {
		const key = 'std-' + Math.random() * Math.random()
		return (
			<div className={'has-text-centered'}>
				<input onChange={this.handleCheckboxChange} disabled={!this.props.canWriteInTable()} id={key} type="checkbox" name="switchRoundedDefault" className="switch is-rounded is-success is-small" checked={this.state.value} />
				<label htmlFor={key} />
				{/*<input disabled={!this.props.canWriteInTable()} type='checkbox' onChange={this.handleCheckboxChange} checked={this.state.value}/>*/}
			</div>
		)
	}
}
