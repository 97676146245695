import React, { useContext, useEffect, useState } from 'react'
import { Button, Heading, Section } from 'react-bulma-components'
import Icon from '../utils/Icon'
import UserMenu from './user/UserMenu'
import GlobalContext from '../../contexts/GlobalContext'

export default function UserPage() {
	const {
		shouldMount,
		school,
		userPage,
		isLightTheme,
		toggleUserPage,
		loadUserPage,
		translate,
		grid
	} = useContext(GlobalContext)

	const name = 'showUserPage'
	const [previous, setPrevious] = useState()
	const [next, setNext] = useState()

	useEffect(() => {
		window.addEventListener('keyup', closeOnEscapeKey)
		shouldMount('Table', 'Lecture') && getPreviousAndNext()

		return () => {
			window.removeEventListener('keyup', closeOnEscapeKey)
		}
	}, [])

	const getPreviousAndNext = () => {
		const {
			informations: { userId }
		} = userPage
		const users = []

		let previous, next
		let userRowIdx

		grid?.forEachNodeAfterFilterAndSort((row, idx) => {
			if (row.data['ID utilisateur'] === userId) {
				userRowIdx = idx
			}

			users.push(row.data['ID utilisateur'])
		})

		previous = userRowIdx === 0 ? null : users[userRowIdx - 1]
		next = userRowIdx === users.length - 1 ? null : users[userRowIdx + 1]

		setPrevious(previous)
		setNext(next)
	}

	const closeOnEscapeKey = event => {
		if (event.code === 'Escape') {
			toggleUserPage()
		}
	}

	const { firstName, lastName, level } = userPage.informations
	const { options } = userPage

	return <Section className={`tab-container fade-in-fwd`} backgroundColor={isLightTheme ? '' : 'black'}
					style={{ borderColor: 'transparent' }}>
		<header className={'is-flex'} style={{ justifyContent: shouldMount('Table', 'Lecture') && 'space-between' }}>
			<section className={'is-flex'}>
				<p className={'is-icon-name'}>
					{firstName.charAt(0).toUpperCase()}
					{lastName.charAt(0).toUpperCase()}
				</p>
				<article>
					<Heading textColor={isLightTheme ? '' : 'light'}>
						{firstName} <strong>{lastName}</strong>
					</Heading>
					<Heading subtitle className={'is-flex'}>
						<Icon icon={'fad fa-university'} />
						<span className="is-text-icon">
									{school.informations.name} - {level || translate('user.no_class')}
								</span>
					</Heading>
				</article>
			</section>
			<div className="buttons">
				{shouldMount('Table', 'Lecture') && previous ? (
					<Button
						color={isLightTheme ? 'light' : 'black'}
						onClick={() => {
							toggleUserPage()
							loadUserPage(previous)
						}}
					>
						<Icon icon={'fa-2x fal fa-arrow-circle-left'} />
					</Button>
				) : null}
				{shouldMount('Table', 'Lecture') && (
					<Button color={isLightTheme ? 'light' : 'black'} onClick={toggleUserPage}>
						<Icon icon={'fa-2x fal fa-times-circle'} />
					</Button>
				)}
				{shouldMount('Table', 'Lecture') && next ? (
					<Button
						color={isLightTheme ? 'light' : 'black'}
						onClick={() => {
							toggleUserPage()
							loadUserPage(next)
						}}
					>
						<Icon icon={'fa-2x fal fa-arrow-circle-right'} />
					</Button>
				) : null}
			</div>
		</header>
		{options && (
			<>
				<br />
				<p>
					<strong>Mes options:</strong> {options.map(({ optionId }) => optionId).join(', ')}
				</p>
			</>
		)}
		{shouldMount('Table', 'Lecture') && <p className="text-xs text-gray-400 italic mt-4">
			Créé le {new Date(userPage.informations.createdOn).toLocaleDateString('fr-FR', {
			year: 'numeric',
			weekday: 'short',
			day: '2-digit',
			month: 'short',
			hour: '2-digit',
			minute: '2-digit'
		})} par {userPage.informations.createdBy || 'inconnu'}.
			Mis à jour le {new Date(userPage.informations.editedOn).toLocaleDateString('fr-FR', {
			year: 'numeric',
			weekday: 'short',
			day: '2-digit',
			month: 'short',
			hour: '2-digit',
			minute: '2-digit'
		})} par {userPage.informations.editedBy}.
		</p>}
		<UserMenu />
	</Section>
}