import React, {ChangeEvent, FC, useCallback, useContext, useEffect, useRef, useState} from 'react'
import {Skeleton} from '../GlobalApps'
// @ts-ignore
import {Button, Columns, Content, Form, Heading, Image} from 'react-bulma-components'
import GlobalContext from '../../../../contexts/GlobalContext'
import Query from '../../../../tools/Query'
import Icon from '../../../utils/Icon'
import {PlateformConfiguratorPropsType, PlatformSettings} from './types'
import GlobalUtils from '../../../utils/GlobalUtils'
import _ from 'lodash'
import {AddSettingsItem} from '../../../atoms/AddSettingsItem'

const PlatformConfigurator: FC<PlateformConfiguratorPropsType> = ({ platform: { name, order, forExport, exportFile, exportType, idPlateforme, newPlateforme, urlSchemeCodeYesNo, urlSchemePwdRequired, urlSchemeCodeRequired, urlSchemeLoginRequired, platformCodeYesNo, platformCodeRequired, urls }, updatePlatforms, alreadyUsedValue }) => {
	const context = useContext(GlobalContext)
	const { translate, showModal, addToast } = context
	const initialValues: PlatformSettings = {
		name,
		order,
		forExport,
		exportFile,
		exportType,
		idPlateforme,
		newIdPlateforme: idPlateforme,
		newPlateforme,
		urlSchemeCodeYesNo,
		urlSchemePwdRequired,
		urlSchemeCodeRequired,
		urlSchemeLoginRequired,
		platformCodeYesNo,
		platformCodeRequired,
		urls
	}

	const [editing, setEditing] = useState<boolean>(name === '')
	const [displayed, setDisplayed] = useState<boolean>(name === '')
	const [loading, setLoading] = useState<boolean>(false)
	const [values, setValues] = useState<PlatformSettings>({ ...initialValues })

	const inputFileRef = useRef<HTMLInputElement>(null)

	useEffect(() => {
		if (newPlateforme && values.newIdPlateforme !== values.idPlateforme) {
			setValues({ ...values, idPlateforme: values.newIdPlateforme })
		}
	}, [newPlateforme, setValues, values])

	function handleChange(event: React.ChangeEvent<HTMLInputElement>, type: string = 'input') {
		const { name, value, checked } = event.target
		const linkedValue = { name: '', value: false }

		if (name === 'urlSchemeCodeRequired') {
			linkedValue.name = 'urlSchemeCodeYesNo'
			linkedValue.value = checked || values.urlSchemeCodeRequired
		} else if (name === 'urlSchemeCodeYesNo') {
			linkedValue.name = 'urlSchemeCodeRequired'
			linkedValue.value = checked && values.urlSchemeCodeRequired
		} else if (name === 'platformCodeYesNo') {
			linkedValue.name = 'platformCodeRequired'
			linkedValue.value = checked && values.platformCodeRequired
		} else if (name === 'platformCodeRequired') {
			linkedValue.name = 'platformCodeYesNo'
			linkedValue.value = checked || values.platformCodeRequired
		}

		if (name.includes('urls.')) {
			const [_, subKey] = name.split('.') as [string, string]

			const copy: PlatformSettings = structuredClone(values)

			copy.urls.find(({name }) => name === subKey)!.value = value

			setValues(copy)
			return
		}

		const linkedValueObject = linkedValue.name ? { [linkedValue.name]: linkedValue.value } : {}
		setValues({
			...values,
			[name]: type === 'input' ? value : checked,
			...linkedValueObject,
		})
	}

	function setInEditMode() {
		setEditing(true)
		setDisplayed(true)
	}

	function hasMadeChanges() {
		return !_.isEqual(initialValues, values)
	}

	function saveChanges(formEvent: React.FormEvent<HTMLFormElement>) {
		formEvent.preventDefault()

		if (!editing) {
			return setInEditMode()
		}

		if (!hasMadeChanges()) {
			return setEditing(false)
		}

		const { urls, ...restOfValues } = values

		const finalValues = {
			...restOfValues,
			...urls.reduce((acc, obj) => ({ [obj.name]: obj.value }), {})
		}

		const { urls: initialUrls, ...restOfInitialValues } = initialValues

		const finalInitialValues: any = {
			...restOfInitialValues,
			...initialUrls.reduce((acc, obj) => ({ [obj.name]: obj.value }), {})
		}

		function formatDiff(key: string, diff: any) {
			return typeof diff === 'boolean' ? (diff ? 'ACTIVE' : 'DESACTIVE') : diff
		}

		showModal('confirmAction', true, {
			content: (
				<>
					<p>Êtes-vous sûr d'appliquer les modifications suivantes ?</p>
					<Content>
						<ul className="mt-2 is-size-7 has-text-grey-darker">
							{Object.entries(GlobalUtils.diffBetweenObjects(finalValues, finalInitialValues)).map(([key, value], idx) => (
								<li key={idx}>
									<span className="font-semibold">{key}</span> :<br />
									<span className="font-mono">
										<span className="has-text-danger">&times; {formatDiff(key, finalInitialValues[key])}</span>
										<br />
										<span className="has-text-success">&#8594; {formatDiff(key, value)}</span>
									</span>
								</li>
							))}
						</ul>
					</Content>
				</>
			),
			action: () => {
				setLoading(true)

				Query.updateGlobalSettings(context, 'platforms', values)
					.then(() => {
						addToast(`La plateforme ${idPlateforme} a bien été configurée.`, { appearance: 'success' })
						updatePlatforms()
						setLoading(false)
						setEditing(false)
					})
					.catch(err => {
						addToast(err, { appearance: 'danger' })
						setLoading(false)
					})
					.finally(() => {
						values.newPlateforme = false
						values.idPlateforme = values.newIdPlateforme
						setValues({ ...values })
					})
			},
		})
	}

	async function uploadNewImageApp(filesEvent: ChangeEvent<HTMLInputElement>) {
		if (!filesEvent.target.files) {
			return
		}

		const file = filesEvent.target.files[0]

		const { url, fields } = await Query.getUploadFileURL(context, idPlateforme, true) as { url: string, fields: any }

		try {
			// send file on s3
			await Query.uploadFile(context, url, fields, file, null)

			handleChange({
				// @ts-ignore
				target: {
					name: 'urls.picturePath',
					value: `https://images-tabuleo.s3.eu-west-3.amazonaws.com/${idPlateforme}`,
				},
			})

			addToast("L'image a bien été upload. Veuillez confirmez vos modifications.", { appearance: 'success' })
		} catch (e) {
			addToast(translate('global.errors.occurred'), { appearance: 'error' })
		}
	}

	const fieldManagerProps = {
		urlSchemeCodeYesNo: values.urlSchemeCodeYesNo,
		urlSchemePwdRequired: values.urlSchemePwdRequired,
		urlSchemeCodeRequired: values.urlSchemeCodeRequired,
		urlSchemeLoginRequired: values.urlSchemeLoginRequired,
		platformCodeYesNo: values.platformCodeYesNo,
		platformCodeRequired: values.platformCodeRequired,
		editing,
		handleChange,
		id: values.idPlateforme,
	}

	const exportCsvFieldsProps = {
		editing,
		handleChange,
		id: values.idPlateforme,
		forExport: values.forExport,
		exportFile: values.exportFile,
		exportType: values.exportType,
	}

	return (
		<form onSubmit={saveChanges}>
			<section className="border shadow-md p-4 rounded-lg">
				<header className="flex place-items-center justify-between">
					<figure className="flex flex-1 mr-24">
						<div className="w-16 h-16">
							{editing && (
								<div className="cursor-pointer absolute z-20 w-16 h-16 flex justify-center place-items-center bg-gray-900 opacity-0 hover:opacity-100 bg-opacity-70" onClick={() => inputFileRef.current!.click()}>
									<Icon icon={'far fa-upload'} color={'link'} />
								</div>
							)}
							<Image src={values.urls.find(({ name }) => name === 'picturePath')!.value ?? undefined} alt='' size={64} />
							{editing && <input type={'file'} className="sr-only" ref={inputFileRef} onChange={uploadNewImageApp} />}
						</div>
						{editing ? (
							<>
								<Form.Field className="ml-2 flex-1">
									<Form.Label size={'small'}>Nom de la plateforme</Form.Label>
									<Form.Control size={'small'}>
										<Form.Input color={alreadyUsedValue('name', values.name) && 'danger'} size={'small'} type={'text'} value={values.name} name={'name'} onChange={handleChange} required />
									</Form.Control>
									{alreadyUsedValue('name', values.name) && <Form.Help color="danger">Nom de la plateforme non disponible</Form.Help>}
								</Form.Field>
								<Form.Field className="ml-2">
									<Form.Label size={'small'}>ID de la plateforme</Form.Label>
									<Form.Control size={'small'}>
										<Form.Input color={alreadyUsedValue('idPlateforme', values.newIdPlateforme) && 'danger'} size={'small'} type={'text'} value={values.newIdPlateforme} name={'newIdPlateforme'} onChange={handleChange} required />
									</Form.Control>
									{alreadyUsedValue('idPlateforme', values.newIdPlateforme) && <Form.Help color="danger">Id de la plateforme disponible</Form.Help>}
								</Form.Field>
							</>
						) : (
							<Heading className="ml-4" size={5}>
								{name}
								<br />
								<span className="has-text-weight-bold is-size-7 has-text-primary">{idPlateforme}</span>
							</Heading>
						)}
					</figure>
					<div className="buttons">
						<EditingButton editing={editing} translate={translate} loading={loading} />
						<SeeMoreButton displayed={displayed} setDisplayed={setDisplayed} translate={translate} />
					</div>
				</header>
				<article className={'transition-all duration-300 overflow-hidden'} style={{ height: displayed ? '300px' : 0 }}>
					<Columns className="pt-8" multiline>
						<Columns.Column size={'one-second'}>
							<ExportCsvFields {...exportCsvFieldsProps} />
						</Columns.Column>
						<Columns.Column size={'one-second'}>
							<FieldManager {...fieldManagerProps} />
						</Columns.Column>
					</Columns>
				</article>
			</section>
		</form>
	)
}

interface EditingButtonPropsType {
	editing: boolean
	translate: Function
	loading: boolean
}

export const EditingButton: FC<EditingButtonPropsType> = ({ editing, translate, loading }) => (
	<Button color={editing ? 'success' : 'info'} size={'small'} type={'submit'} loading={loading}>
		<Icon key={editing ? 'save' : 'edit'} icon={editing ? 'fal fa-check' : 'fal fa-money-check-edit'} />
		<span>{editing ? translate('global.save') : translate('global.update')}</span>
	</Button>
)

interface SeeMoreButtonPropsType {
	displayed: boolean
	setDisplayed: Function
	translate: Function
}

export const SeeMoreButton: FC<SeeMoreButtonPropsType> = ({ displayed, setDisplayed, translate }) => (
	<Button color={'primary'} size={'small'} type={'button'} onClick={() => setDisplayed(!displayed)}>
		<Icon key={displayed ? 'up' : 'down'} icon={`far fa-angle-${displayed ? 'up' : 'down'}`} />
		<span>Voir {displayed ? 'moins' : 'plus'}</span>
	</Button>
)

interface FieldsPropsType {
	editing: boolean
	handleChange: Function
	id: string
}

interface FieldManagerPropsType extends FieldsPropsType {
	urlSchemeCodeYesNo: boolean
	urlSchemePwdRequired: boolean
	urlSchemeCodeRequired: boolean
	urlSchemeLoginRequired: boolean
	platformCodeYesNo: boolean
	platformCodeRequired: boolean
}

export const FieldManager: FC<FieldManagerPropsType> = ({ urlSchemeCodeYesNo, urlSchemeCodeRequired, urlSchemeLoginRequired, urlSchemePwdRequired, platformCodeYesNo, platformCodeRequired, handleChange, editing, id }) => {
	const fieldProps = {
		editing,
		handleChange,
		id,
	}
	const urlSchemeCodeYesNoFieldProps = {
		...fieldProps,
		title: 'Code individuel',
		value: urlSchemeCodeYesNo,
		name: 'urlSchemeCodeYesNo',
	}
	const urlSchemePwdRequiredFieldProps = {
		...fieldProps,
		title: 'Mot de passe obligatoire',
		value: urlSchemePwdRequired,
		name: 'urlSchemePwdRequired',
	}
	const urlSchemeCodeRequiredFieldProps = {
		...fieldProps,
		title: 'Code individuel obligatoire',
		value: urlSchemeCodeRequired,
		name: 'urlSchemeCodeRequired',
	}
	const urlSchemeLoginRequiredFieldProps = {
		...fieldProps,
		title: 'Identifiant obligatoire',
		value: urlSchemeLoginRequired,
		name: 'urlSchemeLoginRequired',
	}
	const platformCodeYesNoFieldProps = {
		...fieldProps,
		title: 'Code commun',
		value: platformCodeYesNo,
		name: 'platformCodeYesNo',
	}
	const platformCodeRequiredFieldProps = {
		...fieldProps,
		title: 'Code commun obligatoire',
		value: platformCodeRequired,
		name: 'platformCodeRequired',
	}

	return (
		<>
			<Heading size={7} className={'border-b pb-2'}>
				Gestion des champs
			</Heading>
			<div className="flex justify-between flex-wrap">
				<IosSwitchForm {...urlSchemeLoginRequiredFieldProps} />
				<IosSwitchForm {...urlSchemePwdRequiredFieldProps} />
				<IosSwitchForm {...urlSchemeCodeYesNoFieldProps} />
				<IosSwitchForm {...urlSchemeCodeRequiredFieldProps} />
				<IosSwitchForm {...platformCodeYesNoFieldProps} />
				<IosSwitchForm {...platformCodeRequiredFieldProps} />
			</div>
		</>
	)
}

interface IosSwitchFormPropsType {
	title: string
	editing: boolean
	handleChange: Function
	value: boolean
	name: string
	id: string
}

const IosSwitchForm: FC<IosSwitchFormPropsType> = ({ title, editing, handleChange, value, name, id }) => {
	return (
		<Form.Field className="w-1/2">
			<Form.Label size={'small'}>{title}</Form.Label>
			<input id={`${id}_${name}`} type="checkbox" className="switch is-rounded is-success is-small" checked={value} name={name} disabled={!editing} onChange={e => handleChange(e, 'checkbox')} />
			<label htmlFor={`${id}_${name}`} />
		</Form.Field>
	)
}

interface ExportCsvFieldsPropsType extends FieldsPropsType {
	forExport: boolean
	exportFile: string
	exportType: string
}

export const ExportCsvFields: FC<ExportCsvFieldsPropsType> = ({ handleChange, editing, id, forExport, exportFile, exportType }) => {
	return (
		<>
			<Heading size={7} className={'border-b pb-2'}>
				Export CSV
			</Heading>
			<Form.Field>
				<Form.Label size={'small'}>Activer l'export</Form.Label>
				<input id={`${id}_forExport`} type="checkbox" className="switch is-rounded is-success is-small" checked={forExport} name={'forExport'} disabled={!editing} onChange={e => handleChange(e, 'checkbox')} />
				<label htmlFor={`${id}_forExport`} />
			</Form.Field>
			<Form.Field>
				<Form.Label size={'small'}>Nom du fichier et son type</Form.Label>
				<Form.Field className={'has-addons'}>
					<Form.Control size={'small'} className="is-expanded">
						<Form.Input size={'small'} name={'exportFile'} type={'text'} fullwidth placeholder={`[UAI]_${id}_[Profil]`} value={exportFile} disabled={!editing} onChange={handleChange} />
					</Form.Control>
					<Form.Control size={'small'}>
						<div className="select is-small">
							<select name={'exportType'} disabled={!editing} id="type" onChange={e => handleChange(e)} defaultValue={exportType}>
								<option value="CSV">.csv</option>
								<option value="XLSX">.xlsx</option>
							</select>
						</div>
					</Form.Control>
				</Form.Field>
			</Form.Field>
		</>
	)
}

export function GlobalPlatforms() {
	const context = useContext(GlobalContext)
	const { addToast, translate } = context
	const [loading, setLoading] = useState<boolean>(true)
	const [platforms, setPlatforms] = useState<PlatformSettings[]>([])

	const fetchPlatforms = useCallback(() => {
		Query.getGlobalSettings(context, 'platforms')
			.then(fetchedPlatforms => {
				if (fetchedPlatforms) setPlatforms(fetchedPlatforms as PlatformSettings[])
				setLoading(false)
			})
			.catch(err => {
				setLoading(false)
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
			})
	}, [addToast, context, translate])

	/**
	 * Verifie si la value n'est pas deja utilisee
	 */
	const alreadyUsedValue = (current: PlatformSettings, name: keyof PlatformSettings, value: any) => {
		return !!platforms.find(platform => platform[name] === value && current[name] !== value)
	}

	useEffect(() => {
		if (platforms.length === 0) fetchPlatforms()
	}, [fetchPlatforms, platforms, setPlatforms])

	const lastPositionPlatform = () => {
		return platforms?.sort((a, b) => a.order - b.order).slice(-1)[0].order + 1
	}

	const handleAddPlatform = () => {
		const newPlatform: PlatformSettings = {
			name: '',
			order: lastPositionPlatform(),
			forExport: false,
			exportFile: '',
			exportType: '',
			idPlateforme: '',
			newPlateforme: true,
			newIdPlateforme: '',
			urlSchemeCodeYesNo: false,
			urlSchemePwdRequired: false,
			urlSchemeCodeRequired: false,
			urlSchemeLoginRequired: false,
			platformCodeYesNo: false,
			platformCodeRequired: false,
			urls: [{ name: 'picturePath', value: null }]
		}
		setPlatforms([...platforms, newPlatform])
	}

	return loading ? (
		<Skeleton />
	) : (
		<div>
			<Heading size={4}>
				{platforms?.length || 0} {translate('settings.menu.platforms')}{' '}
			</Heading>
			<section className="space-y-4">
				{platforms?.map(platform => (
					<PlatformConfigurator platform={platform} updatePlatforms={fetchPlatforms} alreadyUsedValue={(name, value) => alreadyUsedValue(platform, name as keyof PlatformSettings, value)} />
				))}
				<AddSettingsItem handleClick={handleAddPlatform} href="#new-platform" id="new-platform" />
			</section>
		</div>
	)
}
