import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { ToastProvider, useToasts } from 'react-toast-notifications'
import './scss/bulma.css'
import GlobalContext from './contexts/GlobalContext'
import Queries from './tools/Query'
import qrcode from 'qrcode'
import Main from './components/Main'
import LoadingScreen from './components/utils/LoadingScreen'
import * as yup from 'yup'
import { unescape } from 'lodash'

/**
 * Contexte global de notre app, qui réportorie toutes les informations utiles
 * ainsi que des fonctions pratiques.
 */
class Provider extends React.Component {
	constructor(props) {
		super(props)
		this.state = this.initState()
	}

	/**
	 * Définition de chaque état:
	 *    - view = Vue de notre application (réfère à la sidebar) utilisateurs, paramètres ...
	 *    - lang = langue de l'utilisateur
	 *    - reload = Action qui indique que l'application doit se recharger
	 *    - reloadGrid = Action qui indique que la grille doit se recharger
	 *    - auth = l'utilisateur est connecté
	 *    - students = utilisateurs de l'école choisi
	 *    - user = informations de l'utilisateur connecté
	 *    - rights = droits de l'utilisateur connecté
	 *    - school = informations de l'école sélectionnée
	 *    - schools = liste de toutes les écoles
	 *    - modals = Actions pour activer et stocker les props des modals
	 *    - showUserAdd = doit afficher l'ajout utilisateur
	 *    - showUserPage = doit afficher la fiche utilisateur
	 *    - isLightTheme = utilise un mode clair
	 *    - showHour = affiche l'heure dans la navbar
	 *    - filter = liste des filtres appliqués à la grille
	 *    - validations = validations a appliqué sur les champs d'un utilisateur
	 * @returns {{isLightTheme: (boolean), showUserAdd: boolean, showUserPage: boolean, auth: boolean, students: [], showHour: boolean, modals: {}, reloadGrid: boolean, filter: { table: {}, workflow: {}}, view: string, reload: boolean, school: {}, schools: undefined, rights: {}, validations: [], lang: string, user: {}}}
	 */
	initState = () => {
		return {
			view: '',
			lang: this.props.i18n.language,
			reload: false,
			reloadGrid: false,
			auth: false,
			students: [],
			user: {},
			rights: {},
			school: {},
			schools: undefined,
			modals: {},
			showUserAdd: false,
			showUserPage: false,
			isLightTheme: localStorage.getItem('isLightTheme') === 'true' || window.matchMedia('(prefers-color-scheme: light)').matches,
			showHour: (localStorage.getItem('showHour') || false) === 'true',
			usersFilters: {},
			workflowFilters: {},
			validations: [],
			selectedUsersInGrid: [],
		}
	}

	/**
	 * Lorsque l'application est chargé,
	 *
	 * Par défaut, on sauvegarde les préférences de la grille.
	 *
	 * Si l'utilisateur est connecté on va aller chercher la liste des établissements
	 * et chercher s'il y a son uid dans le localStorage pour le connecter automatiquement.
	 */
	componentDidMount() {
		if (!localStorage.getItem('sgp')) {
			localStorage.setItem('sgp', '1')
		}

		this.state.auth &&
			Queries.getSchool().then(res => {
				this.setState({
					schools: res,
				})

				const user = localStorage.getItem('user')

				if (user) {
					const rights = JSON.parse(localStorage.getItem('rights'))
					this.signIn(rights, JSON.parse(user))
				}
			})
	}

	/**
	 * Supprime les données enregistrées.
	 */
	static clearLocalStorage = () => {
		localStorage.removeItem('uid')
		localStorage.removeItem('user')
		localStorage.removeItem('date')
	}

	/**
	 * Change la vue de l'application, et va fermer la page utilisateur ainsi que l'ajout unitaire.
	 * @param newView Nouvelle vue à afficher
	 */
	changeView = newView => {
		this.hideUserPage()
		this.toggleUserAdd(false)
		this.setState({ view: newView })
	}

	/**
	 * Passe du thème clair au thème sombre, ou inversement, et va garder la valeur dans le
	 * storage local.
	 */
	switchTheme = () => {
		this.setState(state => ({
			isLightTheme: !state.isLightTheme,
		}))
		localStorage.setItem('isLightTheme', (!this.state.isLightTheme).toString())
	}

	/**
	 * Défini si l'utilisateur connecté a le droit de lire/écrire un composant.
	 * @param component Composant
	 * @param {'Lecture'|'Ecriture'} permission Lecture ou écriture du droit
	 * @returns {boolean}
	 */
	shouldMount = (component, permission) => {
		return this.state.rights[component] === undefined ? true : this.state.rights[component][permission] === 1
	}

	/**
	 * Affiche ou cache l'ajout unitaire.
	 * @param show Si vrai alors afficher sinon cacher
	 */
	toggleUserAdd = show => {
		this.setState(state => ({
			showUserAdd: show ?? !state.showUserAdd,
			showUserPage: false,
		}))
	}

	/**
	 * Charge un établissement et ses élèves.
	 * @param uai UAI de l'établissement
	 * @param {string} view
	 * @returns {Promise<void>}
	 */
	loadSchool = async (uai, view = 'users') => {
		this.toggleUserAdd(false)
		this.hideUserPage()
		// deleted for fixing userPage edit and change user page
		// this.setSchool({})
		this.setSelectedUsersInGrid([])

		const schoolData = await Queries.getSchool(this, uai)
		const schoolUsers = (await Queries.getUsers(this, uai)).map(({ Commentaire, ...user }) => ({ Commentaire: unescape(Commentaire), ...user }))

		this.setSchool(schoolData, schoolUsers)
		this.refreshGrid()

		this.changeView(view)
	}

	/**
	 * Définie la grille dans notre contexte.
	 * @param grid Grille // AG-GRID
	 */
	setGrid = grid => this.setState({ grid })

	/**
	 * Définie les infos de l'établissement dans le contexte.
	 * Définie les validations grâce aux infos de l'établissement.
	 * @param school Infos de l'établissement
	 * @param users Utilisateur de l'établissement
	 */
	setSchool = (school, users) => {
		this.setState({ school: { ...school }, students: users })
		this.initValidationsFromSchool(school)
	}

	updateSchoolApps = applications => {
		this.setState({ school: { ...this.state.school, applications } })
	}

	/**
	 * Définie l'ensemble des établissements
	 * @param schools Liste des établissements
	 */
	setSchools = schools => this.setState({ schools })

	/**
	 * Met à jour les paramètres de l'établissement dans notre contexte.
	 * Remet à jour les validations.
	 * @param newParams Paramètres mis à jour
	 */
	updateSchoolParams = newParams => {
		this.setState(state => ({ school: { ...state.school, ...newParams } }))
		this.initValidationsFromSchool(this.state.school)
	}

	/**
	 * Défini que l'app doit se rafraichir.
	 */
	refresh = () => {
		this.setState(
			{
				reload: true,
			},
			() => this.setState({ reload: false })
		)
	}

	/**
	 * Défini que la grille doit se rafraichir
	 */
	refreshGrid = () => {
		this.setState(
			{
				reloadGrid: true,
			},
			() => this.setState({ reloadGrid: false })
		)
	}

	/**
	 * Va ajouter un nouveau filtre sur la grille.
	 * @param {'table'|'workflow'} agGridView Grille sur lesquelles les filtre sont appliqués
	 * @param filterToAdd Nouveau filtre
	 */
	pushGridFilter = (agGridView, filterToAdd, origin) => {
		const { usersFilters, workflowFilters } = this.state

		const filter = (() =>
			({
				table: { key: 'usersFilters', content: usersFilters },
				workflow: { key: 'workflowFilters', content: workflowFilters },
			}[agGridView]))()

		this.setState(() => ({
			[filter.key]: {
				...filter.content,
				filterToAdd,
			},
		}))

		// this.setGridFilter(agGridView, { ...map[agGridView], filterToAdd }, null, 'Context.pushGridFilter')
	}

	/**
	 * Supprime un filtre existant de la grille
	 * @param {'table'|'workflow'} agGridView Grille sur lesquelles les filtre sont supprimés
	 * @param filterName Nom du filtre
	 * @param {function} callback
	 */
	removeGridFilter = (agGridView, filterName = '', callback, origin) => {
		const { usersFilters, workflowFilters } = this.state

		const filter = (() =>
			({
				table: { key: 'usersFilters', content: usersFilters },
				workflow: { key: 'workflowFilters', content: workflowFilters },
			}[agGridView]))()

		const updatedFilter = { ...filter.content }

		delete updatedFilter[filterName]

		this.setState(() => ({
			[filter.key]: updatedFilter,
		}))
	}

	/**
	 * Défini les filtres à appliquer sur la grille.
	 * @param {'table'|'workflow'} agGridView Grille sur lesquelles les filtre sont appliqués
	 * @param {object} newFilterModel Ensemble des filtres à appliquer
	 * @param {function} callback
	 * @param origin
	 */
	setGridFilter = (agGridView, newFilterModel, callback = () => {}, origin = 'unknown') => {
		const filterKey = (() =>
			({
				table: 'usersFilters',
				workflow: 'workflowFilters',
			}[agGridView]))()

		this.setState(() => ({
			[filterKey]: newFilterModel,
		}))
	}

	/*
	 * Récupère une platforme grâce à son nom
	 *
	 * @param name Le nom de la plateforme
	 * @returns La plateforme ou null si aucune platforme ne possède ce nom
	 */
	findPlatformByName = name => {
		const { platforms } = this.state.school.platforms
		return platforms.find(platform => platform.name === name)
	}

	/*
	 * Récupère une platforme grâce à son identifiant
	 *
	 * @param id L'identifiant de la plateforme
	 * @returns La plateforme ou null si aucune platforme ne possède cette identifiant
	 */
	findPlatformById = id => {
		const { platforms } = this.state.school.platforms
		return platforms.find(platform => platform.idPlateforme === id)
	}

	/**
	 * Supprime tous les filtres de la grille.
	 * @param {'table'|'workflow'} agGridView Grille sur lesquelles les filtre sont supprimés
	 */
	resetGridFilter = (agGridView, origin) => {
		const filterKey = (() =>
			({
				table: 'usersFilters',
				workflow: 'workflowFilters',
			}[agGridView]))()

		this.setState(() => ({
			[filterKey]: {},
		}))
		// this.setGridFilter(agGridView, {}, null, 'Context.resetGridFilter')
	}

	/**
	 * Authentifie un utilisateur.
	 *
	 * S'il s'agit d'un élève, nous allons charger son établissement ainsi que sa fiche utilisateur.
	 *
	 * @param rights Droits de l'utilisateur
	 * @param userData Informations de l'utilisateur
	 * @param apiVersion Version de l'API
	 * @returns {Promise<void>}
	 */
	signIn = async (rights, userData, apiVersion) => {
		this.setState({
			auth: true,
			user: userData,
			rights,
			apiVersion,
		})

		const schools = await Queries.getAll(this)

		this.setSchools(schools)

		this.changeView(userData.profile === 'Administrateur' ? 'overview' : 'users')

		if (['Eleve', 'Enseignant'].includes(userData.profile)) {
			const school = schools.find(school => school.UAI === userData.uai)
			const schoolData = await Queries.getSchool(this, school.UAI)
			this.setSchool(schoolData)
			await this.loadUserPage(userData.id)
		}

		if (!['Eleve', 'Enseignant', 'Administrateur'].includes(userData.profile)) {
			const school = schools.find(school => school.UAI === userData.uai)
			await this.loadSchool(school.UAI)
		}

		localStorage.setItem('user', JSON.stringify(userData))
	}

	/**
	 * Déconnecte un utilisateur.
	 */
	logout = () => {
		window.history.replaceState(null, null, window.location.pathname)
		Provider.clearLocalStorage()
		Queries.logoutUser(this)
		this.setState(this.initState())
	}

	/**
	 * Renvoie la traduction d'une phrase à partir des fichier de traduction.
	 * @param key Clé dans les fichiers de traduction.
	 * @param params Variable de la traduction.
	 * @returns {*}
	 */
	translate = (key, params = {}) => this.props.t(key, params)

	/**
	 * Change la langue de notre application.
	 * @param newLang Code de la nouvelle langue : fr/en/es ...
	 * @returns {Promise<TFunction>}
	 */
	changeLang = newLang => this.props.i18n.changeLanguage(newLang, () => window.location.reload(true))

	/**
	 * Affiche ou cache la page utilisateur.
	 *
	 * S'il y a eu une modification effectuée, l'application se rafraîchit.
	 */
	toggleUserPage = () => {
		this.setState(
			state => ({ showUserPage: !state.showUserPage, showUserAdd: false }),
			() => {
				if (!this.state.showUserPage && this.updated) {
					this.updated = false
					this.refresh()
				}
			}
		)
	}

	/**
	 * Récupère une platforme grâce à son nom
	 *
	 * @param name Le nom de la plateforme
	 * @returns La plateforme ou null si aucune platforme ne possède ce nom
	 */
	findPlatformByName = name => {
		const { platforms } = this.state.school.platforms
		return platforms.find(platform => platform.name === name)
	}

	/**
	 * Récupère une platforme grâce à son identifiant
	 *
	 * @param id L'identifiant de la plateforme
	 * @returns La plateforme ou null si aucune platforme ne possède cette identifiant
	 */
	findPlatformById = id => {
		const { platforms } = this.state.school.platforms
		return platforms.find(platform => platform.idPlateforme === id)
	}

	/**
	 * Charge les infos de la page utilisateur.
	 *
	 * Si la fiche est ouverte depuis la recherche, nous filtrons sur l'utilisateur.
	 *
	 * @param uid ID de l'utilisateur
	 * @param filterOnUser Indique si l'on doit filtrer sur l'utilisateur après avoir chargé la page.
	 * @returns {Promise<void>}
	 */
	loadUserPage = async (uid, filterOnUser = false) => {
		const qrCode = await qrcode.toDataURL(`tabuleo://IDutilisateur=${uid}`, { type: 'png' }).then(code => code)
		let userData = await Queries.getUser(this, uid)
		console.debug('set user stqte = ')
		this.setState({ userPage: { ...userData, informations: { ...userData.informations, qrCode: qrCode } }, showUserPage: true, showUserAdd: false })
		console.debug('Loaded user page = ')
		filterOnUser &&
			this.setGridFilter(
				'table',
				{
					Login: {
						filterType: 'text',
						type: 'equal',
						filter: userData.informations.login,
					},
				},
				updatedFilters => {
					this.state.grid.setFilterModel(updatedFilters.table)
				}
			)
	}

	/**
	 * Met à jour les infos de la fiche utilisateur dans notre contexte.
	 * @param newUserData Infos mise à jour
	 */
	updateUserPage = newUserData => {
		this.updated = true
		this.setState(state => ({ userPage: { ...state.userPage, ...newUserData } }))
	}

	/**
	 * Cache la page utilisateur.
	 */
	hideUserPage = () => {
		this.setState({ showUserPage: false, userPage: null })
	}

	/**
	 * Créé une notification en bas à droite de l'écran.
	 * @param message Contenu de la notification
	 * @param option Paramètre de la notification.
	 * @param callback Callback qui renvoie l'id du toast.
	 * @returns {*}
	 */
	addToast = (message, option, callback) => this.props.toasts.addToast(message, option, callback)

	/**
	 * Met à jour une notification affichée.
	 * @param id ID de la notification
	 * @param newOption Paramètres pour modificer la notification
	 * @returns {*}
	 */
	updateToast = (id, newOption) => this.props.toasts.updateToast(id, newOption)

	/**
	 * Supprime une notification affichée.
	 * @param id ID de la notification
	 * @returns {*}
	 */
	removeToast = id => this.props.toasts.removeToast(id)

	/**
	 * Affiche ou cache une modal et lui passe des paramètres.
	 *
	 * @param modalName Nom de la fenêtre
	 * @param show Vrai pour l'afficher, faux pour la cacher
	 * @param params Props à passer à la modal
	 */
	showModal = (modalName, show, params = {}) => this.setState({ modals: { [modalName]: show, ...params } })

	getActiveUai = () => this.state.school?.informations?.uai

	/**
	 * Affiche ou cache l'heure dans la navbar.
	 */
	changeHourVisibility = () => {
		this.setState(state => ({ showHour: !state.showHour }))
		localStorage.setItem('showHour', (!this.state.showHour).toString())
	}

	setSelectedUsersInGrid = selectedUsersInGrid => this.setState({ selectedUsersInGrid })

	/**
	 * Définie les validations Formik pour le prénom, nom, login ... et autres informations de l'utilisateur.
	 * Il va transormer les regex établissements et globales puis les appliquer.
	 *
	 * @param school
	 */
	initValidationsFromSchool = school => {
		if (!school || !school.regexGlobal) {
			return
		}

		const translate = this.translate
		const regexes = {}
		const conversions = {}
		const schoolRegexes = school.regexGlobal

		// On remplace @Sp par regex.special
		schoolRegexes.forEach(({ field, conversion, special, structure }) => {
			conversions[field] = conversion
			const regexEstab = school.regexEstab?.find(regEstab => regEstab.field === field)?.regex
			const specialReformat = (regexEstab ? regexEstab : special) ?? ''
			const split = specialReformat.split('')

			for (let i = 0; i < split.length; i++) {
				const actualWord = split[i]
				split[i] = `\\${actualWord}`
			}
			regexes[field] = structure?.replace('$Sp', split.join(''))
		})

		this.setState({
			validations: {
				regexes,
				conversions,
				user: {
					firstName: yup
						.string()
						.required(translate('global.errors.required'))
						.test({
							test: firstName => firstName?.replace(new RegExp(regexes.firstName, 'g'), '') === firstName,
							message: translate('global.errors.invalid'),
						}),
					lastName: yup
						.string()
						.required(translate('global.errors.required'))
						.test({
							test: lastName => !new RegExp(regexes.lastName).test(lastName),
							message: translate('global.errors.invalid'),
						}),
					login: yup
						.string()
						.required(translate('global.errors.required'))
						.test({
							test: login => {
								let loginAlreadyExist = false

								this.state.grid.forEachNode(({ data }) => {
									const userId = this.state.userPage?.informations.userId ?? 'unitary'
									if (userId !== data['ID utilisateur'] && data['Login'] === login) {
										return (loginAlreadyExist = true)
									}
								})

								return !loginAlreadyExist
							},
							message: translate('user_add.username_already_existing'),
						})
						.test({
							test: login => {
								return !new RegExp(regexes.login).test(login)
							},
							message: translate('user_add.username_invalid'),
						}),
					password: yup.string().required(translate('global.errors.required')),
					level: yup.string().required(translate('global.errors.required')),
					profile: yup.string().required(translate('global.errors.required')),
					class: yup.string().nullable(translate('global.errors.required')),
					tabletNumber: yup.string().nullable().min(0).max(12, translate('global.errors.tablet_number_max_length')),
					comment: yup.string().nullable(),
					email: yup.string().email().nullable(),
				},
				applications: Object.values(school.applications)?.reduce((applications, application) => {
					const { urlSchemeLoginRequired, urlSchemePwdRequired, urlSchemeCodeRequired, applicationCodeRequired } = application
					const currentApplicationValidations = {}

					if (urlSchemeLoginRequired) {
						currentApplicationValidations.login = yup.string().required(translate('global.errors.required'))
					} else {
						currentApplicationValidations.login = yup.string().nullable()
					}
					if (urlSchemePwdRequired) {
						currentApplicationValidations.password = yup.string().required(translate('global.errors.required'))
					} else {
						currentApplicationValidations.password = yup.string().nullable()
					}
					if (urlSchemeCodeRequired || applicationCodeRequired) {
						currentApplicationValidations.code = yup.string().required(translate('global.errors.required'))
					} else {
						currentApplicationValidations.code = yup.string().nullable()
					}

					if (Object.keys(currentApplicationValidations).length > 0) {
						applications.set(application.name, currentApplicationValidations)
					}
					return applications
				}, new Map()),
			},
		})
	}

	render() {
		return (
			<GlobalContext.Provider
				value={{
					...this.state,
					shouldMount: this.shouldMount,
					signIn: this.signIn,
					logout: this.logout,
					translate: this.translate,
					loadSchool: this.loadSchool,
					setSchool: this.setSchool,
					loadUserPage: this.loadUserPage,
					hideUserPage: this.hideUserPage,
					switchTheme: this.switchTheme,
					addToast: this.addToast,
					updateToast: this.updateToast,
					removeToast: this.removeToast,
					showModal: this.showModal,
					refresh: this.refresh,
					refreshGrid: this.refreshGrid,
					setGrid: this.setGrid,
					updateSchoolParams: this.updateSchoolParams,
					updateSchoolApps: this.updateSchoolApps,
					updateUserPage: this.updateUserPage,
					changeLang: this.changeLang,
					changeHourVisibility: this.changeHourVisibility,
					toggleUserAdd: this.toggleUserAdd,
					toggleUserPage: this.toggleUserPage,
					changeView: this.changeView,
					setGridFilter: this.setGridFilter,
					pushGridFilter: this.pushGridFilter,
					removeGridFilter: this.removeGridFilter,
					resetGridFilter: this.resetGridFilter,
					getActiveUai: this.getActiveUai,
					setSelectedUSersInGrid: this.setSelectedUsersInGrid,
					findPlatformById: this.findPlatformById,
					findPlatformByName: this.findPlatformByName,
				}}
			>
				<Main />
			</GlobalContext.Provider>
		)
	}
}

function App() {
	const isLightTheme = localStorage.getItem('isLightTheme') === 'true' || window.matchMedia('(prefers-color-scheme: light)').matches

	return (
		<Suspense fallback={<LoadingScreen isLightTheme={isLightTheme} />}>
			<ToastProvider placement={'bottom-right'} autoDismissTimeout={6000} autoDismiss>
				<MyProvider />
			</ToastProvider>
		</Suspense>
	)
}

function MyProvider() {
	const toasts = useToasts()
	const { t, i18n } = useTranslation('common')

	return <Provider toasts={toasts} i18n={i18n} t={t} />
}

export default App
