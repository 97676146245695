import { Box, Heading, Section, Tag } from 'react-bulma-components'
import React, { useContext } from 'react'
import GlobalContext from '../../../contexts/GlobalContext'
import iosDevices from 'ios-device-list'

export function UserDevices() {
	const {
		isLightTheme,
		translate,
		userPage: { devices },
	} = useContext(GlobalContext)

	return (
		<Box style={{ backgroundColor: isLightTheme ? '' : '#222' }}>
			<Heading size={5} textColor={isLightTheme ? '' : 'grey-lighter'}>
				{translate('user.devices')}
				<section className="mt-4 sm:grid-cols-2 lg:grid-cols-3 gap-4 grid">
					{devices ? (
						Object.entries(devices)
							.sort((a, b) => {
								return Date.parse(b[1].modificationDate) - Date.parse(a[1].modificationDate)
							})
							.map(([id, deviceInfos]) => <Device key={id} id={id} {...deviceInfos} />)
					) : (
						<p className="text-sm text-gray-500">Cet utilisateur n'a aucun appareil enregistré.</p>
					)}
				</section>
			</Heading>
		</Box>
	)
}

function Device({ id, applicationVersion, availableSpace, model, operatingSystem, token }) {
	return (
		<Section className={'border rounded-lg p-4 user-app-box'}>
			<header className="flex justify-between">
				<Heading size={6} className="font-bold" marginless textWeight="bold">
					{iosDevices.generationByIdentifier(model) ?? model}
					<span className="text-xs text-gray-500">#{id}</span>
				</Heading>
				<div className="tags mt-0 mb-2">
					<Tag color={'primary'}>v. {applicationVersion}</Tag>
					<Tag color={'info'}>{operatingSystem}</Tag>
				</div>
			</header>
			<p className="text-sm pt-4">
				<code>{(availableSpace / 1000).toFixed(2)}Go</code> d'espace disponibles
			</p>
			<p className="text-sm">
				Token de notification: <code>{token}</code>
			</p>
		</Section>
	)
}
