import React, { useCallback, useContext, useEffect, useState } from 'react'
import Query from '../../../tools/Query'
import GlobalContext from '../../../contexts/GlobalContext'
import { Button, Content, Form, Heading } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import _ from 'lodash'
import GlobalUtils from '../../utils/GlobalUtils'

/**
 * Configuration des champs globaux lié à Tabuléo.
 * @returns {JSX.Element}
 * @constructor
 */
function GlobalTabuleo() {
	const context = useContext(GlobalContext)
	const { addToast, translate, showModal } = context

	const [editing, setEditing] = useState(false)
	const [loading, setLoading] = useState(false)
	const [values, setValues] = useState(null)

	const [initialValue, setInitialValue] = useState(null)

	/**
	 * Récupère les données via l'API
	 * @type {(function(): void)|*}
	 */
	const fetchTabuleoValues = useCallback(() => {
		Query.getGlobalSettings(context, 'tabuleo')
			.then(tabuleo => {
				setInitialValue(tabuleo)
				setValues(tabuleo)
			})
			.catch(err => {
				setValues(false)
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
			})
	}, [addToast, context, translate])

	/**
	 * Récupère les données au premier chargement.
	 */
	useEffect(() => {
		if (!values) {
			fetchTabuleoValues()
		}
	}, [fetchTabuleoValues, values])

	/**
	 * Détecte si l'utilisateur a fait des changements.
	 * @returns {boolean}
	 */
	function hasMadeChanges() {
		return !_.isEqual(initialValue, values)
	}

	/**
	 * Support de saisie.
	 * @param name
	 * @param value
	 */
	function handleChange({ target: { name, value } }) {
		setValues(values => ({
			...values,
			[name]: value,
		}))
	}

	/**
	 * Met à jour les données en base avec un écran de confirmation affichant les différences.
	 * @param formEvent
	 */
	function updateValues(formEvent) {
		formEvent.preventDefault()

		if (!editing) {
			return setEditing(true)
		}

		if (!hasMadeChanges()) {
			return setEditing(false)
		}

		showModal('confirmAction', true, {
			content: (
				<>
					<p>{translate('settings.validate')}</p>
					<Content>
						<ul className="mt-2 is-size-7 has-text-grey-darker">
							{Object.entries(GlobalUtils.diffBetweenObjects(values, initialValue)).map(([key, value], idx) => (
								<li key={idx}>
									<span className="font-semibold">{key}</span> :<br />{' '}
									<span className="font-mono">
										<span className="has-text-danger">&times; {initialValue[key]}</span>
										<br />
										<span className="has-text-success">&#8594; {value}</span>
									</span>
								</li>
							))}
						</ul>
					</Content>
				</>
			),
			action: () => {
				setLoading(true)

				Query.updateGlobalSettings(context, 'tabuleo', values)
					.then(() => {
						addToast(translate('settings.update_success'), { appearance: 'success' })
						setEditing(false)
						setLoading(false)
						setInitialValue({ ...values })
					})
					.catch(() => {
						addToast(translate('global.errors.occurred'), { appearance: 'error' })
						setLoading(false)
					})
			},
		})
	}

	return values ? (
		<form onSubmit={updateValues}>
			<header className="flex justify-between">
				<Heading size={5}>{translate('settings.tab')}</Heading>
				<Button color={editing ? 'success' : 'info'} size={'small'} type={'submit'} loading={loading}>
					<Icon key={editing ? 'save' : 'edit'} icon={editing ? 'fal fa-check' : 'fal fa-money-check-edit'} />
					<span>{editing ? translate('global.save') : translate('global.update')}</span>
				</Button>
			</header>
			<section className="flex flex-wrap">
				{Object.entries(values).map(([field, value], idx) => (
					<Form.Field key={idx} className="w-1/3 pr-4">
						<Form.Label size={'small'}>{field}</Form.Label>
						<Form.Field className="has-addons">
							<Form.Control size={'small'} className="is-expanded">
								<Form.Input size={'small'} name={field} placeholder={field} type={'text'} value={values[field]} disabled={!editing} onChange={handleChange} />
							</Form.Control>
							<Form.Control size={'small'}>
								<a className={'is-small button is-primary'} href={value} target={'_blank'} rel={'noopener noreferrer'}>
									<Icon icon={'fal fa-external-link'} />
								</a>
							</Form.Control>
						</Form.Field>
					</Form.Field>
				))}
			</section>
		</form>
	) : (
		<Skeleton />
	)
}

/**
 * Squelette de chargement.
 * @returns {JSX.Element}
 * @constructor
 */
function Skeleton() {
	const fake = []

	for (let i = 0; i < 8; i++) {
		fake.push(
			<div className="w-1/3 pr-4">
				<div className="w-32 h-4 bg-gray-200 animate-pulse rounded-lg my-2" />
				<div className="w-full h-6 bg-gray-100 animate-pulse rounded-lg" />
			</div>
		)
	}

	return (
		<>
			<div className="w-64 h-6 rounded-lg bg-gray-200 animate-pulse mb-4" />
			<div className="flex flex-wrap">{fake}</div>
		</>
	)
}

export default GlobalTabuleo
