import React from "react";

export default class Icon extends React.Component {
    render = () => {
        const className =
            this.props.className
                ? this.props.className
                : '';

        const color = this.props.color
            ? `has-text-${this.props.color}`
            : '';

        return <span onClick={this.props.onClick} className={`icon ${className} ${color}`} style={this.props.style}><i className={this.props.icon}/></span>;
    }
}