import React from 'react'
import { Table, Button, Heading, Modal } from 'react-bulma-components'
import Icon from '../../utils/Icon'
import GlobalContext from '../../../contexts/GlobalContext'
import Hr from '../../utils/Hr'

export default class FindUser extends React.Component {
	static contextType = GlobalContext

	constructor(props) {
		super(props)
		this.name = 'findUser'
	}

	loadUser = ({ IDutilisateur, UAI }) => {
		const { loadSchool, loadUserPage, changeView } = this.context
		const { onClose } = this.props

		loadSchool(UAI).then(() => {
			onClose(this.name)
			changeView('users')
			setTimeout(() => {
				loadUserPage(IDutilisateur, true)
			}, 1000)
		})
	}

	render() {
		const { translate, isLightTheme } = this.context
		const { users, onClose, show } = this.props
		const hasUsers = users[0] ? users[0].Statut !== 'OK' : false

		return (
			<Modal className={`is-large  ${!isLightTheme ? 'is-dark' : ''}`} show={show} showClose onClose={() => onClose(this.name)} closeOnBlur>
				<Modal.Card className={'fade-in-bottom'}>
					<Modal.Card.Body>
						<header className="is-flex" style={{ justifyContent: 'space-between' }}>
							<div>
								<Heading size={5}>{translate('modals.specific.find_user.title')}</Heading>
								<Heading subtitle size={5}>
									{hasUsers ? users.length : '0'} {translate('global.results')}
								</Heading>
							</div>
							<Button color={'light'} onClick={() => onClose(this.name)}>
								<Icon icon={'fal fa-times fa-2x'} />
							</Button>
						</header>
						<Hr />
						{hasUsers ? (
							<div className="table-container">
								<Table striped className={`is-hoverable is-narrow ${!isLightTheme ? 'is-dark' : ''}`}>
									<thead>
										<tr>
											<th>{translate('global.school')}</th>
											<th>{translate('global.lastname')}</th>
											<th>{translate('global.firstname')}</th>
											<th>{translate('global.login')}</th>
											<th>{translate('global.level')}</th>
											<th>{translate('global.class')}</th>
											<th>{translate('global.profile')}</th>
											<th>{translate('global.city')}</th>
											<th>{translate('global.disabled')}</th>
										</tr>
									</thead>
									<tbody>
										{users.map((user, key) => {
											return (
												<tr key={key} onClick={() => this.loadUser(user)}>
													<td>{user.Etablissement}</td>
													<th>{user.Nom}</th>
													<td>{user.Prénom}</td>
													<td>{user.Login}</td>
													<td>{user.Niveau}</td>
													<td>{user.Classe}</td>
													<td>{user.Profil}</td>
													<td>{user.Commune}</td>
													<td>{user.Désactivé ? translate('global.yes') : translate('global.no')}</td>
												</tr>
											)
										})}
									</tbody>
								</Table>
							</div>
						) : (
							<p>{translate('modals.specific.find_user.no_results')}</p>
						)}
					</Modal.Card.Body>
				</Modal.Card>
			</Modal>
		)
	}
}
