import React, { FC } from 'react'

interface AddSettingsItemType {
	handleClick: () => void
	href: string
	id: string
}

export const AddSettingsItem: FC<AddSettingsItemType> = ({ handleClick, href, id }) => (
	<a id={id} href={href} onClick={handleClick} className="border p-1 w-full rounded-lg text-center hover:bg-gray-100 transition block">
		+
	</a>
)
