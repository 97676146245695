import React, { Component } from 'react'
import _ from 'lodash'
import GlobalContext from '../../../contexts/GlobalContext'

class MultipleChoiceFilter extends Component {
	static contextType = GlobalContext

	constructor(props, context) {
		super(props)
		this.filterTypes = {
			AND: context.translate('global.and'),
			OR: context.translate('global.or'),
		}

		this.initChoices = { ...props.choices, [null]: true }

		this.state = {
			active: false,
			text: '',
			choices: { ...props.choices, [null]: true },
			filterType: this.filterTypes.OR,
			search: '',
		}

		this.valueGetter = props.valueGetter
	}

	handleChange = keyboardEvent => this.setState({ [keyboardEvent.target.name]: keyboardEvent.target.value })

	afterGuiAttached(params) {
		if (!this.isFilterActive()) {
			let choices = this.props.searchThroughTable()
			if (!_.isEqual(Object.keys(choices), Object.keys(this.state.choices))) {
				this.setState({ choices: { ...choices, [null]: true } })
				this.forceUpdate()
			}
		}
	}

	getModelAsString() {
		return Object.entries(this.state.choices)
			.filter(([option, selected]) => selected === true)
			.map(([option, selected]) => option)
			.join(` ${this.state.filterType} `)
	}

	isFilterActive() {
		return this.state.active
	}

	getSelectedOptions = () => {
		return Object.entries(this.state.choices)
			.filter(([option, selected]) => selected === true)
			.map(([option, selected]) => option)
	}

	doesFilterPass(params) {
		const selectedOptions = this.getSelectedOptions()

		if (selectedOptions[0] === 'null' && this.state.filterType === this.filterTypes.AND) {
			return params.data.Option1 === null
		}

		const filtering = filterWord => {
			let pass = false

			Object.entries(params.node.data).forEach(([column, value]) => {
				if (column.match(/Option/)) {
					value = (value + '')?.toLowerCase()

					if (value === '') {
						value = 'null'
					}

					if (value === filterWord.toString().toLowerCase()) {
						pass = true
					}
				}
			})

			return pass
		}

		return this.state.filterType === this.filterTypes.OR ? selectedOptions.some(filterWord => filtering(filterWord)) : selectedOptions.every(filterWord => filtering(filterWord))
	}

	getModel() {
		const { filterType } = this.state

		if (!this.state.active) {
			return null
		}

		return {
			type: filterType,
			value: this.getSelectedOptions(),
		}
	}

	setModel(model) {
		if (!model) {
			this.resetFilter()
		}
	}

	resetFilter = () => {
		const { choices } = this.state
		for (const option in choices) {
			choices[option] = true
		}

		this.setState(
			state => ({
				active: false,
				choices: state.choices,
				filterType: this.filterTypes.OR,
			}),
			() => this.props.filterChangedCallback()
		)
	}

	setChoices = res => {
		const active = this.isActive(Object.values(res), this.state.filterType)
		this.setState(
			{
				active: active,
				choices: res,
			},
			() => this.props.filterChangedCallback()
		)
	}

	isActive = (array, filterType) => {
		const choices = Object.values(array)
		const bool = filterType === this.filterTypes.OR
		return !choices.every(choice => bool === choice)
	}

	changeAll = () => {
		const res = this.state.choices
		const status = !Object.values(this.state.choices).includes(false)
		for (let choicesTrue in res) {
			res[choicesTrue] = !status
		}
		this.setChoices(res)
	}

	onChange = event => {
		const res = this.state.choices

		res[event.target.id] = !res[event.target.id]

		this.setChoices(res)
	}

	onChangeNotting = event => {
		const res = this.state.choices

		res[null] = !res[null]

		this.setChoices(res)
	}

	render() {
		const { translate } = this.context
		const { OR, AND } = this.filterTypes
		const { choices, filterType } = this.state

		return (
			<div className={'ag-set-filter-list'}>
				<div className="ag-virtual-list-viewport ag-filter-virtual-list-viewport ag-focus-managed">
					<div className="ag-tab-guard ag-tab-guard-top" />
					<div className="ag-virtual-list-container ag-filter-virtual-list-container">
						<div className="ag-mini-filter ag-labeled ag-label-align-left ag-text-field ag-input-field">
							<div ref="eLabel" className="ag-input-field-label ag-label ag-hidden ag-text-field-label" />
							<div ref="eWrapper" className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper" role="presentation">
								<input onChange={this.handleChange} name={'searching'} value={this.state.searching} className="ag-input-field-input ag-text-field-input" type="text" id="ag-8555-input" aria-label="Search filter values" placeholder="Rechercher..." />
							</div>
						</div>

						<div
							className="ag-set-filter-item is-flex"
							style={{
								height: '28px',
								padding: '1rem auto',
								justifyContent: 'space-evenly',
								alignItems: 'middle',
							}}
						>
							<fieldset>
								<label style={{ paddingRight: '0.25rem' }} htmlFor="AND">
									{AND}
								</label>
								<input
									id="AND"
									name="filterType"
									checked={filterType === AND}
									type="radio"
									onChange={() => {
										this.changeAll()
										this.setState(
											{
												filterType: AND,
												active: this.isActive(Object.values(choices), AND),
											},
											() => this.props.filterChangedCallback()
										)
									}}
								/>
							</fieldset>
							<fieldset>
								<label style={{ paddingRight: '0.25rem' }} htmlFor="OR">
									{OR}
								</label>
								<input
									id="OR"
									name="filterType"
									checked={this.state.filterType === OR}
									type="radio"
									onChange={() => {
										this.changeAll()
										this.setState(
											{
												filterType: OR,
												active: this.isActive(Object.values(choices), OR),
											},
											() => this.props.filterChangedCallback()
										)
									}}
								/>
							</fieldset>
						</div>

						<div className="ag-set-filter-item" style={{ height: '28px' }}>
							<div role="presentation" className="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field">
								<div className="ag-input-field-label ag-label ag-checkbox-label">({translate('global.all')})</div>
								<div className={`ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ${!Object.values(choices).includes(false) ? 'ag-checked' : ''}`} role="presentation">
									<input id="all" name="all" onChange={this.changeAll} checked={!Object.values(choices).includes(false)} className="ag-input-field-input ag-checkbox-input" type="checkbox" />
								</div>
							</div>
						</div>

						<div className="ag-set-filter-item" style={{ height: '28px' }}>
							<div role="presentation" className="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field">
								<div className="ag-input-field-label ag-label ag-checkbox-label">({translate('global.empty')})</div>
								<div className={`ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ${choices[null] === true ? 'ag-checked' : ''}`} role="presentation">
									<input id="null" name="nothing" onChange={this.onChangeNotting} checked={choices[null]} className="ag-input-field-input ag-checkbox-input" type="checkbox" />
								</div>
							</div>
						</div>

						{Object.keys(this.state.choices)
							.filter(opt => opt.match(new RegExp(this.state.searching, 'gi')))
							.sort()
							.map(
								key =>
									key !== 'null' && (
										<div key={key} className="ag-set-filter-item" style={{ height: '28px' }}>
											<div role="presentation" className="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field">
												<div className="ag-input-field-label ag-label ag-checkbox-label">{key === 'null' ? '(Vide)' : key}</div>
												<div className={`ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ${choices[key] ? 'ag-checked' : ''}`} role="presentation">
													<input name={key} id={key} checked={choices[key]} onChange={this.onChange} className="ag-input-field-input ag-checkbox-input" type="checkbox" />
												</div>
											</div>
										</div>
									)
							)}
					</div>
				</div>
			</div>
		)
	}
}

export default MultipleChoiceFilter
