import React, { FC, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import GlobalContext from '../../../contexts/GlobalContext'
// @ts-ignore
import { Button, Columns, Form, Heading, Image, Modal, Tag } from 'react-bulma-components'
import Hr from '../../utils/Hr'
import { Formik } from 'formik'
import { Schools } from './Schools'
import Query from '../../../tools/Query'
import { SchoolPlatform } from '../../types'
import { PlatformSettings } from '../../settings/global/GlobalPlatforms/types'

interface Props {
	onClose: (name: string) => void
	show: boolean
	task: {
		eventId: string
		enable: 0 | 1
		eventName: string
		intervalField: 'DAY' | 'WEEK' | 'MONTH' | 'YEAR'
		startDate: string
		platformId: string[]
		procedureParameters: {
			arrayUai: string[]
			arrayPlatforms: string[]
		}
	}
	fetchTasks: () => void
}

export function setFormatDateByFrequency(e: React.ChangeEvent<HTMLSelectElement>, handleChange: Function, setFieldValue: Function) {
	if (e.target.value === 'IMMEDIATE') {
		const currentDate = new Date()
		currentDate.setHours(currentDate.getHours() + 2)
		setFieldValue('startDate', currentDate.toISOString().slice(0, 16))
	}
	handleChange(e)
}

export const ScheduledTaskEdit: FC<Props> = ({ onClose, show, task, fetchTasks }) => {
	const NAME = 'editTask'
	const context = useContext(GlobalContext)
	const { translate, isLightTheme, school, addToast } = context
	const [loading, setLoading] = useState<boolean>(false)
	const [uais, setUais] = useState<string[]>([...task.procedureParameters.arrayUai])
	const [platforms, setPlatforms] = useState<PlatformSettings[]>([])

	const fetchPlatforms = useCallback(() => {
		Query.getGlobalSettings(context, 'platforms')
			.then(fetchedPlatforms => {
				if (fetchedPlatforms) setPlatforms(fetchedPlatforms as PlatformSettings[])
				setLoading(false)
			})
			.catch(err => {
				setLoading(false)
				addToast(translate('global.errors.occurred'), { appearance: 'error' })
			})
	}, [addToast, context, translate])

	useEffect(() => {
		fetchPlatforms()
	}, [])

	const editScheduledTask = async (values: Record<string, any>) => {
		setLoading(true)
		try {
			const res = await Query.editTokenScheduledTask(context, {
				eventId: task.eventId,
				eventName: values.label,
				platformId: values.platformId,
				startDate: values.startDate.replace('T', ' '),
				intervalField: values.frequency,
				uais: uais.includes('Tous') ? ['all'] : uais,
				enable: values.enable,
			})
			if (res) {
				setLoading(false)
				onClose(values.name)
				fetchTasks()
			}
		} catch (e) {
			console.error(e)
		}
		setLoading(false)
		addToast(translate('settings.update_success'))
	}

	return (
		<Modal className={`is-large ${!isLightTheme && 'is-dark'}`} show={show} showClose onClose={() => onClose(NAME)} closeOnBlur>
			<Modal.Card className={'fade-in-bottom'}>
				<Modal.Card.Body>
					<Formik
						onSubmit={editScheduledTask}
						initialValues={{
							label: task.eventName,
							frequency: task.intervalField,
							startDate: task.startDate,
							platformId: task.procedureParameters.arrayPlatforms[0],
							enable: task.enable,
						}}
					>
						{({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
							<form onSubmit={handleSubmit}>
								<header className="is-flex" style={{ justifyContent: 'space-between' }}>
									<div>
										<Heading size={5}>Modifier une purge planifiée</Heading>
									</div>
								</header>
								<Hr />

								<Form.Field>
									<Form.Label>Libellé</Form.Label>
									<Form.Control>
										<Form.Input name={'label'} onChange={handleChange} value={values.label} required />
									</Form.Control>
								</Form.Field>

								<Form.Field>
									<Form.Label>
										Statut <span className={'text-slate-400'}>(Active/Inactive)</span>
									</Form.Label>
									<input id={`showTokenStatus`} type="checkbox" className="ml-6 switch is-rounded is-success is-small" name="enable" checked={Boolean(values.enable)} onChange={() => setFieldValue('enable', values.enable === 1 ? 0 : 1)} />
									<label htmlFor={`showTokenStatus`} />
								</Form.Field>

								<Form.Field>
									<Form.Label>Fréquence</Form.Label>
									<Form.Control>
										<Form.Select name={'frequency'} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFormatDateByFrequency(e, handleChange, setFieldValue)} value={values.frequency} required>
											<option value={'IMMEDIATE'}>Immediate</option>
											<option value={'DAY'}>Tous les jours</option>
											<option value={'WEEK'}>Toutes les semaines</option>
											<option value={'MONTH'}>Tous les mois</option>
											<option value={'YEAR'}>Tous les ans</option>
										</Form.Select>
									</Form.Control>
								</Form.Field>

								<Form.Field>
									<Form.Label>Première exécution</Form.Label>
									<Form.Control>
										<Form.Input type={'datetime-local'} name={'startDate'} onChange={handleChange} value={values.startDate} required />
									</Form.Control>
								</Form.Field>

								<Form.Field>
									<Form.Label>Plateforme</Form.Label>
									<Form.Control>
										<Form.Select name={'platformId'} onChange={handleChange} value={values.platformId} required>
											<option selected></option>
											{platforms.map(platform => {
												return <option value={platform.idPlateforme}>{platform.idPlateforme}</option>
											})}
										</Form.Select>
									</Form.Control>
								</Form.Field>

								<Form.Field>
									<Form.Label>UAIS</Form.Label>
									<div className="field is-grouped is-grouped-multiline my-4">
										{uais.map(uai => {
											return (
												<Form.Control>
													<Tag.Group className="has-addons">
														<Tag color={'dark'}>{uai === 'all' ? 'Tous les établissements' : uai}</Tag>
														<Tag
															remove
															className="cursor-pointer"
															onClick={() => {
																uais.splice(uais.indexOf(uai), 1)
																setUais([...uais])
															}}
														/>
													</Tag.Group>
												</Form.Control>
											)
										})}
									</div>

									<Schools
										addSchool={uai => {
											if (!uais.includes(uai)) {
												setUais([...uais, uai])
											}
										}}
									/>
								</Form.Field>

								<div className="is-flex" style={{ justifyContent: 'space-between' }}>
									<Button
										color={'danger'}
										type={'reset'}
										onClick={() => {
											onClose(NAME)
										}}
									>
										{translate('global.cancel')}
									</Button>
									<Button disabled={uais.length < 1} color={'primary'} type={'submit'} loading={loading} data-tooltip={uais.length < 1 ? 'Choisir un uai' : ' Mettre à jour'}>
										{translate('global.update')}
									</Button>
								</div>
							</form>
						)}
					</Formik>
				</Modal.Card.Body>
			</Modal.Card>
		</Modal>
	)
}
