const AG_GRID_LOCALE_EN = {
	// Set Filter
	selectAll: '(Select all)',
	selectAllSearchResults: '(Select all results)',
	searchOoo: 'Search...',
	blanks: '(Empty)',
	noMatches: 'No results',

	// Number Filter & Text Filter
	filterOoo: 'Filter...',
	equals: 'Equals to',
	notEqual: 'Not equals to',
	empty: 'Select one',

	// Number Filter
	lessThan: 'Less than',
	greaterThan: 'Greater than',
	lessThanOrEqual: 'Less or equal',
	greaterThanOrEqual: 'Greater or equal',
	inRange: 'In range',
	inRangeStart: 'from',
	inRangeEnd: 'dto',

	// Text Filter
	contains: 'Contains',
	notContains: 'Not contains',
	startsWith: 'Starts with',
	endsWith: 'Ends with',

	// Date Filter
	dateFormatOoo: 'yyyy-mm-dd',

	// Filter Conditions
	andCondition: 'AND',
	orCondition: 'OR',

	// Filter Buttons
	applyFilter: 'Apply',
	resetFilter: 'Reset',
	clearFilter: 'Clear',
	cancelFilter: 'Cancel',

	// Filter Titles
	textFilter: 'Text filter',
	numberFilter: 'Number filter',
	dateFilter: 'Date filter',
	setFilter: 'Set filter',

	// Side Bar
	columns: 'Columns',
	filters: 'Filter',

	// columns tool panel
	pivotMode: 'Pivot mode',
	groups: 'Groups',
	rowGroupColumnsEmptyMessage: 'Drag here to define groups',
	values: 'Values',
	valueColumnsEmptyMessage: 'Drag here to aggregate',
	pivots: 'Colums name',
	pivotColumnsEmptyMessage: 'Drag here to define column name',

	// Header of the Default Group Column
	group: 'Group',

	// Other
	loadingOoo: 'Loading...',
	noRowsToShow: 'No establishment selected',
	enabled: 'Enable',
	rows: 'users',

	// Menu
	pinColumn: 'Pin column',
	pinLeft: 'Pin to left',
	pinRight: 'Pin to right',
	noPin: 'Unpin',
	valueAggregation: 'Value Aggregation',
	autosizeThiscolumn: 'Autosize this column',
	autosizeAllColumns: 'Autosize all columns',
	groupBy: 'Group by',
	ungroupBy: 'Ungroup by',
	resetColumns: 'Reset all columns',
	expandAll: 'Expand all',
	collapseAll: 'Collapse all',
	copy: 'Copy',
	ctrlC: 'Ctrl+C',
	copyWithHeaders: 'Copy with headers',
	paste: 'Paste',
	ctrlV: 'Ctrl+V',
	export: 'Export',
	csvExport: 'CSV export',
	excelExport: 'Excel export (.xlsx)',
	excelXmlExport: 'Excel export (.xml)',

	sum: 'Sum',
	min: 'Min',
	max: 'Max',
	none: 'None',
	count: 'Count',
	avg: 'Average',
	filteredRows: 'Filtered',
	selectedRows: 'Selected',
	totalRows: 'Total users',
	totalAndFilteredRows: 'Users',
	more: 'Plus',
	to: 'To',
	of: 'of',
	page: 'Page',
	nextPage: 'Next page',
	lastPage: 'Last page',
	firstPage: 'First page',
	previousPage: 'Previous page',

	// Enterprise Menu (Charts)
	pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
	pivotChart: 'Pivot Chart',
	chartRange: 'Chart Range',

	columnChart: 'Column',
	groupedColumn: 'Grouped',
	stackedColumn: 'Stacked',
	normalizedColumn: '100% Stacked',

	barChart: 'Bar',
	groupedBar: 'Grouped',
	stackedBar: 'Stacked',
	normalizedBar: '100% Stacked',

	pieChart: 'Pie',
	pie: 'Pie',
	doughnut: 'Doghnut',

	line: 'Line',

	xyChart: 'X Y (Scatter)',
	scatter: 'Scatter',
	bubble: 'Bubble',

	areaChart: 'Area',
	area: 'Surface',
	stackedArea: 'Stacked',
	normalizedArea: '100% Stacked',

	histogramChart: 'Histogram',

	// Charts
	pivotChartTitle: 'Pivot Chart',
	rangeChartTitle: 'Range Chart',
	settings: 'Settings',
	data: 'Data',
	format: 'Format',
	categories: 'Categories',
	defaultCategory: '(None)',
	series: 'Series',
	xyValues: 'X Y Values',
	paired: 'Paired Mode',
	axis: 'Axis',
	navigator: 'Navigator',
	color: 'Color',
	thickness: 'Thickness',
	xType: 'X Type',
	automatic: 'Automatic',
	category: 'Category',
	number: 'Number',
	time: 'Time',
	xRotation: 'X Rotation',
	yRotation: 'Y Rotation',
	ticks: 'Ticks',
	width: 'Width',
	height: 'Height',
	length: 'Length',
	padding: 'Padding',
	spacing: 'Spacing',
	chart: 'Chart',
	title: 'Title',
	titlePlaceholder: 'Chart title - double click to edit',
	background: 'Background',
	font: 'Font',
	top: 'Top',
	right: 'Right',
	bottom: 'Bottom',
	left: 'Left',
	labels: 'Labels',
	size: 'Size',
	minSize: 'Minimum Size',
	maxSize: 'Maximum Size',
	legend: 'Legend',
	position: 'Position',
	markerSize: 'Marker Size',
	markerStroke: 'Marker Stroke',
	markerPadding: 'Marker Padding',
	itemSpacing: 'Item Spacing',
	itemPaddingX: 'Item Padding X',
	itemPaddingY: 'Item Padding Y',
	layoutHorizontalSpacing: 'Horizontal Spacing',
	layoutVerticalSpacing: 'Vertical Spacing',
	strokeWidth: 'Stroke Width',
	offset: 'Offset',
	offsets: 'Offsets',
	tooltips: 'Tooltips',
	callout: 'Callout',
	markers: 'Markers',
	shadow: 'Shadow',
	blur: 'Blur',
	xOffset: 'X Offset',
	yOffset: 'Y Offset',
	lineWidth: 'Line Width',
	normal: 'Normal',
	bold: 'Bold',
	italic: 'Italic',
	boldItalic: 'Bold Italic',
	predefined: 'Predefined',
	fillOpacity: 'Fill Opacity',
	strokeOpacity: 'Line Opacity',
	histogramBinCount: 'Bin count',
	columnGroup: 'Column',
	barGroup: 'Bar',
	pieGroup: 'Pie',
	lineGroup: 'Line',
	scatterGroup: 'X Y (Scatter)',
	areaGroup: 'Area',
	histogramGroup: 'Histogram',
	groupedColumnTooltip: 'Grouped',
	stackedColumnTooltip: 'Stacked',
	normalizedColumnTooltip: '100% Stacked',
	groupedBarTooltip: 'Grouped',
	stackedBarTooltip: 'Stacked',
	normalizedBarTooltip: '100% Stacked',
	pieTooltip: 'Pie',
	doughnutTooltip: 'Doughnut',
	lineTooltip: 'Line',
	groupedAreaTooltip: 'Area',
	stackedAreaTooltip: 'Stacked',
	normalizedAreaTooltip: '100% Stacked',
	scatterTooltip: 'Scatter',
	bubbleTooltip: 'Bubble',
	histogramTooltip: 'Histogram',
	noDataToChart: 'No data available to be charted.',
	pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
}

export default AG_GRID_LOCALE_EN
