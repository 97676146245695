import React, { useContext, useState } from 'react';
import GlobalContext from '../../../contexts/GlobalContext';
import Icon from '../../utils/Icon';
// @ts-ignore
import { Button, Columns, Form, Heading, Image, Modal } from 'react-bulma-components'

/**
 * Champs similaire à celui de la navbar, pour filtrer parmi les établissements disponible.
 * @param addSchool
 * @return {JSX.Element}
 * @constructor
 */

type Application = {

	applicationCodeRequired: boolean
	applicationCodeYesNo: boolean
	boLogin: boolean | null
	boPassword: boolean | null
	boUrl: boolean | null
	commonCode: boolean | null
	connectDiffPassword: boolean
	exportFile: boolean
	exportType: string
	forExport: boolean
	idApplication: string
	name: string
	order: number
	platformId: string
	urlOnLine: boolean
	urlSchemeCodeRequired: boolean
	urlSchemeCodeYesNo: boolean
	urlSchemeLoginRequired: boolean
	urlSchemePwdRequired: boolean
	urls: Array<{
		WindowsAppName: string
		appStore: string
		dmgLink: string
		exeLink: string
		picturePath: string
		playStore: string
		urlClean: string
		urlLine: string
		urlOpen: string
		urlScheme: string
	}>

}

type addAppType = {
	addApp: (nom: string) => void
	dataApp: Application[]
}

export function Apps({ addApp, dataApp }: addAppType ) {

  const [value, setValue] = useState('');

  function filterApp() {
    const escapedValue = (value || '').replace(/[()]/gi, '');
    const regex = new RegExp(escapedValue, 'gi');
    let filtered = (dataApp || []).filter((app: any) => `${app.idApplication}`.match(regex)) as any[];
    filtered.unshift('all')

    return filtered.length === 0 ? (
		<p className={'dropdown-item has-text-danger is-size-7'}>Aucune matière disponible</p>
	) : (
      filtered.map((app, key) => (
		  <a
			  href={'/'}
			  key={key}
			  onClick={event => {
				  event.preventDefault()
				  setValue('')
				  addApp(app.idApplication)
			  }}
			  className={'dropdown-item dropdown-school is-size-7 overflow-ellipsis'}
		  >
			  <p>{app.name}</p>
		  </a>
	  ))
	);
  }

	return (
		<>
			<Form.Field className='w-full'>
			  <Form.Control className={'is-expanded'} iconLeft size={'small'}>
				  <Form.Input autoComplete={'off'} size='small' fullwidth placeholder="Rechercher une application"
							  value={value} onChange={(e: any) => setValue(e.target.value)} name={'app'} />
				  <Icon icon={'fad fa-folder'} className={'is-small is-left'} />
			  </Form.Control>
		  </Form.Field>
		  <div className='max-h-64 overflow-y-auto overflow-x-hidden overflow-ellipsis'>
			  {filterApp()}
		  </div>
	  </>
  );
}
